// import React from "react";
// import "../Home/Home.scss";
// import "reactjs-popup/dist/index.css";
// import BannerSection from "../../components/BannerSection/BannerSection";
// import KeepUp from "../../components/KeepUp/KeepUp";
// import ContactSection from "../../components/ContactSection/ContactSection";
// import Products from "../../components/Products/Products";
// import Requirements from "../../components/Requirements/Requirements";
// import ToolsProducts from "../../components/ToolsProducts/ToolsProducts";
// import DealOfTheDayProducts from "../../components/Products/DealOfTheDayProducts/DealOfTheDayProducts";
// import DealOfDayToolsProducts from "../../components/ToolsProducts/DealOfDayToolsProducts";
// import Chatbot from "../../components/ChatBot"

// function LandingPageHome() {
//   return (
//     <>
//       {/* <Chatbot /> */}

//       <BannerSection />
//       <Products />
//       <DealOfTheDayProducts />
//       <Requirements />
//       <ToolsProducts />
//       <DealOfDayToolsProducts />
//       <KeepUp />
//       <ContactSection />
//     </>
//   );
// }

// export default LandingPageHome;

import React, { useState, useEffect } from "react";
import "../Home/Home.scss";
import "reactjs-popup/dist/index.css";
import BannerSection from "../../components/BannerSection/BannerSection";
import KeepUp from "../../components/KeepUp/KeepUp";
import ContactSection from "../../components/ContactSection/ContactSection";
import Products from "../../components/Products/Products";
import Requirements from "../../components/Requirements/Requirements";
import ToolsProducts from "../../components/ToolsProducts/ToolsProducts";
import DealOfTheDayProducts from "../../components/Products/DealOfTheDayProducts/DealOfTheDayProducts";
import DealOfDayToolsProducts from "../../components/ToolsProducts/DealOfDayToolsProducts";
import Chatbot from "../../components/ChatBot";
import { useNavigate } from "react-router-dom";
import HeroBanner from "../../components/BannerSection/HeroBanner";
import { Helmet } from "react-helmet";

function LandingPageHome() {
  const navigate = useNavigate();
  // const [previousRefereshToken, setPreviousRefereshToken] = useState(null);
  const refreshToken = localStorage.getItem("refreshToken");

  // useEffect(() => {

  //   const refreshTokenDelete = () => {
  //     if (localStorage.getItem("refreshToken") === previousRefereshToken) {
  //       localStorage.removeItem("refreshToken");
  //       // navigate("/loginpage");
  //       console.log("refresh token deleted");
  //     }
  //   };

  //   if (localStorage.getItem("refreshToken") !== "undefined") {
  //     const intervalId = setInterval(() => {
  //       setPreviousRefereshToken(localStorage.getItem("refreshToken"));
  //     }, 14000);

  //     // const timeoutId = setTimeout(refreshTokenDelete, 15000);

  //     // Cleanup function to clear intervals and timeouts
  //     return () => {
  //       clearInterval(intervalId);
  //       // clearTimeout(timeoutId);
  //     };
  //   }
  // }, [navigate, previousRefereshToken, refreshToken]);

  if (!refreshToken) {
    setTimeout(6000);

    // navigate("/loginpage");
    // return;
  }
  return (
    <>
      <Helmet>
        <title>Fasteners Manufacturers In India</title>
        <meta
          name="description"
          content="Leading fasteners manufacturers in India, offering a wide range of high-quality bolts, nuts, screws, and industrial fasteners for various applications."
        />
      </Helmet>
      {/* <Chatbot /> */}
      {/* <BannerSection /> */}
      <HeroBanner />
      <Products />
      <DealOfTheDayProducts />
      <Requirements />
      <ToolsProducts />
      <DealOfDayToolsProducts />
      <KeepUp />
      <ContactSection />
    </>
  );
}

export default LandingPageHome;
