import React from 'react'
import FastanersCategories from './FastanersCategories/FastanersCategories'
import FastanersProducts from './FastanersProducts/FastanersProducts'

function Fastaners() {
  return (
    <div className='px-2r py-4'>
        <div className="row">
            <div className="col-md-3">
                <FastanersCategories />
            </div>
            <div className="col-md-9">
              <FastanersProducts />
            </div>
        </div>
    </div>
  )
}

export default Fastaners