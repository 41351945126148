import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { API_BASE_URL } from "../../utils";
import axios from "axios";
import { useAuthHeaders } from "../../components/Token";

function Coupon({ setPlaceOrder, placeOrder, state }) {
  const [show, setShow] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const headers = useAuthHeaders();

  const subTotal = placeOrder?.cartProductsTotals?.find(
    (item) => item?.type === "Sub Total"
  );

  const shipping = placeOrder?.cartProductsTotals?.find(
    (item) => item?.type === "Shipping"
  );

  const handleCoupen = (e) => {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/user/coupon`,
        {
          couponCode: couponCode,
          cartItems: placeOrder?.products,
          // SubtotalAmount: subTotal?.value,
          shippingAmount: shipping?.value,
          stateName: state,
        },
        {
          headers,
        }
      )
      .then((res) => {
        const updatedCartProductsTotals = res?.data?.cartProductsTotals;

        setPlaceOrder((preValue) => ({
          ...preValue,
          cartProductsTotals: updatedCartProductsTotals,
        }));

        setCouponCode("");
        setShow(false);

        Swal.fire({
          icon: "success",
          title: "Coupen Apply SuccessFully",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log("error in coupen", error?.response?.data?.message);
        setCouponCode("");

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error?.response?.data?.message,
        });
      });
  };

  return (
    <div>
      <div onClick={handleShow} className="coupon-button">
        Have any coupon?
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Apply Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="p-2 w-100"
            placeholder="Enter Coupon Code"
            value={couponCode}
            required
            onChange={(e) => setCouponCode(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCoupen}>
            Apply Coupon
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Coupon;
