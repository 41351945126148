// import React, { useEffect, useState } from "react";
// import "../CheckOut/CheckOut.scss";
// import "../../Common/Common.scss";
// import Form from "react-bootstrap/Form";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils";
// import { useAuthHeaders } from "../../components/Token";
// import Swal from "sweetalert2/dist/sweetalert2.js";
// import ChoosePrevAddress from "./ChoosePrevAddress";

// function Billing({
//   firstName,
//   setFirstNumber,
//   lastName,
//   setLastNumber,
//   streetAdderss,
//   setStreetAdderss,
//   setCountery,
//   countery,
//   setState,
//   phone,
//   setphone,
//   setEmail,
//   setPinCode,
//   pinCode,
//   shipToDifferentAddress,
//   setShipToDifferentAddress,
//   email,
//   placeOrder,
//   setPlaceOrder,
//   setSelectDistrict,
//   setAlternateFirstNumber,
//   alternateLastName,
//   setAlternateLastNumber,
//   setCompany,
//   alternateAddress,
//   setAlternateAddress,
//   alternateFirstName,
//   setAlternateCountery,
//   setAlternateState,
//   setalternateDistrictSelect,
//   setalternatePinCode,

//   alternateCountery,
//   alternateState,
//   alternateDistrictSelect,
//   alternatePinCode,
//   company,
//   chooseAddress,
//   districtSelect,
// }) {
//   const headers = useAuthHeaders();
//   const [zone, setZone] = useState([]);
//   const [selectZone, setSelectZone] = useState("");
//   const [zoneRegion, setZoneRegion] = useState([]);
//   const [selectZoneRegion, setSelectZoneRegion] = useState("");

//   const [zoneRegionAlternate, setZoneRegionAlternate] = useState([]);

//   const [selectedAlternateZoneId, setSelectedAlternateZoneId] = useState("");
//   const [selectedAlternateZoneRegeionId, setSelectedAlternateZoneRegeionId] =
//     useState("");

//   const [showForm, setShowForm] = useState(true);
//   const [showFormAlternative, setShowFormAlternative] = useState(true);

//   const [shippingCountery, setShippingCountery] = useState("");

//   // useEffect(() => {
//   //   axios
//   //     .get(`${API_BASE_URL}/user/getallzones`, { headers })
//   //     .then((response) => {
//   //       setZone(response?.data?.Zones);
//   //     })
//   //     .catch((error) => {
//   //       console.error("Error fetching subcategory data:", error);
//   //     });
//   // }, []);
//   useEffect(() => {
//     const fetchZones = async () => {
//       try {
//         const response = await axios.get(`${API_BASE_URL}/user/getallzones`, { headers });
//         setZone(response?.data?.Zones);
//       } catch (error) {
//         console.error("Error fetching zone data:", error);
//       }
//     };

//     fetchZones();
//   }, []);

//   const handleZoneRegeion = (e) => {
//     e.preventDefault();

//     const getZoneId = e.target.value;
//     setSelectZone(getZoneId);

//     const findZoneName = zone.find((item) => item._id === getZoneId);

//     if (findZoneName) {
//       const countryName = findZoneName?.zonename;
//       setCountery(countryName);
//       setShippingCountery(countryName);
//     } else {
//       console.log("No matching zone found for ID: ", getZoneId);
//     }

//     axios
//       .get(`${API_BASE_URL}/user/shipping/getoneregionbyzone/${getZoneId}`, {
//         headers,
//       })
//       .then((response) => {
//         setZoneRegion(response?.data?.zoneRegionNames);
//         // console.log("getoneregionbyzone", response.data.zoneRegionNames);
//       })
//       .catch((error) => {
//         console.error("Error fetching subcategory data:", error);
//       });
//   };

//   // ```````````````````````````````````

//   const handleZoneRegeionAlternateState = (e) => {
//     e.preventDefault();

//     const getZoneId = e.target.value;
//     setSelectedAlternateZoneId(getZoneId);

//     const findZoneName = zone.find((item) => item?._id === getZoneId);

//     if (findZoneName) {
//       const countryName = findZoneName.zonename;
//       setAlternateCountery(countryName);
//       setShippingCountery(countryName);
//     } else {
//       console.log("No matching zone found for ID: ", getZoneId);
//     }

//     axios
//       .get(`${API_BASE_URL}/user/shipping/getoneregionbyzone/${getZoneId}`, {
//         headers,
//       })
//       .then((response) => {
//         setZoneRegionAlternate(response?.data?.zoneRegionNames);
//       })
//       .catch((error) => {
//         console.error("Error fetching subcategory data:", error);
//       });
//   };

//   const handleDistrictAlternateState = (e) => {
//     e.preventDefault();

//     const getState = e.target.value;

//     setAlternateState(getState);
//     // setShippingState(getState);

//     handleShipping(getState);
//     setSelectedAlternateZoneRegeionId(getState);
//   };

//   const handleSelectState = (e) => {
//     e.preventDefault();

//     const StateName = e.target.value;
//     setSelectZoneRegion(StateName);
//     setState(StateName);

//     handleShipping(StateName);
//   };

//   const handleShipping = (StateName) => {
//     axios
//       .post(
//         `${API_BASE_URL}/user/order/shippingamountcalculation`,
//         {
//           cartItems: placeOrder?.products?.map((item) => ({
//             productId: item.productId,
//             quantity: item.quantity,
//           })),

//           totalWeight: placeOrder?.cartproductweight,

//           countryname: shippingCountery,
//           statename: StateName,
//         },
//         {
//           headers,
//         }
//       )
//       .then((response) => {
//         const updatedCartProductsTotals = response.data.cartProductsTotals;
//         // console.log("updatedCartProductsTotals", updatedCartProductsTotals);

//         setPlaceOrder((prevCartItems) => ({
//           ...prevCartItems,
//           cartProductsTotals: updatedCartProductsTotals,
//         }));
//       })
//       .catch((error) => {
//         console.error("Error fetching subcategory data:", error);
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: error.response.data.message,
//         });
//       });
//   };

//   const handleAddressSelection = (address) => {
//     console.log("address in select", address.shippingInfo);
//     setShowForm(false);
//     setFirstNumber(address?.shippingInfo?.firstname);
//     setLastNumber(address?.shippingInfo?.lastname);
//     setStreetAdderss(address?.shippingInfo?.streetaddress);
//     setCountery(address?.shippingInfo?.country);
//     setState(address?.shippingInfo?.state);
//     setPinCode(address?.shippingInfo?.postalCode);
//     setphone(address?.shippingInfo?.phone);
//     setEmail(address?.shippingInfo?.email);
//     setSelectDistrict(address?.shippingInfo?.district);
//   };

//   const handleAlternativAddressSelection = (addtess) => {
//     // console.log("addtess in Alternativ", addtess);
//     setShowFormAlternative(false);
//     setAlternateFirstNumber(addtess?.shippingInfo?.firstname);
//     setAlternateLastNumber(addtess?.shippingInfo?.lastname);
//     setAlternateAddress(addtess?.shippingInfo?.streetaddress);
//     setalternateDistrictSelect(addtess?.shippingInfo?.district);
//     setalternatePinCode(addtess?.shippingInfo?.postalCode);
//   };

//   return (
//     <div className="col-lg-6 billing-inner">
//       <>
//         <h4>Billing Details</h4>

//         <div>
//           <div className="d-flex justify-content-between py-4">
//             <input
//               type="text"
//               name="name"
//               id="name"
//               placeholder="First name"
//               className="input"
//               style={{ marginRight: "10px" }}
//               value={firstName}
//               onChange={(e) => setFirstNumber(e.target.value)}
//               required
//             />

//             <input
//               type="text"
//               name="name"
//               id="name"
//               placeholder="Last name"
//               className="input"
//               value={lastName}
//               onChange={(e) => setLastNumber(e.target.value)}
//               required
//             />
//           </div>

//           <strong>Address</strong>

//           <input
//             type="text"
//             name="address"
//             id="address"
//             placeholder="House number and street name"
//             className="my-2 input"
//             value={streetAdderss}
//             onChange={(e) => setStreetAdderss(e.target.value)}
//             required
//           />

//           {/* choose address */}
//           <>
//             {chooseAddress.map((item, index) => {
//               if (item?.shippingInfo && showForm && index === 0) {
//                 return (
//                   <Form key={item?._id}>
//                     {["radio"].map((type) => (
//                       <div key={`inline-${type}`} className="mb-3">
//                         <Form.Check
//                           inline
//                           // label="Choose Previous Address"
//                           label="Choose Previous Billing Address "
//                           name="group1"
//                           type={type}
//                           id={`inline-${type}-1`}
//                           onClick={() => handleAddressSelection(item)}
//                         />

//                         <br />

//                         <Form.Check
//                           inline
//                           label="Add New Address"
//                           name="group1"
//                           className="text-danger"
//                           type={type}
//                           id={`inline-${type}-2`}
//                           onClick={() => setShowForm(false)}
//                         />
//                       </div>
//                     ))}
//                   </Form>
//                 );
//               } else {
//                 return null;
//               }
//             })}
//           </>
//           {/* choose address */}

//           <p className="py-1 m-0">
//             <Form.Select
//               aria-label="Default select example"
//               className="my-2"
//               value={selectZone}
//               onChange={handleZoneRegeion}
//               required
//             >
//               <option value="">Select a Country</option>
//               {Array.isArray(zone) ? (
//                 zone.map((zone) => (
//                   <option key={zone?._id} value={zone?._id}>
//                     {zone?.zonename}
//                   </option>
//                 ))
//               ) : (
//                 <option value="">Loading...</option>
//               )}
//             </Form.Select>
//           </p>

//           <p className="py-1 m-0">
//             <Form.Select
//               aria-label="Default select example"
//               className="my-2"
//               value={selectZoneRegion}
//               onChange={handleSelectState}
//               required
//             >
//               <option value="">Select a State</option>
//               {Array.isArray(zoneRegion) ? (
//                 zoneRegion?.map((zoneRegion) => (
//                   <option key={zoneRegion} value={zoneRegion}>
//                     {zoneRegion}
//                   </option>
//                 ))
//               ) : (
//                 <option value="">Loading...</option>
//               )}
//             </Form.Select>
//           </p>

//           <p className="py-1 m-0">
//             <input
//               type="text"
//               placeholder="Enter Your District Name"
//               className="my-2 input"
//               value={districtSelect}
//               onChange={(e) => setSelectDistrict(e.target.value)}
//             />
//           </p>

//           <p className="py-1 m-0">
//             <input
//               type="number"
//               placeholder="Enter Your Pincode"
//               className="my-2 input"
//               value={pinCode}
//               onChange={(e) => setPinCode(e.target.value)}
//             />
//           </p>

//           <p className="py-2 m-0">
//             <label htmlFor="billing_country">Phone</label>
//             <input
//               type="text"
//               name="number"
//               id="number"
//               className="my-2 input"
//               value={phone}
//               onChange={(e) => setphone(e.target.value)}
//               required
//             />
//           </p>

//           <p className="m-0">
//             <label htmlFor="billing_country">Email address</label>
//             <input
//               type="text"
//               name="email"
//               id="email"
//               className="my-2 input"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//           </p>
//         </div>

//         <form action="">
//           <div className="diff-address-header d-flex  justify-content-between my-2 fs-5">
//             <label htmlFor="diffcheck" className="fw-bold">
//               Ship to a different address?
//             </label>

//             <input
//               type="checkbox"
//               id="diffcheck"
//               className="diff-address-header-input"
//               value={shipToDifferentAddress}
//               onChange={() =>
//                 setShipToDifferentAddress(!shipToDifferentAddress)
//               }
//               checked={shipToDifferentAddress}
//               required
//             />
//           </div>

//           <div
//             className={`content ${shipToDifferentAddress ? "show" : "hide"}`}
//           >
//             <>
//               <div className="d-flex justify-content-between">
//                 <input
//                   type="text"
//                   name="name"
//                   id="name"
//                   placeholder="First name"
//                   className="input"
//                   style={{ marginRight: "10px" }}
//                   value={alternateFirstName}
//                   onChange={(e) => setAlternateFirstNumber(e.target.value)}
//                 />

//                 <input
//                   type="text"
//                   name="name"
//                   id="name"
//                   placeholder="Last name"
//                   className="input"
//                   value={alternateLastName}
//                   onChange={(e) => setAlternateLastNumber(e.target.value)}
//                 />
//               </div>

//               <input
//                 type="text"
//                 name="name"
//                 id="name"
//                 placeholder="Company name (optional)"
//                 className="input my-4"
//                 value={company}
//                 onChange={(e) => setCompany(e.target.value)}
//               />

//               <p className="m-0 py-2">
//                 <label htmlFor="billing_country">Country / Region</label>
//               </p>

//               <strong>Street address</strong>

//               <input
//                 type="text"
//                 name="address"
//                 id="address"
//                 placeholder="House number and street name"
//                 className="my-2 input"
//                 value={alternateAddress}
//                 onChange={(e) => setAlternateAddress(e.target.value)}
//               />

//               {/* choose address */}
//               <>
//                 {chooseAddress.map((item, index) => {
//                   if (
//                     item.alternateAddress &&
//                     showFormAlternative &&
//                     index === 0
//                   ) {
//                     return (
//                       <Form key={item._id}>
//                         {["radio"].map((type) => (
//                           <div key={`inline-${type}`} className="mb-3">
//                             <Form.Check
//                               inline
//                               label="Choose Previous Address"
//                               // label="Choose Previous Billing Address"
//                               name="group1"
//                               type={type}
//                               id={`inline-${type}-1`}
//                               onClick={() =>
//                                 handleAlternativAddressSelection(item)
//                               }
//                             />

//                             <br />

//                             <Form.Check
//                               inline
//                               label="Add New Address"
//                               name="group1"
//                               className="text-danger"
//                               type={type}
//                               id={`inline-${type}-2`}
//                               onClick={() => setShowFormAlternative(false)}
//                             />
//                           </div>
//                         ))}
//                       </Form>
//                     );
//                   } else {
//                     return null;
//                   }
//                 })}
//               </>
//               {/* choose address */}

//               <p className="py-1 m-0">
//                 <Form.Select
//                   aria-label="Default select example"
//                   className="my-2"
//                   value={selectedAlternateZoneId}
//                   onChange={handleZoneRegeionAlternateState}
//                   required
//                 >
//                   <option value="1232">Select a Country</option>
//                   {Array.isArray(zone) ? (
//                     zone.map((zone) => (
//                       <option key={zone?._id} value={zone?._id}>
//                         {zone?.zonename}
//                       </option>
//                     ))
//                   ) : (
//                     <option value="">Loading...</option>
//                   )}
//                 </Form.Select>
//               </p>

//               <p className="py-1 m-0">
//                 <Form.Select
//                   aria-label="Default select example"
//                   className="my-2"
//                   value={selectedAlternateZoneRegeionId}
//                   onChange={handleDistrictAlternateState}
//                   required
//                 >
//                   <option value="">Select a State</option>
//                   {Array.isArray(zoneRegion) ? (
//                     zoneRegionAlternate.map((zoneRegion) => (
//                       // console.log("zoneRegion", zoneRegion)
//                       <option key={zoneRegion} value={zoneRegion}>
//                         {zoneRegion}
//                       </option>
//                     ))
//                   ) : (
//                     <option value="">Loading...</option>
//                   )}
//                 </Form.Select>
//               </p>

//               <input
//                 type="text"
//                 placeholder="Enter Your District"
//                 className="my-2 input"
//                 value={alternateDistrictSelect}
//                 onChange={(e) => setalternateDistrictSelect(e.target.value)}
//               />

//               <input
//                 type="number"
//                 placeholder="Enter Your Pincode"
//                 className="my-2 input"
//                 value={alternatePinCode}
//                 onChange={(e) => setalternatePinCode(e.target.value)}
//               />
//             </>
//           </div>
//         </form>
//       </>
//     </div>
//   );
// }

// export default Billing;

import React, { useEffect, useState } from "react";
import "../CheckOut/CheckOut.scss";
import "../../Common/Common.scss";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../../components/Token";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ChoosePrevAddress from "./ChoosePrevAddress";
import { useNavigate } from "react-router-dom";

function Billing({
  firstName,
  setFirstNumber,
  lastName,
  setLastNumber,
  streetAdderss,
  setStreetAdderss,
  setCountery,
  countery,
  setState,
  phone,
  setphone,
  setEmail,
  setPinCode,
  pinCode,
  shipToDifferentAddress,
  setShipToDifferentAddress,
  email,
  placeOrder,
  setPlaceOrder,
  setSelectDistrict,
  setAlternateFirstNumber,
  alternateLastName,
  setAlternateLastNumber,
  setCompany,
  alternateAddress,
  setAlternateAddress,
  alternateFirstName,
  setAlternateCountery,
  setAlternateState,
  setalternateDistrictSelect,
  setalternatePinCode,

  alternateCountery,
  alternateState,
  alternateDistrictSelect,
  alternatePinCode,
  company,
  chooseAddress,
  districtSelect,
}) {
  const navigate = useNavigate();
  const headers = useAuthHeaders();
  const [zone, setZone] = useState([]);
  const [selectZone, setSelectZone] = useState("");
  const [zoneRegion, setZoneRegion] = useState([]);
  const [selectZoneRegion, setSelectZoneRegion] = useState("");

  const [zoneRegionAlternate, setZoneRegionAlternate] = useState([]);

  const [selectedAlternateZoneId, setSelectedAlternateZoneId] = useState("");
  const [selectedAlternateZoneRegeionId, setSelectedAlternateZoneRegeionId] =
    useState("");

  const [showForm, setShowForm] = useState(true);
  const [showFormAlternative, setShowFormAlternative] = useState(true);

  const [shippingCountery, setShippingCountery] = useState("");

  const [showAddressList, setShowAddressList] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(null);

  const handlePreviousAddressClick = () => {
    setShowAddressList(true);
  };

  const handleAddNewAddressClick = () => {
    setShowAddressList(false);
    setSelectedAddress(null); // Reset selected address
    setShowForm(false);
  };

  const handleAddressClick = (item) => {
    setSelectedAddress(item);
    handleAddressSelection(item);
    setShowAddressList(false); // Hide address list after selection
  };
  // const handlePreviousAddressClick = () => {
  //   setShowAddressList(true);
  // };

  // const handleAddNewAddressClick = () => {
  //   setShowAddressList(false);
  //   setShowForm(false);
  // };

  // const handleAddressClick = (item) => {
  //   setSelectedAddress(item);
  //   handleAddressSelection(item);
  //   setShowAddressList(false); // Hide the address list after selecting an address
  // };

  // useEffect(() => {
  //   axios
  //     .get(`${API_BASE_URL}/user/getallzones`, { headers })
  //     .then((response) => {
  //       setZone(response?.data?.Zones);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching subcategory data:", error);
  //     });
  // }, []);
  useEffect(() => {
    const fetchZones = async () => {
      try {
        const userId = localStorage.getItem("userId");

        // if (!userId) {
        //   navigate("/");
        //   return;
        // }

        const headers = {
          Authorization: `Bearer ${userId}`,
          "Content-Type": "application/json",
        };

        const response = await axios.get(`${API_BASE_URL}/user/getallzones`, {
          headers:headers,
        });
        setZone(response?.data?.Zones);
      } catch (error) {
        console.error("Error fetching zone data:", error);
      }
    };

    fetchZones();
  }, []);

  const handleZoneRegeion = (e) => {
    e.preventDefault();

    const getZoneId = e.target.value;
    setSelectZone(getZoneId);

    const findZoneName = zone.find((item) => item._id === getZoneId);

    if (findZoneName) {
      const countryName = findZoneName?.zonename;
      setCountery(countryName);
      setShippingCountery(countryName);
    } else {
      // console.log("No matching zone found for ID: ", getZoneId);
    }

    axios
      .get(`${API_BASE_URL}/user/shipping/getoneregionbyzone/${getZoneId}`, {
        headers,
      })
      .then((response) => {
        setZoneRegion(response?.data?.zoneRegionNames);
        // console.log("getoneregionbyzone", response.data.zoneRegionNames);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  // ```````````````````````````````````

  const handleZoneRegeionAlternateState = (e) => {
    e.preventDefault();

    const getZoneId = e.target.value;
    setSelectedAlternateZoneId(getZoneId);

    const findZoneName = zone.find((item) => item?._id === getZoneId);

    if (findZoneName) {
      const countryName = findZoneName.zonename;
      setAlternateCountery(countryName);
      setShippingCountery(countryName);
    } else {
      console.log("No matching zone found for ID: ", getZoneId);
    }

    axios
      .get(`${API_BASE_URL}/user/shipping/getoneregionbyzone/${getZoneId}`, {
        headers,
      })
      .then((response) => {
        setZoneRegionAlternate(response?.data?.zoneRegionNames);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  const handleDistrictAlternateState = (e) => {
    e.preventDefault();

    const getState = e.target.value;

    setAlternateState(getState);
    // setShippingState(getState);

    handleShipping(getState);
    setSelectedAlternateZoneRegeionId(getState);
  };

  const handleSelectState = (e) => {
    e.preventDefault();

    const StateName = e.target.value;
    setSelectZoneRegion(StateName);
    setState(StateName);

    handleShipping(StateName);
  };

  const handleShipping = (StateName) => {
    const userId = localStorage.getItem("userId");

    if (!userId) {
      navigate("/");
      return;
    }

    const headers = {
      Authorization: `Bearer ${userId}`,
      "Content-Type": "application/json",
    };

    axios
      .post(
        `${API_BASE_URL}/user/order/shippingamountcalculation`,
        {
          cartItems: placeOrder?.products?.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
          })),

          totalWeight: placeOrder?.cartproductweight,

          countryname: shippingCountery,
          statename: StateName,
        },
        {
          headers,
        }
      )
      .then((response) => {
        const updatedCartProductsTotals = response.data.cartProductsTotals;
        // console.log("updatedCartProductsTotals", updatedCartProductsTotals);

        setPlaceOrder((prevCartItems) => ({
          ...prevCartItems,
          cartProductsTotals: updatedCartProductsTotals,
        }));
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const handleAddressSelection = (address) => {
    // console.log("address in select", address.shippingInfo);
    setShowForm(false);
    // setFirstNumber(address?.shippingInfo?.firstname);
    // setLastNumber(address?.shippingInfo?.lastname);
    setStreetAdderss(address?.street);
    setCountery(address?.country);
    setState(address?.state);
    setPinCode(address?.pincode);
    // setphone(address?.phone);
    // setEmail(address?.email);
    setSelectDistrict(address?.district);
  };

  const handleAlternativAddressSelection = (addtess) => {
    // console.log("addtess in Alternativ", addtess);
    // setShowFormAlternative(false);
    // setAlternateFirstNumber(addtess?.shippingInfo?.firstname);
    // setAlternateLastNumber(addtess?.shippingInfo?.lastname);
    // setAlternateAddress(addtess?.shippingInfo?.streetaddress);
    // setalternateDistrictSelect(addtess?.shippingInfo?.district);
    // setalternatePinCode(addtess?.shippingInfo?.postalCode);
    // setShowFormAlternative(false);
    // setAlternateFirstNumber(addtess?.shippingInfo?.firstname);
    // setAlternateLastNumber(addtess?.shippingInfo?.lastname);
    setAlternateAddress(addtess?.street);
    setalternateDistrictSelect(addtess?.district);
    setalternatePinCode(addtess?.pincode);
  };

  return (
    <div className="col-lg-6 billing-inner">
      <>
        <h4>Billing Details</h4>

        <div>
          <div className="d-flex justify-content-between py-4">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="First name"
              className="input"
              style={{ marginRight: "10px" }}
              value={firstName}
              onChange={(e) => setFirstNumber(e.target.value)}
              required
            />

            <input
              type="text"
              name="name"
              id="name"
              placeholder="Last name"
              className="input"
              value={lastName}
              onChange={(e) => setLastNumber(e.target.value)}
              required
            />
          </div>

          <strong>Address</strong>

          <input
            type="text"
            name="address"
            id="address"
            placeholder="House number and street name"
            className="my-2 input"
            value={streetAdderss}
            onChange={(e) => setStreetAdderss(e.target.value)}
            required
          />

          {/* choose address */}
          {/* <>
            {chooseAddress.map((item, index) => {
              if (item && showForm) {
                console.log(`address array ${index}`, item);
                return (
                  <Form key={item?._id}>
                    {["radio"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          // label="Choose Previous Address"
                          label="Choose Previous Billing Address "
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          onClick={() => handleAddressSelection(item)}
                        />

                        <br />

                        <Form.Check
                          inline
                          label="Add New Address"
                          name="group1"
                          className="text-danger"
                          type={type}
                          id={`inline-${type}-2`}
                          onClick={() => setShowForm(false)}
                        />
                      </div>
                    ))}
                  </Form>
                );
              } else {
                return null;
              }
            })}
          </> */}

          {/* <>
            {chooseAddress.length !== 0 ? (
              <>
                {chooseAddress.map((item, index) => {
                  if (item && showForm && index === 0) {
                    console.log(`address array ${index}`, item);
                    return (
                      <Form key={item._id}>
                        {chooseAddress.map((item, index) => {
                          <div className="mb-3">
                            <Form.Check
                              inline
                              label="Choose Previous Billing Address"
                              name="group1"
                              type="radio"
                              id={`inline-radio-${index}`}
                              onClick={() => handleAddressSelection(item)}
                            />
                          </div>;
                        })}
                        <div className="mb-3">
                          <Form.Check
                            inline
                            label="Add New Address"
                            name="group1"
                            className="text-danger"
                            type="radio"
                            id="inline-radio-new"
                            onClick={() => setShowForm(false)}
                          />
                        </div>
                      </Form>
                    );
                  } else {
                    return null;
                  }
                })}
              </>
            ) : (
"")}
          </> */}

          {/* <>
          {chooseAddress.map((item, index) => {
            if (item && showForm && index === 0) {
              console.log(`address array ${index}`, item);
              return (
                <Form key={item._id}>
                  {chooseAddress.map((innerItem, innerIndex) => (
                    <div key={innerItem._id} className="mb-3">
                      <Form.Check
                        inline
                        label="Choose  Billing Address"
                        name="group1"
                        type="radio"
                        id={`inline-radio-${innerIndex}`}
                        onClick={() => handleAddressSelection(innerItem)}
                      />
                    </div>
                  ))}
                  <div className="mb-3">
                    <Form.Check
                      inline
                      label="Add New Address"
                      name="group1"
                      className="text-danger"
                      type="radio"
                      id="inline-radio-new"
                      onClick={() => setShowForm(false)}
                    />
                  </div>
                </Form>
              );
            } else {
              return null;
            }
          })}
        </> */}

          {/* <Form>
      <div className="mb-3">
        <Form.Check
          inline
          label="Choose Previous Billing Address"
          name="group1"
          type="radio"
          id="inline-radio-choose"
          onClick={handlePreviousAddressClick}
        />
      </div>
      {showAddressList && (
        <>
          {chooseAddress.map((item, index) => (
            <div key={item._id} className="mb-3">
              <Form.Check
                inline
                label={item.street} // Assuming item has an address field
                name="addressList"
                type="radio"
                id={`inline-address-${index}`}
                onClick={() => handleAddressSelection(item)}
              />
            </div>
          ))}
        </>
      )}
      <div className="mb-3">
        <Form.Check
          inline
          label="Add New Address"
          name="group1"
          className="text-danger"
          type="radio"
          id="inline-radio-new"
          onClick={handleAddNewAddressClick}
        />
      </div>
    </Form> */}

          {/* <Form>
            <div className="mb-3">
              <Form.Check
                inline
                label="Choose Previous Billing Address"
                name="group1"
                type="radio"
                id="inline-radio-choose"
                onClick={handlePreviousAddressClick}
              />
            </div>
            {showAddressList && (
              <>
                {chooseAddress.map((item, index) => (
                  <div key={item._id} className="mb-3">
                    <Form.Check
                      inline
                      label={item.street} // Assuming item has an address field
                      name="addressList"
                      type="radio"
                      id={`inline-address-${index}`}
                      onClick={() => handleAddressSelection(item)}
                    />
                  </div>
                ))}
              </>
            )}
            <div className="mb-3">
              <Form.Check
                inline
                label="Add New Address"
                name="group1"
                className="text-danger"
                type="radio"
                id="inline-radio-new"
                onClick={handleAddNewAddressClick}
              />
            </div>
          </Form> */}
          {/* choose address */}

          {/* <Form>
            {!selectedAddress && (
              <div className="mb-3">
                <Form.Check
                  inline
                  label="Choose Previous Billing Address"
                  name="group1"
                  type="radio"
                  id="inline-radio-choose"
                  onClick={handlePreviousAddressClick}
                />
              </div>
            )}
            {showAddressList && (
              <>
                {chooseAddress.map((item, index) => (
                  <div key={item._id} className="mb-3">
                    <Form.Check
                      inline
                      label={item.street} // Assuming item has an address field
                      name="addressList"
                      type="radio"
                      id={`inline-address-${index}`}
                      onClick={() => handleAddressClick(item)}
                    />
                  </div>
                ))}
              </>
            )}
            {!selectedAddress && (
              <div className="mb-3">
                <Form.Check
                  inline
                  label="Add New Address"
                  name="group1"
                  className="text-danger"
                  type="radio"
                  id="inline-radio-new"
                  onClick={handleAddNewAddressClick}
                />
              </div>
            )}
            {selectedAddress && (
              <div className="mb-3">
                <p>Selected Address: {selectedAddress.street}</p>
              </div>
            )}
          </Form> */}

          <Form>
            {!selectedAddress ? (
              <>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Choose Previous Billing Address"
                    name="group1"
                    type="radio"
                    id="inline-radio-choose"
                    onClick={handlePreviousAddressClick}
                  />
                </div>
                {showAddressList && (
                  <>
                    {chooseAddress.map((item, index) => (
                      <div key={item._id} className="mb-3">
                        <Form.Check
                          inline
                          label={item.street}
                          name="addressList"
                          type="radio"
                          id={`inline-address-${index}`}
                          onClick={() => handleAddressClick(item)}
                        />
                      </div>
                    ))}
                  </>
                )}
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Add New Address"
                    name="group1"
                    className="text-danger"
                    type="radio"
                    id="inline-radio-new"
                    onClick={handleAddNewAddressClick}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label={`Selected Address: ${selectedAddress.street}`}
                    name="group1"
                    type="radio"
                    id="inline-radio-selected"
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Add New Address"
                    name="group1"
                    className="text-danger"
                    type="radio"
                    id="inline-radio-new"
                    onClick={handleAddNewAddressClick}
                  />
                </div>
              </>
            )}
          </Form>
          <p className="py-1 m-0">
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectZone}
              onChange={handleZoneRegeion}
              required
            >
              <option value="">Select a Country</option>
              {Array.isArray(zone) ? (
                zone.map((zone) => (
                  <option key={zone?._id} value={zone?._id}>
                    {zone?.zonename}
                  </option>
                ))
              ) : (
                <option value="">Loading...</option>
              )}
            </Form.Select>
          </p>

          <p className="py-1 m-0">
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectZoneRegion}
              onChange={handleSelectState}
              required
            >
              <option value="">Select a State</option>
              {Array.isArray(zoneRegion) ? (
                zoneRegion?.map((zoneRegion) => (
                  <option key={zoneRegion} value={zoneRegion}>
                    {zoneRegion}
                  </option>
                ))
              ) : (
                <option value="">Loading...</option>
              )}
            </Form.Select>
          </p>

          <p className="py-1 m-0">
            <input
              type="text"
              placeholder="Enter Your District Name"
              className="my-2 input"
              value={districtSelect}
              onChange={(e) => setSelectDistrict(e.target.value)}
            />
          </p>

          <p className="py-1 m-0">
            <input
              type="number"
              placeholder="Enter Your Pincode"
              className="my-2 input"
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
            />
          </p>

          <p className="py-2 m-0">
            <label htmlFor="billing_country">Phone</label>
            <input
              type="text"
              name="number"
              id="number"
              className="my-2 input"
              value={phone}
              onChange={(e) => setphone(e.target.value)}
              required
            />
          </p>

          <p className="m-0">
            <label htmlFor="billing_country">Email address</label>
            <input
              type="text"
              name="email"
              id="email"
              className="my-2 input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </p>
        </div>

        <form action="">
          <div className="diff-address-header d-flex  justify-content-between my-2 fs-5">
            <label htmlFor="diffcheck" className="fw-bold">
              Ship to a different address?
            </label>

            <input
              type="checkbox"
              id="diffcheck"
              className="diff-address-header-input"
              value={shipToDifferentAddress}
              onChange={() =>
                setShipToDifferentAddress(!shipToDifferentAddress)
              }
              checked={shipToDifferentAddress}
              required
            />
          </div>

          <div
            className={`content ${shipToDifferentAddress ? "show" : "hide"}`}
          >
            <>
              <div className="d-flex justify-content-between">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="First name"
                  className="input"
                  style={{ marginRight: "10px" }}
                  value={alternateFirstName}
                  onChange={(e) => setAlternateFirstNumber(e.target.value)}
                />

                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Last name"
                  className="input"
                  value={alternateLastName}
                  onChange={(e) => setAlternateLastNumber(e.target.value)}
                />
              </div>

              <input
                type="text"
                name="name"
                id="name"
                placeholder="Company name (optional)"
                className="input my-4"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />

              <p className="m-0 py-2">
                <label htmlFor="billing_country">Country / Region</label>
              </p>

              <strong>Street address</strong>

              <input
                type="text"
                name="address"
                id="address"
                placeholder="House number and street name"
                className="my-2 input"
                value={alternateAddress}
                onChange={(e) => setAlternateAddress(e.target.value)}
              />

              {/* choose address */}
              {/* <>
                {chooseAddress.map((item, index) => {
                  if (item && showFormAlternative) {
                    return (
                      <Form key={item._id}>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`} className="mb-3">
                            {console.log("check the type--------->", type)}
                            <Form.Check
                              inline
                              label="Choose Previous Address"
                              label="Choose Previous Billing Address"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              onClick={() =>
                                handleAlternativAddressSelection(item)
                              }
                            />

                            <br />

                            <Form.Check
                              inline
                              label="Add New Address"
                              name="group1"
                              className="text-danger"
                              type={type}
                              id={`inline-${type}-2`}
                              onClick={() => setShowFormAlternative(false)}
                            />
                          </div>
                        ))}
                      </Form>
                    );
                  } else {
                    return null;
                  }
                })}
              </> */}


<Form>
            {!selectedAddress ? (
              <>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Choose Previous Billing Address"
                    name="group1"
                    type="radio"
                    id="inline-radio-choose"
                    onClick={handlePreviousAddressClick}
                  />
                </div>
                {showAddressList && (
                  <>
                    {chooseAddress.map((item, index) => (
                      <div key={item._id} className="mb-3">
                        <Form.Check
                          inline
                          label={item.street}
                          name="addressList"
                          type="radio"
                          id={`inline-address-${index}`}
                          onClick={() => handleAddressClick(item)}
                        />
                      </div>
                    ))}
                  </>
                )}
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Add New Address"
                    name="group1"
                    className="text-danger"
                    type="radio"
                    id="inline-radio-new"
                    onClick={handleAddNewAddressClick}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label={`Selected Address: ${selectedAddress.street}`}
                    name="group1"
                    type="radio"
                    id="inline-radio-selected"
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Add New Address"
                    name="group1"
                    className="text-danger"
                    type="radio"
                    id="inline-radio-new"
                    onClick={handleAddNewAddressClick}
                  />
                </div>
              </>
            )}
          </Form>
              {/* choose address */}

              <p className="py-1 m-0">
                <Form.Select
                  aria-label="Default select example"
                  className="my-2"
                  value={selectedAlternateZoneId}
                  onChange={handleZoneRegeionAlternateState}
                  required
                >
                  <option value="1232">Select a Country</option>
                  {Array.isArray(zone) ? (
                    zone.map((zone) => (
                      <option key={zone?._id} value={zone?._id}>
                        {zone?.zonename}
                      </option>
                    ))
                  ) : (
                    <option value="">Loading...</option>
                  )}
                </Form.Select>
              </p>

              <p className="py-1 m-0">
                <Form.Select
                  aria-label="Default select example"
                  className="my-2"
                  value={selectedAlternateZoneRegeionId}
                  onChange={handleDistrictAlternateState}
                  required
                >
                  <option value="">Select a State</option>
                  {Array.isArray(zoneRegion) ? (
                    zoneRegionAlternate.map((zoneRegion) => (
                      // console.log("zoneRegion", zoneRegion)
                      <option key={zoneRegion} value={zoneRegion}>
                        {zoneRegion}
                      </option>
                    ))
                  ) : (
                    <option value="">Loading...</option>
                  )}
                </Form.Select>
              </p>

              <input
                type="text"
                placeholder="Enter Your District"
                className="my-2 input"
                value={alternateDistrictSelect}
                onChange={(e) => setalternateDistrictSelect(e.target.value)}
              />

              <input
                type="number"
                placeholder="Enter Your Pincode"
                className="my-2 input"
                value={alternatePinCode}
                onChange={(e) => setalternatePinCode(e.target.value)}
              />
            </>
          </div>
        </form>
      </>
    </div>
  );
}

export default Billing;
