import React, { memo } from "react";

import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import ProductImages from "../Products/ProductImages/ProductImages";
import AddCartButton from "../Buttons/AddCartButton";
import WishlistButton from "../Buttons/WishlistButton";
import ProductOveriew from "../ProductOveriew/ProductOveriew";

const ProductCard = memo(({ data }) => {
  // console.log("====================?>", data);
  // console.log("product discription", data[0]?.descriptions[0]?.value);

  // data?.descriptions[0]?.value
  const replaceSpacesWithHyphens = (urlString) => {
    if (typeof urlString === "string") {
      // Replace all spaces with hyphens
      return urlString.replace(/ /g, "-") .toLocaleLowerCase();
    } else return urlString;
  };

  return (
    <>
      {data.map((product) => (
        <Card className="card result-card" key={product?._id}>
          <center>
            <Card.Title className="px-2 text-dark card-title">
              <Link
                className="text-dark text-decoration-none"
                // to={`/product/${product?._id}`}
                // to={`/product/${product?.slug}`}
                to={
                  product?.descriptions[0]?.value !== undefined
                    ? `/${replaceSpacesWithHyphens(product?.category?.name)}/${replaceSpacesWithHyphens(
                        product?.subcategoryname?.name
                      )}/${replaceSpacesWithHyphens(
                        product?.descriptions[0]?.value
                      )}/${product?.slug}`
                    : `/product/${product?.slug}`
                }
                // to={`/product/${product?.partNumber}`}
              >
                {product?.partNumber || "Product Name"}
              </Link>
            </Card.Title>
          </center>

          {/* <Link to={`/product/${product?._id}`}> */}
          {/* <Link to={`/product/${product?.partNumber}`}> */}
          {/* <Link to={`/product/${product?.slug}`}> */}
          <Link
            to={
              product?.descriptions[0]?.value !== undefined
                ? // ? `/${product?.category?.name}/${product?.subcategoryname?.name}/${product?.descriptions[0]?.value}/${product?.slug}`
                `/${replaceSpacesWithHyphens(product?.category?.name)}/${replaceSpacesWithHyphens(product?.subcategoryname?.name)}/${replaceSpacesWithHyphens(product?.descriptions[0]?.value)}/${product?.slug}`
                : `/product/${product?.slug}`
            }
          >
       
            <ProductImages productimages={product?.productimages} />
          </Link>

          <center className="cart-footer">
            <Link
              // to={`/product/${product?._id}`}
              // to={`/product/${product?.slug}`}
              to={
                product?.descriptions[0]?.value !== undefined
                  // ? `/${product?.category?.name}/${product?.subcategoryname?.name}/${product?.descriptions[0]?.value}/${product?.slug}`
                  ? `/${replaceSpacesWithHyphens(product?.category?.name)}/${replaceSpacesWithHyphens(product?.subcategoryname?.name)}/${replaceSpacesWithHyphens(product?.descriptions[0]?.value)}/${product?.slug}`
                  : `/product/${product?.slug}`
              }
              // to={`/product/${product?.partNumber}`}
              className="card-price text-decoration-none"
            >
              <h6 className="m-0 current-price">
                ₹ {product?.saleprice || "N/A"}
              </h6>
              <p className="m-0 old-price">₹ {product?.oldprice || "N/A"}</p>
            </Link>

            <AddCartButton
              type="product_button"
              className="card-add-cart-button"
              data={product}
            />
          </center>

          <Card.Body className="card-content text-danger">
            <WishlistButton type="icon" data={product} />
            <div className="add-to-card">
              <ProductOveriew product={product} />
            </div>
          </Card.Body>
        </Card>
      ))}
    </>
  );
});

export default ProductCard;
