// // import React, { useEffect, useState } from "react";
// // import { Link } from "react-router-dom";
// // import axios from "axios";
// // import { API_BASE_URL } from "../../../utils";
// // import AdsPoster from "../../../Assets/Images/ads_poster.png";
// // import Loader from "../../Loader";
// // import ProductCard from "../../Card/ProductCard";

// // function FastenersProducts({ categoryId }) {
// //   const [data, setData] = useState([]);

// //   useEffect(() => {
// //     if (categoryId) {
// //       axios
// //         .get(`${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`)
// //         .then((response) => {
// //           setData(response?.data?.products?.slice(0, 8));
// //           // console.log("getproductbycategory", response.data);
// //         })
// //         .catch((error) => {
// //           console.error("Error fetching data:", error);
// //         });
// //     }
// //   }, [categoryId]);

// //   return (
// //     <>
// //       {data?.length !== 0 ? (
// //         <div className="products m-0">
// //           <ProductCard data={data} />

// //           <Link to="view-all-products" className="add-poster">
// //             <img src={AdsPoster} alt="AdsPoster" />
// //           </Link>
// //         </div>
// //       ) : (
// //         <Loader />
// //       )}
// //     </>
// //   );
// // }

// // export default FastenersProducts;



// // import React, { useEffect, useState } from "react";
// // import { Link } from "react-router-dom";
// // import axios from "axios";
// // import { API_BASE_URL } from "../../../utils";
// // import AdsPoster from "../../../Assets/Images/ads_poster.png";
// // import ProductCard from "../../Card/ProductCard";
// // import Lottie from "lottie-react";
// // import NoProductFound from "./No product Found Animation.json";
// // import Preloader from "./Preloader-Animation.json";

// // function FastenersProducts({ categoryId }) {
// //   const [data, setData] = useState([]);
// //   const [loading, setLoading] = useState(true);

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         setLoading(true);
// //         if (categoryId) {
// //           const response = await axios.get(
// //             `${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`
// //           );
// //           setData(response?.data?.products?.slice(0, 8));
// //         }
// //       } catch (error) {
// //         console.error("Error fetching data:", error);
// //       } finally {
// //         setLoading(false);
// //       }
// //     };
// //     fetchData();
// //   }, [categoryId]);

// //   return (
// //     <>
// //       {loading ? (
// //         <Lottie
// //           style={{ width: "100vw", height: "50vh" }}
// //           animationData={Preloader}
// //         />
// //       ) : (
// //         <>
// //           {data?.length !== 0 ? (
// //             <div className="products m-0">
// //               <ProductCard data={data} />
// //               <Link to="view-all-products" className="add-poster">
// //                 <img src={AdsPoster} alt="AdsPoster" />
// //               </Link>
// //             </div>
// //           ) : (
// //             <Lottie
// //               style={{ width: "100vw", height: "50vh" }}
// //               animationData={NoProductFound}
// //             />
// //           )}
// //         </>
// //       )}
// //     </>
// //   );
// // }

// // export default FastenersProducts;



//==============================================================================================================================================================
//==============================================================================================================================================================
//                                       Before Banner update
//==============================================================================================================================================================
//==============================================================================================================================================================
// import React, { useEffect, useState, memo } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import { API_BASE_URL } from "../../../utils";
// import AdsPoster from "../../../Assets/Images/ads_poster.png";
// import ProductCard from "../../Card/ProductCard";
// import Lottie from "lottie-react";
// import NoProductFound from "./No product Found Animation.json";
// import Preloader from "./Preloader-Animation.json";

// const FastenersProducts = memo(({ categoryId }) => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const [ads , setAdsFaster]=useState()


//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true);
//         if (categoryId) {
//           const response = await axios.get(
//             `${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`
//           );
//           setData(response?.data?.products?.slice(0, 8));
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, [categoryId]);


// useEffect(()=>{
// axios.get(`${API_BASE_URL}/offerposter`)
// .then(res =>{
//   console.log(res.data ," FAstersProducts ")


//     setAdsFaster(res.data.posters
//     )
  
// })

// },[])
  




//   return (
//     <>
//       {loading ? (
//         <Lottie
//           style={{ width: "100vw", height: "50vh" }}
//           animationData={Preloader}
//         />
//       ) : (
//         <>
//           {data?.length !== 0 ? (
//             <div className="products m-0">
//               <ProductCard data={data} />
//               <Link to="view-all-products" className="add-poster">

//                 <MemoizedImage src={AdsPoster} alt="AdsPoster" />


//               {
//                 ads.map((ads,i)=>{


//                   <img src={ads?.posters[0].poster} alt="AdsPoster" />
//                 })
//               }
//               </Link>
//             </div>
//           ) : (
//             <Lottie
//               style={{ width: "100vw", height: "50vh" }}
//               animationData={NoProductFound}
//             />
//           )}
//         </>
//       )}
//     </>
//   );
// }

// export default FastenersProducts;
// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import { API_BASE_URL } from "../../../utils";
// import ProductCard from "../../Card/ProductCard";
// import Lottie from "lottie-react";
// import NoProductFound from "./No product Found Animation.json";
// import Preloader from "./Preloader-Animation.json";
// import ReactImageGallery from "react-image-gallery";

// function FastenersProducts({ categoryId }) {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [ads, setAdsFaster] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true);
//         if (categoryId) {
//           const { data: response } = await axios.get(
//             `${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`
//           );
//           setData(response?.products?.slice(0, 8) || []);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, [categoryId]);

//   useEffect(() => {
//     const fetchAds = async () => {
//       try {
//         const { data: response } = await axios.get(`${API_BASE_URL}/offerposter?category=${categoryId}`);
//         console.log(response.posters,
//           'topLevel'
//         )
//         setAdsFaster(response?.posters || []);
//       } catch (error) {
//         console.error("Error fetching ads:", error);
//       }
//     };
//     fetchAds();
//   }, []);
// // console.log(ads,"toplevel")
//   const images = ads.map((ad, index) => ({
//     original: ad?.offerposters[0]?.offerposter,
    
//    key:index
//   }));
//   console.log(images,"ssssssssssss")
//   const gallerySettings = {
//     autoPlay: true,
//     slideInterval: 3000,
//     showFullscreenButton: false,
//     showPlayButton: false,
//     showNav: false,
//     showThumbnails: false,
//     showBullets: false,
//   };
//   return (
//     <>
//       {loading ? (
//         <Lottie
//           style={{ width: "100vw", height: "50vh" }}
//           animationData={Preloader}
//         />
       
//       ) : (
//         <>
//           {data.length !== 0 ? (
//             <div className="products m-0">
//               <ProductCard data={data} />
//               <Link to="view-all-products" className="add-poster">
//         {console.log(images,"Ssssssss")}
//                   <ReactImageGallery items={images} className="banner-image"  {...gallerySettings} />
              

//                 <img src={AdsPoster} alt="AdsPoster" />

//               </Link>
//             </div>
//           ) : (
//             <Lottie
//               style={{ width: "100vw", height: "50vh" }}
//               animationData={NoProductFound}
//             />
//           )}
//         </>
//       )}
//     </>
//   );
// });

// const MemoizedImage = memo(({ src, alt }) => <img src={src} alt={alt} />);

// export default FastenersProducts;



//==============================================================================================================================================================
//==============================================================================================================================================================
//                                      After  Banner update
//==============================================================================================================================================================
//==============================================================================================================================================================


import React, { useEffect, useState,memo} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../../utils";

import AdsPoster from "../../../Assets/Images/ads_poster.png";

import ProductCard from "../../Card/ProductCard";
import Lottie from "lottie-react";
// import NoProductFound from "./No product Found Animation.json";
import NoProductFound from "../../../Preloader/No product Found Animation.json";
import Preloader from "../../../Preloader/Preloader-Animation.json";
import ReactImageGallery from "react-image-gallery";

function FastenersProducts({ categoryId }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ads, setAdsFaster] = useState([]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (categoryId) {

          const { data: response } = await axios.get(
            `${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`
          );
          setData(response?.products?.slice(0, 8) || []);


        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [categoryId]);


  useEffect(() => {
    const fetchAds = async () => {
      try {
        const { data: response } = await axios.get(`${API_BASE_URL}/offerposter?category=${categoryId}`);
        setAdsFaster(response?.posters || []);
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };
    fetchAds();
  }, [categoryId]);

  const images = ads.flatMap((ad, index) =>
    ad.offerposters.map((poster, posterIndex) => ({
      original: poster.offerposter,
      key: `${index}-${posterIndex}`,
    }))
  );

  const gallerySettings = {
    autoPlay: true,
    slideInterval: 3000,
    showFullscreenButton: false,
    showPlayButton: false,
    showNav: false,
    showThumbnails: false,
    showBullets: false,
  };


  return (
    <>
      {loading ? (
        <Lottie
          style={{ width: "100vw", height: "50vh" }}
          animationData={Preloader}
        />
      ) : (
        <>

          {data.length !== 0 ? (
            <div className="products m-0">
              <ProductCard data={data} />
              <Link to="view-all-products" className="add-poster">
                <ReactImageGallery
                  items={images}
                  className="banner-image"
                  {...gallerySettings}
                />        
              </Link>
            </div>
          ) : (
            <Lottie
              style={{ width: "100vw", height: "50vh" }}
              animationData={NoProductFound}
            />
            
          )}
        </>
      )}
    </>
  );


}


const MemoizedImage = memo(({ src, alt }) => <img src={src} alt={alt} />);

export default FastenersProducts;
