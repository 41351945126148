import React, { useState } from "react";
import loginLogo from "../../Assets/Images/brand-logo.svg";
import "../../Assets/Images/loginPage-banner.jpg";
import "../LoginPage/LoginPage.scss";
import { BsArrowLeftCircle } from "react-icons/bs";
import { LuMail } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import Swal from "sweetalert2";

function ForgotPassword() {
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    
    axios
      .post(`${API_BASE_URL}/forgotpassword`, {
        email: email,
      })
      .then((result) => {
        // console.log(result);
      
        navigate("/successpage"); 
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
        console.log("Error in API call:", error);
      });
  };

  return (
    <div className="login-page">
      <div className="login-page-inner">
        <img className="login-logo" src={loginLogo} alt="loginLogo" />
        <form className="right " name="RegForm" onSubmit={handleSubmit}>
          <div className="login-title" style={{ width: "80%" }}>
            <Link to="/loginpage">
              <BsArrowLeftCircle className="login-title-icon" />
            </Link>
            <h6>Forgot Password</h6>
          </div>

          <div className="form-control">
            <LuMail className="form-control-icon" />
            <input
              className="form-control-input"
              required
              type="validation"
              name="email"
              id="email"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="submit"
              value="send"
              name="Submit"
              className="submit-button "
            >
              Continue
            </button>
          </div>

          <Link
            to="/loginpage"
            className="forgot-password d-flex justify-content-center"
          >
            Already have an Account?
          </Link>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
