// import React, { useContext, useState } from "react";
// import loginLogo from "../../Assets/Images/brand-logo.svg";
// import "../../Assets/Images/loginPage-banner.jpg";
// import "../LoginPage/LoginPage.scss";
// import { GoKey } from "react-icons/go";
// import { BsArrowLeftCircle } from "react-icons/bs";
// import { TbLogin2 } from "react-icons/tb";
// import { BiPhone } from "react-icons/bi";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils/index";
// import "react-toastify/dist/ReactToastify.css";
// import { Toast } from "react-bootstrap";
// import Swal from "sweetalert2/dist/sweetalert2.js";
// import { CiMail } from "react-icons/ci";
// import userContext from "../../components/UseContext/UseContext";
// import { FaRegEyeSlash } from "react-icons/fa6";
// import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";

// function LoginPage() {
//   const ProfileUseContext = useContext(userContext);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [isLoading, setLoading] = useState(false);
//   const [showErrorToast, setShowErrorToast] = useState(false);
//   const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
//   const navigate = useNavigate();
//   const token = localStorage.getItem("userId");

//   const headers = {
//     Authorization: `Barear ${token}`,
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (isLoading) {
//       return;
//     }

//     setLoading(true);

//     axios
//       .post(
//         `${API_BASE_URL}/loginpage`,
//         {
//           email: email,
//           password: password,
//         },
//         {
//           headers,
//         }
//       )
//       .then((result) => {
//         console.log(result?.data);
//         Swal.fire({
//           icon: "success",
//           title: "Sent Otp",
//           showConfirmButton: false,
//           timer: 1500,
//         });
//         ProfileUseContext?.setProfileActive(true);
//         navigate("/otpverification");
//       })
//       .catch((error) => {
//         console.error("Error in API call:", error);
//         setShowErrorToast(true);
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: error?.response?.data?.message,
//         });
//       })
//       .finally(() => {
//         setLoading(false); // Reset loading state after the API call is complete
//       });
//   };

//   const handleCloseErrorToast = () => {
//     setShowErrorToast(false);
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div className="login-page position-relative">
//       <div className="login-page-inner">
//         <div className="text-center">
//           <img className="login-logo" src={loginLogo} alt="loginLogo" />
//         </div>
//         <form className="right " name="RegForm" onSubmit={handleSubmit}>
//           <div className="login-title">
//             <Link to="/registration">
//               <BsArrowLeftCircle className="login-title-icon" />
//             </Link>
//             <h6>Log In</h6>
//           </div>

//           <div className="form-control">
//             <CiMail className="form-control-icon" />
//             <input
//               className="form-control-input"
//               required
//               type="validation"
//               name="email"
//               id="email"
//               placeholder="Enter Your Email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//           </div>

//           <div className="form-control">
//             <GoKey className="form-control-icon" />
//             <input
//               className="form-control-input"
//               required
//               type={showPassword ? "text" : "password"}
//               name="password"
//               id="password"
//               placeholder="Password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />
//             <button  type="button" onClick={togglePasswordVisibility}>
//               {showPassword ? (
//                 <IoEyeOffSharp className="eye-icon" />
//               ) : (
//                 <IoEyeSharp className="eye-icon" />
//               )}
//             </button>
//           </div>

//           <div className="sub-items py-2">
//             <div className="d-flex align-items-center">
//               <input type="checkbox" id="check" />
//               <label htmlFor="check">Remember me</label>
//             </div>
//             <Link to="/forgotpassword" className="forgot-password">
//               Forgot Password ?
//             </Link>
//           </div>

//           <div className="d-flex justify-content-center">
//             <button
//               type="submit"
//               value="send"
//               name="Submit"
//               className="submit-button"
//               disabled={isLoading}
//               onClick={handleSubmit}
//             >
//               <TbLogin2 className="submit-button-icon" />
//               {isLoading ? "Loading…" : "Continue"}
//             </button>
//           </div>

//           <Link
//             to="/registration"
//             className="forgot-password d-flex justify-content-center"
//           >
//             Register a new membership
//           </Link>
//         </form>
//       </div>
//       {/* Error Toast */}
//       <Toast
//         onClose={handleCloseErrorToast}
//         show={showErrorToast}
//         delay={3000}
//         autohide
//         style={{
//           position: "fixed",
//           bottom: "20px",
//           right: "20px",
//           zIndex: 9999,
//           background: "red",
//           color: "white",
//         }}
//       ></Toast>
//     </div>
//   );
// }

// export default LoginPage;

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
                                                      //  remember me password click email id only stored in the session storage
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------

// import React, { useContext, useEffect, useState } from "react";
// import loginLogo from "../../Assets/Images/brand-logo.svg";
// import "../../Assets/Images/loginPage-banner.jpg";
// import "../LoginPage/LoginPage.scss";
// import { GoKey } from "react-icons/go";
// import { BsArrowLeftCircle } from "react-icons/bs";
// import { TbLogin2 } from "react-icons/tb";
// import { BiPhone } from "react-icons/bi";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils/index";
// import "react-toastify/dist/ReactToastify.css";
// import { Toast } from "react-bootstrap";
// import Swal from "sweetalert2/dist/sweetalert2.js";
// import { CiMail } from "react-icons/ci";
// import userContext from "../../components/UseContext/UseContext";
// import { FaRegEyeSlash } from "react-icons/fa6";
// import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";

// function LoginPage() {
//   const ProfileUseContext = useContext(userContext);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [isLoading, setLoading] = useState(false);
//   const [showErrorToast, setShowErrorToast] = useState(false);
//   const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
//   const navigate = useNavigate();
//   const [rememberMe, setRememberMe] = useState(false);

//   const token = localStorage.getItem("userId");

//   const headers = {
//     Authorization: `Barear ${token}`,
//   };

//   useEffect(() => {
//     sessionStorage.setItem("email", email);
//     const savedEmail = localStorage.getItem('email') || sessionStorage.getItem('email');

//     if (savedEmail) setEmail(savedEmail);
//     // if (savedPassword) setPassword(savedPassword);
//   }, []);

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (isLoading) {
//       return;
//     }

//     setLoading(true);

//     axios
//       .post(
//         `${API_BASE_URL}/loginpage`,
//         {
//           email: email,
//           password: password,
//         },
//         {
//           headers,
//         }
//       )
//       .then((result) => {
//         console.log(result?.data);
//         Swal.fire({
//           icon: "success",
//           title: "Sent Otp",
//           showConfirmButton: false,
//           timer: 1500,
//         });
//         ProfileUseContext?.setProfileActive(true);
//         navigate("/otpverification");

//         if (rememberMe) {
//           localStorage.setItem("email", email);
//         } else {
//           sessionStorage.setItem("email", email);
//         }
//       })
//       .catch((error) => {
//         console.error("Error in API call:", error);
//         setShowErrorToast(true);
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: error?.response?.data?.message,
//         });
//       })
//       .finally(() => {
//         setLoading(false); // Reset loading state after the API call is complete
//       });
//   };

//   const handleCloseErrorToast = () => {
//     setShowErrorToast(false);
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div className="login-page position-relative">
//       <div className="login-page-inner">
//         <div className="text-center">
//           <img className="login-logo" src={loginLogo} alt="loginLogo" />
//         </div>
//         <form className="right " name="RegForm" onSubmit={handleSubmit}>
//           <div className="login-title">
//             <Link to="/registration">
//               <BsArrowLeftCircle className="login-title-icon" />
//             </Link>
//             <h6>Log In</h6>
//           </div>

//           <div className="form-control">
//             <CiMail className="form-control-icon" />
//             <input
//               className="form-control-input"
//               required
//               type="validation"
//               name="email"
//               id="email"
//               placeholder="Enter Your Email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//           </div>

//           <div className="form-control">
//             <GoKey className="form-control-icon" />
//             <input
//               className="form-control-input"
//               required
//               type={showPassword ? "text" : "password"}
//               name="password"
//               id="password"
//               placeholder="Password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />
//             <button type="button" onClick={togglePasswordVisibility}>
//               {showPassword ? (
//                 <IoEyeOffSharp className="eye-icon" />
//               ) : (
//                 <IoEyeSharp className="eye-icon" />
//               )}
//             </button>
//           </div>

//           <div className="sub-items py-2">
//             <div className="d-flex align-items-center">
//               <input
//                 type="checkbox"
//                 id="check"
//                 checked={rememberMe}
//                 onChange={() => setRememberMe(!rememberMe)}
//               />
//               <label htmlFor="check">Remember me</label>
//             </div>
//             <Link to="/forgotpassword" className="forgot-password">
//               Forgot Password ?
//             </Link>
//           </div>

//           <div className="d-flex justify-content-center">
//             <button
//               type="submit"
//               value="send"
//               name="Submit"
//               className="submit-button"
//               disabled={isLoading}
//               onClick={handleSubmit}
//             >
//               <TbLogin2 className="submit-button-icon" />
//               {isLoading ? "Loading…" : "Continue"}
//             </button>
//           </div>

//           <Link
//             to="/registration"
//             className="forgot-password d-flex justify-content-center"
//           >
//             Register a new membership
//           </Link>
//         </form>
//       </div>
//       {/* Error Toast */}
//       <Toast
//         onClose={handleCloseErrorToast}
//         show={showErrorToast}
//         delay={3000}
//         autohide
//         style={{
//           position: "fixed",
//           bottom: "20px",
//           right: "20px",
//           zIndex: 9999,
//           background: "red",
//           color: "white",
//         }}
//       ></Toast>
//     </div>
//   );
// }

// export default LoginPage;


//--------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------


// import React, { useContext, useEffect, useState } from "react";
// import loginLogo from "../../Assets/Images/brand-logo.svg";
// import "../../Assets/Images/loginPage-banner.jpg";
// import "../LoginPage/LoginPage.scss";
// import { GoKey } from "react-icons/go";
// import { BsArrowLeftCircle } from "react-icons/bs";
// import { TbLogin2 } from "react-icons/tb";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils/index";
// import "react-toastify/dist/ReactToastify.css";
// import { Toast } from "react-bootstrap";
// import Swal from "sweetalert2/dist/sweetalert2.js";
// import { CiMail } from "react-icons/ci";
// import userContext from "../../components/UseContext/UseContext";
// import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
// import Cookies from 'js-cookie'; // Import js-cookie

// function LoginPage() {
//   const ProfileUseContext = useContext(userContext);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [isLoading, setLoading] = useState(false);
//   const [showErrorToast, setShowErrorToast] = useState(false);
//   const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
//   const navigate = useNavigate();
//   const [rememberMe, setRememberMe] = useState(false);

//   const token = localStorage.getItem("userId");

//   const headers = {
//     Authorization: `Bearer ${token}`, // Fixed typo from "Barear" to "Bearer"
//   };

//   useEffect(() => {
//     // Retrieve saved credentials and rememberMe state from cookies if available
//     const savedEmail = Cookies.get('email');
//     const savedPassword = Cookies.get('password');
//     const savedRememberMe = Cookies.get('rememberMe') === 'true';

//     if (savedEmail) {
//       setEmail(savedEmail);
//     }

//     if (savedPassword) {
//       setPassword(savedPassword);
//     }

//     setRememberMe(savedRememberMe);
//   }, []);

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (isLoading) {
//       return;
//     }

//     setLoading(true);

//     axios
//       .post(
//         `${API_BASE_URL}/loginpage`,
//         {
//           email: email,
//           password: password,
//         },
//         {
//           headers,
//         }
//       )
//       .then((result) => {
//         console.log(result?.data);
//         Swal.fire({
//           icon: "success",
//           title: "Sent Otp",
//           showConfirmButton: false,
//           timer: 1500,
//         });
//         ProfileUseContext?.setProfileActive(true);
//         navigate("/otpverification");

//         if (rememberMe) {
//           // localStorage.setItem("email", email);
//           Cookies.set('email', email); // Store email in cookies
//           Cookies.set('password', password); // Store password in cookies
//           Cookies.set('rememberMe', 'true'); // Store rememberMe state in cookies
//         } else {
//           sessionStorage.setItem("email", email);
//           Cookies.remove('email');
//           Cookies.remove('password');
//           Cookies.remove('rememberMe');
//         }
//       })
//       .catch((error) => {
//         console.error("Error in API call:", error);
//         setShowErrorToast(true);
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: error?.response?.data?.message,
//         });
//       })
//       .finally(() => {
//         setLoading(false); // Reset loading state after the API call is complete
//       });
//   };

//   const handleCloseErrorToast = () => {
//     setShowErrorToast(false);
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div className="login-page position-relative">
//       <div className="login-page-inner">
//         <div className="text-center">
//           <img className="login-logo" src={loginLogo} alt="loginLogo" />
//         </div>
//         <form className="right " name="RegForm" onSubmit={handleSubmit}>
//           <div className="login-title">
//             <Link to="/registration">
//               <BsArrowLeftCircle className="login-title-icon" />
//             </Link>
//             <h6>Log In</h6>
//           </div>

//           <div className="form-control">
//             <CiMail className="form-control-icon" />
//             <input
//               className="form-control-input"
//               required
//               type="email"
//               name="email"
//               id="email"
//               placeholder="Enter Your Email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//           </div>

//           <div className="form-control">
//             <GoKey className="form-control-icon" />
//             <input
//               className="form-control-input"
//               required
//               type={showPassword ? "text" : "password"}
//               name="password"
//               id="password"
//               placeholder="Password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />
//             <button type="button" onClick={togglePasswordVisibility}>
//               {showPassword ? (
//                 <IoEyeOffSharp className="eye-icon" />
//               ) : (
//                 <IoEyeSharp className="eye-icon" />
//               )}
//             </button>
//           </div>

//           <div className="sub-items py-2">
//             <div className="d-flex align-items-center">
//               <input
//                 type="checkbox"
//                 id="check"
//                 checked={rememberMe}
//                 onChange={() => setRememberMe(!rememberMe)}
//               />
//               <label htmlFor="check">Remember me</label>
//             </div>
//             <Link to="/forgotpassword" className="forgot-password">
//               Forgot Password ?
//             </Link>
//           </div>

//           <div className="d-flex justify-content-center">
//             <button
//               type="submit"
//               value="send"
//               name="Submit"
//               className="submit-button"
//               disabled={isLoading}
//               onClick={handleSubmit}
//             >
//               <TbLogin2 className="submit-button-icon" />
//               {isLoading ? "Loading…" : "Continue"}
//             </button>
//           </div>

//           <Link
//             to="/registration"
//             className="forgot-password d-flex justify-content-center"
//           >
//             Register a new membership
//           </Link>
//         </form>
//       </div>
//       {/* Error Toast */}
//       <Toast
//         onClose={handleCloseErrorToast}
//         show={showErrorToast}
//         delay={3000}
//         autohide
//         style={{
//           position: "fixed",
//           bottom: "20px",
//           right: "20px",
//           zIndex: 9999,
//           background: "red",
//           color: "white",
//         }}
//       ></Toast>
//     </div>
//   );
// }

// export default LoginPage;



//--------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------
//                                                Login via Mobile number updation
//--------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------

import React, { useContext, useEffect, useState } from "react";
import loginLogo from "../../Assets/Images/brand-logo.svg";
import "../../Assets/Images/loginPage-banner.jpg";
import "../LoginPage/LoginPage.scss";
import { GoKey } from "react-icons/go";
import { BsArrowLeftCircle } from "react-icons/bs";
import { TbLogin2 } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import "react-toastify/dist/ReactToastify.css";
import { Toast } from "react-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CiMail } from "react-icons/ci";
import userContext from "../../components/UseContext/UseContext";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import Cookies from "js-cookie";

function LoginPage() {
  const ProfileUseContext = useContext(userContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); 
  const [showPassword, setShowPassword] = useState(false); 
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  const token = localStorage.getItem("userId");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const savedEmail = Cookies.get("email");
    const savedPassword = Cookies.get("password");
    const savedRememberMe = Cookies.get("rememberMe") === "true";

    if (savedEmail) {
      setEmail(savedEmail);
    }

    if (savedPassword) {
      setPassword(savedPassword);
    }

    setRememberMe(savedRememberMe);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    setLoading(true);

  
    const isMobile = /^[0-9]{10}$/.test(email); 
    const url = isMobile ? `${API_BASE_URL}/mobilelogin` : `${API_BASE_URL}/loginpage`;

    const payload = isMobile
      ? { mobile: email, password: password } 
      : { email: email, password: password }; 

    axios
      .post(url, payload, { headers })
      .then((result) => {
        Swal.fire({
          icon: "success",
          title: "Sent OTP",
          showConfirmButton: false,
          timer: 1500,
        });
        ProfileUseContext?.setProfileActive(true);
        navigate("/otpverification");

        if (rememberMe) {
          Cookies.set("email", email);
          Cookies.set("password", password);
          Cookies.set("rememberMe", "true");
        } else {
          sessionStorage.setItem("email", email);
          const isMobile = /^[0-9]{10}$/.test(email);
          isMobile ? sessionStorage.setItem("email", email) : sessionStorage.setItem("email", email);
          Cookies.remove("email");
          Cookies.remove("password");
          Cookies.remove("rememberMe");
        }
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.message || "An error occurred");
        setShowErrorToast(true);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMessage,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseErrorToast = () => {
    setShowErrorToast(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-page position-relative">
      <div className="login-page-inner">
        <div className="text-center">
          <img className="login-logo" src={loginLogo} alt="loginLogo" />
        </div>
        <form className="right " name="RegForm" onSubmit={handleSubmit}>
          <div className="login-title">
            <Link to="/registration">
              <BsArrowLeftCircle className="login-title-icon" />
            </Link>
            <h6>Log In</h6>
          </div>

          <div className="form-control">
            <CiMail className="form-control-icon" />
            <input
              className="form-control-input"
              required
              type="text" 
              name="email"
              id="email"
              placeholder="Enter Your Email or Mobile Number"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-control">
            <GoKey className="form-control-icon" />
            <input
              className="form-control-input"
              required
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              aria-label="Toggle password visibility"
            >
              {showPassword ? (
                <IoEyeOffSharp className="eye-icon" />
              ) : (
                <IoEyeSharp className="eye-icon" />
              )}
            </button>
          </div>

          <div className="sub-items py-2">
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                id="check"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label htmlFor="check">Remember me</label>
            </div>
            <Link to="/forgotpassword" className="forgot-password">
              Forgot Password ?
            </Link>
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="submit"
              value="send"
              name="Submit"
              className="submit-button"
              disabled={isLoading}
            >
              <TbLogin2 className="submit-button-icon" />
              {isLoading ? "Loading…" : "Continue"}
            </button>
          </div>

          <Link
            to="/registration"
            className="forgot-password d-flex justify-content-center"
          >
            Register a new membership
          </Link>
        </form>
      </div>
      <Toast
        onClose={handleCloseErrorToast}
        show={showErrorToast}
        delay={3000}
        autohide
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 9999,
          background: "red",
          color: "white",
        }}
      >
        <Toast.Body>{errorMessage}</Toast.Body> 
      </Toast>
    </div>
  );
}

export default LoginPage;
