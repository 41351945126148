import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
// import { toast } from "react-toastify";
import { useContext } from "react";
import { toast } from "react-hot-toast";

export const storeWhisLIstProducts = async ({ productId, productContext }) => {
  try {
    const token = localStorage.getItem("userId");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.post(
      `${API_BASE_URL}/user/wishlist`,
      {
        products: [{ ProductId: productId }],
      },
      {
        headers:headers,
      }
    );

    // console.log("wishlist :: in api", response.data.wishlist);
    productContext.setWishlist(response?.data?.wishlist);
    toast.success("Add To Wishlist.", {
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
      },
      iconTheme: {
        primary: "#713200",
        secondary: "#FFFAEE",
      },
    });

    // toast("Whislist To Cart Successful");
  } catch (error) {
    console.error("Error storing cart products:", error?.response);
    // throw error;
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error?.response?.data?.message,
    });
  }
};
