// import React, { useState } from "react";
// import Card from "react-bootstrap/Card";
// import ListGroup from "react-bootstrap/ListGroup";

// function Filters({ filter, handleLabelClick }) {
//   const [selectedCheckboxes, setSelectedCheckboxes] = useState(new Set());

//   const [selectedOption, setSelectedOption] = useState({});

//   // const toggleCheckbox = (filterType) => {
//   //   const updatedSelectedCheckboxes = new Set(selectedCheckboxes);

//   //   if (updatedSelectedCheckboxes.has(filterType)) {
//   //     updatedSelectedCheckboxes.delete(filterType);
//   //   } else {
//   //     updatedSelectedCheckboxes.add(filterType);
//   //   }

//   //   setSelectedCheckboxes(updatedSelectedCheckboxes);
//   // };

//   const handleOptionChange = (filterItem, filterType) => {

//     setSelectedOption({
//       ...selectedOption,
//       [filterItem.filter.name]: filterType,
//     });
//     // ==================================================================================================================================================
//     // console.log("selected option is",filterItem)
//     // console.log("selected filterItem--->filter---> name is ",filterItem.filter.name,"======= selected body filerItem--->filterType",filterType)
 
//     let filterName=filterItem.filter.name;

//     // ============================================================================================================================================================
//     handleLabelClick(filterType,filterName);
//   };

//   return (
//     <div className="filter container">
//       <h5 className="text-uppercase fw-bold" >Filters</h5>

//       {filter.length > 0 ? (
//         <>
//           {filter.map((filterItem, index) => (
//             <div key={index} className="filter-section  p-3" style={{background:"#F3F3F3"}}>
//               <h6 className="filter-title " style={{color:"black"}}>{filterItem?.filter?.name}</h6>
//               {/* <Card.Body>
//                 <div className="form-check">
//                   {filterItem?.filter_Types.map((filterType, i) => {
//                     const checkboxId = `filterCheckbox_${index}_${i}`;
//                     const isChecked = selectedCheckboxes.has(filterType);

//                     return (
//                       <div key={i}>
//                         <input
//                           className="form-check-input"
//                           type="checkbox"
//                           id={checkboxId}
//                           checked={isChecked}
//                           onChange={() => toggleCheckbox(filterType)}
//                         />

//                         <label
//                           className="form-check-label"
//                           htmlFor={checkboxId}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             handleLabelClick(filterType);
//                           }}
//                         >
//                           <span>{filterType}</span>
//                         </label>
//                       </div>
//                     );
//                   })}
//                 </div>
//               </Card.Body> */}





// {/* 
// ======================== given below is the radio box =================================
// */}




//               <div className="form-check  filter-body bg-white ">
//                 {filterItem?.filter_Types.map((filterType, i) => {
//                   const radioId = `filterRadio_${index}_${i}`;
//                   const isChecked =
//                     selectedOption[filterItem.filter.name] === filterType;

//                   return (
//                     <div key={i}>
//                       <input
//                         className="form-check-input ms-1 "
//                         style={{borderRadius:"0"}}
//                         type="radio"
//                         id={radioId}
//                         name={filterItem.filter.name}
//                         checked={isChecked}
//                         onChange={() =>
//                           handleOptionChange(filterItem, filterType)
//                         }
//                       />

//                       <label
//                         className="form-check-label filter-name ms-2"
//                         htmlFor={radioId}
//                       >
//                         <span>{filterType}</span>
//                       </label>
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           ))}
//         </>
//       ) : (
//         <>
//           <p className="text-danger">No Filters</p>
//         </>
//       )}
//     </div>
//   );
// }

// export default Filters;
// import React, { useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

// function Filters({ filter, handleLabelClick }) {
//   const [selectedOption, setSelectedOption] = useState({});
//   const [expandedSections, setExpandedSections] = useState(new Set());

//   const handleOptionChange = (filterItem, filterType) => {
//     setSelectedOption({
//       ...selectedOption,
//       [filterItem.filter.name]: filterType,
//     });
//     handleLabelClick(filterType, filterItem.filter.name);
//   };

//   const toggleSection = (filterName) => {
//     setExpandedSections(prev => {
//       const newExpandedSections = new Set(prev);
//       if (newExpandedSections.has(filterName)) {
//         newExpandedSections.delete(filterName);
//       } else {
//         newExpandedSections.add(filterName);
//       }
//       return newExpandedSections;
//     });
//   };

//   return (
//     <div className="filter container">
//       <h5 className="text-uppercase fw-bold" >Filters</h5>
//       <div className="mt-4">

//       {filter.length > 0 ? (
//         <>
//           {filter.map((filterItem, index) => {  
//             const isExpanded = expandedSections.has(filterItem.filter.name);
//             return (
//               <div key={index} className="filter-section p-3 " style={{ background: "#F3F3F3" }}>
//                 <div className="d-flex align-items-center" onClick={() => toggleSection(filterItem.filter.name)}>
//                   <h6 className="filter-title" style={{ color: "black", cursor: "pointer" }}>
//                     {filterItem?.filter?.name}
//                   </h6>
//                   <FontAwesomeIcon
//                     icon={isExpanded ? faChevronUp : faChevronDown}
//                     style={{ marginLeft: 'auto', color: 'black' }}
//                   />
//                 </div>

//                 {isExpanded && (
//                   <div className="form-check filter-body bg-white mt-2">
//                     {filterItem?.filter_Types.map((filterType, i) => {
//                       const radioId = `filterRadio_${index}_${i}`;
//                       const isChecked = selectedOption[filterItem.filter.name] === filterType;

//                       return (
//                         <div key={i} className="d-flex align-items-center">
//                           <input
//                             className="form-check-input ms-1"
//                             style={{ borderRadius: "0" }}
//                             type="checkbox"
//                             id={radioId}
//                             name={filterItem.filter.name}
//                             checked={isChecked}
//                             onChange={() => handleOptionChange(filterItem, filterType)}
//                           />
//                           <label
//                             className="form-check-label filter-name ms-2"
//                             htmlFor={radioId}
//                           >
//                             <span>{filterType}</span>
//                           </label>
//                         </div>
//                       );
//                     })}
//                   </div>
//                 )}
//               </div>
//             );
//           })}
//         </>
//       ) : (
//         <p className="text-danger">No Filters</p>
//       )}
//       </div>

//     </div>
//   );
// }

// export default Filters;


//===================================================================================================================================
//===================================================================================================================================
//===================================================================================================================================
//===================================================================================================================================

// import React, { useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
// import { useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

// function Filters({ filter, handleLabelClick }) {
//   const [selectedOption, setSelectedOption] = useState({});
//   const [expandedSections, setExpandedSections] = useState(new Set());
//   const { filterSection } = useParams();
//   const navigate = useNavigate();
  
//   // console.log("filter value is the ----------->",filter)

//   const handleOptionChange = (filterItem, filterType) => {
//     setSelectedOption({
//       ...selectedOption,
//       [filterItem.filter.name]: filterType,
//     });
//     handleLabelClick(filterType, filterItem.filter.name);
//   };

//   const toggleSection = (filterName) => {
//     setExpandedSections(prev => {
//       const newExpandedSections = new Set(prev);
//       if (newExpandedSections.has(filterName)) {
//         newExpandedSections.delete(filterName);
//       } else {
//         newExpandedSections.add(filterName);
//       }
//       return newExpandedSections;
//     });
//   };


// const clearFilters = () => {  
//   setSelectedOption({});
//   setExpandedSections(new Set());
// window.location.reload();

//   // Optionally, you can call handleLabelClick with null or empty values to notify parent components
//   // filter.forEach(filterItem => handleLabelClick(null, filterItem.filter.name));
// };

//   return (
//     <div className="filter container">
// <div className="d-flex justify-content-between align-items-center">
//         <h5 className="text-uppercase fw-bold">Filters</h5>
//         {Object.keys(selectedOption).length > 0 && (
//           <button className="btn btn-outline-danger btn-sm me-3" onClick={clearFilters}>
//             Clear Filters
//           </button>
//         )}
//       </div>
//       <div className="mt-4">

//       {filter.length > 0 ? (
//         <>
//           {filter.map((filterItem, index) => {  
//             const isExpanded = expandedSections.has(filterItem.filter.name);
//             return (
//               <div key={index} className="filter-section p-3 " style={{ background: "#F3F3F3" }}>
//                 <div className="d-flex align-items-center" onClick={() => toggleSection(filterItem.filter.name)}>
//                   <h6 className="filter-title" style={{ color: "black", cursor: "pointer" }}>
//                     {filterItem?.filter?.name}
//                   </h6>
//                   <FontAwesomeIcon
//                     icon={isExpanded ? faChevronUp : faChevronDown}
//                     style={{ marginLeft: 'auto', color: 'black' }}
//                   />
//                 </div>

//                 {isExpanded && (
//                   <div className="form-check filter-body bg-white mt-2">
//                     {filterItem?.filter_Types.map((filterType, i) => {
//                       const radioId = `filterRadio_${index}_${i}`;
//                       const isChecked = selectedOption[filterItem.filter.name] === filterType;

//                       return (
//                         <div key={i} className="d-flex align-items-center">
//                           <input
//                             className="form-check-input ms-1"
//                             style={{ borderRadius: "0" }}
//                             type="checkbox"
//                             id={radioId}
//                             name={filterItem.filter.name}
//                             // checked={isChecked}
//                             onChange={() => handleOptionChange(filterItem, filterType)}
//                           />
//                           <label
//                             className="form-check-label filter-name ms-2"
//                             htmlFor={radioId}
//                           >
//                             <span>{filterType}</span>
//                           </label>
//                         </div>
//                       );
//                     })}
//                   </div>
//                 )}
//               </div>
//             );
//           })}
//         </>
//       ) : (
//         <p className="text-danger">No Filters</p>
//       )}
//       </div>

//     </div>
//   );
// }

// export default Filters;

//==========================================================================================================================================================
//==========================================================================================================================================================
//==========================================================================================================================================================
//==========================================================================================================================================================
//==========================================================================================================================================================



// import React, { useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

// // Function to remove duplicates and structure data
// const processPayload = (names, values) => {
//   const filterMap = {};

//   names.forEach((name, index) => {
//     if (!filterMap[name]) {
//       filterMap[name] = new Set();
//     }
//     filterMap[name].add(values[index]);
//   });

//   // Convert the filter map into an array of objects
//   return Object.keys(filterMap).map(name => ({
//     filter: { name },
//     filter_Types: Array.from(filterMap[name])
//   }));
// };

// function Filters({ names, values, handleLabelClick }) {
//   const [selectedOptions, setSelectedOptions] = useState({});
//   const [expandedSections, setExpandedSections] = useState(new Set());

//   const handleOptionChange = (filterItem, filterType) => {
//     setSelectedOptions(prev => {
//       const currentSelection = prev[filterItem.filter.name] || [];
//       const newSelection = currentSelection.includes(filterType)
//         ? currentSelection.filter(option => option !== filterType)
//         : [...currentSelection, filterType];
        
//       handleLabelClick(newSelection, filterItem.filter.name);

//       return {
//         ...prev,
//         [filterItem.filter.name]: newSelection
//       };
//     });
//   };

//   const toggleSection = (filterName) => {
//     setExpandedSections(prev => {
//       const newExpandedSections = new Set(prev);
//       if (newExpandedSections.has(filterName)) {
//         newExpandedSections.delete(filterName);
//       } else {
//         newExpandedSections.add(filterName);
//       }
//       return newExpandedSections;
//     });
//   };

//   const clearFilters = () => {
//     setSelectedOptions({});
//     setExpandedSections(new Set());
//   };

//   // Process the payload to get unique filter names and values
//   const filters = processPayload(names, values);

//   return (
//     <div className="filter container">
//       <div className="d-flex justify-content-between align-items-center">
//         <h5 className="text-uppercase fw-bold">Filters</h5>
//         {Object.keys(selectedOptions).length > 0 && (
//           <button className="btn btn-outline-danger btn-sm me-3" onClick={clearFilters}>
//             Clear Filters
//           </button>
//         )}
//       </div>
//       <div className="mt-4">
//         {filters.length > 0 ? (
//           filters.map((filterItem, index) => {
//             const filterName = filterItem.filter.name;
//             const isExpanded = expandedSections.has(filterName);

//             return (
//               <div key={index} className="filter-section p-3 " style={{ background: "#F3F3F3" }}>
//                 <div className="d-flex align-items-center" onClick={() => toggleSection(filterName)}>
//                   <h6 className="filter-title" style={{ color: "black", cursor: "pointer" }}>
//                     {filterName}
//                   </h6>
//                   <FontAwesomeIcon
//                     icon={isExpanded ? faChevronUp : faChevronDown}
//                     style={{ marginLeft: 'auto', color: 'black' }}
//                   />
//                 </div>
//                 {isExpanded && (
//                   <div className="form-check filter-body bg-white mt-2">
//                     {filterItem.filter_Types.map((filterType, i) => {
//                       const radioId = `filterRadio_${index}_${i}`;
//                       const isChecked = selectedOptions[filterName]?.includes(filterType) || false;

//                       return (
//                         <div key={i} className="d-flex align-items-center">
//                           <input
//                             className="form-check-input ms-1"
//                             style={{ borderRadius: "0" }}
//                             type="checkbox"
//                             id={radioId}
//                             name={filterName}
//                             checked={isChecked}
//                             disabled={isChecked} // Disable checkbox if it is checked
//                             onChange={() => handleOptionChange(filterItem, filterType)}
//                           />
//                           <label
//                             className="form-check-label filter-name ms-2"
//                             htmlFor={radioId}
//                           >
//                             <span>{filterType}</span>
//                           </label>
//                         </div>
//                       );
//                     })}
//                   </div>
//                 )}
//               </div>
//             );
//           })
//         ) : (
//           <p className="text-danger">No Filters</p>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Filters;


//====================================================================================================================================================================================================
//====================================================================================================================================================================================================
//====================================================================================================================================================================================================
//====================================================================================================================================================================================================
//====================================================================================================================================================================================================

import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Filters({ filter, handleLabelClick }) {
  const [selectedOption, setSelectedOption] = useState({});
  const [expandedSections, setExpandedSections] = useState(new Set());
  const { filterSection } = useParams();
  const navigate = useNavigate();
  
  const handleOptionChange = (filterItem, filterType) => {
    setSelectedOption({
      ...selectedOption,
      [filterItem.filter.name]: filterType,
    });
    handleLabelClick(filterType, filterItem.filter.name);
  };

  const toggleSection = (filterName) => {
    setExpandedSections(prev => {
      const newExpandedSections = new Set(prev);
      if (newExpandedSections.has(filterName)) {
        newExpandedSections.delete(filterName);
      } else {
        newExpandedSections.add(filterName);
      }
      return newExpandedSections;
    });
  };


const clearFilters = () => {  
  setSelectedOption({});
  setExpandedSections(new Set());
window.location.reload();

};

  return (
    <div className="filter container">
<div className="d-flex justify-content-between align-items-center">
        <h5 className="text-uppercase fw-bold">Filters</h5>
        {Object.keys(selectedOption).length > 0 && (
          <button className="btn btn-outline-danger btn-sm me-3" onClick={clearFilters}>
            Clear Filters
          </button>
        )}
      </div>
      <div className="mt-4">

      {filter.length > 0 ? (
        <>
          {filter.map((filterItem, index) => {  
            const isExpanded = expandedSections.has(filterItem.filter.name);
            return (
              <div key={index} className="filter-section p-3 " style={{ background: "#F3F3F3" }}>
                <div className="d-flex align-items-center" onClick={() => toggleSection(filterItem.filter.name)}>
                  <h6 className="filter-title" style={{ color: "black", cursor: "pointer" }}>
                    {filterItem?.filter?.name}
                  </h6>
                  <FontAwesomeIcon
                    icon={isExpanded ? faChevronUp : faChevronDown}
                    style={{ marginLeft: 'auto', color: 'black' }}
                  />
                </div>

                {isExpanded && (
                  <div className="form-check filter-body bg-white mt-2">
                    {filterItem?.filter_Types.map((filterType, i) => {
                      const radioId = `filterRadio_${index}_${i}`;
                      const isChecked = selectedOption[filterItem.filter.name] === filterType;

                      return (
                        <div key={i} className="d-flex align-items-center">
                          <input
                            className="form-check-input ms-1"
                            style={{ borderRadius: "0" }}
                            type="checkbox"
                            id={radioId}
                            name={filterItem.filter.name}
                            // checked={isChecked}
                            onChange={() => handleOptionChange(filterItem, filterType)}
                          />
                          <label
                            className="form-check-label filter-name ms-2"
                            htmlFor={radioId}
                          >
                            <span>{filterType}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <p className="text-danger">No Filters</p>
      )}
      </div>

    </div>
  );
}

export default Filters;
