import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { toast } from "react-hot-toast";

export const storeCartProducts = async ({ productId, quantity, slug, headers }) => {
  try {
    const userId=localStorage.getItem("userId")
          const headers1 = {
            Authorization: `Bearer ${userId}`,
            "Content-Type": "application/json",
          };
    const response = await axios.post(
      `${API_BASE_URL}/user/order/addtocart/`,
      {
        cartItems: [{ productId, quantity }],
      },
      {
        headers:headers1,
      }
    );

    // console.log("addtocart", response?.data);

    localStorage.setItem("addtocartLength", response?.data?.cart?.length);
    // toast.success("Add Cart Successfully");
  } catch (error) {
    console.error("Error storing cart products:", error?.response);

    if (error?.response?.status === 401) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.error,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
    }
  }
};
