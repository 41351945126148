import React from "react";
import email_icon from "../../Assets/Images/mail-icon.png";
import Headset_icon from "../../Assets/Images/Nav-Headphones-icon.png";
import AuthAndCartStatus from "./AuthAndCartStatus";

function SubHeader() {
  const subHeaderData = [
    {
      icon: Headset_icon,
      title: "Call Us Now",
      // contactInfo: "tel:+91 63837 35782",
      contactInfo: "tel:+91 78128 10488",
      content:"+91 78128 10488",
      altname:"contact"
    },
    {
      icon: email_icon,
      title: "Mail Us",
      // contactInfo: "mailto:info@bestindiakart.com",
      contactInfo: "mailto:marketing@bumaas.com",
      content:"marketing@bumaas.com",
      altname:"mail us",
    },
  ];

  return (
    <div className="header">
      <p className="header-text">WELCOME TO OUR STORE...!</p>

      <div className="nav-content d-flex">
        {subHeaderData?.map((item, index) => (
          <a
            href={item?.contactInfo}
            className="d-flex align-items-center text-decoration-none text-light"
            target="_blank"
            key={index}
          >
            <img className="nav-icon" src={item?.icon} alt={item.altname}/>
            <div>
              {/* <p className="m-0 nav-title">{item?.title}</p> */}
              {/* <p className="m-0 nav-number">+91 78128 10488</p> */}
              <p className="m-0 nav-number">{item?.content}</p>
            </div>
          </a>
        ))}
        <AuthAndCartStatus />
      </div>

    </div>
  );
}

export default SubHeader;