import React from "react";
import "./KeepUp.scss";
import plant from "../../Assets/Images/plane.png.png";
import { TbMailFilled } from "react-icons/tb";
import { Link } from "react-router-dom";

export default function KeepUp() {
  return (
    <div>
      <div className="keep-up m-0" style={{background:"#0443C0"}}>
        <div className="left">
          <h3 className="keep-up-title">
            Keep up to date - Get e-mail updates
          </h3>
          <p className="keep-up-content">
            stay connected with our latest company news.
          </p>
        </div>

        <img className="plant" src={plant} alt="plant" />

        {/* <form className="right">
          <div className="icon">
            <TbMailFilled className="mail-icon" />
          </div>
          <input
            type="email"
            id="email"
            name="email"
            className="keep-up-input"
            placeholder="Enter your email address"
            required
          />
          <div className="button-wrapper">
            <button className="keep-up-button">Sign Up</button>
          </div>
        </form> */}
        <Link to={"/registration"}>
          {" "}
          <button
            className="button"
            style={{
              backgroundColor: "#f04b25",
              border: "navajowhite",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
              fontWeight: "500",
              ":hover": {
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
              },
            }}
          >
            Register
          </button>
        </Link>
      </div>
    </div>
  );
}
