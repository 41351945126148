// import React from "react";
// import "../CopyRights/copyRights.scss";
// import { Link } from "react-router-dom";
// import pay_1 from "../../Assets/Images/pay1.png.png";
// import pay_2 from "../../Assets/Images/pay2.png.png";
// import pay_3 from "../../Assets/Images/pay3.png.png";
// import pay_4 from "../../Assets/Images/pay4.png.png";
// import pay_5 from "../../Assets/Images/pay5.png.png";

// function CopyRights() {
//   const date = new Date();
//   const getCurrentYear = date.getFullYear();

//   const payments = [
//     {
//       icons: pay_1,
//     },
//     {
//       icons: pay_2,
//     },
//     {
//       icons: pay_3,
//     },
//     {
//       icons: pay_4,
//     },
//     {
//       icons: pay_5,
//     },
//   ];

//   return (
//     <>
//       <div className="copy-right-page">
//         <Link to="https://webdads2u.com/" target="_blank">
//           &copy; {getCurrentYear} .All Right Reserved. Design and Developed by
//           WEBDADS2U PVT LTD
//         </Link>

//         <div className="payment-method">
//           {payments.map((item, i) => (
//             <img src={item.icons} alt="icons" kay={i} />
//           ))}
//         </div>
//       </div>
//     </>
//   );
// }

// export default CopyRights;




import React from "react";
import "../CopyRights/copyRights.scss";
import { Link } from "react-router-dom";
import pay_1 from "../../Assets/Images/pay1.png.png";
import pay_2 from "../../Assets/Images/pay2.png.png";
import pay_3 from "../../Assets/Images/pay3.png.png";
import pay_4 from "../../Assets/Images/pay4.png.png";
import pay_5 from "../../Assets/Images/pay5.png.png";




function CopyRights() {
  const date = new Date();
  const getCurrentYear = date.getFullYear();

  const payments = [
    {
      icons: pay_1,
    },
    {
      icons: pay_2,
    },
    {
      icons: pay_3,
    },
    {
      icons: pay_4,
    },
    {
      icons: pay_5,
    },
  ];

  return (
    <>
      <div className="copy-right-page">
        <Link to="https://webdads2u.com/" target="_blank">
          &copy; {getCurrentYear} .All Right Reserved. BestIndiaKart and  Developed by
          WEBDADS2U PVT LTD
        </Link>

        <div className="payment-method">
          {payments?.map((item, i) => (
            <img src={item?.icons} alt="icons" key={i} />
          ))}
        </div>
      </div>
    </>
  );
}

export default CopyRights;
