import React, { useContext, useEffect } from "react";
import "../CheckOut/CheckOut.scss";
import { useState } from "react";
import "../../Common/Common.scss";
import { useNavigate } from "react-router-dom";
import PlaceOrder from "./PlaceOrder";
import Billing from "./Billing";
import { useAuthHeaders } from "../../components/Token";
import axios from "axios";
import { API_BASE_URL, Key_Id, key_secret } from "../../utils";
import userContext from "../../components/UseContext/UseContext";
import Swal from "sweetalert2/dist/sweetalert2.js";

function CheckOut() {
  const [firstName, setFirstNumber] = useState("");
  const [lastName, setLastNumber] = useState("");
  const [streetAdderss, setStreetAdderss] = useState("");
  const [countery, setCountery] = useState("");
  const [state, setState] = useState("");
  const [districtSelect, setSelectDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [phone, setphone] = useState("");
  const [email, setEmail] = useState("");
  const [placeOrder, setPlaceOrder] = useState([]);
  const productContext = useContext(userContext);
  const navigate = useNavigate();
  const headers = useAuthHeaders();
  const [shipToDifferentAddress, setShipToDifferentAddress] = useState(false);
  const [alternateFirstName, setAlternateFirstNumber] = useState("");
  const [alternateLastName, setAlternateLastNumber] = useState("");
  const [company, setCompany] = useState("");
  const [alternateAddress, setAlternateAddress] = useState("");
  const [alternateCountery, setAlternateCountery] = useState("");
  const [alternateState, setAlternateState] = useState("");
  const [alternateDistrictSelect, setalternateDistrictSelect] = useState("");
  const [alternatePinCode, setalternatePinCode] = useState("");
  const [chooseAddress, setChooseAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [privacyPolicey, setPrivacyPolicey] = useState(false);
  const [termsAndConditions, setTermsAndConditons] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (!userId) {
      navigate("/");
      return;
    }

    const headers = {
      Authorization: `Bearer ${userId}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${API_BASE_URL}/user/order/getcart`, {
        headers,
      })
      .then((res) => {
        setPlaceOrder(res?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });

    axios
      .get(`${API_BASE_URL}/myprofile`, {
        headers,
      })
      .then((result) => {
        setEmail(result?.data?.user?.email);
        setphone(result?.data?.user?.mobileno);
        setFirstNumber(result?.data?.user?.name);
        if (result?.data?.user?.address.length !== 0) {
          setChooseAddress(result?.data?.user?.address);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });

    axios
      .get(`${API_BASE_URL}/user/order/myorder`, {
        headers,
      })
      .then((result) => {
        // console.log("My order api", result.data.orders);
        // setChooseAddress(result?.data?.orders);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const subTotal = placeOrder?.cartProductsTotals?.find(
    (item) => item?.type === "Sub Total"
  );

  const total = placeOrder?.cartProductsTotals?.find(
    (item) => item?.type === "Total"
  );

  const shipping = placeOrder?.cartProductsTotals?.find(
    (item) => item?.type === "Shipping"
  );

  const GST = placeOrder?.cartProductsTotals?.find(
    (item) => item?.type === "GST (18%)" || "Total IGST (18%)"
  );

  const handlePayment = async (customHandler) => {
    try {
      const userId = localStorage.getItem("userId");

      if (!userId) {
        navigate("/");
        return;
      }

      const headers = {
        Authorization: `Bearer ${userId}`,
        "Content-Type": "application/json",
      };

      setLoading(true);
      const res = await axios.post(
        `${API_BASE_URL}/user/order/neworder`,
        {
          shippingInfo: {
            firstname: firstName,
            lastname: lastName,
            streetaddress: streetAdderss,
            country: countery,
            state: state,
            postalCode: pinCode,
            phone: phone,
            email: email,
            district: districtSelect,
          },

          shipToDifferentAddress: shipToDifferentAddress,

          orderItems: placeOrder?.products.map((product) => ({
            productId: product?.productId,
            quantity: product?.quantity,
            productSubTotal: product?.subTotal,
          })),

          subTotal: subTotal?.value,
          shipping: shipping?.value,
          orderTotal: total?.value,
          gstAmount: GST?.value,

          privacy_policy: privacyPolicey,
          terms_condition: termsAndConditions,

          alternateAddress: {
            alternateFirstName: alternateFirstName,
            alternateLastName: alternateLastName,
            alternateStreetAddress: alternateAddress,
            alternateCompanyName: company,
            alternateCountry: alternateCountery,
            alternateState: alternateState,
            alternateTownCity: alternateDistrictSelect,
            alternatePostalCode: alternatePinCode,
          },
        },
        { headers:headers }
      );

      const { order } = res?.data;

      const options = {
        key: Key_Id,
        key_secret: key_secret,
        amount: order.orderTotal * 100,
        currency: "INR",
        name: "Your Company Name",
        description: "Payment for Order",
        order_id: order.razorpayOrderId,
        handler: function (response) {
          // console.log(response);
          axios
            .post(
              `${API_BASE_URL}/order/verifypayment`,
              {
                orderId: response?.razorpay_order_id,
                paymentId: response?.razorpay_payment_id,
                signature: response?.razorpay_signature,
              },
              {
                headers,
              }
            )
            .then((apiResponse) => {
              console.log(
                "verifypayment post API response:",
                apiResponse?.data
              );

              if (apiResponse?.data?.success) {
                Swal.fire({
                  icon: "success",
                  title: "Payment successful!",
                  showConfirmButton: false,
                  timer: 1500,
                });

                if (customHandler) {
                  customHandler(apiResponse?.data);
                }

                // cart length mines
                axios
                  .get(`${API_BASE_URL}/user/order/getcart`, {
                    headers,
                  })
                  .then((result) => {
                    productContext.setAddCartLength(
                      result?.data?.cartItemLength
                    );

                    localStorage.setItem(
                      "addtocartLength",
                      result?.data?.cartItemLength
                    );

                    // setEmail(result.data.user.email);
                  })
                  .catch((error) => {
                    console.log("error", error);
                  });

                navigate("/");
              } else {
                console.error("API error:", apiResponse?.data?.message);
              }
            })
            .catch((error) => {
              console.error("API error:", error);
            });
        },
        prefill: {
          name: `${firstName} ${lastName}`,
          email: email,
          contact: phone,
        },
      };

      // console.log(window.Razorpay);

      const rzpay = new window.Razorpay(options);
      rzpay.open();
    } catch (error) {
      console.error("Error during payment:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.response?.data?.message,
      });
    }
  };

  const handlePlaceOrder = (e) => {
    e.preventDefault();
    handlePayment();
  };

  return (
    <>
      <div className="listProducts-banner">
        <h2>Check Out</h2>
      </div>

      <div className="px-2r">
        <form className="row billing my-4" onSubmit={handlePlaceOrder}>
          <Billing
            firstName={firstName}
            setFirstNumber={setFirstNumber}
            lastName={lastName}
            setLastNumber={setLastNumber}
            streetAdderss={streetAdderss}
            setStreetAdderss={setStreetAdderss}
            countery={countery}
            setCountery={setCountery}
            state={state}
            setState={setState}
            setPinCode={setPinCode}
            pinCode={pinCode}
            phone={phone}
            setphone={setphone}
            setEmail={setEmail}
            email={email}
            placeOrder={placeOrder}
            setPlaceOrder={setPlaceOrder}
            shipToDifferentAddress={shipToDifferentAddress}
            setShipToDifferentAddress={setShipToDifferentAddress}
            setSelectDistrict={setSelectDistrict}
            setCompany={setCompany}
            alternateFirstName={alternateFirstName}
            setAlternateFirstNumber={setAlternateFirstNumber}
            alternateLastName={alternateLastName}
            setAlternateLastNumber={setAlternateLastNumber}
            alternateAddress={alternateAddress}
            setAlternateAddress={setAlternateAddress}
            setAlternateCountery={setAlternateCountery}
            setAlternateState={setAlternateState}
            setalternateDistrictSelect={setalternateDistrictSelect}
            setalternatePinCode={setalternatePinCode}
            alternateCountery={alternateCountery}
            alternateState={alternateState}
            districtSelect={districtSelect}
            alternateDistrictSelect={alternateDistrictSelect}
            alternatePinCode={alternatePinCode}
            company={company}
            chooseAddress={chooseAddress}
          />

          <PlaceOrder
            placeOrder={placeOrder}
            state={state}
            setPlaceOrder={setPlaceOrder}
            loading={loading}
            setPrivacyPolicey={setPrivacyPolicey}
            privacyPolicey={privacyPolicey}
            setTermsAndConditons={setTermsAndConditons}
            termsAndConditions={termsAndConditions}
          />
        </form>
      </div>
    </>
  );
}

export default CheckOut;
