// import React, { useState, useEffect } from "react";

// function ErrorBoundary(props) {
//   const [hasError, setHasError] = useState(false);

//   useEffect(() => {
//     const componentDidCatch = (error, info) => {
//       // console.error("Error caught by error boundary:", error, info);
//       setHasError(true);
//     };

//     const handleFetchError = (event) => {
//       if (event && event.status === 401) {
//         console.error("Unauthorized access detected.");
//         // Remove userId from localStorage
//         localStorage.removeItem("userId");
//         // Set hasError to true to trigger error rendering
//         setHasError(true);
//       }
//     };

//     window.addEventListener("error", componentDidCatch);
//     window.addEventListener("fetchError", handleFetchError);

//     return () => {
//       window.removeEventListener("error", componentDidCatch);
//       window.removeEventListener("fetchError", handleFetchError);
//     };
//   }, []);

//   if (hasError) {
//     return (
//       <div className="m-4">
//         <h2>Oops! Something went wrong.</h2>
//         <p>We're sorry, but there was an error loading this content.</p>
//         <p>Please try refreshing the page or check back later.</p>
//       </div>
//     );
//   }

//   return props.children;
// }

// export default ErrorBoundary;






import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error("Error caught by error boundary:", error, info);
  }

  handleFetchError = (event) => {
    if (event && event?.status === 401) {
      console.error("Unauthorized access detected.");
      // Remove userId from localStorage
      localStorage.removeItem("userId");
      // Set hasError to true to trigger error rendering
      this.setState({ hasError: true });
    }
  };

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.handleUnhandledRejection);
    window.addEventListener("fetchError", this.handleFetchError);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.handleUnhandledRejection);
    window.removeEventListener("fetchError", this.handleFetchError);
  }

  handleUnhandledRejection = (event) => {
    console.error("Unhandled promise rejection:", event.reason);
    this.setState({ hasError: true });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="m-4">
          <h2>Oops! Something went wrong.</h2>
          <p>We're sorry, but there was an error loading this content.</p>
          <p>Please try refreshing the page or check back later.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
