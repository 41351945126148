import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function FilterTable({ searchResult }) {

  // console.log("searchResult------------?", searchResult);

  const descriptionTemplate =
    searchResult?.length > 0 ? searchResult[0]?.descriptions : [];

    const replaceSpacesWithHyphens = (urlString) => {

      if (typeof urlString === 'string') {
        // Replace all spaces with hyphens
        return urlString.replace(/ /g, '-').toLocaleLowerCase();
      }else return urlString;
  }
  
  return (
    <Table striped="columns" className="product-table  ">
      <thead>
        <tr>
          <th className="title text-center">Part Number</th>

          {descriptionTemplate.map((description, index) => (
            <th className="title text-center" key={index}>
              {description?.name}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {searchResult?.length > 0 ? (
          searchResult?.map((result, resultIndex) => (
            <tr key={resultIndex} className="text-center">
              <td className="value">
                <Link
                  // to={`/product/${result?._id}`}
                  to={result?.descriptions?.[0]?.value !==undefined? 
                    `/${replaceSpacesWithHyphens(result?.category?.name)}/${replaceSpacesWithHyphens(result?.subcategoryname?.name)}/${replaceSpacesWithHyphens(result?.descriptions[0]?.value)}/${result?.slug}` 
                    : 
                    `/product/${result?.slug}`}
                  className="text-decoration-none table-partNumber text-dark px-1"
                >
                  {result.partNumber}
                </Link>
              </td>
              {descriptionTemplate?.map((templateDescription, descIndex) => {
                
                const currentDescription = result?.descriptions?.find(
                  (description) => description?.name === templateDescription?.name
                );

                return (
                  <td className="value text-center" key={descIndex}>
                    <Link
                      // to={`/product/${result?._id}`}
                      // to={`/product/${result?.partNumber}`}
                      // to={`/product/${result?.slug}`}
                      to={result?.descriptions?.[0]?.value !==undefined? 
                        `/${replaceSpacesWithHyphens(result?.category?.name)}/${replaceSpacesWithHyphens(result?.subcategoryname?.name)}/${replaceSpacesWithHyphens(result?.descriptions[0]?.value)}/${result?.slug}` 
                        : 
                        `/product/${result?.slug}`}
                      className="text-decoration-none text-dark px-1"
                    >
                      {currentDescription ? currentDescription?.value : "-"}
                    </Link>
                  </td>
                );
              })}
            </tr>
          ))
        ) : (
          <h6 className="text-danger">No Products</h6>
        )}
      </tbody>
    </Table>
  );
}

export default FilterTable;
