// import React, { useState, useEffect } from "react";
// import Countdown from "react-countdown";

// function DealCountdown({ expiryDate }) {

//   const calculateTimeRemaining = () => {
//     const currentTime = new Date().getTime();
//     const expiryTime =
//       new Date(expiryDate).getTime() - new Date().getTimezoneOffset() * 60000;
//     const timeDifference = expiryTime - currentTime;

//     return timeDifference;
//   };

//   const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setTimeRemaining(calculateTimeRemaining());
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const totalHours = Math.floor(
//     (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
//   );

//   const hours = totalHours < 10 ? `0${totalHours}` : totalHours;

//   const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
//   const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

//   return (
//     <div className="deal-time-container">
//       <div className="deal-time-part hours">
//         <p>{hours}</p>
//         <span>Hours</span>
//       </div>
//       <div className="deal-time-part minutes">
//         <p>{minutes}</p>
//         <span>Mins</span>
//       </div>
//       <div className="deal-time-part seconds">
//         <p>{seconds}</p>
//         <span>Sec</span>
//       </div>
//     </div>
//   );
// }

// export default DealCountdown;

import React, { useState, useEffect } from "react";

function DealCountdown({ expiryDate }) {
  const calculateTimeRemaining = () => {
    const currentTime = new Date().getTime();
    const expiryTime =
      new Date(expiryDate)?.getTime() - new Date().getTimezoneOffset() * 60000;
    const timeDifference = expiryTime - currentTime;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const { days, hours, minutes, seconds } = timeRemaining;

  const formattedValue = (value) => (value < 10 ? `0${value}` : value);

  return (
    <div className="deal-time-container">
      <div className="deal-time-part days">
        <p>{formattedValue(days)}</p>
        <span>Days</span>
      </div>
      <div className="deal-time-part hours">
        <p>{formattedValue(hours)}</p>
        <span>Hours</span>
      </div>
      <div className="deal-time-part minutes">
        <p>{formattedValue(minutes)}</p>
        <span>Mins</span>
      </div>
      <div className="deal-time-part seconds">
        <p>{formattedValue(seconds)}</p>
        <span>Sec</span>
      </div>
    </div>
  );
}

export default DealCountdown;
