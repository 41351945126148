import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../components/ProductOveriew/ProductOveriew.scss";
import { AiOutlineEye } from "react-icons/ai";
import AddCartButton from "../Buttons/AddCartButton";

export default function ProductOveriew({ product }) {
  const [modalShow, setModalShow] = useState(false);

   return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row over-view-container">
            <div className="col-lg-6">
              <div className="product-overview-image">
                {product?.productimages?.map((image, i) => {
                  return (
                    <img
                      src={image?.productimage || "null"}
                      key={i}
                      alt="product_overview"
                    />
                  );
                })}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="product-overview-content py-2">
                <h2>{product.product}</h2>
                <h6 className="price">Rs.{product?.saleprice}/-</h6>
                <div className="py-3">
                  <div>
                    <label htmlFor="">Part Number </label> :
                    <span>{product?.partNumber}</span>
                  </div>
                  <div>
                    <label htmlFor="">Product Weight </label> :
                    <span>{product?.productweight}</span>
                  </div>
                </div>

                <div className="buying-buttons">
                  {/* <input type="number" placeholder="Quantity" /> */}
                  <AddCartButton type="button" data={product} />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div onClick={() => setModalShow(true)}>
        <AiOutlineEye className="card-menu" />
      </div>
    </>
  );
}
