import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  Box,
  Typography,
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import "./PrivacyPolicy.scss";
import { Helmet } from "react-helmet";
const TermsAndConditionsOfUse = () => {
  return (
    <section className="privacy-section">
       <Helmet>
        <title>Terms And Conditions | Best India Kart screw manufacturers in india</title>
        <meta name="description" content="Best India Kart manufactures premium-quality screws for diverse industries in India, ensuring strength, precision, and reliability with every product."/>
        {/* <meta name="keywords" content="Jobs, Service,Automobiles,Store, Education, Travels, Event"/> */}
      </Helmet>
      <Container className="privacy-policy-wrapper">
        <h1 className="privacypolicy-page-heading">
          TERMS AND CONDITIONS OF USE
        </h1>
        <Box mt={4}>
          <Typography variant="subtitle1" gutterBottom>
            Welcome to BEST Indiakart!
            <br />
            <br />
            These terms and conditions of use ("Terms of Use") constitute a
            legal agreement between you ("you" or "user") and Bumaas Engineering
            Solution And Technologies Pvt Ltd ("BEST Indiakart", "we", "us", or
            "our") governing your use of the BEST Indiakart website ("Website")
            and its associated services ("Services"). By accessing or using the
            Website and Services, you agree to be bound by these Terms of Use.
            If you do not agree to these Terms of Use, please do not use the
            Website or Services.
          </Typography>
          <Row>
            <Col lg={8}>
              <Box mt={3} className="privacy-policy-content">
                <Typography variant="h6" component="h3" id="1" gutterBottom>
                  1. ACCEPTANCE OF TERMS
                </Typography>
                <Typography variant="body1" gutterBottom>
                  1.1. By using the Website or Services, you agree to comply
                  with and be legally bound by these Terms of Use, our Privacy
                  Policy, and all other policies and guidelines posted on the
                  Website.
                  <br /> <br />
                  1.2. We reserve the right to amend or modify these Terms of
                  Use at any time without prior notice. Your continued use of
                  the Website or Services after any such changes constitutes
                  your acceptance of the new Terms of Use.
                </Typography>
                <Typography variant="h6" component="h3" id="2" gutterBottom>
                  2. REGISTRATION AND ACCOUNT{" "}
                </Typography>
                <Typography variant="body2" component="p" gutterBottom>
                  2.1. To access certain features of the Website or Services,
                  you may be required to register for an account. You agree to
                  provide accurate, current, and complete information during the
                  registration process and to update such information to keep it
                  accurate, current, and complete.
                  <br />
                  <br />
                  2.2. You are responsible for maintaining the confidentiality
                  of your account and password and for restricting access to
                  your account. You agree to accept responsibility for all
                  activities that occur under your account.
                </Typography>
                <Typography variant="h6" component="h3" id="3" gutterBottom>
                  3. USE OF THE WEBSITE AND SERVICES{" "}
                </Typography>
                <Typography variant="body3" component="p" gutterBottom>
                  3.1. You agree to use the Website and Services only for lawful
                  purposes and in accordance with these Terms of Use.
                  <br />
                  <br />
                  3.2. You agree not to:
                  <br />
                  <br />
                  3.2.1. Violate any applicable law or regulation;
                  <br />
                  <br />
                  3.2.2. Use the Website or Services for any fraudulent or
                  unlawful purpose;
                  <br />
                  <br />
                  3.2.3. Impersonate any person or entity, or falsely state or
                  otherwise misrepresent your affiliation with a person or
                  entity;
                  <br />
                  <br />
                  3.2.4. Interfere with or disrupt the operation of the Website
                  or Services or the servers or networks connected to the
                  Website or Services;
                  <br />
                  <br />
                  3.2.5. Upload or transmit viruses or any other type of
                  malicious code that will or may be used in any way that will
                  affect the functionality or operation of the Website or
                  Services;
                  <br />
                  <br />
                  3.2.6. Collect or track the personal information of others;
                  <br />
                  <br />
                  3.2.7. Engage in any other conduct that restricts or inhibits
                  anyone's use or enjoyment of the Website or Services, or
                  which, as determined by us, may harm BEST Indiakart or users
                  of the Website or Services, or expose them to liability.
                </Typography>
                <Typography variant="h6" component="h3" id="4" gutterBottom>
                  4. INTELLECTUAL PROPERTY RIGHTS{" "}
                </Typography>
                <Typography variant="body4" component="p" gutterBottom>
                  4.1. The Website and its entire contents, features, and
                  functionality (including but not limited to all information,
                  software, text, displays, images, video, and audio, and the
                  design, selection, and arrangement thereof) are owned by BEST
                  Indiakart, its licensors, or other providers of such material
                  and are protected by Indian and international copyright,
                  trademark, patent, trade secret, and other intellectual
                  property or proprietary rights laws.
                  <br />
                  <br />
                  4.2. You are granted a limited, non-exclusive,
                  non-transferable, revocable license to access and use the
                  Website and Services for your personal, non-commercial use
                  only.
                </Typography>
                <Typography variant="h6" component="h3" id="5" gutterBottom>
                  5. PRODUCT LISTINGS AND ORDERS{" "}
                </Typography>
                <Typography variant="body5" component="p" gutterBottom>
                  5.1. All product listings on the Website are invitations to
                  make an offer to purchase. Your placement of an order
                  constitutes your offer to buy the products specified in the
                  order.
                  <br />
                  <br />
                  5.2. We reserve the right to refuse or cancel any order for
                  any reason at any time prior to shipment, including but not
                  limited to product availability, errors in product or pricing
                  information, or suspicion of fraudulent activity.
                  <br />
                  <br />
                  5.3. Upon acceptance of your order, we will send you an order
                  confirmation. The contract of sale is concluded only when the
                  product is dispatched, and we send you a shipment
                  confirmation.
                </Typography>
                <Typography variant="h6" component="h3" id="6" gutterBottom>
                  6. PRICING AND PAYMENT{" "}
                </Typography>
                <Typography variant="body6" component="p" gutterBottom>
                  6.1. All prices listed on the Website are in Indian Rupees and
                  are inclusive of applicable taxes unless otherwise stated.
                  <br />
                  <br />
                  6.2. Payment for orders must be made through the payment
                  methods available on the Website. You agree to pay all charges
                  incurred by your account and provide BEST Indiakart with valid
                  payment information.
                </Typography>
                <Typography variant="h6" component="h3" id="7" gutterBottom>
                  7. SHIPPING AND DELIVERY{" "}
                </Typography>
                <Typography variant="body7" component="p" gutterBottom>
                  7.1. Shipping and delivery terms are as specified in the
                  shipping policy available on the Website. Risk of loss and
                  title for products purchased from BEST Indiakart pass to you
                  upon delivery of the items to the carrier.
                </Typography>
                <Typography variant="h6" component="h3" id="8" gutterBottom>
                  8. RETURNS AND REFUNDS{" "}
                </Typography>
                <Typography variant="body8" component="p" gutterBottom>
                  8.1. Our returns and refunds policy is detailed in the returns
                  policy available on the Website. You agree to comply with the
                  terms of the returns policy in the event you wish to return a
                  product purchased from BEST Indiakart.{" "}
                </Typography>
                <Typography variant="h6" component="h3" id="9" gutterBottom>
                  9. WARRANTIES AND DISCLAIMERS{" "}
                </Typography>
                <Typography variant="body9" component="p" gutterBottom>
                  9.1. BEST Indiakart makes no representations or warranties of
                  any kind, express or implied, regarding the Website or
                  Services, including but not limited to warranties of
                  merchantability, fitness for a particular purpose, or
                  non-infringement.
                  <br />
                  <br />
                  9.2. BEST Indiakart does not warrant that the Website or
                  Services will be error-free or uninterrupted, that defects
                  will be corrected, or that the Website or Services or the
                  server that makes them available are free of viruses or other
                  harmful components.
                </Typography>
                <Typography variant="h6" component="h3" id="10" gutterBottom>
                  10. LIMITATION OF LIABILITY{" "}
                </Typography>
                <Typography variant="body10" component="p" gutterBottom>
                  10.1. In no event shall BEST Indiakart, its officers,
                  directors, employees, or agents be liable to you or any third
                  party for any indirect, consequential, exemplary, incidental,
                  special, or punitive damages, including lost profit damages
                  arising from your use of the Website or Services.
                </Typography>
                <Typography variant="h6" component="h3" id="11" gutterBottom>
                  11. INDEMNIFICATION{" "}
                </Typography>
                <Typography variant="body10" component="p" gutterBottom>
                  11.1. You agree to indemnify and hold harmless BEST Indiakart,
                  its affiliates, officers, directors, employees, and agents
                  from and against any and all claims, liabilities, damages,
                  losses, costs, expenses, or fees (including reasonable
                  attorneys' fees) arising from your use of the Website or
                  Services or your violation of these Terms of Use.{" "}
                </Typography>
                <Typography variant="h6" component="h3" id="12" gutterBottom>
                  12. TERMINATION{" "}
                </Typography>
                <Typography variant="body10" component="p" gutterBottom>
                  12.1. BEST Indiakart reserves the right to terminate or
                  suspend your access to the Website and Services without prior
                  notice for any reason, including if we believe that you have
                  violated these Terms of Use.
                  <br />
                  <br />
                  12.2. You may terminate these Terms of Use by discontinuing
                  your use of the Website and Services. Sections 4, 9, 10, 11,
                  and 13 shall survive termination of these Terms of Use.
                </Typography>
                <Typography variant="h6" component="h3" id="13" gutterBottom>
                  13. GOVERNING LAW AND DISPUTE RESOLUTION
                </Typography>
                <Typography variant="body10" component="p" gutterBottom>
                  13.1. These Terms of Use are governed by the laws of India,
                  without regard to its conflict of laws principles. Any dispute
                  arising out of or relating to these Terms of Use or your use
                  of the Website or Services shall be subject to the exclusive
                  jurisdiction of the courts in Chennai, Tamil Nadu.{" "}
                </Typography>
                <Typography variant="h6" component="h3" id="14" gutterBottom>
                  14. MISCELLANEOUS
                </Typography>
                <Typography variant="body10" component="p" gutterBottom>
                  14.1. These Terms of Use constitute the entire agreement
                  between you and BEST Indiakart regarding your use of the
                  Website and Services.
                  <br />
                  <br />
                  14.2. If any provision of these Terms of Use is found to be
                  invalid or unenforceable, that provision shall be limited or
                  eliminated to the minimum extent necessary so that these Terms
                  of Use shall otherwise remain in full force and effect.
                </Typography>
              </Box>
            </Col>
            <Col lg={4}>
              <Box mt={3} p={2} bgcolor="#f9f9f9" border={1} borderColor="#ddd">
                <Typography variant="h6" component="h3" gutterBottom>
                  Table of Contents
                </Typography>
                <List component="nav" aria-label="Table of Contents">
                  <ListItem button component="a" href="#1">
                    <ListItemText primary="1. Acceptance of Terms" />
                  </ListItem>
                  <ListItem button component="a" href="#2">
                    <ListItemText primary="2. Registration and Account" />
                  </ListItem>
                  <ListItem button component="a" href="#3">
                    <ListItemText primary="3. Use of the Website and Services" />
                  </ListItem>
                  <ListItem button component="a" href="#4">
                    <ListItemText primary="4. Intellectual Property Rights" />
                  </ListItem>
                  <ListItem button component="a" href="#5">
                    <ListItemText primary="5. Product Listings and Orders" />
                  </ListItem>
                  <ListItem button component="a" href="#6">
                    <ListItemText primary="6. Pricing and Payment" />
                  </ListItem>
                  <ListItem button component="a" href="#7">
                    <ListItemText primary="7. Shipping and Delivery" />
                  </ListItem>
                  <ListItem button component="a" href="#8">
                    <ListItemText primary="8. Returns and Refunds" />
                  </ListItem>
                  <ListItem button component="a" href="#9">
                    <ListItemText primary="9. Warranties and Disclaimers" />
                  </ListItem>
                  <ListItem button component="a" href="#10">
                    <ListItemText primary="10. Limitation of Liability" />
                  </ListItem>
                  <ListItem button component="a" href="#11">
                    <ListItemText primary="11. Indemnification" />
                  </ListItem>
                  <ListItem button component="a" href="#11">
                    <ListItemText primary="12. Termination" />
                  </ListItem>
                  <ListItem button component="a" href="#11">
                    <ListItemText primary="13. Governing Law and Dispute Resolution" />
                  </ListItem>
                  <ListItem button component="a" href="#11">
                    <ListItemText primary="14. Miscellaneous" />
                  </ListItem>
                </List>
              </Box>
            </Col>
          </Row>
        </Box>
      </Container>
    </section>
  );
};

export default TermsAndConditionsOfUse;
