// import React from "react";
// import "../Header/Header.scss";
// import SubHeader from "./SubHeader";
// import MainHeader from "./MainHeader";
// import MenuBar from "./MenuBar";

// function LandingPageHeader() {
//   return (
//     <>
//       <SubHeader />

//       <MainHeader />

//       <MenuBar />
//     </>
//   );
// }

// export default LandingPageHeader;














import React from "react";
import "../Header/Header.scss";
import SubHeader from "./SubHeader";
import MainHeader from "./MainHeader";
import MenuBar from "./MenuBar";
import BannerSection from "../BannerSection/BannerSection";

function LandingPageHeader() {
  return (
    <>
      <SubHeader />
      <header className="sticky-header">
      <MainHeader />
      <MenuBar />
    <BannerSection /> 
</header>
    </>
  );
}

export default LandingPageHeader;