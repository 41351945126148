import React, { useEffect } from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import BreadCrumbs from "./components/BannerSection/Breadcrumbs";
import Scrollup from "./Scrollup/Scrollup";

function Layout() {
  const navigate = useNavigate();

  const refreshToken = localStorage.getItem("refreshToken");

  
  // useEffect(() => {
  //   if (!refreshToken) {
  //     navigate("/loginpage");

  //     localStorage.removeItem("userId");
  //   }
  // }, [refreshToken]);

  return (
    <>
      <Header />
      <div className="">

      <BreadCrumbs/>
      <Scrollup/>
      </div>
      <Outlet />
      <Footer />
    </>
  );
}

export default Layout;
