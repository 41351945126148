// import React from "react";
// import { Link, useLocation } from "react-router-dom";
// import Typography from '@mui/material/Typography';
// import { Breadcrumbs } from "@mui/material";

// const BreadCrumbs = () => {
//   const location = useLocation();
//   let crumbLink = "";

//   const crumbPath = location.pathname
//     .split("/")
//     .filter((path) => path !== "")
//     .map((crumb, index, array) => {
//       // Replace spaces with hyphens (or use replace(/\s+/g, '_') for underscores)
//       const sanitizedCrumb = crumb.replace(/\s+/g, '-');
//       crumbLink += `/${sanitizedCrumb}`;

//       return index === array.length - 1 ? (
//         <Typography key={sanitizedCrumb} color="textPrimary">
//           {sanitizedCrumb}
//         </Typography>
//       ) : (
//         <Link key={sanitizedCrumb} to={crumbLink}>
//           {sanitizedCrumb}
//         </Link>
//       );
//     });

//   return (
//     <div>
//       <Breadcrumbs aria-label="breadcrumb">
//         {crumbPath}
//       </Breadcrumbs>
//     </div>
//   );
// };

// export default BreadCrumbs;
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// import React from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import Typography from '@mui/material/Typography';
// import { Breadcrumbs } from '@mui/material';
// import HomeIcon from '@mui/icons-material/Home';

// const BreadCrumbs = () => {
//   const location = useLocation();
  
//   // Split the path into segments and filter out empty segments
//   const pathSegments = location.pathname
//     .split('/')
//     .filter(segment => segment !== '');

//   // Generate breadcrumb items
//   const breadcrumbItems = pathSegments.map((segment, index) => {
//     // Create a link path
//     const crumbPath = `/${pathSegments.slice(0, index + 1).join('/')}`;
    
//     // Replace spaces with hyphens for the display text
//     const displayText = segment.replace(/\s+/g, '-');

//     return index === pathSegments.length - 1 ? (
//       <Typography
//         key={crumbPath}
//         color="textPrimary"
//         sx={{ fontWeight: 'bold' }}  // Increase font weight
//       >
//         {displayText}
//       </Typography>
//     ) : (
//       <Link
//         key={crumbPath}
//         to={crumbPath}
//         className='text-decoration-none'
//      // Increase font weight
//       >
//         {displayText}
//       </Link>
//     );
//   });

//   return (
//     <div>
//       <Breadcrumbs aria-label="breadcrumb" separator=" > " style={{fontWeight: 'bold'}} className='justify-content-center'>
//         {/* <Link to="/" style={{ display: 'flex', alignItems: 'center',  }}> */}
//           {/* <HomeIcon style={{ marginRight: 4 }} />
//           Home */}
//         {/* </Link> */}
//         {breadcrumbItems}
//       </Breadcrumbs>
//     </div>
//   );
// };

// export default BreadCrumbs;
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// import React from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import Typography from '@mui/material/Typography';
// import { Breadcrumbs } from '@mui/material';
// import HomeIcon from '@mui/icons-material/Home';

// const BreadCrumbs = () => {
//   const location = useLocation();
  
//   // Split the path into segments and filter out empty segments
//   const pathSegments = location.pathname
//     .split('/')
//     .filter(segment => segment !== '');

//   // Generate breadcrumb items
//   const breadcrumbItems = pathSegments.map((segment, index) => {
//     // Create a link path
//     const crumbPath = `/${pathSegments.slice(0, index + 1).join('/')}`;
    
//     // Replace spaces with hyphens for the display text
//     const displayText = segment.replace(/\s+/g, '-');

//     return index === pathSegments.length - 1 ? (
//       <Typography
//         key={crumbPath}
//         color="textPrimary"
//         sx={{ fontWeight: 'bold' }}  // Increase font weight
//       >
//         {displayText}
//       </Typography>
//     ) : (
//       <Link
//         key={crumbPath}
//         to={crumbPath}
//         style={{ fontWeight: 'bold' }}  // Increase font weight
//       >
//         {displayText}
//       </Link>
//     );
//   });

//   return (
//     <div>
//       <Breadcrumbs aria-label="breadcrumb" separator=" > ">
//         <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
//           <HomeIcon />
//         </Link>
//         {breadcrumbItems}
//       </Breadcrumbs>
//     </div>
//   );
// };

// export default BreadCrumbs;
// Newly updatetion
import React from 'react';
import { FaHome } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((item) => item);
// console.log(pathnames,"Ssssssssssssssssss")
  // Don't render breadcrumbs on the home page
  if (location.pathname === '/') {
    return null;
  }

  return (
    <nav aria-label="breadcrumb " >
      <ol className="breadcrumb justify-content-center m-2" >
        <li className="breadcrumb-item" >
          <Link to="/" className='' style={{ textDecoration: 'none',color:"#a19e99"}} onMouseEnter={(e) => e.currentTarget.style.color = "var(--bc-color)"} onMouseLeave={(e) => e.currentTarget.style.color = "#a19e99"}>Home</Link>
        </li>
        {pathnames.map((name, index) => {
          // Replace '%20' with '-' for the breadcrumb name
          const formattedName = name.replace(/%20/g, '-');
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <li key={name} className="breadcrumb-item active text-black " aria-current="page"  >
            {/* <li key={name} className="breadcrumb-item active fs-5" aria-current="page" style={{ textDecoration: 'none' ,color:"black"}}> */}
              {formattedName}
            </li>
          ) : (
            <li key={name} className="breadcrumb-item " >
              {/* <Link to={routeTo}  onMouseEnter={(e) => e.currentTarget.style.color = "var(--bc-color)"} onMouseLeave={(e) => e.currentTarget.style.color = "black"}>{formattedName}</Link> */}
              <Link to={routeTo} style={{ textDecoration: 'none',color:"#a19e99"}} onMouseEnter={(e) => e.currentTarget.style.color = "var(--bc-color)"} onMouseLeave={(e) => e.currentTarget.style.color = "#a19e99"}>{formattedName}</Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
