

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import blue from "../../Assets/Blue.png";
import yellow from "../../Assets/Yellow.png";
import green from "../../Assets/Green.png";
import "../Brochure/Brochure.css";
import { API_BASE_URL } from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import { Button, Col, Row } from "react-bootstrap";

// Validation schema with Yup
const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  mobile: Yup.string()
    .matches(/^\d{3}[- ]?\d{3}[- ]?\d{4}$/, "Invalid phone number format")
    .required("Required"),
  companyName: Yup.string().required("Required"),
  catalogName: Yup.string().required("Please select a catalog"),
});

const Brochure = () => {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/user/request-catalogue`,
        values
      );
      // console.log(response, "data from brochure");
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Monthly update submitted successfully.",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong. Please try again later.",
        confirmButtonText: "OK",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container me-5 mt-2">
      <h3 className="text-center about-heading">Brochure</h3>
      <Formik
        initialValues={{
          name: "",
          email: "",
          catalogName: "",
          mobile: "",
          companyName: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <Row>
            <Col>
            <div>
              <h4 className="fw-bold">Request Catalogs</h4>
              <p>
                Best Indiakart catalogs are free-of-charge. Please fill in the
                required fields and submit the request form. The catalog will be
                delivered within two or three days of your request. To receive
                our catalogs, you need to be registered with our company. If you
                have not yet registered, please do so now.
                <p>
                  Any information obtained in the course of responding to this
                  request will be used exclusively to fulfill your request and
                  will not be used for any other purpose. For further details,
                  please refer to our Privacy Policy page.
                </p>
              </p>
            </div>
            <div>
              <h6 className="fw-bold">Please send the following catalogs</h6>
              <label className="label-s">
                <Field
                  type="radio"
                  name="catalogName"
                  value="GRIP BLIND RIVETS"
                  id="grip-blind-rivets"
                  checked={values.catalogName === "GRIP BLIND RIVETS"}
                  onChange={(e) => setFieldValue("catalogName", e.target.value)}
                />
                <img src={blue} alt="Grip Blind Rivets" width={"120px"} /> GRIP
                BLIND RIVETS
              </label>
              <label className="label-s">
                <Field
                  type="radio"
                  name="catalogName"
                  value="GRIP BREAKSTEM SYSTEMS"
                  id="grip-breakstem-systems-yellow"
                  checked={values.catalogName === "GRIP BREAKSTEM SYSTEMS"}
                  onChange={(e) => setFieldValue("catalogName", e.target.value)}
                />
                <img
                  src={yellow}
                  alt="Grip Breakstem Systems"
                  width={"120px"}
                />{" "}
                GRIP BREAKSTEM SYSTEMS
              </label>
              <label className="label-s">
                <Field
                  type="radio"
                  name="catalogName"
                  value="GRIP BREAKSTEM SYSTEMS GREEN"
                  id="grip-breakstem-systems-green"
                  checked={
                    values.catalogName === "GRIP BREAKSTEM SYSTEMS GREEN"
                  }
                  onChange={(e) => setFieldValue("catalogName", e.target.value)}
                />
                <img
                  src={green}
                  alt="Grip Breakstem Systems Green"
                  width={"120px"}
                />{" "}
                GRIP BREAKSTEM SYSTEMS
              </label>
            </div>
            </Col>

            </Row>
            <Row>
              <Col>
              
            <h5 className="fw-bold">
              {" "}
              Contact Information{" "}
            </h5>
            <hr />
            <p className="fw-bold">Required Field <span className="text-danger fs-4">*</span></p>
            <div className="input-group mb-3">
              <label htmlFor="name" className="sdsds">Name <span className="text-danger fs-4">*</span></label>
              <Field
                type="text"
                id="name"
                name="name"
                className="form-control"
              /> <br />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
            </div>

            <div className="input-group mb-3">
              <label htmlFor="email" className="sdsds">Email <span className="text-danger fs-4">*</span></label>
              <Field
                type="text"
                id="email"
                name="email"
                className="form-control"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="input-group mb-3">
              <label htmlFor="mobile" className="sdsds">Mobile No <span className="text-danger fs-4">*</span></label>
              <Field
                type="text"
                id="mobile"
                name="mobile"
                className="form-control"
              />
              <ErrorMessage
                name="mobile"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="input-group mb-3">
              <label htmlFor="companyName" className="sdsds">Company Name <span className="text-danger fs-4">*</span></label>
              <Field
                type="text"
                id="companyName"
                name="companyName"
                className="form-control"
              />
              <ErrorMessage
                name="companyName"
                component="div"
                className="text-danger"
              />
            </div>

            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                Street Address {" "}
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>

            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                Post Code{" "}
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                Country
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                City
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                State/Province
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                Website
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
              </Col>

            </Row>
 <Row>
  <Col>
            <h6 className="fw-bold">
              {" "}
              Please select your main severd industry{" "}
            </h6>
            <hr />
            {/* <p>Required Field</p> */}
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                please select your main served industry
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                please select your business field
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                please select your deparment
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                What is the main type of products manufactured by your company ?
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                Please select Capitailzation of your company
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                How many employees are there in your company ?
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                How many desgin Engineers are there in your company ?
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                How did you know BEST INDIAKART
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <label class="sdsds" id="basic-addon1">
                How sort of information do you want to receive From BEST
                INIAKART
              </label>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div class="input-group mb-3">
              <input
                type="checkbox"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
              />{" "}
              <span className="ms-2">
                I agree with the BEST INDIKART Privacy polices
              </span>
            </div>
            <Button type="submit" className="btnv">
              SUBMIT
            </Button>
  </Col>

 </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Brochure;
