
import React, { useEffect, useState, memo, useCallback } from "react";
import "../BannerSection/BannerSection.scss";
import banner_image_one from "../../Assets/Images/new_banner.png";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import bannerProductOne from "../../Assets/Images/banner_product_1.png";
import bannerProductTwo from "../../Assets/Images/banner_product_2.png";
import bannerProductThree from "../../Assets/Images/banner_product_3.png";
import bannerProductFour from "../../Assets/Images/banner_product_4.png";
import bannerProductFive from "../../Assets/Images/banner_product_5.png";
import bannerProductsix from "../../Assets/Images/banner_product_6.png";
import bannerProductseven from "../../Assets/Images/banner_product_7.png";
import bannerProductHeite from "../../Assets/Images/banner_product_6.png";
import Slider from "react-slick";
import { Accordion, Card } from "react-bootstrap";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Link } from "react-router-dom";

const BannerSection = () => {
  const [data, setData] = useState([]);
  const [subCategories, setSubCategories] = useState({});
  const [showStates, setShowStates] = useState({});
  const [showStatesParent, setShowStatesParent] = useState(false);
  const [activeKey, setActiveKey] = useState("0");



  const handleShow = useCallback((category) => {
    setShowStates((prevStates) => ({
      ...prevStates,
      [category]: !prevStates[category],
    }));
  }, []);

  // const handleShowParent = useCallback(() => {
  //   setShowStatesParent(!showStatesParent);
  // }, [showStatesParent]);
  const handleShowParent = useCallback(() => {
    setShowStatesParent((prev) => !prev);
    if (!showStatesParent) {
      setActiveKey("0"); // Set to default active key
      if (data.length > 0) {
        // Fetch subcategories for the first category (if not already done)
        axios
          .get(`${API_BASE_URL}/category/admin/${data[0]?._id}`)
          .then((response) => {
            setSubCategories({
              [data[0]?._id]: response?.data?.subcategoryNames,
            });
          })
          .catch((error) => {
            console.error("Error fetching subcategories:", error);
          });
      }
    } else {
      setActiveKey(null); // Collapse all when closing
    }
  }, [showStatesParent, data]);
  const CustomToggle = memo(
    ({ children, eventKey, category, handleShow, isChild }) => {
      const decoratedOnClick = useAccordionButton(eventKey, () => {
        handleShow(category);

        if (isChild && !subCategories[category]) {
          axios
            .get(`${API_BASE_URL}/category/admin/${category}`)
            .then((response) => {
              setSubCategories((prevSubCategories) => ({
                ...prevSubCategories,
                [category]: response?.data?.subcategoryNames,
              }));
            })
            .catch((error) => {
              console.error("Error fetching subcategories:", error);
            });
        }
      });

      return (
        <button type="button" onClick={decoratedOnClick}>
          {children}
        </button>
      );
    }
  );

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/user/getcategory`)
      .then((response) => {
        const categories = response?.data?.categories || [];
        setData(categories);

        const initialState = categories.reduce((acc, category, index) => {
          acc[category?._id] = index === 0;
          return acc;
        }, {});

        setShowStates(initialState);

        if (categories.length > 0) {
          axios
            .get(`${API_BASE_URL}/category/admin/${categories[0]?._id}`)
            .then((response) => {
              setSubCategories({
                [categories[0]?._id]: response?.data?.subcategoryNames,
              });
            })
            .catch((error) => {
              console.error("Error fetching subcategories:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        setShowStatesParent(false);
        setActiveKey(null); // Collapse all
        setShowStates((prevShowStates) => {
          const newShowStates = { ...prevShowStates };
          Object.keys(newShowStates).forEach((key) => {
            newShowStates[key] = false;
          });
          return newShowStates;
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showStatesParent, showStates]);

  const handleLinkClick = () => {
    setShowStatesParent(false);
    setActiveKey(null);
  
    
  };


  const replaceSpacesWithHyphens = (urlString) => {

    if (typeof urlString === 'string') {
      // Replace all spaces with hyphens
      return urlString.replace(/ /g, '-')
    .toLowerCase()
    }else return urlString;
}

  return (
    <>
      <div className="row row-one ms-5 m-0 ">
        <div
          className="col-lg-3 col-md-3 left"
          style={{
            background: "transparent",
            position: "absolute",
            marginTop: "-46px",
          }}
        >
          <div
            className="categories"
            style={{ borderRadius: "10px", background: "yellow" }}
          >
            <span style={{ color: "Orange", fontWeight: "40px" }}> </span>
            <div className="categories-inner" style={{ borderRadius: "0px" }}>
              {/* <Accordion activeKey={activeKey} className="parent-accrodion" style={{ borderRadius: "10px 10px 10px 10px", background: "green" }}>
                <Accordion.Item eventKey="0" className="parent-accrodion-item">
                  <Accordion.Header className="parent-accrodion-header">
                    <CustomToggle
                      eventKey="0"
                      className="parent-accrodion-toggle"
                      isChild={false}
                      handleShow={handleShowParent}
                    >
                      <p className="m-0 px-1">ALL Categories</p>
                      {showStatesParent ? (
                        <IoIosArrowUp className="eye-icon" />
                      ) : (
                        <IoIosArrowDown className="eye-icon" />
                      )}
                    </CustomToggle>
                  </Accordion.Header>
                  <Accordion.Body style={{ borderRadius: "0px 0px 10px 10px" }}>
                    <Accordion defaultActiveKey="0" style={{ borderRadius: "0px 0px 10px 10px" }}>
                      <Card className="products">
                        {data?.length === 0
                          ? "No data available"
                          : data.map((category, index) => (
                              <div key={index} style={{ borderRadius: "0px 0px 10px 10px" }}>
                                <Card.Header style={{ borderRadius: "0px" }}>
                                  <CustomToggle
                                    eventKey={index.toString()}
                                    className="categories-sub-title"
                                    category={category?._id}
                                    handleShow={handleShow}
                                    isChild={true}
                                  >
                                    <p className="m-0 px-1 banner-sidebar-category" style={{ color: "#0443C0" }}>
                                      {category?.name}
                                    </p>
                                    {showStates[category?._id] ? (
                                      <IoIosArrowUp className="eye-icon" />
                                    ) : (
                                      <IoIosArrowDown className="eye-icon" />
                                    )}
                                  </CustomToggle>
                                </Card.Header>

                                <Accordion.Collapse eventKey={index.toString()} style={{ borderRadius: "0px 0px 10px 10px " }}>
                                  <Card.Body>
                                    {(subCategories[category?._id] || []).map(
                                      (subCategory, subIndex) => (
                                        <div key={subIndex} className="categories-links my-3">
                                          <Link
                                            style={{
                                              textDecoration: "none",
                                              color: "black",
                                            }}
                                            to={`/sub-category/${subCategory?._id}`}
                                            className="categories-links-subcategories"
                                          >
                                            {subCategory?.name}
                                          </Link>
                                        </div>
                                      )
                                    )}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </div>
                            ))}
                      </Card>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}
              <Accordion
                activeKey={activeKey}
                className="parent-accrodion"
                style={{
                  borderRadius: "10px 10px 10px 10px",
                  background: "green",
                }}
              >
                <Accordion.Item eventKey="0" className="parent-accrodion-item">
                  <Accordion.Header className="parent-accrodion-header">
                    <CustomToggle
                      eventKey="0"
                      className="parent-accrodion-toggle"
                      isChild={false}
                      handleShow={handleShowParent}
                    >
                      <p className="m-0 px-1">Product Categories</p>
                      {showStatesParent ? (
                        <IoIosArrowUp className="eye-icon" />
                      ) : (
                        <IoIosArrowDown className="eye-icon" />
                      )}
                    </CustomToggle>
                  </Accordion.Header>
                  <Accordion.Body style={{ borderRadius: "0px 0px 10px 10px" }}>
                    <Accordion
                      defaultActiveKey="0"
                      style={{ borderRadius: "0px 0px 10px 10px" }}
                    >
                      <Card className="products">
                        {data?.length === 0
                          ? "No data available"
                          : data.map((category, index) => (
                              <div
                                key={index}
                                style={{ borderRadius: "0px 0px 10px 10px" }}
                              >
                                <Card.Header style={{ borderRadius: "0px" }}>
                                  <CustomToggle
                                    eventKey={index.toString()}
                                    className="categories-sub-title"
                                    category={category?._id}
                                    handleShow={handleShow}
                                    isChild={true}
                                  >
                                    <p
                                      className="m-0 px-1 banner-sidebar-category"
                                      style={{ color: "#0443C0" }}
                                    >
                                      {category?.name}
                                    </p>
                                    {showStates[category?._id] ? (
                                      <IoIosArrowUp className="eye-icon" />
                                    ) : (
                                      <IoIosArrowDown className="eye-icon" />
                                    )}
                                  </CustomToggle>
                                </Card.Header>

                                <Accordion.Collapse
                                  eventKey={index.toString()}
                                  style={{ borderRadius: "0px 0px 10px 10px " }}
                                >
                                  <Card.Body>
                                    {(subCategories[category?._id] || []).map(
                                      (subCategory, subIndex) => (
                                        <Link
                                        key={subIndex}
                                          style={{
                                            textDecoration: "none",
                                            color: "black",
                                          }}
                                          // to={`/sub-category/${subCategory?._id}`}
                                          // to={`/sub-category/${subCategory?.name}`}
                                          // to={`/${category?.name}/${subCategory?.name}`}

                                          to={`/${replaceSpacesWithHyphens(category?.name)}/${replaceSpacesWithHyphens(subCategory?.name)}`}
                                          className="categories-links-subcategories"
                                        >
                                          {console.log(`/${replaceSpacesWithHyphens(category?.name)}/${replaceSpacesWithHyphens(subCategory?.name)}`)}
                                          <div
                                            key={subIndex}
                                            className="categories-links "
                                            onClick={handleLinkClick}
                                          >
                                            

                                            {subCategory?.name}
                                          </div>
                                          
                                        </Link>
                                      )
                                    )}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </div>
                            ))}
                      </Card>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default memo(BannerSection);
