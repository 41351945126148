import React, { useState } from "react";
import "../../Assets/Images/loginPage-banner.jpg";
import "../LoginPage/LoginPage.scss";
import success from "../../Assets/Images/success-icon.png"
import "../SuccessPage/SuccessPage.scss"

function SuccessPage() {
  return (
    <div className="login-page">
      <div className="login-page-inner">
        <center className="w-100" >
          <img src={success} className="success-icon" alt="success" />
          <h4 className="fw-semibold fs-4 py-2 m-0">Success</h4>
          <p className="fw-medium">Password Reset Link Sent to Your Email</p>
        </center>
      </div>
    </div>
  );
}

export default SuccessPage;