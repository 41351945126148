import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaCircle, FaSearch } from "react-icons/fa";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useAuthHeaders } from "../../../components/Token";
import "../Profile.scss";
import Swal from "sweetalert2";
import { API_BASE_URL } from "../../../utils";
const ITEMS_PER_PAGE = 5;

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "2-digit" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};
const Inquiry = () => {
  const [inquiry, setInquiry] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalOrders, setTotalOrders] = useState(0);
  const totalPages = Math.ceil(filteredOrders.length / ITEMS_PER_PAGE);

  const isLoggedIn = localStorage.getItem("userId");
  const headers1 = useAuthHeaders();

  const userId = localStorage.getItem("userId");
  const headers = {
    Authorization: `Bearer ${userId}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/user/myenquiry`, { headers: headers })
      .then((res) => {
        // console.log("result form Inquiry ", res?.data);
        setInquiry(res?.data?.enquiry);
      });
  }, []);

  const getStatusStyle = (orderStatus) => {
    switch (orderStatus) {
      case "Payment Pending":
        return { color: "#F3A638" };
      case "Payment success":
        return { color: "#4CB64C" };
      case "processing":
        return { color: "#54B7D3" };
      case "shipped":
        return { color: "#1E91CF" };
      case "delivered":
        return { color: "#4CB64C" };
      case "cancel":
        return { color: "#E3503E" };
      default:
        return {};
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalOrders / ITEMS_PER_PAGE)) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    const searchQuery = event.target.value.toLowerCase();
    const filtered = inquiry.filter((order) =>
      order.enquiryId.toLowerCase().includes(searchQuery)
    );
    setFilteredOrders(filtered);
    setTotalOrders(filtered.length);
    setCurrentPage(1);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="d-flex mt-4 mb-4 dsds">
            <h2>My Inquiries</h2>
            <div
              className="ms-auto d-flex bg-light"
              style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
            >
              <input
                type="search"
                placeholder="Search by Order ID"
                value={search}
                onChange={handleSearchChange}
                className="form-control border border-0"
              />
              <InputGroup.Text className="bg-white border border-0">
                <FaSearch />
              </InputGroup.Text>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead style={{ border: "1px solid" }}>
                <tr className="border border-1">
                  <th scope="col" className="border border-1">
                    S.No
                  </th>
                  <th scope="col" className="border border-1">
                    InquiryId
                  </th>
                  <th scope="col" className="border border-1">
                    subject
                  </th>
                  <th scope="col" className="border border-1">
                    Date
                  </th>

                  <th scope="col" className="border border-1">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {inquiry.length > 0 ? (
                  inquiry.map((inquiry, index) => (
                    <tr key={inquiry?._id}>
                      <td>{index + 1}</td>
                      <td>{inquiry?.enquiryId}</td>
                      <td>{inquiry?.subject}</td>
                      <td>{formatDate(inquiry?.createdAt)}</td>
                      <td>
                        <FaCircle style={getStatusStyle(inquiry?.Status)} />{" "}
                        {inquiry?.Status}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center text-danger">
                      No Inquiries are found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {inquiry.length > 5 ? (
            <div className="">
              <ul className="pagination justify-content-center ">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Inquiry;