// import React, { useState, useEffect } from "react";
// import ProgressBar from "react-bootstrap/ProgressBar";

// function TimeProgressBar({ totalTime }) {
//   const calculateTimeRemaining = () => {
//     const currentTime = new Date().getTime();
//     return totalTime - currentTime;
//   };

//   const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setTimeRemaining(calculateTimeRemaining());
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [totalTime]);

//   let progressPercentage = 0;

//   if (timeRemaining <= 0) {
//     progressPercentage = 100;
//   } else {
//     const totalTimeInMilliseconds = new Date(totalTime).getTime();
//     const elapsedTimeInMilliseconds = totalTimeInMilliseconds - timeRemaining;
//     progressPercentage =
//       (elapsedTimeInMilliseconds / totalTimeInMilliseconds) * 100;
//   }

//   const displayPercentage = isNaN(progressPercentage) ? 0 : progressPercentage;

//   return (
//     <div className="progress-bar">
//       <span>Hurry up offer ends in</span>
//       <ProgressBar
//         animated
//         now={progressPercentage}
//         label={`${displayPercentage}%`}
//       />
//     </div>
//   );
// }

// export default TimeProgressBar;











// import React, { useState, useEffect } from "react";
// import ProgressBar from "react-bootstrap/ProgressBar";

// function TimeProgressBar({ totalTime }) {
//   const calculateTimeRemaining = () => {
//     const currentTime = new Date().getTime();
//     return new Date(totalTime).getTime() - currentTime;
//   };

//   const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setTimeRemaining(calculateTimeRemaining());
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [totalTime]);

//   let progressPercentage = 0;

//   if (timeRemaining <= 0) {
//     progressPercentage = 100;
//   } else {
//     const totalTimeInMilliseconds = new Date(totalTime).getTime();
//     // console.log("total time milli seconds",totalTimeInMilliseconds)
//     // console.log("calculated Time",calculateTimeRemaining()) 
//     const elapsedTimeInMilliseconds =
//       totalTimeInMilliseconds - calculateTimeRemaining();
//       // console.log("elapsedTimeInMilliseconds ---",elapsedTimeInMilliseconds)
//       // console.log("==============================")
//     progressPercentage =
//       (elapsedTimeInMilliseconds / totalTimeInMilliseconds) 
//   }


//   const displayPercentage = isNaN(progressPercentage) ? 0 : progressPercentage;

//   useEffect(() => {
//     // console.log("Total Time:", totalTime);
//     // console.log("Time Remaining:", timeRemaining);
//   }, [totalTime, timeRemaining]);
  
// // console.log("display percentage",displayPercentage)

//   return (
//     <div className="progress-bar">
//       <span>Hurry up offer ends in</span>
//       <ProgressBar
//         animated
//         now={displayPercentage}
//         label={`${Math.round(displayPercentage)}%`}
      
//       />
//     </div>
//   );
// }

// export default TimeProgressBar;


import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

function TimeProgressBar({ totalTime }) {
  const calculateTimeRemaining = () => {
    const currentTime = new Date().getTime();
    return new Date(totalTime).getTime() - currentTime;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, [totalTime]);

  const totalTimeInMilliseconds = new Date(totalTime).getTime();
  const initialTime = totalTimeInMilliseconds - calculateTimeRemaining();
  let progressPercentage = 0;

  if (timeRemaining <= 0) {
    progressPercentage = 100;
  } else {
    const elapsedTimeInMilliseconds = initialTime - timeRemaining;
    progressPercentage = ((elapsedTimeInMilliseconds / initialTime)).toFixed(2);
  }

  const displayPercentage = isNaN(progressPercentage) ? 0 : progressPercentage;

  useEffect(() => {
    // console.log("Total Time:", totalTime);
    // console.log("Time Remaining:", timeRemaining);
  }, [totalTime, timeRemaining]);

  return (
    <div className="progress-bar">
      <span>Hurry up offer ends in</span>
      <ProgressBar
        animated
        now={displayPercentage}
        // label={`${Math.round(displayPercentage)}%`}
        label={`${displayPercentage}%`}
      />
    </div>
  );
}

export default TimeProgressBar;
