import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  Box,
  Typography,
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import "./PrivacyPolicy.scss";
const Cancellation_and_Refund_Policy = () => {
  return (
    <section className="privacy-section">
      <Container className="privacy-policy-wrapper">
        <h1 className="privacypolicy-page-heading">
          Cancellation and Refund Policy
        </h1>
        <Box mt={4}>
          <Row>
            <Col lg={8}>
              <Box mt={3} className="privacy-policy-content">
                <Typography variant="h6" component="h3" id="1" gutterBottom>
                  1. Terms of Cancellation
                </Typography>
                <Typography variant="body1" component="p" id="1" gutterBottom>
                  <strong>1.1. Cancellation Process:</strong> You can cancel your order any time
                  before the Product is dispatched. Once dispatch begins,
                  cancellation is not possible. If your order has been
                  dispatched and you still wish to cancel, contact Customer
                  Support immediately. We will endeavor to prevent delivery;
                  however, if delivery is attempted, you may reject it.
                </Typography>

                <Typography variant="body2" style={{fontSize:"1rem"}} component="p" id="2" gutterBottom>
                <strong>1.2. How to Cancel:</strong> Cancel your order through the 'My Orders'
                  section using the order tracking number, or by contacting our
                  customer care number as provided by BEST Indiakart.
                </Typography>
                <Typography variant="body3" component="p" id="3" gutterBottom>
                <strong>1.3. Refund Process:</strong> Upon receipt of your cancellation
                  request, we will cancel your order immediately. Refunds for
                  prepaid orders will be initiated within 24 hours of
                  cancellation confirmation.
                </Typography>
                <Typography variant="h6" component="h3" id="4" gutterBottom>
                  2. Terms of Return and Refund{" "}
                </Typography>
                <Typography variant="body4" component="p" id="4" gutterBottom>
                <strong>2.1. Return Eligibility:</strong> You may return Products that are
                  damaged, defective, not in working condition, or different
                  from what was ordered, as per the conditions specified in this
                  Policy. Requests for returns must be made within 7 days of
                  order delivery.
                </Typography>
                <Typography variant="body5" component="p" id="5" gutterBottom>
                <strong>2.2. Packaging Requirement:</strong> Ensure that tamper-proof packaging
                  is intact upon receipt. Acceptance of tampered or damaged
                  packages disqualifies you from return claims for physical
                  damage, defects, incorrect Products, or missing accessories.
                </Typography>
                <Typography variant="body6" component="p" id="6" gutterBottom>
                <strong>2.3. Return Process:</strong> Register a complaint or return request
                  through BEST Indiakart’s 'Inquiry' section. Provide necessary
                  shipment and order details, describe the issue, and request a
                  return. For quality assessment, images of the Product may be
                  required. The 'return' option will be available during the
                  return window.
                </Typography>
                <Typography variant="body7" component="p" id="7" gutterBottom>
                <strong>2.4. Verification and Resolution:</strong> We will verify the claim
                  upon receipt of your return request. This may include
                  requesting images or scheduling a visit for assessment. We
                  reserve the right to accept or reject return requests based on
                  our assessment of the issue.
                </Typography>
                <Typography variant="body8" component="p" id="8" gutterBottom>
                <strong>2.5. Refund Timeline:</strong> Upon verification, refunds for eligible
                  Products will be processed within 1 business day to your
                  original payment method. Refund timelines thereafter depend on
                  your financial institution.
                </Typography>
                <Typography variant="body9" component="p" id="9" gutterBottom>
                <strong>2.6. Refund Completion:</strong> You can expect refunds within 15
                  working days, depending on your chosen payment method. Contact
                  us if your refund does not reflect within the advised
                  timeline.
                </Typography>
                <Typography variant="body10" component="p" id="10" gutterBottom>
                <strong>2.7. Shipping Charges:</strong> Shipping charges will not be refunded
                  for returns other than for mentioned damages.
                </Typography>
                <Typography variant="h6" component="h3" id="11" gutterBottom>
                  3. Customer Support Team
                </Typography>
                <Typography variant="body11" component="p" id="11" gutterBottom>
                  <article style={{ fontSize: "15px" }}>
                    For any queries or feedback, please contact us at:
                  </article>

                  <Typography variant="body11" component="p" gutterBottom>
                    <ul>
                      <li>
                        <Typography variant="body11" component="p" gutterBottom>
                        Email&nbsp;
                          <a
                            href="mailto:marketing@bumaas.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "green" }}
                          >
                            marketing@bumaas.com
                          </a>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body11" component="p" gutterBottom>
                        	Call : &nbsp;
                          <a
                            href="tel:7812810488"
                            style={{ textDecoration: "none", color: "green" }}
                          >
                            +91 7812810488
                          </a>

                        </Typography>
                      </li>
                    </ul>
                  </Typography>
                </Typography>
              </Box>
            </Col>
            <Col lg={4}>
              <Box mt={3} p={2} bgcolor="#f9f9f9" border={1} borderColor="#ddd">
                <Typography variant="h6" component="h3" gutterBottom>
                  Table of Contents
                </Typography>
                <List component="nav" aria-label="Table of Contents">
                  <ListItem button component="a" href="#1">
                    <ListItemText primary="1. Introduction" />
                  </ListItem>
                  <ListItem button component="a" href="#1">
                    <ListItemText primary="1.1. Cancellation Process" />
                  </ListItem>
                  <ListItem button component="a" href="#2">
                    <ListItemText primary="1.2. How to Cancel" />
                  </ListItem>
                  <ListItem button component="a" href="#3">
                    <ListItemText primary="1.3. Refund Process" />
                  </ListItem>
                  <ListItem button component="a" href="#4">
                    <ListItemText primary="2. Terms of Return and Refund" />
                  </ListItem>
                  <ListItem button component="a" href="#4">
                    <ListItemText primary="2.1. Return Eligibility" />
                  </ListItem>
                  <ListItem button component="a" href="#5">
                    <ListItemText primary="2.2. Packaging Requirement" />
                  </ListItem>
                  <ListItem button component="a" href="#6">
                    <ListItemText primary="2.3. Return Process" />
                  </ListItem>
                  <ListItem button component="a" href="#7">
                    <ListItemText primary="2.4. Verification and Resolution" />
                  </ListItem>
                  <ListItem button component="a" href="#8">
                    <ListItemText primary="2.5. Refund Timeline" />
                  </ListItem>
                  <ListItem button component="a" href="#9">
                    <ListItemText primary="2.6. Refund Completion" />
                  </ListItem>
                  <ListItem button component="a" href="#10">
                    <ListItemText primary="2.7. Shipping Charges" />
                  </ListItem>
                  <ListItem button component="a" href="#11">
                    <ListItemText primary="3. Customer Support Team" />
                  </ListItem>
                </List>
              </Box>
            </Col>
          </Row>
        </Box>
      </Container>
    </section>
  );
};

export default Cancellation_and_Refund_Policy;
