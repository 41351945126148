import React from 'react'
import ReactImageGallery from "react-image-gallery";
import slider1 from "../../Assets/BIK_slide1.jpg"
import slider2 from "../../Assets/BIK Slide 2.jpg"
// import slider2 from "../../Assets/BIK Slide 2.webp"
import slider3 from "../../Assets/BIK Slide 3.jpg"
// import slider3 from "../../Assets/BIK Silde 3.jpg"
import Slider from 'react-slick';
// import { FaBuilding, FaUsers, FaCalendarAlt, FaHandshake, FaMoneyBillWave, FaCode, FaFileInvoice, FaChartLine } from 'react-icons/fa';
import { FaChartLine } from 'react-icons/fa';
import { FaHandHoldingDollar } from "react-icons/fa6";
import { MdOutlineApartment } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi2";
import { HiClipboardList } from "react-icons/hi";

function HeroBanner() {
    const images = [
        {
          original:` ${slider1}`,
        
        },
        {
          original: `${slider2}`,
      
        },
        {
          original: `${slider3}`,
      
        },
      
      ];
      const gallerySettings = {
        autoPlay: true,
        slideInterval: 4000,
        showFullscreenButton: false,
        showPlayButton: false,
        showNav: false,
        showThumbnails: false,
        showBullets: false,
      };

      const bannerImageSlide = [
    { icon: <FaHandHoldingDollar />, text: "Nature of Business ",Data:" Exporter & Manufacturer of Fasteners & Tools" },
    { icon: <HiUserGroup />, text: "Total Number of Employees",Data:" 26 to 40 people" },
    { icon: <MdOutlineApartment />, text: "Year of Establishment",Data:" 2018" },
    // { icon: <FaHandshake />, text: "Legal Status of Firm",Data:" Partnership Firm" },
    { icon: <FaChartLine />, text: "Annual Turnover",Data:" Rs 5-10 crore" },
    // { icon: <FaCode />, text: "Import Export Code (IEC)",Data:"adwfs*******" },
    { icon:<HiClipboardList />
      , text: "GST No",Data:"33AAHCB8093N1Z5" }
];
       const settings = {
        autoplay: true,
        autoplaySpeed: 2000, // 2000 milliseconds = 2 seconds
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    
  return (
    <div>
        <div className="col-xl-12"> 
           <div className="banner">
            {/* <img
              src={banner_image_one}
              className="banner-image"
              alt="banner_image_one"
            /> */}
            <ReactImageGallery items={images} {...gallerySettings} className="banner-image" /> 
          </div>  
     </div>
     <div className="banner-products-slide mt-1" width={"0%"} style={{background:""}} >
        {/* <Slider {...settings}>
          {bannerImageSlide.map((item, i) => (
            <div key={i} className="products-slide">
              <img src={item?.image} alt="banner-products-slide-image" />
              <p className="m-0">{item?.text}</p>
            </div>
          ))}
        </Slider> */}<Slider {...settings}>
    {bannerImageSlide.map((item, i) => (
      <div key={i} className="products-slide  ">
        {/* <img src={item?.image} alt="banner-products-slide-image" /> */}
        <ul className="  ">

       <li className='fs-1' style={{color:"#0443c0"}}>
       {item.icon}
        </li> 
        <li className="m-0 text-black">{item?.text}</li>
        <p className='fw-bold text-black'>{item.Data}</p>
        </ul>
      </div>
    ))}
  </Slider>
      </div>
    </div>
  )
}

export default HeroBanner
