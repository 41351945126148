// import React, { useContext, useEffect } from "react";
// import userContext from "../../components/UseContext/UseContext";
// import "../Wishlist/Wishlist.scss";
// import { AiOutlineClose } from "react-icons/ai";
// import { Link } from "react-router-dom";
// import AddCartButton from "../../components/Buttons/AddCartButton";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils";
// import { useAuthHeaders } from "../../components/Token";
// import img1 from "../../Assets/Images/Capture-01.png";
// import { motion } from "framer-motion";
// // import "../AddCartProducts/AddCartProducts.css";
// import "../Wishlist/NoWishlist.css";

// function Wishlist() {
//   const isLoggedIn = localStorage.getItem("tokenId");
//   const headers = useAuthHeaders();
//   const wishlistContext = useContext(userContext);
//   const { wishlist, setWishlist } = wishlistContext;

//   const listVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.3,
//       },
//     },
//   };

//   const itemVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: { opacity: 1, y: 0 },
//     hover: { scale: 1.05 },
//     tap: { scale: 0.95 },
//     exit: { opacity: 0, y: -20, transition: { duration: 0.3 } },
//   };

//   // product remove
//   const removeWishlist = (productToRemove) => {
//     const productId = productToRemove?.ProductId?._id;

//     if (isLoggedIn) {
//       axios
//         .delete(`${API_BASE_URL}/user/deletewishlist/${productId}`, { headers:headers })
//         .then((res) => {
//           setWishlist((prevData) =>
//             prevData?.filter((item) => item?.ProductId?._id !== productId)
//           );
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     } else {
//       const updatedWishlist = wishlist?.filter(
//         (product) => product?._id !== productToRemove?._id
//       );

//       setWishlist(updatedWishlist);
//       localStorage.setItem(
//         "wishlistProducts",
//         JSON?.stringify(updatedWishlist)
//       );
//     }
//   };

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/user/iswishlist`, { headers })
//       .then((res) => {
//         // console?.log("res in iswishlist", res?.data);
//         setWishlist(res?.data?.wishlist);
//       })
//       .catch((error) => {
//         // console.log("error", error);
//       });
//   }, []);


//   const replaceSpacesWithHyphens = (urlString) => {

//     if (typeof urlString === 'string') {
//       // Replace all spaces with hyphens
//       return urlString.replace(/ /g, '-');
//     }else return urlString;
// }


//   return (
//     <>
//       <div className="listProducts-banner">
//         <h2>Wishlist</h2>
//       </div>
//       {wishlist?.length ? (
//         <div className="listProducts px-2r my-5">
//           {wishlist?.map((wishlistProduct) => {
//             // console.log("wishlistProduct : in", wishlistProduct);
//             return (
//               <div style={{ overflowX: "auto" }}>
//                 <table className="wishlist-products">
//                   <tbody>
//                     <tr>
//                       <td
//                         className="px-3 "
//                         onClick={() => removeWishlist(wishlistProduct)}
//                       >
//                         <AiOutlineClose />
//                       </td>
//                       <Link
//                         // to={`/product/${wishlistProduct?._id}`}
//                         to={wishlistProduct?.descriptions[0]?.value !==undefined ?`/${wishlistProduct?.category?.name}/${replaceSpacesWithHyphens(wishlistProduct?.subcategoryname?.name)}/${replaceSpacesWithHyphens(wishlistProduct?.descriptions[0]?.value)}/${wishlistProduct?.slug}`:`/product/${wishlistProduct?.slug}`}
//                         className="text-decoration-none text-dark"
//                       >
//                         <td className="">
//                           {isLoggedIn ? (
//                             <img
//                               src={
//                                 wishlistProduct?.ProductId?.productimages[0]
//                                   ?.productimage
//                               }
//                               alt="wishlistProducts-image"
//                             />
//                           ) : (
//                             <>
//                               <img
//                                 src={
//                                   wishlistProduct?.productimages[0]
//                                     ?.productimage
//                                 }
//                                 width={"10px"}
//                                 alt="wishlistProducts-image"
//                               />
//                             </>
//                           )}
//                         </td>
//                       </Link>
//                       <td
//                         className="px-2 "
//                         style={{ width: "50%" }}
//                       >
//                         <h6 className="wishlistProducts-name text-dark">
//                           {isLoggedIn ? (
//                             <>{wishlistProduct?.ProductId?.partNumber}</>
//                           ) : (
//                             <>{wishlistProduct?.partNumber}</>
//                           )}
//                         </h6>
//                       </td>
//                       <td>
//                         <p className="fw-bold m-0">
//                           {isLoggedIn ? (
//                             <>{wishlistProduct?.ProductId?.saleprice}</>
//                           ) : (
//                             <>₹{wishlistProduct?.saleprice}</>
//                           )}
//                         </p>
//                       </td>
//                       <td>
//                         <AddCartButton
//                           type="button"
//                           className="wishlistProductsbButton"
//                           data={wishlistProduct}
//                         />
//                       </td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//             );
//           })}
//         </div>
//       ) : (
//         <>
//           <div>
//             {" "}
//             <motion.ul
//               className="list-style-none text-center"
//               initial="hidden"
//               animate="visible"
//               exit="exit"
//               variants={listVariants}
//             >
//               <motion.li
//                 variants={itemVariants}
//                 whileHover="hover"
//                 whileTap="tap"
//                 className="NoWishlistlists"
//               >
//                 <img src={img1} alt="Empty Cart" className="NoWishlistImage" />
//               </motion.li>

//               <motion.li
//                 variants={itemVariants}
//                 whileHover="hover"
//                 whileTap="tap"
//               >
//                 <h4 className="p-3 text-center no-wishlist-content">
//                   There are no products on the Wishlist!
//                 </h4>
//               </motion.li>
//             </motion.ul>
//           </div>
//         </>
//       )}
//     </>
//   );
// }

// export default Wishlist;

import React, { useContext, useEffect } from "react";
import userContext from "../../components/UseContext/UseContext";
import "../Wishlist/Wishlist.scss";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import AddCartButton from "../../components/Buttons/AddCartButton";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../../components/Token";
import img1 from "../../Assets/Images/Capture-01.png";
import { motion } from "framer-motion";
import "../Wishlist/NoWishlist.css";

function Wishlist() {
  const isLoggedIn = localStorage.getItem("tokenId");
  const headers = useAuthHeaders();
  const wishlistContext = useContext(userContext);
  const { wishlist, setWishlist } = wishlistContext;

  const listVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    hover: { scale: 1.05 },
    tap: { scale: 0.95 },
    exit: { opacity: 0, y: -20, transition: { duration: 0.3 } },
  };

  const removeWishlist = (productToRemove) => {
    const productId = productToRemove?.ProductId?._id;

    if (isLoggedIn) {
      axios
        .delete(`${API_BASE_URL}/user/deletewishlist/${productId}`, { headers: headers })
        .then((res) => {
          setWishlist((prevData) =>
            prevData?.filter((item) => item?.ProductId?._id !== productId)
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      const updatedWishlist = wishlist?.filter(
        (product) => product?._id !== productToRemove?._id
      );

      setWishlist(updatedWishlist);
      localStorage.setItem(
        "wishlistProducts",
        JSON?.stringify(updatedWishlist)
      );
    }
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/user/iswishlist`, { headers })
      .then((res) => {
        setWishlist(res?.data?.wishlist);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }, [headers, setWishlist]);

  const replaceSpacesWithHyphens = (urlString) => {
    if (typeof urlString === 'string') {
      return urlString.replace(/ /g, '-').toLocaleLowerCase();
    }
    return urlString;
  };

  return (
    <>
      <div className="listProducts-banner">
        <h2>Wishlist</h2>
      </div>
      {wishlist?.length ? (
        <div className="listProducts px-2r my-5">
          {wishlist.map((wishlistProduct) => (
            <div key={wishlistProduct._id} style={{ overflowX: "auto" }}>
              <table className="wishlist-products">
                <tbody>
                  <tr>
                    <td
                      className="px-3 "
                      onClick={() => removeWishlist(wishlistProduct)}
                    >
                      <AiOutlineClose />
                    </td>
                    <Link
                      to={wishlistProduct?.descriptions[0]?.value !== undefined
                        ? `/${replaceSpacesWithHyphens(wishlistProduct?.category?.name)}/${replaceSpacesWithHyphens(wishlistProduct?.subcategoryname?.name)}/${replaceSpacesWithHyphens(wishlistProduct?.descriptions[0]?.value)}/${wishlistProduct?.slug}`
                        : `/product/${wishlistProduct?.slug}`}
                      className="text-decoration-none text-dark"
                    >
                      <td className="">
                        {isLoggedIn ? (
                          <img
                            src={wishlistProduct?.ProductId?.productimages[0]?.productimage}
                            alt="wishlistProducts-image"
                          />
                        ) : (
                          <img
                            src={wishlistProduct?.productimages[0]?.productimage}
                            width={"10px"}
                            alt="wishlistProducts-image"
                          />
                        )}
                      </td>
                    </Link>
                    <td className="px-2 " style={{ width: "50%" }}>
                      <h6 className="wishlistProducts-name text-dark">
                        {isLoggedIn ? (
                          <>{wishlistProduct?.ProductId?.partNumber}</>
                        ) : (
                          <>{wishlistProduct?.partNumber}</>
                        )}
                      </h6>
                    </td>
                    <td>
                      <p className="fw-bold m-0">
                        {isLoggedIn ? (
                          <>{wishlistProduct?.ProductId?.saleprice}</>
                        ) : (
                          <>₹{wishlistProduct?.saleprice}</>
                        )}
                      </p>
                    </td>
                    <td>
                      <AddCartButton
                        type="button"
                        className="wishlistProductsbButton"
                        data={wishlistProduct}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <motion.ul
            className="list-style-none text-center"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={listVariants}
          >
            <motion.li
              key="empty-image"
              variants={itemVariants}
              whileHover="hover"
              whileTap="tap"
              className="NoWishlistlists"
            >
              <img src={img1} alt="Empty Cart" className="NoWishlistImage" />
            </motion.li>

            <motion.li
              key="empty-message"
              variants={itemVariants}
              whileHover="hover"
              whileTap="tap"
            >
              <h4 className="p-3 text-center no-wishlist-content">
                There are no products on the Wishlist!
              </h4>
            </motion.li>
          </motion.ul>
        </div>
      )}
    </>
  );
}

export default Wishlist;
