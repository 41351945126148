import axios from "axios";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../utils";

const FastenersProductIdProvider = (WrappedComponent) => {

  return function WithHOC(props) {
    const [category, setCategory] = useState([]); 
    const categoryId = category[0]?._id;
  
    const categoryName = category[0]?.name;

    useEffect(() => {
      axios
        .get(`${API_BASE_URL}/category/user/getcategory`)
        .then((response) => {
          setCategory(response?.data?.categories || []);
          // console.log("================>",response.data)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, []);

    return categoryId ? (
      <WrappedComponent
        {...props}
        categoryId={categoryId}
        categoryName={categoryName}
      />
    ) : null;
  };
};

export default FastenersProductIdProvider;