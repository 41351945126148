import React from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import "./Howtouse.css";
import { Link } from "react-router-dom";
import video from "./Colorful Online E-Commerce Website Promotion Video.mp4";
import { Helmet } from "react-helmet";

export default function Howtouse() {
  return (
    <div className="container">
       <Helmet>
        <title> bestindiakart Is Leading Fasteners Manufacturer in India.</title>
        <meta name="description" content="Best India Kart stands as India’s top fasteners manufacturer, providing durable, precision-engineered bolts, nuts, and screws to meet the needs of diverse industries."/>
        {/* <meta name="keywords" content="Jobs, Service,Automobiles,Store, Education, Travels, Event"/> */}
      </Helmet>
      <Row className="mb-4">
        <Col md={7}>
          <iframe
            width="100%"
            height="315"
            // src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
            src={video}
            title="Video Tutorial"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Col>
        <Col md={5}>
          <div style={{}}>
            <div style={{padding:"0px !important"}}>
              <div className="text-center fw-bold fs-4">
                How to Use Our Website
              </div>
              <div style={{ padding: "", wordSpacing: "2px",height:"315px",overflow:"auto" }} className="p-3">
                {/* Welcome to <span className="fw-semibold">BEST INDIA KART</span>,
                your one-stop solution for high-quality fasteners! Whether
                you’re a DIY enthusiast, a contractor, or an industrial buyer,
                our website makes it easy to browse, select, and purchase the
                fasteners you need. Follow these simple steps to get started. */}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Welcome to <span className="fw-semibold">BEST INDIA KART</span>, your trusted partner for sourcing
                premium fasteners of all types! Whether you're a DIY enthusiast
                working on home projects, a contractor managing large-scale
                builds, or an industrial buyer sourcing materials for
                manufacturing, we are here to streamline your shopping
                experience. At BEST INDIA KART, we provide a vast selection of
                high-quality fasteners to meet your unique needs, and we’ve made
                our website intuitive and easy to navigate so you can find
                exactly what you're looking for with ease.
                <br /> <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; By following the step-by-step guide below, you'll learn how to
                browse through our extensive catalog, add products to your cart,
                and complete your purchase in just a few clicks. Whether you're
                shopping for nuts, bolts, washers, screws, or specialty
                fasteners, our user-friendly platform ensures a smooth shopping
                journey from start to finish.
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="step-wrapper">
        <div className="step-item">
          <Badge bg="primary" className="step-badge">
            1
          </Badge>
          <div className="step-content">
            <h5>Register an Account</h5>
            <p>
              Click on the "<Link to="/registration">Sign Up</Link>" button at
              the top-right corner of the homepage. Fill in your details,
              including your name, email, and password. Verify your email via a
              One-Time Password (OTP) sent to your inbox. Once verified, log in
              with your credentials to start exploring!
            </p>
          </div>
        </div>
        <div className="step-item">
          <Badge bg="primary" className="step-badge">
            2
          </Badge>
          <div className="step-content">
            <h5>Login</h5>
            <p>
              Already have an account? Simply: Click "{" "}
              <Link to="/login">Login</Link> " at the top of the page. Enter
              your email and password. If required, complete OTP verification
              for added security.
            </p>
          </div>
        </div>
      </div>
      <div className="step-wrapper">
        <div className="step-item">
          <Badge bg="primary" className="step-badge">
            3
          </Badge>
          <div className="step-content">
            <h5>Browse and Select Products</h5>
            <p>
              Search for products using the search bar or browse through the
              categories. View detailed information about each product,
              including sizes, materials, and availability. Use the filter
              options to refine your search and find exactly what you're looking
              for.
            </p>
          </div>
        </div>
        <div className="step-item">
          <Badge bg="primary" className="step-badge">
            4
          </Badge>
          <div className="step-content">
            <h5>Add to Cart</h5>
            <p>
              Once you’ve found the right products, select the quantity and
              click the "Add to Cart" button. You can continue shopping or
              proceed to checkout.
            </p>
          </div>
        </div>
      </div>
      <div className="step-wrapper">
        <div className="step-item">
          <Badge bg="primary" className="step-badge">
            5
          </Badge>
          <div className="step-content">
            <h5>Review Your Cart</h5>
            <p>
              Click the cart icon at the top of the page to review the products
              you've added. If everything looks good, proceed to checkout.
            </p>
          </div>
        </div>
        <div className="step-item">
          <Badge bg="primary" className="step-badge">
            6
          </Badge>
          <div className="step-content">
            <h5>Checkout & Payment</h5>
            <p>
              Enter your shipping information and choose your preferred shipping
              method. Select your payment method from our secure payment options
              (Credit Card, Debit Card, Net Banking, etc.). Review your order
              details and click on "Place Order" to confirm your purchase.
            </p>
          </div>
        </div>
      </div>
      <div className="step-wrapper">
        <div className="step-item">
          <Badge bg="primary" className="step-badge">
            7
          </Badge>
          <div className="step-content">
            <h5>Shipping & Delivery</h5>
            <p>
              Once your order is placed, you’ll receive a confirmation email
              with your order details. Our team will prepare your order and ship
              it to your provided address. Track your order through the Order
              Tracking page and get notified when it’s out for delivery.
            </p>
          </div>
        </div>
        <div className="step-item">
          <Badge bg="primary" className="step-badge">
            8
          </Badge>
          <div className="step-content">
            <h5>Order Complete</h5>
            <p>
              After your order has been delivered, don’t forget to: Leave a
              review for the products you purchased. If you have any issues or
              questions, visit our Help Center for assistance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

// console.log("sdfsd")
