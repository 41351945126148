import React from "react";
import CartItem from "./CartItem";
  import img1 from "../../Assets/Images/Capture-01.png";
import { motion } from 'framer-motion';
import "../AddCartProducts/AddCartProducts.css";


function AddCartProducts({
  products,
  cartIncrement,
  cartDecrement,
  removeItem,
}) {
  const isValidData = Array.isArray(products) && products?.length > 0;




const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  hover: { scale: 1.05 },
  tap: { scale: 0.95 },
  exit: { opacity: 0, y: -20, transition: { duration: 0.3 } },
};

  return (
    <tbody>
      {isValidData ? (
        products.map((product, index) => (
          
          // {console.log(product,"productdetails")}
          <CartItem
            cartDecrement={cartDecrement}
            removeItem={removeItem}
            cartIncrement={cartIncrement}
            key={index}
            product={product}
          />
        )
        )
      ) : (
        <tr>
          <td colSpan="7">
          <motion.ul
          className="list-style-none text-center"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={listVariants}
        >
          <motion.li
            variants={itemVariants}
            whileHover="hover"
            whileTap="tap"
          >
            <img src={img1} alt="Empty Cart" className="Empty-cart" />
          </motion.li>
          <motion.li
            variants={itemVariants}
            whileHover="hover"
            whileTap="tap"
          >
            <h5>Your cart is Empty</h5>
          </motion.li>
          <motion.li
            variants={itemVariants}
            whileHover="hover"
            whileTap="tap"
          >
            <p>Looks like you have not added anything to your cart. Go ahead & explore top Categories.</p>
          </motion.li>
        </motion.ul>
            
            </td>
        </tr>
      )}
    </tbody>
  );
}

export default AddCartProducts;









// import React from "react";
// import CartItem from "./CartItem";
// import img1 from "../../Assets/Images/Capture-01.png"
// import { motion } from 'framer-motion';
// import "../AddCartProducts/AddCartProducts.css"
// const listVariants = {
//   hidden: { opacity: 0 },
//   visible: {
//     opacity: 1,
//     transition: {
//       staggerChildren: 0.3,
//     },
//   },
// };

// const itemVariants = {
//   hidden: { opacity: 0, y: 20 },
//   visible: { opacity: 1, y: 0 },
//   hover: { scale: 1.05 },
//   tap: { scale: 0.95 },
//   exit: { opacity: 0, y: -20, transition: { duration: 0.3 } },
// };
// function AddCartProducts({ products,cartIncrement, cartDecrement, removeItem }) {
//   const isValidData = Array.isArray(products) && products.length > 0

//   return (
//     <tbody>
//       {isValidData ? (
//         products.map((product, index) => (
//           <CartItem cartDecrement={cartDecrement} removeItem={removeItem} cartIncrement={cartIncrement} key={index} product={product} />
//         ))
//       ) : (
//       <>

//       <motion.ul
//    className="list-style-none  text-center"
//    initial="hidden"
//    animate="visible"
//    exit="exit"
//    variants={listVariants}
//  >
//    <motion.li
//      variants={itemVariants}
//      whileHover="hover"
//      whileTap="tap"
//    >
//      <img src={img1} alt="ssss" className="w-100" />
//    </motion.li>
//    <motion.li
//      variants={itemVariants}
//      whileHover="hover"
//      whileTap="tap"
//    >
//      <h5>Your cart is Empty</h5>
//    </motion.li>
//    <motion.li
//      variants={itemVariants}
//      whileHover="hover"
//      whileTap="tap"
//    >
//      <p>Looks like you have not added anything to your cart. Go ahead & explore top Categories.</p>
//    </motion.li>
//  </motion.ul>

//       </>

//       )}
//     </tbody>
//   );
// }

// export default AddCartProducts;

// import React from "react";
// import CartItem from "./CartItem";
// import img1 from "../../Assets/Images/Capture-01.png";
// import { motion } from 'framer-motion';
// import "../AddCartProducts/AddCartProducts.css";

// const listVariants = {
//   hidden: { opacity: 0 },
//   visible: {
//     opacity: 1,
//     transition: {
//       staggerChildren: 0.3,
//     },
//   },
// };

// const itemVariants = {
//   hidden: { opacity: 0, y: 20 },
//   visible: { opacity: 1, y: 0 },
//   hover: { scale: 1.05 },
//   tap: { scale: 0.95 },
//   exit: { opacity: 0, y: -20, transition: { duration: 0.3 } },
// };

// function AddCartProducts({ products, cartIncrement, cartDecrement, removeItem }) {
//   const isValidData = Array.isArray(products) && products.length > 0;

//   return (
//     <>
//       {!isValidData ? (
//         <motion.ul
//           className="list-style-none text-center"
//           initial="hidden"
//           animate="visible"
//           exit="exit"
//           variants={listVariants}
//         >
//           <motion.li
//             variants={itemVariants}
//             whileHover="hover"
//             whileTap="tap"
//           >
//             <img src={img1} alt="Empty Cart" className="w-100" />
//           </motion.li>
//           <motion.li
//             variants={itemVariants}
//             whileHover="hover"
//             whileTap="tap"
//           >
//             <h5>Your cart is Empty</h5>
//           </motion.li>
//           <motion.li
//             variants={itemVariants}
//             whileHover="hover"
//             whileTap="tap"
//           >
//             <p>Looks like you have not added anything to your cart. Go ahead & explore top Categories.</p>
//           </motion.li>
//         </motion.ul>
//       ) : (
//         <table>
//           <tbody>
//             {products.map((product, index) => (
//               <CartItem
//                 cartDecrement={cartDecrement}
//                 removeItem={removeItem}
//                 cartIncrement={cartIncrement}
//                 key={index}
//                 product={product}
//               />
//             ))}
//           </tbody>
//         </table>
//       )}
//     </>
//   );
// }

// export default AddCartProducts;
