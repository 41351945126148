import React from "react";
import "../CheckOut/CheckOut.scss";
import "../../Common/Common.scss";
import { Link } from "react-router-dom";
import Coupon from "./Coupon";

function PlaceOrder({
  placeOrder,
  state,
  setPlaceOrder,
  loading,
  setPrivacyPolicey,
  privacyPolicey,
  setTermsAndConditons,
  termsAndConditions,
}) {
  const cartTotals = placeOrder?.cartProductsTotals;
  const cartProducts = placeOrder?.products;

  return (
    <div className="col-lg-6 billing-inner order-form">
      <div className="cart-collaterals">
        <div className="cart_totals">
          <h6>Your order</h6>
          <div className="shop_table py-2">
            <table className="w-100">
              <thead>
                <tr className="cart-row">
                  <th className=" py-2">Product</th>
                  <th className="text-center py-2">Quantity</th>
                  <th className="text-end py-2">Total Price</th>
                </tr>
              </thead>

              <tbody>
                {cartProducts &&
                Array.isArray(cartProducts) &&
                cartProducts?.length > 0 ? (
                  cartProducts.map((products, index) => {
                    return (
                      <tr className="cart-row ">
                        <td key={index} className="fw-medium w-50 py-2">
                          {products?.partNumber}
                        </td>

                        <td className="text-center py-2">
                          {products?.quantity}
                        </td>
                        <td className="text-end py-2">
                          ₹ {products?.subTotal}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <p>No products in the cart</p>
                )}
              </tbody>
            </table>

            <div>
              {cartTotals &&
                cartTotals.map((cart, index) => {
                  return (
                    <div
                      className="cart-row d-flex justify-content-between"
                      key={index}
                    >
                      <span className="fw-bold  py-2">{cart?.type}</span>
                      <span className="text-end py-2">₹ {cart?.value}</span>
                    </div>
                  );
                })}

              {/* <div className="d-flex justify-content-between">
                <td className="fw-bold  py-3">Total</td>
                <h6 className="text-end py-3 text-danger fw-semibold">
                  ₹ {placeOrder.total}
                </h6>
              </div> */}
            </div>

            {state && (
              <Coupon
                setPlaceOrder={setPlaceOrder}
                placeOrder={placeOrder}
                state={state}
              />
            )}

            <div className="my-4 d-flex align-items-start">
              <input
              required
                type="checkbox"
                id="privacy_policy"
                className="my-1"
                onChange={(e) => setPrivacyPolicey(!privacyPolicey)}
              />

              <label htmlFor="privacy_policy" className="privacy-policy mx-2">
                Your personal data will be used to process your order, support
                your experience throughout this website, and for other purposes
                described in our &nbsp;
                <Link className="text-danger" to="/privacypolicy">
                  privacy policy.
                </Link>
              </label>
            </div>
            <div className="my-4 d-flex align-items-start">
              <input
              required
                type="checkbox"
                id="privacy_policy"
                className="my-1"
                onChange={(e) => setTermsAndConditons(!termsAndConditions)}
              />

              <label htmlFor="privacy_policy" className="privacy-policy mx-2">
              By placing an order, you agree to our terms and conditions and acknowledge our privacy policy. 
              Please read these documents carefully before proceeding with your order.
                <Link className="text-danger" to="/terms-and-conditions">
                  Terms and Conditions.
                </Link>
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="checkout-button text-light"
            disabled={loading}
          >
            {loading ? "Processing..." : "Pay Now"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PlaceOrder;
