import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollTop() {
  const pathName = useLocation();

  useEffect(() => {
    window.scrollTo(0, 10);
  }, [pathName]);
  
  return null;
}

export default ScrollTop;
