import React from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { storeCartProducts } from "../services/cart.services";
import { useContext } from "react";
import userContext from "../UseContext/UseContext";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../Token";
import { Toaster } from "react-hot-toast";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default function AddCartButton({ type, data, className }) {
  const productContext = useContext(userContext);
  const guestID = localStorage.getItem("guestId");
  const headers = useAuthHeaders();

  const handleClick = async () => {
    const isLoggedIn = localStorage.getItem("userId");

    const productId = data?._id;
    const quantity = 1;
    const slug = data?.slug;
    // console.log("data is comeing --------->",data)

    if (isLoggedIn) {
      try {
        // await storeCartProducts({ productId, quantity, slug, headers });

        const userId = localStorage.getItem("userId");
        const headers1 = {
          Authorization: `Bearer ${userId}`,
          "Content-Type": "application/json",
        };

        const response = await storeCartProducts({
          productId,
          quantity,
          slug,
          headers,
        });

        // console.log("addtocart", response?.data);

        localStorage.setItem("addtocartLength", response?.data?.cart?.length);
        productContext.setAddCartLength(response?.data?.cart?.length);
        toast.success("Added to Cart Successfully");

        productContext?.setCart([...productContext?.cart, data]);
      } catch (error) {
        console.error("Error adding to cart:", error);
        handleCartError(error);
      }
    } else {
      handleGuestCart(productId, quantity, slug);
    }
  };

  const handleCartError = (error) => {
    if (error?.response?.status === 401) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.error,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
    }
    // toast?.error("Failed to add to Cart");
  };

  const handleGuestCart = (productId, quantity, slug) => {
    const guestID = localStorage.getItem("guestID");

    const impureArray = localStorage.getItem("CartProducts");
    let existingCart = impureArray ? JSON.parse(impureArray) : [];

    // Check if the product is already in the cart
    const productIndex = existingCart.findIndex(
      (cartItem) => cartItem.productId === productId
    );

    if (productIndex !== -1) {
      // If product is found, update the quantity
      existingCart[productIndex].quantity += quantity;
      toast.success("Product quantity updated in the cart");
    } else {
      // If product is not found, add it to the cart
      existingCart.push({ productId, quantity, slug });
      toast.success("Added to Cart Successfully");
    }

    localStorage.setItem("CartProducts", JSON.stringify(existingCart));
    localStorage.setItem("addtocartLength", existingCart.length);
    productContext.setAddCartLength(existingCart.length);

    addProductToLocalStorage(existingCart, productId, quantity, slug, guestID);
  };

  const addProductToLocalStorage = (
    existingCart,
    productId,
    quantity,
    slug,
    guestID
  ) => {
    const gustIDs = localStorage.getItem("guestId");
    const headers1 = {
      Authorization: `Bearer ${gustIDs}`,
      "Content-Type": "application/json",
    };

    axios
      .post(
        `${API_BASE_URL}/order/addtocart`,
        { productId, quantity, Guestid: gustIDs },
        { headers: headers1 }
      )
      .then((res) => {
        // console.log("getcart in local ::", res?.data);
        localStorage.setItem("addtocartLength", res?.data?.cartLength);
        productContext.setAddCartLength(res?.data?.cartLength);
        // toast.success("Added to Cart Successfully!");

        // const updatedCart = [...existingCart, res?.data?.cart];
        // localStorage.setItem("CartProducts", JSON.stringify(updatedCart));
      })
      .catch((error) => {
        console.error("Error adding to cart:", error);
        // toast.error("Failed to add to Cart");
      });
  };

  if (type === "icon") {
    return (
      <div className="add-to-card">
        <AiOutlineShoppingCart onClick={handleClick} className="card-menu" />

        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
    );
  }

  if (type === "product_button") {
    return (
      <div className="add-to-card">
        <button className={className} onClick={handleClick}>
          Add to Cart
        </button>

        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
    );
  }

  if (type === "button") {
    return (
      <>
        <button
          className={className}
          // onClick={() => cartHandler(data)}
          onClick={handleClick}
          // className={`${className} ${isButtonDisabled ? "disabled" : ""}`}
        >
          Add to Cart
        </button>

        <Toaster position="bottom-right" reverseOrder={false} />
      </>
    );
  }
  return null;
}
