import React from "react";
import { BsArrowRight } from "react-icons/bs";
import "../../Pages/Home/Home.scss";
import plant from "../../Assets/Images/plane.png.png";
import { Link } from "react-router-dom";

function Requirements() {
  return (
    <div className="requirements px-2r" style={{background:"#0443C0"}}>
      <div>
        <h3>Requirements, information or Something else?</h3>
        <p>We Please be so kind and send us your enquiry.</p>
      </div>
      
      <img src={plant} className="plant-image" alt="plant" />
      <Link to={"/brochure"}>    <button className="button">
        Download Brochure <BsArrowRight />
      </button></Link> 
      
      
      
  
     
    </div>
  );
}

export default Requirements;
