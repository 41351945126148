// import React from "react";
// import Email from "../Assets/email_icon.png";
// import Mobile from "../Assets/phone.png";
// import Website from "../Assets/world.png";
// import Location from "../Assets/loaction.png";
// import "./Contact.scss";
// import { Link } from "react-router-dom";
// import { SlGlobe } from "react-icons/sl";

// const Contact = () => {
//   return (
//     <div>
//       <section className="about-ecommerce-platform">
//         <h2 className="contact-heading">Contact Us</h2>
//         <div className="container " style={{ background: "F8F4E1" }}>
//           {/* <div className="row text-bg-dark " style={{backgroundColor:"#1B4242"}}> */}
//           <div
//             className="row contact-background-color"
//             style={{ backgroundColor: "#1B4242", color: "white" }}
//           >
//             <div
//               className="col-md-6 p-3 contact-info-container"
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 flexDirection: "column",
//               }}
//             >
//               {/* <table className="table"> */}

//               <ul className="">
//                 <li className=" ">
//                   <span className="">
//                     <i class="fa-solid fa-location-dot m-2" style={{color:"#F04B25"}}></i>
//                     <span style={{color:"#F04B25"}}>Address:</span>
//                   </span>
//                   <br></br>
//                 </li>
//                 <li className="ms-5" style={{color: "#0443C0",fontWeight:"bold"}}>
//                   No 1A, Jeevarathinam Street, KSR Nagar, Ambattur, Chennai,
//                   Tamil Nadu - 600053
//                 </li>
//               </ul>

//               <ul className="">
//                 <li>
//                   <span className="" style={{color:"#F04B25"}}>
//                     <i class="fa-solid fa-phone m-2" style={{color:"#F04B25"}}></i>Lets Talk
//                   </span>
//                   <br></br>
//                 </li>
//                 <li className="ms-5">
//                   <a
//                     href="tel:+917812810488"
//                     style={{ textDecoration: "none",color: "#0443C0",fontWeight:"bold"}}
//                     className="contact-web-links"
//                   >
//                     +91 78128 10488
//                   </a>
//                 </li>
//               </ul>

//               <ul className="">
//                 <li className="" style={{color:"#F04B25"}}>
//                   <img
//                     src={Email}
//                     className="about-contact-images m-2 "
//                     alt="image not found"
//                     width={"20px"}
                 
//                   />
//                   E-Mail to
//                 </li>
//                 <li className="ms-5">
//                   <li>
//                     <a
//                       href="mailto:marketing@bumaas.com"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       style={{ textDecoration: "none", color: "#0443C0",fontWeight:"bold" }}
//                       className="contact-web-links"
//                     >
//                       marketing@bumaas.com.{" "}
//                     </a>
//                   </li>
//                 </li>
//               </ul>

//               <ul className="">
//                 {/* <li className="m-3"> */}
//                 <li className="" style={{color:"#F04B25"}}>
//                   {/* <img
//                     src={Website}
//                     className="about-contact-images m-2 "
//                     alt="image not found"
//                     width={"20px"}
                    
//                   /> */}
//                   <SlGlobe />&nbsp;
//                   Websites:
//                 </li>
//                 <li className="ms-5">
//                   <li>
//                     <a
//                       href="https://www.bumaas.com/"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       style={{ textDecoration: "none", color: "#0443C0" ,fontWeight:"bold"}}
//                       className="contact-web-links"
//                     >
//                       www.bumaas.com
//                     </a>
//                   </li>
//                   <li>
//                     <a
//                       href="https://www.grip-fasteners.com/"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       style={{ textDecoration: "none", color: "#0443C0",fontWeight:"bold"}}
//                     >
//                       grip-fasteners.com
//                     </a>
//                   </li>
//                   <li>
//                     <a
//                       href="https://www.bestindiakart.com/"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       style={{ textDecoration: "none", color: "#0443C0",fontWeight:"bold" }}
//                     >
//                       bestindiakart.com
//                     </a>
//                   </li>
//                 </li>
//               </ul>

//               <article style={{ fontSize: "16px", wordSpacing: "1px",color: "#0443C0"}}>
//                 Thank you for choosing Bumaas Engineering Solution &
//                 Technologies Pvt Ltd. We look forward to serving you and
//                 providing the finest fastening solutions for your business.{" "}
//                 <Link to="/inquiry"> for inquiry</Link>
//               </article>
//               {/* </table> */}
//             </div>

//             <div className="col-md-6 bg-white">

//               <iframe
//                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.936773944414!2d80.15362727367393!3d13.103191911982373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52632252dbf201%3A0x5c05afd003bc7d8e!2s1A%2C%20Jeevarathinam%20St%2C%20beside%20Ashwin%20Automations%2C%20Ksr%20Nagar%2C%20Ambattur%2C%20Chennai%2C%20Tamil%20Nadu%20600053!5e0!3m2!1sen!2sin!4v1720618533891!5m2!1sen!2sin"
//                 allowFullScreen
//                 loading="lazy"
//                 referrerPolicy="no-referrer-when-downgrade"
//                 className="w-100 h-100"
//               ></iframe>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Contact;




//--------------07/31/2024----------------------------------



import React from "react";
import Email from "../Assets/email_icon.png";
import Mobile from "../Assets/phone.png";
import Website from "../Assets/world.png";
import Location from "../Assets/loaction.png";
import "./Contact.scss";
import { Link } from "react-router-dom";
import { SlGlobe } from "react-icons/sl";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { SlEnvolope } from "react-icons/sl";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <div className="about-ecommerce-platform ">
       <Helmet>
        <title>Contact Us | Best India Kart Fasteners Manufacturer in India </title>
        <meta
          name="description"
          content="Contact with the Best India Kart -bestindiakart is Leading Fasteners Manufacturer in India."
        />
      </Helmet>
      <section >
        <h2 className="about-heading ">Contact Us</h2>
        <div className="container " style={{}}>
          {/* <div className="row text-bg-dark " style={{backgroundColor:"#1B4242"}}> */}
          <div
            className="row contact-background-color"
            style={{ color: "white" }}
          >
            <div
              className="col-md-6 p-3 contact-info-container"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {/* <table className="table"> */}

              <ul className="cont-sty">
                <li className="fs-5 ">
                  <span className="">
                  <FaLocationDot />&nbsp;
                    <span style={{color: "black",fontFamily:"outfit"}}>Address</span>
                  </span>
                  <br></br>
                </li>
                <li className="con-lf" style={{color: "black",fontWeight:"400",fontFamily:"arial"}}>
                  No 1A, Jeevarathinam Street, KSR Nagar, Ambattur, Chennai,
                  Tamil Nadu - 600053
                </li>
             
                <hr style={{color:"black",opacity:0.1}}></hr>
              </ul>

              <ul className="cont-sty">
                <li className="fs-5">
                <FaPhoneAlt />&nbsp;<span className="" style={{color: "black",}}>
                  Lets Talk
                  </span>
                  <br></br>
                </li>
                <li className="con-lf text-black">
                  <a
                    href="tel:+917812810488"
                    style={{ textDecoration: "none",color:"black",fontWeight:"400",fontsize:"13px"}}
                    className="text-black"
                  >
                    +91 78128 10488
                  </a>
                </li>
                <hr style={{color:"black",opacity:0.1}}></hr>
              </ul>

              <ul className="cont-sty">
                <li className="text-black fs-5" style={{color:"#F04B25"}}>
                  {/* <img
                    src={Email}
                    className=" m-2 text-black "
                    alt="image not found"
                    width={"20px"}
                    style={{fontFamily:"outfit"}}
                 
                  /> */}

             <SlEnvolope />&nbsp;<span>E-Mail to</span>
                </li>
                <li className="con-lf">
                  <li>
                    <a
                      href="mailto:marketing@bumaas.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "black",fontWeight:"400",fontFamily:"arial" }}
                      className="text-black"
                    >
                      marketing@bumaas.com{" "}
                    </a>
                  </li>
                </li>
                <hr style={{color:"black",opacity:0.1}}></hr>
              </ul>

              <ul className="cont-sty">
                {/* <li className="m-3"> */}
                <li className="text-black fs-5" >
                  {/* <img
                    src={Website}
                    className="about-contact-images m-2 "
                    alt="image not found"
                    width={"20px"}
                    
                  /> */}
                  <SlGlobe style={{color:"#f4857e",fontFamily:"outfit"}} />&nbsp;
                  <span>Websites</span>
                </li>
                <li className="con-lf">
                  <li>
                    <a
                      href="https://www.bumaas.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "black" ,fontWeight:"400",fontFamily:"arial"}}
                      className=""
                    >
                      www.bumaas.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.grip-fasteners.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none",color: "black",fontWeight:"400",fontFamily:"arial"}}
                    >
                      grip-fasteners.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.bestindiakart.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "black",fontWeight:"400",fontFamily:"arial" }}
                    >
                      bestindiakart.com
                    </a>
                  </li>
                </li>
                <hr style={{color:"black",opacity:0.1}}></hr>
              </ul>

              <article style={{ fontSize: "13px", wordSpacing: "1px",color: "black"}} className="text-center">
                Thank you for choosing BEST. We look forward to serving you and
                providing the finest fastening solutions for your business.{" "}
                <Link to="/inquiry"> for inquiry</Link>
              </article>
              {/* </table> */}
            </div>

            <div className="col-md-6 ">

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.936773944414!2d80.15362727367393!3d13.103191911982373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52632252dbf201%3A0x5c05afd003bc7d8e!2s1A%2C%20Jeevarathinam%20St%2C%20beside%20Ashwin%20Automations%2C%20Ksr%20Nagar%2C%20Ambattur%2C%20Chennai%2C%20Tamil%20Nadu%20600053!5e0!3m2!1sen!2sin!4v1720618533891!5m2!1sen!2sin"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-100 h-100 p-3"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;














