// import axios from "axios";
// import { useContext, useState } from "react";
// import { CiCircleRemove } from "react-icons/ci";
// import { useAuthHeaders } from "../Token";
// import "../../Common/Common.scss";
// import userContext from "../UseContext/UseContext";
// import { useEffect } from "react";
// import { API_BASE_URL } from "../../utils";
// import { Link } from "react-router-dom";

// export default function CartItem({ product }) {

//   const productId = product.productId;
//   const headers = useAuthHeaders();
//   console.log(headers)
//   const store = useContext(userContext);
//   const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
//   const [quantity, setQuantity] = useState(product?.quantity || 1);
//   const isLoggedIn = localStorage.getItem("userId");

//   const guesrID = localStorage.getItem("guestId");

//   const handleQuantityChange = (e) => {
//     const newQuantity = parseInt(e?.target?.value, 10);
//     setQuantity(newQuantity);
//   };

//   const removeFromCart = async () => {
//     try {
//       const headers1 = {
//         Authorization: `Bearer ${isLoggedIn}`,
//         "Content-Type": "application/json",
//       };
//       if (isLoggedIn) {
//         axios
//           .delete(`${API_BASE_URL}/user/order/deleteaddtocart/${productId}`, {
//             headers:headers1,
//           })
//           .then((res) => {
//             store?.setCartResults(res?.data);
//             console.log("deleteaddtocart", res.data.response.cartLength);
//             localStorage.setItem(
//               "addtocartLength",
//               res?.data?.response?.cartLength
//             );

//             // console.log(res.data)
//             // console.log("=============")
//             // console.log(res.data.response)

//           })
//           .catch((error) => {
//             console.log("error", error);
//           });
//       } else {
//         axios
//           .delete(`${API_BASE_URL}/order/guestremovecart/${productId}`, {
//             data: { guestId: guesrID },
//           })
//           .then((res) => {
//             store?.setCartResults(res?.data);
//             // setCartItems(res.data);
//             localStorage.setItem(
//               "addtocartLength",
//               res?.data?.response?.cartLength
//             );

//             const storedGuestProducts = JSON?.parse(
//               localStorage?.getItem("CartProducts")
//             );

//             const indexToRemove = 0;

//             if (
//               indexToRemove >= 0 &&
//               indexToRemove < storedGuestProducts?.length
//             ) {
//               storedGuestProducts?.splice(indexToRemove, 1);

//               localStorage.setItem(
//                 "CartProducts",
//                 JSON.stringify(storedGuestProducts)
//               );
//             } else {
//               console.error("Invalid index to remove");
//             }
//           })
//           .catch((error) => {
//             console.log("error", error);
//           });
//       }
//     } catch (error) {
//       console.error("Error removing item from cart:", error);
//     }
//   };

//   const handleIncrement = async () => {
//     try {
//       const newQuantity = quantity + 1;
//       setQuantity(newQuantity);

//       if (isLoggedIn) {
//         axios
//           .put(
//             `${API_BASE_URL}/user/order/updateaddtocart/${productId}`,
//             {
//               quantity: 1,
//             },
//             {
//               headers,
//             }
//           )
//           .then((res) => {
//             console.log("getcart in guest::", res.data);
//             store.setCartResults(res.data);
//           })
//           .catch((error) => {
//             console.log("error", error);
//           });
//       } else {
//         axios
//           .put(`${API_BASE_URL}/order/guestupdatecart/${productId}`, {
//             guestId: guesrID,
//             quantity: quantity + 1,
//           })
//           .then((res) => {
//             store?.setCartResults(res.data);
//             // console.log("getcart in guest::", res.data);
//             // setCartItems(res.data);
//           })
//           .catch((error) => {
//             console.log("error", error);
//           });
//       }
//     } catch (error) {
//       console.error("Error updating quantity:", error);
//     }
//   };

//   const handleDecrement = async () => {
//     try {
//       const newQuantity = Math.max(quantity - 1, 1);
//       setQuantity(newQuantity);

//       if (isLoggedIn) {
//         axios
//           .put(
//             `${API_BASE_URL}/user/order/updateaddtocart/${productId}`,
//             {
//               quantity: -1,
//             },
//             {
//               headers,
//             }
//           )
//           .then((res) => {
//             console.log("getcart in guest::", res.data);
//             store.setCartResults(res.data);
//           })
//           .catch((error) => {
//             console.log("error", error);
//           });
//       } else {
//         axios
//           .put(
//             `${API_BASE_URL}/order/guestupdatecart/${productId}`,
//             {
//               guestId: guesrID,
//               // quantity: -1,
//               quantity: quantity - 1,
//             },
//             {
//               headers,
//             }
//           )
//           .then((res) => {
//             console.log("getcart in guest::", res.data);
//             store.setCartResults(res.data);
//             // setCartItems(res.data);
//           })
//           .catch((error) => {
//             console.log("error", error);
//           });
//       }
//     } catch (error) {
//       console.error("Error updating quantity:", error);
//     }
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       setIsLargeScreen(window?.innerWidth > 768);
//     };

//     window?.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   if (isLargeScreen) {
//     return (
//       <tr className="text-center">
//         <td className="product-data">
//           <button
//             onClick={() => removeFromCart(product)}
//             className="btn btn-sm"
//           >
//             <CiCircleRemove className="text-dark fs-5" />
//           </button>
//         </td>

//         <td>
//           <Link to={`/product/${productId}`}>
//             <img
//               className="product-thumbnail"
//               src={product?.productimages[0]?.productimage}
//               alt="product-thumbnail"
//             />
//           </Link>
//         </td>

//         <td className="product-data">{product?.partNumber}</td>

//         <td className="product-data" style={{width:"20%"}}>{product?.salePrice}</td>

//         <td className="product-data">{product?.BoxofQantity}</td>

//         <td className="product-data">
//           <div className="input-group-quantity">
//             <button
//               className="btn btn-outline-secondary"
//               type="button"
//               onClick={handleDecrement}
//               disabled={quantity <= 1}
//             >
//               -
//             </button>
//             <input
//               type="number"
//               className="form-control text-center"
//               min={1}
//               value={quantity}
//               name="quantity"
//               onChange={handleQuantityChange}
//             />
//             <button
//               className="btn btn-outline-secondary"
//               type="button"
//               onClick={handleIncrement}
//             >
//               +
//             </button>
//           </div>
//         </td>

//         <td className="product-data text-center">
//           <h6>{product?.subTotal}</h6>
//         </td>
//       </tr>
//     );
//   } else {
//     return (
//       <div className="product-container">
//         <Link to={`/product/${product?._id}`} className="product-image">
//           <img
//             className="product-thumbnail"
//             src={product?.productimages[0]?.productimage}
//             alt="product-thumbnail"
//           />
//         </Link>
//         <div className="product-content w-100">
//           <div className="product-content-row">
//             <h6 className="">{product?.partNumber}</h6>

//             <button onClick={removeFromCart} className="btn btn-sm">
//               <CiCircleRemove className="text-dark fs-5" />
//             </button>
//           </div>

//           <div className="product-content-row">
//             <p className="m-0">PRICE PER PICE</p>

//             <h6 className="m-0">₹{product?.salePrice}</h6>
//           </div>

//           <div className="product-content-row">
//             <p className="m-0">BOQ</p>

//             <h6 className="m-0">₹{product?.BoxofQantity}</h6>
//           </div>

//           <div className="product-content-row">
//             <p className="m-0">QUANTITY</p>

//             <div className="input-group-quantity">
//               <input
//                 type="number"
//                 className="text-center"
//                 min={1}
//                 style={{ width: "40px" }}
//                 name="quantity"
//                 value={quantity}
//                 // value={product.currentQuantity || 1}
//                 onChange={handleQuantityChange}
//               />

//               <div className="quantity-button-group">
//                 <button className="" type="button" onClick={handleIncrement}>
//                   +
//                 </button>

//                 <button
//                   className=""
//                   type="button"
//                   onClick={handleDecrement}
//                   disabled={quantity <= 1}
//                 >
//                   -
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div className="product-content-row">
//             <p className="m-0">SUBTOTAL</p>
//             {/* <h6>₹ {product.saleprice * product.currentQuantity}</h6> */}
//             <h6>₹ {product?.subTotal}</h6>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

import axios from "axios";
import { useContext, useState } from "react";
import { CiCircleRemove } from "react-icons/ci";
import { useAuthHeaders } from "../Token";
import "../../Common/Common.scss";
import userContext from "../UseContext/UseContext";
import { useEffect } from "react";
import { API_BASE_URL } from "../../utils";
import { Link } from "react-router-dom";

export default function CartItem({
  cartDecrement,
  removeItem,
  cartIncrement,
  // key,
  product,
}) {
  // console.log("product  ------------>",product?product:"data not fetching")
  // const productId = product.productId;
  // const productId = product.productId;
  const productId = product?.slug;

  // console.log("product id is the ------------>", product);
  const headers = useAuthHeaders();
  // console.log(headers)
  const store = useContext(userContext);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  const [quantity, setQuantity] = useState(product?.quantity || 1);
  // console.log(product,"productid")
  const isLoggedIn = localStorage.getItem("userId");

  const guesrID = localStorage.getItem("guestId");

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e?.target?.value, 10);
    setQuantity(newQuantity);
  };

 

  const removeFromCart = async () => {
    try {
      const headers1 = {
        Authorization: `Bearer ${isLoggedIn}`,
        "Content-Type": "application/json",
      };

      const removeGuestCart = async () => {
        try {
          const res = await axios.delete(
            `${API_BASE_URL}/order/guestremovecart/${productId}`,
            {
              data: { guestId: guesrID },
            }
          );
          store?.setCartResults(res?.data);
          localStorage.setItem(
            "addtocartLength",
            res?.data?.response?.cartLength
          );

          const storedGuestProducts = JSON?.parse(
            localStorage?.getItem("CartProducts")
          );
          // console.log("storedGuestProducts",storedGuestProducts)
          const indexToRemove = storedGuestProducts.findIndex(
            (p) => p.slug === productId
          );

          if (indexToRemove >= 0) {
            storedGuestProducts.splice(indexToRemove, 1);
            localStorage.setItem(
              "CartProducts",
              JSON.stringify(storedGuestProducts)
            );
          } else {
            console.error("Invalid index to remove");
          }
        } catch (error) {
          console.log("error", error);
        }
      };

      const removeUserCart = async () => {
        try {
          const res = await axios.delete(
            `${API_BASE_URL}/user/order/deleteaddtocart/${productId}`,
            {
              headers: headers1,
            }
          );
          store?.setCartResults(res?.data);
          localStorage.setItem(
            "addtocartLength",
            res?.data?.response?.cartLength
          );
        } catch (error) {
          console.log("error", error);
        }
      };

      if (isLoggedIn) {
        // await Promise.all([removeUserCart(), removeGuestCart()]);
        await Promise.all([removeUserCart()]);
      } else {
        await removeGuestCart();
      }
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  const handleIncrement = async () => {
    try {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);

      const headers1 = {
        Authorization: `Bearer ${isLoggedIn}`,
        "Content-Type": "application/json",
      };
      if (isLoggedIn) {
        axios
          .put(
            `${API_BASE_URL}/user/order/updateaddtocart/${productId}`,
            {
              // quantity: quantity + 1,
              quantity: 1,
            },
            {
              headers: headers1,
            }
          )
          .then((res) => {
            // console.log("getcart in guest::", res.data);
            store.setCartResults(res.data);
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        axios
          .put(`${API_BASE_URL}/order/guestupdatecart/${productId}`, {
            guestId: guesrID,
            quantity: quantity + 1,
            // quantity: 1,
          })
          .then((res) => {
            store?.setCartResults(res.data);
            // console.log("getcart in guest::", res.data);
            // setCartItems(res.data);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };

  const handleDecrement = async () => {
    try {
      const newQuantity = Math.max(quantity - 1, 1);
      setQuantity(newQuantity);

      const headers1 = {
        Authorization: `Bearer ${isLoggedIn}`,
        "Content-Type": "application/json",
      };

      if (isLoggedIn) {
        axios
          .put(
            `${API_BASE_URL}/user/order/updateaddtocart/${productId}`,
            {
              quantity: -1,
            },
            {
              headers: headers1,
            }
          )
          .then((res) => {
            // console.log("getcart in guest::", res.data);/
            store.setCartResults(res.data);
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        axios
          .put(
            `${API_BASE_URL}/order/guestupdatecart/${productId}`,
            {
              guestId: guesrID,
              // quantity: -1,
              quantity: quantity - 1,
            },
            {
              headers,
            }
          )
          .then((res) => {
            // console.log("getcart in guest::", res.data);
            store.setCartResults(res.data);
            // setCartItems(res.data);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window?.innerWidth > 768);
    };

    window?.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const replaceSpacesWithHyphens = (urlString) => {
    if (typeof urlString === "string") {
      // Replace all spaces with hyphens
      return urlString.replace(/ /g, "-").toLocaleLowerCase();
    } else return urlString;
  };

  if (isLargeScreen) {
    return (
      <tr className="text-center">
        <td className="product-data">
          <button
            onClick={() => removeFromCart(product)}
            className="btn btn-sm"
          >
            <CiCircleRemove className="text-dark fs-5" />
          </button>
        </td>

        <td>
          <Link
            to={
              // product?.descriptions[0]?.value !== undefined
              //   ? `/${product?.category}/${replaceSpacesWithHyphens(product?.subcategory)}/${replaceSpacesWithHyphens(product?.descriptions[0]?.value)}/${productId}`
              //   : `/product/${product?.slug}`
              product?.descriptions[0]?.value !== undefined
                ? `/${replaceSpacesWithHyphens(product?.category)}/${replaceSpacesWithHyphens(
                    product?.subcategory
                  )}/${replaceSpacesWithHyphens(
                    product?.descriptions[0]?.value
                  )}/${product?.slug}`
                : `/product/${product?.slug}`
            }
          >
            <img
              className="product-thumbnail"
              src={product?.productimages[0]?.productimage}
              alt="product-thumbnail"
            />
          </Link>
        </td>

        <td className="product-data">{product?.partNumber}</td>

        <td className="product-data" style={{ width: "20%" }}>
          {product?.salePrice}
        </td>

        <td className="product-data">{product?.BoxofQantity}</td>

        <td className="product-data">
          <div className="input-group-quantity">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={handleDecrement}
              disabled={quantity <= 1}
            >
              -
            </button>
            <input
              type="number"
              className="form-control text-center"
              min={1}
              value={quantity}
              name="quantity"
              onChange={handleQuantityChange}
              readOnly
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={handleIncrement}
            >
              +
            </button>
          </div>
        </td>

        <td className="product-data text-center">
          <h6>{product?.subTotal}</h6>
        </td>
      </tr>
    );
  } else {
    return (
      <div className="product-container">
        {/* <Link to={`/product/${product?._id}`} className="product-image"> */}
        {/* <Link to={`/product/${product?.partNumber}`} className="product-image"> */}
        <Link
          to={
            product?.descriptions[0]?.value !== undefined
              ? `/${replaceSpacesWithHyphens(product?.category?.name)}/${replaceSpacesWithHyphens(
                  product?.subcategoryname?.name
                )}/${replaceSpacesWithHyphens(
                  product?.descriptions[0]?.value
                )}/${product?.slug}`
              : `/product/${product?.slug}`
          }
          className="product-image"
        >
          <img
            className="product-thumbnail"
            src={product?.productimages[0]?.productimage}
            alt="product-thumbnail"
          />
        </Link>
        <div className="product-content w-100">
          <div className="product-content-row">
            <h6 className="">{product?.partNumber}</h6>

            <button onClick={removeFromCart} className="btn btn-sm">
              <CiCircleRemove className="text-dark fs-5" />
            </button>
          </div>

          <div className="product-content-row">
            <p className="m-0">PRICE PER PICE</p>

            <h6 className="m-0">₹{product?.salePrice}</h6>
          </div>

          <div className="product-content-row">
            <p className="m-0">BOQ</p>

            <h6 className="m-0">₹{product?.BoxofQantity}</h6>
          </div>

          <div className="product-content-row">
            <p className="m-0">QUANTITY</p>

            <div className="input-group-quantity">
              <input
                type="number"
                className="text-center"
                min={1}
                style={{ width: "40px" }}
                name="quantity"
                value={quantity}
                // value={product.currentQuantity || 1}
                onChange={handleQuantityChange}
              />
              {console.log(quantity, "quantity values")}

              <div className="quantity-button-group">
                <button className="" type="button" onClick={handleIncrement}>
                  +
                </button>

                <button
                  className=""
                  type="button"
                  onClick={handleDecrement}
                  disabled={quantity <= 1}
                >
                  -
                </button>
              </div>
            </div>
          </div>

          <div className="product-content-row">
            <p className="m-0">SUBTOTAL</p>
            {/* <h6>₹ {product.saleprice * product.currentQuantity}</h6> */}
            <h6>₹ {product?.subTotal}</h6>
          </div>
        </div>
      </div>
    );
  }
}
