// import React, { useEffect, useState } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import LoginPage from "./Pages/LoginPage/LoginPage";
// import userContext from "./components/UseContext/UseContext";
// import Wishlist from "./Pages/Wishlist/Wishlist";
// import CheckOut from "./Pages/CheckOut/CheckOut";
// import Layout from "./Layout";
// import Home from "./Pages/Home/Home";
// import ProductDeital from "./Pages/ProductDeital/ProductDeital";
// import Registration from "./Pages/Registration/Registration";
// import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
// import OtpVerification from "./Pages/OtpVerification/OtpVerification";
// import AddToCard from "./Pages/AddToCard/AddToCard";
// import OtpError from "./Pages/OtpError/OtpError";
// import SuccessPage from "./Pages/SuccessPage/SuccessPage";
// import ResetPassword from "./Pages/ResetPassword/ResetPassword";
// import Profile from "./Pages/Profile/Profile";
// import Catalogs from "./Pages/Catalogs/Catalogs";
// import Fastaners from "./components/Fastaners/Fastaners";
// import SubCategory from "./components/ProductFlowPages/SubCategory";
// import PartNumberPage from "./components/ProductFlowPages/PartNumberPage/PartNumberPage";
// import { AuthProvider } from "./components/Token";
// import ScrollTop from "./components/ScrollTop";
// import RegistrationSuccess from "./Pages/Registration/RegistrationSuccess";
// import axios from "axios";
// import { API_BASE_URL } from "./utils";
// import ErrorBoundary from "./components/ErrorBoundary";
// import ViewAllProducts from "./components/Products/ViewAllProducts";
// import ViewAllToolsProducts from "./components/ToolsProducts/ViewAllToolsProducts";
// import SearchResultsPage from "./Pages/SearchResultsPage/SearchResultsPage";

// function App() {
//   const [cart, setCart] = useState([]);
//   const [wishlist, setWishlist] = useState([]);
//   const [cartResults, setCartResults] = useState([]);
//   const [addCartLength, setAddCartLength] = useState([]);
//   const [addCartLengthLocal, setAddCartLengthLocal] = useState([]);
//   const [totalPrice, setTotalPrice] = useState([]);
//   const [checkOut, setCheckOut] = useState([]);
//   const [productReviews, setProductReviews] = useState("");
//   const [logOut, setLogOut] = useState(false);
//   const [profileActive, setProfileActive] = useState(false);

//   // getGuestId()
//   // localStorage.setItem(
//   //   "guestId",
//   //   "87adfffa-9ffe-4b45-960d-1fb9f5a8b690_1708579865036"
//   // );

//   useEffect(() => {
//     const storedWishlist = localStorage.getItem("wishlist");
//     const storedCart = localStorage.getItem("cart ");

//     try {
//       setWishlist(JSON.parse(storedWishlist) || []);
//       setCart(JSON.parse(storedCart) || []);
//     } catch (error) {
//       console.error("Error parsing JSON:", error);
//     }
//   }, []);

//   useEffect(() => {
//     localStorage.setItem("wishlist", JSON.stringify(wishlist));
//   }, [wishlist]);

//   useEffect(() => {
//     localStorage.setItem("cart", JSON.stringify(cart));
//   }, [cart]);

//   useEffect(() => {
//     localStorage.getItem("CartProducts");
//     // localStorage.getItem("addtocartLength")
//   }, [cart]);

//   const isLoggedIn = localStorage.getItem("userId");
//   const guesrID = localStorage.getItem("guestId");
//   const userId = localStorage.getItem("userId");

//   const headers = {
//     Authorization: `Bearer ${userId}`,
//   };

//   useEffect(() => {
//     if (isLoggedIn) {
//       axios
//         .get(`${API_BASE_URL}/user/order/getcart`, {
//           headers,
//         })
//         .then((res) => {
//           setAddCartLength(res.data.cartItemLength);
//           localStorage.setItem("addtocartLength", res.data.cartItemLength);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     } else {
//       axios
//         .get(`${API_BASE_URL}/order/getguestcart/${guesrID}`, {
//           headers,
//         })
//         .then((res) => {
//           setAddCartLength(res.data.cartLength);
//           localStorage.setItem("addtocartLength", res.data.cartLength);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [addCartLength]);

//   const defaultValue = {
//     wishlist,
//     setWishlist,
//     cart,
//     setCart,
//     cartResults,
//     setCartResults,
//     addCartLength,
//     setAddCartLength,
//     totalPrice,
//     setTotalPrice,
//     addCartLengthLocal,
//     setAddCartLengthLocal,
//     checkOut,
//     setCheckOut,
//     setProductReviews,
//     productReviews,
//     setLogOut,
//     logOut,
//     setProfileActive,
//     profileActive,
//   };

//   return (
//     <ErrorBoundary>
//       <userContext.Provider value={defaultValue}>
//         <AuthProvider>
//           <BrowserRouter>
//             <Routes>
//               {/* <Route
//                 path="/registrationSuccess"
//                 element={<RegistrationSuccess />}
//               /> */}

//               <Route path="/" element={<Layout />}>
//                 <Route index element={<Home />} />
//                 <Route path="/search/:slug" element={<SearchResultsPage />} />

//                 <Route
//                   path="/view-all-products"
//                   element={<ViewAllProducts />}
//                 />

//                 <Route
//                   path="/view-all-tools-products"
//                   element={<ViewAllToolsProducts />}
//                 />

//                 <Route path="/product/:_id" element={<ProductDeital />} />
//                 <Route path="/wishlist" element={<Wishlist />} />
//                 <Route path="/carts" element={<AddToCard />} />
//                 <Route path="/checkout" element={<CheckOut />} />
//                 <Route path="/profile" element={<Profile />} />
//                 <Route path="/catalogs" element={<Catalogs />} />
//                 <Route path="/fastaners" element={<Fastaners />} />

//                 <Route>
//                   <Route path="/registration" element={<Registration />} />
//                   <Route path="/loginpage" element={<LoginPage />} />
//                   <Route path="/forgotpassword" element={<ForgotPassword />} />
//                   <Route
//                     path="/otpverification"
//                     element={<OtpVerification />}
//                   />
//                   <Route path="/otperror" element={<OtpError />} />
//                   <Route path="/successpage" element={<SuccessPage />} />
//                   <Route
//                     path="/sub-category/:subcategoryName"
//                     element={<SubCategory />}
//                   />

//                   <Route
//                     path="/part-number-page/:filterSection"
//                     element={<PartNumberPage />}
//                   />

//                   {/* after mail sent */}
//                   <Route
//                     path="/resetpassword/:resetToken"
//                     element={<ResetPassword />}
//                   />
//                   {/* after mail sent */}
//                 </Route>
//               </Route>
//             </Routes>
//             <ScrollTop />
//           </BrowserRouter>
//         </AuthProvider>
//       </userContext.Provider>
//     </ErrorBoundary>
//   );
// }

// export default App;

import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./Pages/LoginPage/LoginPage";
import userContext from "./components/UseContext/UseContext";
import Wishlist from "./Pages/Wishlist/Wishlist";
import CheckOut from "./Pages/CheckOut/CheckOut";
import Layout from "./Layout";
import Home from "./Pages/Home/Home";
import ProductDeital from "./Pages/ProductDeital/ProductDeital";
import Registration from "./Pages/Registration/Registration";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import OtpVerification from "./Pages/OtpVerification/OtpVerification";
import AddToCard from "./Pages/AddToCard/AddToCard";
import OtpError from "./Pages/OtpError/OtpError";
import SuccessPage from "./Pages/SuccessPage/SuccessPage";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import Profile from "./Pages/Profile/Profile";
import Catalogs from "./Pages/Catalogs/Catalogs";
import Fastaners from "./components/Fastaners/Fastaners";
import SubCategory from "./components/ProductFlowPages/SubCategory";
import PartNumberPage from "./components/ProductFlowPages/PartNumberPage/PartNumberPage";
import { AuthProvider } from "./components/Token";
import ScrollTop from "./components/ScrollTop";
import RegistrationSuccess from "./Pages/Registration/RegistrationSuccess";
import axios from "axios";
import { API_BASE_URL } from "./utils";
import ErrorBoundary from "./components/ErrorBoundary";
import ViewAllProducts from "./components/Products/ViewAllProducts";
import ViewAllToolsProducts from "./components/ToolsProducts/ViewAllToolsProducts";
import SearchResultsPage from "./Pages/SearchResultsPage/SearchResultsPage";
import Timer from "./components/Timer"; // Import Timer

//================
import About from "./CommonPages/About";
import Cancellation_and_Refund_Policy from "./CommonPages/Cancellation_and_Refund_Policy";
import Contact from "./CommonPages/Contact";
import InquiryPage from "./CommonPages/InquiryPage";
import PrivacyPolicy from "./CommonPages/PrivacyPolicy";
import Shipping_and_Delivery_Policy from "./CommonPages/Shipping_and_Delivery_Policy";
import TermsAndConditionsOfUse from "./CommonPages/TermsAndConditionsOfUse";
import Brochure from "./components/Brochure/Brochure";
import Product from "./components/Products/Product";
import SubProducts from "./components/Products/SubProducts/SubProducts";
import { Search } from "react-bootstrap-icons";
import SearchProduct from "./components/Products/SearchProduct/SearchProduct";
import Blog from "./Pages/Blog/Blog";
import Howtouse from "./Pages/howtouse/Howtouse";
function App() {
  const [cart, setCart] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [cartResults, setCartResults] = useState([]);
  const [addCartLength, setAddCartLength] = useState([]);
  const [addCartLengthLocal, setAddCartLengthLocal] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [checkOut, setCheckOut] = useState([]);
  const [productReviews, setProductReviews] = useState("");
  const [logOut, setLogOut] = useState(false);
  const [profileActive, setProfileActive] = useState(false);
  const [subProductdatas,setSubProductDatas]=useState("")
  const [constxtSubCategory,setConstxtSubCategory] =useState("")



  useEffect(() => {
    const storedWishlist = localStorage.getItem("wishlist");
    const storedCart = localStorage.getItem("cart ");

    try {
      setWishlist(JSON.parse(storedWishlist) || []);
      setCart(JSON.parse(storedCart) || []);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
    // localStorage.setItem("cart", JSON.stringify(cart));
    localStorage.getItem("CartProducts");
  }, [wishlist, cart]);

  // useEffect(() => {
  //   localStorage.setItem("cart", JSON.stringify(cart));

  // }, [cart]);

  // useEffect(() => {
  //   localStorage.getItem("CartProducts");
  // }, [cart]);

  const isLoggedIn = localStorage.getItem("userId");
  const guesrID = localStorage.getItem("guestId");
  const userId = localStorage.getItem("userId");

  const headers = {
    Authorization: `Bearer ${userId}`,
  };

  useEffect(() => {
    if (isLoggedIn) {
      axios
        .get(`${API_BASE_URL}/user/order/getcart`, {
          headers:headers
        })
        .then((res) => {
          setAddCartLength(res.data.cartItemLength);
          localStorage.setItem("addtocartLength", res.data.cartItemLength);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      axios
        .get(`${API_BASE_URL}/order/getguestcart/${guesrID}`, {
          headers:headers,
        })
        .then((res) => {
          setAddCartLength(res.data.cartLength);
          localStorage.setItem("addtocartLength", res.data.cartLength);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [addCartLength]);

  const defaultValue = {
    wishlist,
    setWishlist,
    cart,
    setCart,
    cartResults,
    setCartResults,
    addCartLength,
    setAddCartLength,
    totalPrice,
    setTotalPrice,
    addCartLengthLocal,
    setAddCartLengthLocal,
    checkOut,
    setCheckOut,
    setProductReviews,
    productReviews,
    setLogOut,
    logOut,
    setProfileActive,
    profileActive,
    subProductdatas,
    setSubProductDatas,
  };

  return (
    <ErrorBoundary>
      <userContext.Provider value={defaultValue}>
        <AuthProvider>
          <BrowserRouter>
            <Timer /> {/* Add Timer here */}
            <Routes>
              {/* <Route
                     path="/registrationSuccess"
                     element={<RegistrationSuccess />}
                 /> */}
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/brochure" element={<Brochure />} />
                <Route path="/search" element={<SearchProduct />} />
                <Route path="/search/:slug" element={<SearchResultsPage />} />
                <Route
                  path="/view-all-products"
                  element={<ViewAllProducts />}
                />
                <Route
                  path="/view-all-tools-products"
                  element={<ViewAllToolsProducts />}
                />
                <Route path="/product/:_id" element={<ProductDeital />} />
                <Route path="/:slug/:slug/:filterSection/:_id" element={<ProductDeital />} />
                <Route path="/wishlist" element={<Wishlist />} />
                <Route path="/carts" element={<AddToCard />} />
                <Route path="/checkout" element={<CheckOut />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/catalog" element={<Catalogs />} />
                <Route path="/fastaners" element={<Fastaners />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/loginpage" element={<LoginPage />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/otpverification" element={<OtpVerification />} />
                <Route path="/otperror" element={<OtpError />} />
                <Route path="/successpage" element={<SuccessPage />} />
                <Route path="/blogs" element={<Blog />} />
                <Route path="/howtouse" element={<Howtouse />} />
                <Route
                  // path="/sub-category/:subcategoryName"
                  path="/:slug/:subcategoryName"
                  element={<SubCategory />}
                />
                <Route
                  // path="/part-number-page/:filterSection"  
                  // path="/:slug/:slug/:filterSection"
                  path="/:slug/:subcategoryName/:filterSection"
                  element={<PartNumberPage />}
                />
                <Route
                  path="/resetpassword/:resetToken"
                  element={<ResetPassword />}
                />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/inquiry" element={<InquiryPage />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditionsOfUse />}
                />
                <Route
                  path="/shipping-policy"
                  element={<Shipping_and_Delivery_Policy />}
                />
                <Route
                  path="/cancel-and-refund"
                  element={<Cancellation_and_Refund_Policy />}
                />
                <Route path="/product" element={<Product />} />
                {/* <Route path="/sub-category" element={<SubProducts />} /> */}
                <Route path="/:slug" element={<SubProducts />} />
              </Route>
            </Routes>
            <ScrollTop />
          </BrowserRouter>
        </AuthProvider>
      </userContext.Provider>
    </ErrorBoundary>
  );
}
export default App;
