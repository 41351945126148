// import React from 'react'
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
// import "../PartNumberPage/PartNumberPage.scss"
// function FIlterCards( searchResult) {
//     const descriptionTemplate =
//     searchResult?.length > 0 ? searchResult[0]?.descriptions : [];
//     // console.log(searchResult,"shit")
//     const data  = searchResult.searchResult
// // console.log(data,"shit data")
//   return (
//     <div>

// {console.log(data[0]?.productimages[0]?.productimage,"shite")}

// <Card style={{ width: '18rem' }} >
//     {console.log(data[0],"shit")}
//       <Card.Img variant="top" src={data[0]?.productimages[0]?.productimage
// } />
//       <Card.Body>
//         {/* <Card.Title>Card Title</Card.Title> */}
//         {/* <Card.Text>
//           Some quick example text to build on the card title and make up the
//           bulk of the card's content.
//         </Card.Text> */}
//         <div className='d-flex filtercard-bg '>

//         <p className="text-uppercase fw-bold ms-1 mt-2 ms-3" >{data[0]?.partNumber}</p><p  className='ms-auto me-2 mt-2'>Rs{data[0]?.Productprice}/- </p>
//         </div>
//       </Card.Body>
//     </Card>

//     </div>
//   )
// }

// export default FIlterCards
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "../PartNumberPage/PartNumberPage.scss";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function FilterCards({ searchResult,categoryName,subCategoryName }) {
  // console.log("searchResult", searchResult);
  const descriptionTemplate =
    searchResult?.length > 0 ? searchResult[0]?.descriptions : [];



    const replaceSpacesWithHyphens = (urlString) => {

      if (typeof urlString === 'string') {
        // Replace all spaces with hyphens
        return urlString.replace(/ /g, '-').toLocaleLowerCase();
      }else return urlString;
  }
  return (
    <div>
      <Row>
        {searchResult.length > 0 &&
          searchResult.map((result, index) => (
            <Col md={3} className="">
              <Link  to={`/${replaceSpacesWithHyphens(categoryName)}/${replaceSpacesWithHyphens(subCategoryName)}/${replaceSpacesWithHyphens(result?.descriptions[0].value)}/${result?.slug}`} style={{textDecoration:"none"}}>
              {/* {console.log("subCategoryName ----->",subCategoryName)} */}
                <Card style={{}} key={index}>
                  {/* {console.log(result.partNumber, "Shit")} */}
                  <Card.Img
                    className="m-2"
                    src={result?.productimages[0]?.productimage}
                    style={{ height: "173px", width: "70%" }}
                  />
                  <Card.Body>
                    <div className="d-flex filtercard-bg">
                      <p className="text-uppercase fw-bold ms-1 mt-2 ms-3">
                        {result?.partNumber}
                      </p>
                      <p className="ms-auto me-2 mt-2">
                        Rs {result?.saleprice}/-
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
      </Row>
    </div>
  );
}

export default FilterCards;
