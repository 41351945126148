import React, { memo, useCallback, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import axios from "axios";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { API_BASE_URL } from "../../utils/index";

function MenuBar() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showDropdown, setShowDropdown] = useState(false);
 const [data, setData] = useState([]);
  const [subCategories, setSubCategories] = useState({});
  const [showStates, setShowStates] = useState({});
  const [showStatesParent, setShowStatesParent] = useState(false);
  const [activeKey, setActiveKey] = useState("0");
  const location = useLocation();
  const handleOffcanvasItemClick = () => {
    handleClose();
  };
  const handleLinkClick = () => {
    handleClose();
    setShowStatesParent(false);
    setActiveKey(null);
  };
  const navLinks = [
    {
      name: "Home",
      links: "/",
    },
    {
      name: "About Us",
      links: "/about",
    },
    {
      name: "How to Use",
      links: "/howtouse",
    },
    {
      name: "Inquiry",
      links: "/inquiry",
    },
    {
      name: "Contact Us",
      links: "/contact",
    },
  ];
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/user/getcategory`)
      .then((response) => {
        const categories = response?.data?.categories || [];
        setData(categories);

        const initialState = categories.reduce((acc, category, index) => {
          acc[category?._id] = index === 0;
          return acc;
        }, {});

        setShowStates(initialState);

        if (categories.length > 0) {
          axios
            .get(`${API_BASE_URL}/category/admin/${categories[0]?._id}`)
            .then((response) => {
              setSubCategories({
                [categories[0]?._id]: response?.data?.subcategoryNames,
              });
            })
            .catch((error) => {
              console.error("Error fetching subcategories:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const handleShows = useCallback((category) => {
    setShowStates((prevStates) => ({
      ...prevStates,
      [category]: !prevStates[category],
    }));
  }, []);

  const handleShowParent = useCallback(() => {
    setShowStatesParent((prev) => !prev);
    if (!showStatesParent) {
      setActiveKey("0"); // Set to default active key
      if (data.length > 0) {
        // Fetch subcategories for the first category (if not already done)
        axios
          .get(`${API_BASE_URL}/category/admin/${data[0]?._id}`)
          .then((response) => {
            setSubCategories({
              [data[0]?._id]: response?.data?.subcategoryNames,
            });
          })
          .catch((error) => {
            console.error("Error fetching subcategories:", error);
          });
      }
    } else {
      setActiveKey(null); // Collapse all when closing
    }
  }, [showStatesParent, data]);
  const CustomToggle = memo(
    ({ children, eventKey, category, handleShow, isChild }) => {
      const decoratedOnClick = useAccordionButton(eventKey, () => {
        handleShows(category);

        if (isChild && !subCategories[category]) {
          axios
            .get(`${API_BASE_URL}/category/admin/${category}`)
            .then((response) => {
              setSubCategories((prevSubCategories) => ({
                ...prevSubCategories,
                [category]: response?.data?.subcategoryNames,
              }));
            })
            .catch((error) => {
              console.error("Error fetching subcategories:", error);
            });
        }
      });

      return (
        <button type="button" onClick={decoratedOnClick}>
          {children}
        </button>
      );
    }
  );

  const replaceSpacesWithHyphens = (urlString) => {

    if (typeof urlString === 'string') {
      // Replace all spaces with hyphens
      return urlString.replace(/ /g, '-') .toLocaleLowerCase();
    }else return urlString;
}
  return (
    <div className="sub-navbar">
      {/* <div className="sub-brand">
        <IoMenu className="fs-4" />
        <p className="m-0 px-2">Shop By Categories</p>
      </div>

      <DropdownButton id="dropdown-basic-button" title="Dropdown button">
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </DropdownButton> */}

      {/* <ul className="sub-navbar-menu ">
        {navLinks.map((item, i) => (
          <li key={i} >
            <Link to={item?.links} className="mt-4 "> {item?.name} </Link>
          </li>
        ))}
        </ul> */}
          <ul className="sub-navbar-menu">
      {navLinks.map((item, i) => {
        // Determine if the link is active
        const isActive = location.pathname === item.links;

        return (
          <li key={i}>
            <Link
              to={item.links}
              className={isActive ? 'active' : ''}
            >
              {item.name}
            </Link>
          </li>
        );
      })}
    </ul>

      {/* responsive header */}
      <div className="offcanvas-sidebar">
        <IoMenu className="fs-2 text-dark hambargure" onClick={handleShow} />

        <Offcanvas show={show} onHide={handleClose} backdrop="static">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="fw-bold">
              Best India kart
            </Offcanvas.Title>
          </Offcanvas.Header>

          <Offcanvas.Body>
            <ul className="offcanvas-sub-navbar-menu m-0 mb-2">
              <li onClick={handleOffcanvasItemClick}>
                <Link to="/">Home</Link>
              </li>
              <li onClick={handleOffcanvasItemClick}>
                <Link to="/about">About Us</Link>
              </li>
              <li onClick={handleOffcanvasItemClick}>
                <Link to="/howtouse">How to Use</Link>
              </li>
              <li onClick={handleOffcanvasItemClick}>
                <Link to="/inquiry">Inquiry</Link>
              </li>
              <li onClick={handleOffcanvasItemClick}>
                <Link to="/contact">Contact Us</Link>
              </li>
            


            </ul>
            <span class="drawer-heading  m-4 mt-3 mb-3">Category Menu</span>
              <Accordion
                      defaultActiveKey="0"
                      style={{ borderRadius: "0px 0px 10px 10px" }}
                      className="mt-2"
                    >
                      <Card className="products">
                        {data?.length === 0
                          ? "No data available"
                          : data.map((category, index) => (
                              <div
                                key={index}
                                style={{ borderRadius: "0px 0px 10px 10px" }}
                              >
                                <Card.Header style={{ borderRadius: "0px",padding:"14px" }} className="filtercater">
                                  <CustomToggle
                                    eventKey={index.toString()}
                                    
                                    category={category?._id}
                                    handleShows={handleShows}
                                    isChild={true}
                                  >
                                    <p
                                      className="m-0 px-1"
                                      style={{ color: "black" }}
                                    >
                                      {category?.name}
                                    </p>
                                    {showStates[category?._id] ? (
                                      <IoIosArrowUp className="eye-icon" />
                                    ) : (
                                      <IoIosArrowDown className="eye-icon" />
                                    )}
                                  </CustomToggle>
                                </Card.Header>

                                <Accordion.Collapse
                                  eventKey={index.toString()}
                                  style={{ borderRadius: "0px 0px 10px 10px " }}
                                >
                                  <Card.Body>
                                    {(subCategories[category?._id] || []).map(
                                      (subCategory, subIndex) => (
                                        <Link
                                          key={subIndex}
                                          style={{
                                            textDecoration: "none",
                                            color: "black",
                                          }}
                                          // to={`/sub-category/${subCategory?._id}`}
                                          // to={`/sub-category/${subCategory?.name}`}
                                          to={`/${replaceSpacesWithHyphens(category?.name)}/${replaceSpacesWithHyphens(subCategory?.name)}`}
                                          className="categories-links-subcategories"
                                        >
                                          <div
                                          //  key={subIndex}
                                            className="categories-links "
                                            onClick={handleLinkClick}
                                          >
                                            {subCategory?.name}
                                          </div>
                                        </Link>
                                      )
                                    )}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </div>
                            ))}
                      </Card>
                    </Accordion>
               
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      {/* responsive header */}
    </div>
  );
}

export default MenuBar;

// import React, { useEffect, useState } from "react";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import { Link } from "react-router-dom";
// import { IoMenu } from "react-icons/io5";
// import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import { API_BASE_URL } from "../../utils";
// import axios from "axios";
// import { useAccordionButton } from "react-bootstrap/AccordionButton";
// import Slider from "react-slick";
// import { Accordion, Card } from "react-bootstrap";
// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
// // import { Link } from "react-router-dom";

// function MenuBar() {
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const [showDropdown, setShowDropdown] = useState(false);

//   const [data, setData] = useState([]);
//   const [subCategories, setSubCategories] = useState({});
//   const [showStates, setShowStates] = useState({});

//   const handleShows = (category) => {
//     setShowStates((prevStates) => ({
//       ...prevStates,
//       [category]: !prevStates[category],
//     }));
//   };

//   const CustomToggle = ({ children, eventKey, category, handleShow }) => {
//     const decoratedOnClick = useAccordionButton(eventKey, () => {
//       handleShow(category);

//       if (!subCategories[category]) {
//         axios
//           .get(`${API_BASE_URL}/category/admin/${category}`)
//           .then((response) => {
//             setSubCategories((prevSubCategories) => ({
//               ...prevSubCategories,
//               [category]: response?.data?.subcategoryNames,
//             }));
//           })
//           .catch((error) => {
//             console.error("Error fetching subcategories:", error);
//           });
//       }
//     });

//     return (
//       <button type="button" onClick={decoratedOnClick}>
//         {children}
//       </button>
//     );
//   };

//   const handleOffcanvasItemClick = () => {
//     handleClose();
//   };

//   const navLinks = [
//     {
//       name: "Home",
//       links: "/",
//     },
//     {
//       name: "About Us",
//       links: "/about",
//     },
//     {
//       name: "How to Use",
//       links: "/",
//     },
//     {
//       name: "Inquiry",
//       links: "/inquiry",
//     },
//     {
//       name: "Contact Us",
//       links: "/contact",
//     },
//   ];

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/category/user/getcategory`)
//       .then((response) => {
//         const categories = response?.data?.categories || [];
//         setData(categories);

//         const initialState = categories.reduce((acc, category, index) => {
//           acc[category?._id] = index === 0;
//           return acc;
//         }, {});

//         setShowStates(initialState);

//         if (categories.length > 0) {
//           axios
//             .get(`${API_BASE_URL}/category/admin/${categories[0]?._id}`)
//             .then((response) => {
//               setSubCategories({
//                 [categories[0]?._id]: response?.data?.subcategoryNames,
//               });
//             })
//             .catch((error) => {
//               console.error("Error fetching subcategories:", error);
//             });
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   return (
//     <div className="sub-navbar">
//       {/* <div className="sub-brand">
//         <IoMenu className="fs-4" />
//         <p className="m-0 px-2">Shop By Categories</p>
//       </div>

//       <DropdownButton id="dropdown-basic-button" title="Dropdown button">
//         <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
//         <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
//         <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
//       </DropdownButton> */}
//       {/* <ul className="sub-navbar-menu">
//         {navLinks.map((item, i) => (
//           <li key={i}>
//             <Link to={item?.links}> {item?.name} </Link>
//           </li>
//         ))}
//       </ul> */}

//       <ul className="sub-navbar-menu">
//         <li
//           style={{
//             color: "blue",
//             background: "orange",
//             borderRadius: "10px 10px 0px 0px",
//           }}
//         >
//           <div></div>

//         </li>
//         {navLinks.map((item, i) => (
//           <li key={i}>
//             <Link to={item?.links}> {item?.name} </Link>
//           </li>
//         ))}
//       </ul>

//       {/* responsive header */}
//       <div className="offcanvas-sidebar">
//         <IoMenu className="fs-2 text-dark hambargure" onClick={handleShow} />

//         <Offcanvas show={show} onHide={handleClose} backdrop="static">
//           <Offcanvas.Header closeButton>
//             <Offcanvas.Title className="fw-bold">
//               Best India kart
//             </Offcanvas.Title>
//           </Offcanvas.Header>

//           <Offcanvas.Body>
//             <ul className="offcanvas-sub-navbar-menu m-0">
//               <li onClick={handleOffcanvasItemClick}>
//                 <Link to="/">Home</Link>
//               </li>
//               <li onClick={handleOffcanvasItemClick}>
//                 <Link to="/about">About Us</Link>
//               </li>
//               <li onClick={handleOffcanvasItemClick}>
//                 <Link to="#">How to Use</Link>
//               </li>
//               <li onClick={handleOffcanvasItemClick}>
//                 <Link to="/inquiry">Inquiry</Link>
//               </li>
//               <li onClick={handleOffcanvasItemClick}>
//                 <Link to="#">preload-cardpreload-card Us</Link>
//               </li>
//             </ul>
//           </Offcanvas.Body>
//         </Offcanvas>
//       </div>
//       {/* responsive header */}
//     </div>
//   );
// }

// export default MenuBar;

// import React, { useEffect, useState } from "react";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import { Link } from "react-router-dom";
// import { IoMenu } from "react-icons/io5";
// import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import { API_BASE_URL } from "../../utils";
// import axios from "axios";
// import { useAccordionButton } from "react-bootstrap/AccordionButton";
// import Slider from "react-slick";
// import { Accordion, Card } from "react-bootstrap";
// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
// // import { Link } from "react-router-dom";

// function MenuBar() {
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const [showDropdown, setShowDropdown] = useState(false);

//   const [data, setData] = useState([]);
//   const [subCategories, setSubCategories] = useState({});
//   const [showStates, setShowStates] = useState({});

//   const handleShows = (category) => {
//     setShowStates((prevStates) => ({
//       ...prevStates,
//       [category]: !prevStates[category],
//     }));
//   };

//   const CustomToggle = ({ children, eventKey, category, handleShow }) => {
//     const decoratedOnClick = useAccordionButton(eventKey, () => {
//       handleShow(category);

//       if (!subCategories[category]) {
//         axios
//           .get(`${API_BASE_URL}/category/admin/${category}`)
//           .then((response) => {
//             setSubCategories((prevSubCategories) => ({
//               ...prevSubCategories,
//               [category]: response?.data?.subcategoryNames,
//             }));
//           })
//           .catch((error) => {
//             console.error("Error fetching subcategories:", error);
//           });
//       }
//     });

//     return (
//       <button type="button" onClick={decoratedOnClick}>
//         {children}
//       </button>
//     );
//   };

//   const handleOffcanvasItemClick = () => {
//     handleClose();
//   };

//   const navLinks = [
//     {
//       name: "Home",
//       links: "/",
//     },
//     {
//       name: "About Us",
//       links: "/about",
//     },
//     {
//       name: "How to Use",
//       links: "/",
//     },
//     {
//       name: "Inquiry",
//       links: "/inquiry",
//     },
//     {
//       name: "Contact Us",
//       links: "/contact",
//     },
//   ];

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/category/user/getcategory`)
//       .then((response) => {
//         const categories = response?.data?.categories || [];
//         setData(categories);

//         const initialState = categories.reduce((acc, category, index) => {
//           acc[category?._id] = index === 0;
//           return acc;
//         }, {});

//         setShowStates(initialState);

//         if (categories.length > 0) {
//           axios
//             .get(`${API_BASE_URL}/category/admin/${categories[0]?._id}`)
//             .then((response) => {
//               setSubCategories({
//                 [categories[0]?._id]: response?.data?.subcategoryNames,
//               });
//             })
//             .catch((error) => {
//               console.error("Error fetching subcategories:", error);
//             });
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   return (
//     <div className="sub-navbar header-nav hide-below-1200">
//       <div className="container">
//         <div className="header-inner justify-content-start">
//           <div className="column left align-center col-md-3">
//             <div className="dropdown-cats dropdown">
//               <a className="dropdown-toggle" href="#">
//                 <span className="text">All Categories</span>
//               </a>
//               <div className="dropdown-menu collapse show">
//                 <Accordion defaultActiveKey="0">
//                   <Card className="products">
//                     {data?.length === 0
//                       ? "No data available"
//                       : data.map((category, index) => (
//                           <div key={index}>
//                             <Card.Header>
//                               <CustomToggle
//                                 eventKey={index.toString()}
//                                 className="categories-sub-title"
//                                 category={category?._id}
//                                 handleShow={handleShows}
//                               >
//                                 <p
//                                   className="m-0 px-1 banner-sidebar-category"
//                                   style={{ color: "#0443C0" }}
//                                 >
//                                   {category?.name}
//                                 </p>
//                                 {showStates[category?._id] ? (
//                                   <IoIosArrowUp className="eye-icon" />
//                                 ) : (
//                                   <IoIosArrowDown className="eye-icon" />
//                                 )}
//                               </CustomToggle>
//                             </Card.Header>

//                             <Accordion.Collapse eventKey={index.toString()}>
//                               <Card.Body>
//                                 {(subCategories[category?._id] || []).map(
//                                   (subCategory, subIndex) => (
//                                     <div
//                                       key={subIndex}
//                                       className="categories-links my-3"
//                                     >
//                                       <Link
//                                         style={{
//                                           textDecoration: "none",
//                                           color: "black",
//                                         }}
//                                         to={`/sub-category/${subCategory?._id}`}
//                                       >
//                                         {subCategory?.name}
//                                       </Link>
//                                     </div>
//                                   )
//                                 )}
//                               </Card.Body>
//                             </Accordion.Collapse>
//                           </div>
//                         ))}
//                   </Card>
//                 </Accordion>
//               </div>
//             </div>
//           </div>

//           <ul className="sub-navbar-menu column left align-center">
//             <li
//               style={{
//                 color: "blue",
//                 background: "orange",
//                 borderRadius: "10px 10px 0px 0px",
//               }}
//             ></li>
//             {navLinks.map((item, i) => (
//               <li key={i}>
//                 <Link to={item?.links}> {item?.name} </Link>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//       {/* responsive header */}
//       <div className="offcanvas-sidebar">
//         <IoMenu className="fs-2 text-dark hambargure" onClick={handleShow} />

//         <Offcanvas show={show} onHide={handleClose} backdrop="static">
//           <Offcanvas.Header closeButton>
//             <Offcanvas.Title className="fw-bold">
//               Best India kart
//             </Offcanvas.Title>
//           </Offcanvas.Header>

//           <Offcanvas.Body>
//             <ul className="offcanvas-sub-navbar-menu m-0">
//               <li onClick={handleOffcanvasItemClick}>
//                 <Link to="/">Home</Link>
//               </li>
//               <li onClick={handleOffcanvasItemClick}>
//                 <Link to="/about">About Us</Link>
//               </li>
//               <li onClick={handleOffcanvasItemClick}>
//                 <Link to="#">How to Use</Link>
//               </li>
//               <li onClick={handleOffcanvasItemClick}>
//                 <Link to="/inquiry">Inquiry</Link>
//               </li>
//               <li onClick={handleOffcanvasItemClick}>
//                 <Link to="#">preload-cardpreload-card Us</Link>
//               </li>
//             </ul>
//           </Offcanvas.Body>
//         </Offcanvas>
//       </div>
//       {/* responsive header */}
//     </div>
//   );
// }

// export default MenuBar;



// import React, { useEffect, useState } from "react";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import { Link } from "react-router-dom";
// import { IoMenu } from "react-icons/io5";
// import axios from "axios";
// import { useAccordionButton } from "react-bootstrap/AccordionButton";
// import { Accordion, Card } from "react-bootstrap";
// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
// import { API_BASE_URL } from "../../utils";

// function MenuBar() {
//   const [show, setShow] = useState(false);
//   const [showCategories, setShowCategories] = useState(false);
//   const [data, setData] = useState([]);
//   const [subCategories, setSubCategories] = useState({});
//   const [showStates, setShowStates] = useState({});

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const handleShowCategoriesToggle = () => {
//     setShowCategories((prevShow) => !prevShow);
//   };

//   const handleShows = (category) => {
//     setShowStates((prevStates) => ({
//       ...prevStates,
//       [category]: !prevStates[category],
//     }));
//   };

//   const CustomToggle = ({ children, eventKey, category, handleShow }) => {
//     const decoratedOnClick = useAccordionButton(eventKey, () => {
//       handleShow(category);

//       if (!subCategories[category]) {
//         axios
//           .get(`${API_BASE_URL}/category/admin/${category}`)
//           .then((response) => {
//             setSubCategories((prevSubCategories) => ({
//               ...prevSubCategories,
//               [category]: response?.data?.subcategoryNames,
//             }));
//           })
//           .catch((error) => {
//             console.error("Error fetching subcategories:", error);
//           });
//       }
//     });

//     return (
//       <button type="button" onClick={decoratedOnClick} className="btn btn-link">
//         {children}
//       </button>
//     );
//   };

//   const handleOffcanvasItemClick = () => {
//     handleClose();
//   };

//   const navLinks = [
//     { name: "Home", links: "/" },
//     { name: "About Us", links: "/about" },
//     { name: "How to Use", links: "/" },
//     { name: "Inquiry", links: "/inquiry" },
//     { name: "Contact Us", links: "/contact" },
//   ];

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/category/user/getcategory`)
//       .then((response) => {
//         const categories = response?.data?.categories || [];
//         setData(categories);

//         const initialState = categories.reduce((acc, category) => {
//           acc[category?._id] = false;
//           return acc;
//         }, {});

//         setShowStates(initialState);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   return (
//     <div className="sub-navbar header-nav hide-below-1200">
//       <div className="container">
//         <div className="header-inner justify-content-start">
//           <div className="column left align-center col-md-3">
//             <div className="dropdown-cats dropdown">
//               <button className="dropdown-toggle" href="#" onClick={handleShowCategoriesToggle}>
//                 <span className="text">All Categories</span>
//               </button>
//               {showCategories && (
//                 <div className="dropdown-menu show" style={{position:'fixied',zIndex:"3"}}>
//                   <Accordion>
//                     <Card className="products">
//                       {data.length === 0 ? (
//                         "No data available"
//                       ) : (
//                         data.map((category, index) => (
//                           <div key={category._id}>
//                             <Card.Header>
//                               <CustomToggle
//                                 eventKey={index.toString()}
//                                 category={category._id}
//                                 handleShow={handleShows}
//                               >
//                                 <p className="m-0 px-1 banner-sidebar-category" style={{ color: "#0443C0" }}>
//                                   {category.name}
//                                 </p>
//                                 {showStates[category._id] ? (
//                                   <IoIosArrowUp className="eye-icon" />
//                                 ) : (
//                                   <IoIosArrowDown className="eye-icon" />
//                                 )}
//                               </CustomToggle>
//                             </Card.Header>
//                             <Accordion.Collapse eventKey={index.toString()}>
//                               <Card.Body>
//                                 {(subCategories[category._id] || []).map(
//                                   (subCategory) => (
//                                     <div key={subCategory._id} className="categories-links my-3">
//                                       <Link
//                                         style={{ textDecoration: "none", color: "black" }}
//                                         to={`/sub-category/${subCategory._id}`}
//                                       >
//                                         {subCategory.name}
//                                       </Link>
//                                     </div>
//                                   )
//                                 )}
//                               </Card.Body>
//                             </Accordion.Collapse>
//                           </div>
//                         ))
//                       )}
//                     </Card>
//                   </Accordion>
//                 </div>
//               )}
//             </div>
//           </div>

//           <ul className="sub-navbar-menu column left align-center">
//             {navLinks.map((item, i) => (
//               <li key={i}>
//                 <Link to={item.links}>{item.name}</Link>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//       <div className="offcanvas-sidebar">
//         <IoMenu className="fs-2 text-dark hambargure" onClick={handleShow} />
//         <Offcanvas show={show} onHide={handleClose} backdrop="static">
//           <Offcanvas.Header closeButton>
//             <Offcanvas.Title className="fw-bold">Best India kart</Offcanvas.Title>
//           </Offcanvas.Header>
//           <Offcanvas.Body>
//             <ul className="offcanvas-sub-navbar-menu m-0">
//               {navLinks.map((item, i) => (
//                 <li key={i} onClick={handleOffcanvasItemClick}>
//                   <Link to={item.links}>{item.name}</Link>
//                 </li>
//               ))}
//             </ul>
//           </Offcanvas.Body>
//         </Offcanvas>
//       </div>
//     </div>
//   );
// }

// export default MenuBar;
