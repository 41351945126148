import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  Box,
  Typography,
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import "./PrivacyPolicy.scss";
const Shipping_and_Delivery_Policy = () => {
  return (
    <section className="privacy-section">
      <Container className="privacy-policy-wrapper">
        <h1 className="privacypolicy-page-heading">
          Shipping and Delivery Policy
        </h1>
        <Box mt={4}>
          <Typography variant="subtitle1" gutterBottom>
            Thank you for choosing Bumaas Engineering Solution & Technologies
            Pvt Ltd. Below are the terms and conditions governing shipping and
            delivery of products purchased through our ecommerce platforms:
          </Typography>
          <Row>
            <Col lg={8}>
              <Box mt={3} className="privacy-policy-content">
                <Typography variant="h6" component="h3" id="1" gutterBottom>
                  1. Shipping Methods :
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We partner with trusted logistics service providers to ensure
                  timely delivery of your products. Shipping methods may include
                  standard and expedited options.
                </Typography>
                <Typography variant="h6" component="h3" id="2" gutterBottom>
                  2. Shipping Locations:
                </Typography>
                <Typography variant="body2" component="p"  gutterBottom>
                  We strive to deliver products across India. Please check the
                  availability of delivery to your location by entering your pin
                  code during checkout.
                </Typography>
                <Typography variant="h6" component="h3" id="3" gutterBottom>
                  3. International Delivery:
                </Typography>
                <Typography variant="body3" component="p" gutterBottom>
                  <article style={{ fontSize: "15px" }}>
                    international orders, our terms are Ex Works (EXW):
                  </article>
                  <ul style={{ listStyle: "disc" }}>
                    <li>
                      <strong>Courier Charges: </strong>The customer will bear
                      the cost of courier charges.
                    </li>
                    <li>
                      <strong>Duty Taxes:</strong>Duty taxes, customs duties,
                      and any related fees are not included and are the
                      responsibility of the customer.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="h6" component="h3" id="4" gutterBottom>
                  4. Processing Time:
                </Typography>
                <Typography variant="body4" component="p" gutterBottom>
                  Orders are typically dispatched within 2 to 4 days from the
                  date of order confirmation.
                </Typography>
                <Typography variant="h6" component="h3" id="5" gutterBottom>
                  5. Delivery Timelines:
                </Typography>
                <Typography variant="body5" component="p" gutterBottom>
                  Upon order confirmation, an estimated delivery timeline will
                  be provided to you. This timeline may vary based on your
                  location and the logistics partner handling your delivery.
                </Typography>
                <Typography variant="h6" component="h3" id="6" gutterBottom>
                  6. Delivery Attempts:
                </Typography>
                <Typography variant="body6" component="p" gutterBottom>
                  Our logistics partners will attempt delivery up to three
                  times. If unsuccessful after the third attempt due to
                  unavailability, the order may be cancelled, and shipping
                  charges may be deducted from any applicable refund.
                </Typography>
                <Typography variant="h6" component="h3" id="7" gutterBottom>
                  7. Delays:
                </Typography>
                <Typography variant="body7" component="p" gutterBottom>
                  Delays in delivery may occur due to factors such as logistics
                  issues, weather conditions, strikes, or unforeseeable
                  circumstances. We will make reasonable efforts to notify you
                  of any delays.
                </Typography>
                <Typography variant="h6" component="h3" id="8" gutterBottom>
                  8. Tracking Orders:
                </Typography>
                <Typography variant="body8" component="p" gutterBottom>
                  Once your order is dispatched, you will receive a unique
                  tracking ID via email or SMS. You can track your order status
                  using this ID on our website or the logistics partner’s
                  platform.
                </Typography>
                <Typography variant="h6" component="h3" id="9" gutterBottom>
                  9. Shipping Fees:
                </Typography>
                <Typography variant="body9" component="p" gutterBottom>
                  Shipping fees may apply based on factors such as order value,
                  product type, and delivery location. These fees are
                  non-refundable unless the product is defective or incorrect.
                </Typography>

                <Typography variant="h6" component="h3" id="10" gutterBottom>
                  10. Customer Support:
                </Typography>
                <Typography variant="body10" component="p" id="10" gutterBottom>
                  <article style={{ fontSize: "15px" }}>
                    For any queries related to shipping and delivery, please
                    contact our customer support team:{" "}
                  </article>
                  <ul>
                    <li>
                      <Typography variant="body10" component="p" gutterBottom>
                        <strong>Email:</strong>&nbsp;
                        <a
                          href="mailto:marketing@bumaas.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none", color: "green" }}
                         
                        >
                          marketing@bumaas.com
                        </a>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body10" component="p" gutterBottom>
                        <strong>Toll-Free Number</strong> : &nbsp;
                        <a
                          href="tel:7812810488"
                          style={{ textDecoration: "none", color: "green" }}
                         
                        >
                          +91 7812810488
                        </a>{" "}
                        (9:00 AM to 7:00 PM IST)
                      </Typography>
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Col>
            <Col lg={4}>
              <Box mt={3} p={2} bgcolor="#f9f9f9" border={1} borderColor="#ddd">
                <Typography variant="h6" component="h3" gutterBottom>
                  Table of Contents
                </Typography>
                <List component="nav" aria-label="Table of Contents">
                  <ListItem button component="a" href="#1">
                    <ListItemText primary="1. Shipping Methods" />
                  </ListItem>
                  <ListItem button component="a" href="#2">
                    <ListItemText primary="2. Shipping Locations" />
                  </ListItem>
                  <ListItem button component="a" href="#3">
                    <ListItemText primary="3. International Delivery" />
                  </ListItem>
                  <ListItem button component="a" href="#4">
                    <ListItemText primary="4. Processing Time" />
                  </ListItem>
                  <ListItem button component="a" href="#5">
                    <ListItemText primary="5. Delivery Timelines" />
                  </ListItem>
                  <ListItem button component="a" href="#6">
                    <ListItemText primary="6. Delivery Attempts" />
                  </ListItem>
                  <ListItem button component="a" href="#7">
                    <ListItemText primary="7. Delays" />
                  </ListItem>
                  <ListItem button component="a" href="#8">
                    <ListItemText primary="8. Tracking Orders" />
                  </ListItem>
                  <ListItem button component="a" href="#9">
                    <ListItemText primary="9. Shipping Fees" />
                  </ListItem>
                  <ListItem button component="a" href="#10">
                    <ListItemText primary="10. Customer Support" />
                  </ListItem>

                </List>
              </Box>
            </Col>
          </Row>
        </Box>
      </Container>
    </section>
  );
};

export default Shipping_and_Delivery_Policy;
