import React from "react";
import "./product.css"
function ProductImages({ productimages, className }) {
  // console.log("=========>",productimages?.productimages[0]?.productimage,"productimages")
  return (
    <div>
      {/* {productimages?.map((product, index) => ( */}
        {/* <div key={index} className="card-image-wrapper"> */}
        <div className="card-image-wrapper">
          <img
            className={`card-image ${className}`}
            variant="top"
            src={productimages[0]?.productimage}
            alt={`Product Image not found`}
          />
        </div>
      {/* ))} */}
    </div>
  );
}

export default ProductImages;
