import axios from "axios";
import { API_BASE_URL } from "../../utils";

export const searchApi = async ({ query }) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/user/products/userGetallproducts?keyword=${query}`
    );

    return response?.data?.products;
  } catch (error) {
    console.log("search error", error);
    throw error; // Re-throw the error to handle it in the calling code
  }
};
