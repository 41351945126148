
import React from 'react';
import AnitLooseningFasteners from '../../src/Assets/Products/Anti Loosening Fasteners.png';
import BatteryOperatedRivettool from '../../src/Assets/Products/Battery Operated Rivet tool.jpg';
import Cagenuts from '../../src/Assets/Products/Cage nuts.png';
import GeneralFasteners from '../../src/Assets/Products/General Fasteners.png';
import Nut from '../../src/Assets/Products/Nut.png';
import PneumaticOperatedRivetTool from '../../src/Assets/Products/Pneumatic Operated Rivet Tool.jpg';
import PneumaticOperatedRivetnutTool from '../../src/Assets/Products/Pneumatic Operated Rivet nut Tool.jpg';
import PneumaticOperatedSealingPlugRivetTool from '../../src/Assets/Products/Pneumatic Operated Sealing Plug Rivet Tool.jpg';
import Rivet from '../../src/Assets/Products/Rivet.jpg';
import WeldedFasteners from '../../src/Assets/Products/Welded Fasteners.png';
import Slider from "react-slick";
import "./ProductsImages.css";

const images = [
  { src: AnitLooseningFasteners, name: "Anti Loosening Fasteners" },
  { src: BatteryOperatedRivettool, name: "Battery Operated Rivet Tool" },
  { src: Cagenuts, name: "Cage Nuts" },
  { src: GeneralFasteners, name: "General Fasteners" },
  { src: Nut, name: "Nut" },
  // { src: PneumaticOperatedRivetTool, name: "Pneumatic Operated Rivet Tool" },
  { src: PneumaticOperatedRivetnutTool, name: "Pneumatic Operated Rivet Nut Tool" },
  { src: PneumaticOperatedSealingPlugRivetTool, name: "Pneumatic Operated Sealing Plug Rivet Tool" },
  { src: Rivet, name: "Rivet" },
  { src: WeldedFasteners, name: "Welded Fasteners" },
];

const ProductsImages = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 0.3,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="products-slider">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image.src} alt={image.name} style={{backgroundColor:"white",marginLeft:"2px",marginRight:"2px"}}/>
            <p className="image-caption">{image.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductsImages;




















































// import React from 'react';
// import Slider from 'react-slick';
// import AnitLooseningFasteners from '../../src/Assets/Products/Anti Loosening Fasteners.png';
// import BatteryOperatedRivettool from '../../src/Assets/Products/Battery Operated Rivet tool.jpg';
// import Cagenuts from '../../src/Assets/Products/Cage nuts.png';
// import GeneralFasteners from '../../src/Assets/Products/General Fasteners.png';
// import Nut from '../../src/Assets/Products/Nut.png';
// import PneumaticOperatedRivetTool from '../../src/Assets/Products/Pneumatic Operated Rivet Tool.jpg';
// import PneumaticOperatedRivetnutTool from '../../src/Assets/Products/Pneumatic Operated Rivet nut Tool.jpg';
// import PneumaticOperatedSealingPlugRivetTool from '../../src/Assets/Products/Pneumatic Operated Sealing Plug Rivet Tool.jpg';
// import Rivet from '../../src/Assets/Products/Rivet.jpg';
// import WeldedFasteners from '../../src/Assets/Products/Welded Fasteners.png';
// import './ProductsImages.css';

// const images = [
//   { src: AnitLooseningFasteners, name: 'Anti Loosening Fasteners' },
//   { src: BatteryOperatedRivettool, name: 'Battery Operated Rivet Tool' },
//   { src: Cagenuts, name: 'Cage Nuts' },
//   { src: GeneralFasteners, name: 'General Fasteners' },
//   { src: Nut, name: 'Nut' },
//   { src: PneumaticOperatedRivetTool, name: 'Pneumatic Operated Rivet Tool' },
//   { src: PneumaticOperatedRivetnutTool, name: 'Pneumatic Operated Rivet Nut Tool' },
//   { src: PneumaticOperatedSealingPlugRivetTool, name: 'Pneumatic Operated Sealing Plug Rivet Tool' },
//   { src: Rivet, name: 'Rivet' },
//   { src: WeldedFasteners, name: 'Welded Fasteners' },
// ];

// const ProductsImages = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 2000, // Increase the speed for smoother transition
//     slidesToShow: 4,
//     slidesToScroll: 0.5, // Scroll smaller amounts for smoother effect
//     autoplay: true,
//     autoplaySpeed: 2000,
//     cssEase: 'linear', // Linear easing for smooth scrolling
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 576,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="products-slider">
//       <Slider {...settings}>
//         {images.map((image, index) => (
//           <div key={index} className="image-container">
//             <img src={image.src} alt={image.name} style={{ backgroundColor: 'white' }} />
//             <p className="image-caption">{image.name}</p>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default ProductsImages;
