import React, { useContext } from "react";
import userContext from "../UseContext/UseContext";
import { AiOutlineHeart } from "react-icons/ai";
import { BsHeart } from "react-icons/bs";
import "../Buttons/Buttons.scss";
// import { ToastContainer, toast } from "react-toastify";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { storeWhisLIstProducts } from "../services/whisList.service";

export default function WishlistButton({ type, data, className }) {
  const productContext = useContext(userContext);

  const isLogged = localStorage.getItem("tokenId");

  const wishlistHandler = async () => {
    const { wishlist } = productContext;
    const isAlreadyInWishlist = wishlist?.some((item) => item?._id === data?._id);
    const productId = data?._id;

    if (isLogged) {
      if (productId) {
        await storeWhisLIstProducts({ productId, productContext });
      } else {
        console.error("productId is undefined or null");
      }
    } else {
      if (!isAlreadyInWishlist) {
        const wishlistTempArray = [...wishlist, data];

        productContext.setWishlist(wishlistTempArray);

        localStorage.setItem(
          "wishlistProducts",
          JSON.stringify(wishlistTempArray)
        );

        // toast("Add To Wishlist Successful");

        toast.success("Add To Wishlist.", {
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
          iconTheme: {
            primary: "#713200",
            secondary: "#FFFAEE",
          },
        });
      } else {
        Swal.fire({
          text: "Item is already in the wishlist",
          icon: "question",
        });
      }
    }
  };

  const isDangerTheme = type === "danger";

  if (type === "icon") {
    return (
      <div className="add-to-card">
        <AiOutlineHeart className="card-menu" onClick={wishlistHandler} />
        {/* <Toaster position="top-center" reverseOrder={false} /> */}
      </div>
    );
  }

  if (type === "button") {
    return (
      <button className={className} onClick={wishlistHandler}>
        ADD TO FAVOURITE <BsHeart className="add-to-icon" />
      </button>
    );
  }
  return null;
}
