
// // import axios from "axios";
// // import React, { useEffect, useState } from "react";
// // import { Container, Card, Button, Tab, Tabs, Row, Col } from "react-bootstrap";
// // import { API_BASE_URL } from "../../utils";
// // import { FaCalendarDays } from "react-icons/fa6";
// // import { IoPerson } from "react-icons/io5";

// // function Blog() {
// //   const [categories, setCategories] = useState([]);
// //   const [blogData, setBlogData] = useState([]);
// //   const [selectedCategoryId, setSelectedCategoryId] = useState(null);
// //   const [loading, setLoading] = useState(true);
// //   const [error, setError] = useState(null);

// //   // Fetch categories data
// //   useEffect(() => {
// //     axios
// //       .get(`${API_BASE_URL}/category/user/getcategory`)
// //       .then((response) => {
// //         setCategories(response.data.categories || []); 
// //         setLoading(false);
// //       })
// //       .catch((err) => {
// //         console.error(
// //           "Error fetching categories:",
// //           err.response ? err.response.data : err.message
// //         );
// //         setError(
// //           err.response
// //             ? err.response.data.message
// //             : "Error fetching categories."
// //         );
// //         setLoading(false);
// //       });
// //   }, []);

// //   useEffect(() => {
// //     if (selectedCategoryId) {
// //       setLoading(true);
// //       axios
// //         .get(`${API_BASE_URL}/blogs/${selectedCategoryId}`)
// //         .then((response) => {
// //           setBlogData(response.data.blogs || []);
// //           setLoading(false);
// //         })
// //         .catch((err) => {
// //           console.error(
// //             "Error fetching blog data:",
// //             err.response ? err.response.data : err.message
// //           );
// //           setError(
// //             err.response
// //               ? err.response.data.message
// //               : "Error fetching blog data."
// //           );
// //           setLoading(false);
// //         });
// //     }
// //   }, [selectedCategoryId]);

// //   const handleTabSelect = (key) => {
// //     setSelectedCategoryId(key);
// //   };

// //   const formatDate = (dateString) => {
// //     return new Date(dateString).toLocaleDateString("en-US", {
// //       year: "numeric",
// //       month: "short",
// //       day: "numeric",
// //     });
// //   };

// //   if (loading) return <p>Loading...</p>;
// //   if (error) return <p>{error}</p>;

// //   return (
// //     <Container>
      
// //       <div className="my-3">
// //         {categories.length > 0 ? (
// //           <div className="text-center">
// //             {categories.map((category) => (
        
                  
// //                 <Button
// //                   variant={selectedCategoryId === category._id ? "primary" : "secondary"}
// //                   onClick={() => handleTabSelect(category._id)}
// //                   block
// //                   className="m-2"
// //                 >
// //                   {category.name}
// //                 </Button>
                

          
          
// //             ))}
// //           </div>
// //         ) : (
// //           <p>No categories available</p>
// //         )}
// //       </div>

 
// //       {blogData.length > 0 ? (
// //         blogData.map((blog) => (
         
          
// //           <div className={`card-container1  m-5 d-flex `} key={blog._id}>
// //              <div className="card-header1">
// //                <img
// //                 src={blog.blogimage}
// //                 alt="card-image"
// //                 className=""
// //                 width={"400px"}
// //               />
// //             </div>
// //             <div className="card-body1 ms-3">
// //               <h4 className="card-subtitle1">{blog.title}</h4>
// //               <h6 className="card-title1">
// //                 <span
// //                   className="text-capitalize"
// //                   style={{ fontSize: "12px", fontWeight: "200px" }}
// //                 >
              
// //                 </span>
// //               </h6>
// //               <ul className=" d-flex">
              
              
// //                     <li className="text-center fw-light"><IoPerson className="me-2" />{blog.author}</li>
             
// //                     <li className="text-center fw-light ms-4">
// //                     <FaCalendarDays className="me-2" />    {formatDate(blog.updatedAt)}
// //                     </li>
          
                  
               
// //               </ul>
// //               <p className="card-text1">{blog.content}</p>
// //             </div>
// //           </div>
// //         ))
// //       ) : (
// //         <p>No blogs available for this category.</p>
// //       )}
// //     </Container>
// //   )
// // }

// // export default Blog;
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Container, Button } from "react-bootstrap";
// import { API_BASE_URL } from "../../utils";
// import { FaCalendarDays } from "react-icons/fa6";
// import { IoPerson } from "react-icons/io5";
// import { Display } from "react-bootstrap-icons";

// // Inline styles for hover effect and card layout
// const cardStyles = {
//   container: {
//     margin: '1rem',
//     borderRadius: '8px',
//     overflow: 'hidden',
//     // boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
//     transition: 'transform 0.3s, box-shadow 0.3s',
//     cursor: 'pointer',
//     display:"flex"
//   },
//   image: {
//     width: '400px',
//     height: 'auto',
//   },
//   content: {
//     padding: '1rem',
//     background: '#fff',
//   },
//   hoverEffect: {
//   //   transform: 'scale(1.03)',
//     // boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
//   }
// };

// function Blog() {
//   const [categories, setCategories] = useState([]);
//   const [blogData, setBlogData] = useState([]);
//   const [selectedCategoryId, setSelectedCategoryId] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   // Fetch categories data
//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/category/user/getcategory`)
//       .then((response) => {
//         setCategories(response.data.categories || []); 
//         setLoading(false);
//       })
//       .catch((err) => {
//         console.error(
//           "Error fetching categories:",
//           err.response ? err.response.data : err.message
//         );
//         setError(
//           err.response
//             ? err.response.data.message
//             : "Error fetching categories."
//         );
//         setLoading(false);
//       });
//   }, []);

//   useEffect(() => {
//     if (selectedCategoryId) {
//       setLoading(true);
//       axios
//         .get(`${API_BASE_URL}/blogs/${selectedCategoryId}`)
//         .then((response) => {
//           setBlogData(response.data.blogs || []);
//           setLoading(false);
//         })
//         .catch((err) => {
//           console.error(
//             "Error fetching blog data:",
//             err.response ? err.response.data : err.message
//           );
//           setError(
//             err.response
//               ? err.response.data.message
//               : "Error fetching blog data."
//           );
//           setLoading(false);
//         });
//     }
//   }, [selectedCategoryId]);

//   const handleTabSelect = (key) => {
//     setSelectedCategoryId(key);
//   };

//   const formatDate = (dateString) => {
//     return new Date(dateString).toLocaleDateString("en-US", {
//       year: "numeric",
//       month: "short",
//       day: "numeric",
//     });
//   };

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>{error}</p>;

//   return (
//     <Container>
//       <div className="my-3">
//         {categories.length > 0 ? (
//           <div className="text-center">
//             {categories.map((category) => (
//               <Button
//                 key={category._id}
//                 variant={selectedCategoryId === category._id ? "secondary" : "primary"}
//                 onClick={() => handleTabSelect(category._id)}
//                 block
//                 className="m-2"
//               >
//                 {category.name}
//               </Button>
//             ))}
//           </div>
//         ) : (
//           <p>No categories available</p>
//         )}
//       </div>

//       {blogData.length > 0 ? (
//         blogData.map((blog, index) => (
//           <div
//             className={`card-container1 ${index === 0 ? 'highlight' : ''}`}
//             style={index === 0 ? { ...cardStyles.container, ...cardStyles.hoverEffect } : cardStyles.container}
//             key={blog._id}
//           >
//             <div className="card-header1">
//               <img
//                 src={blog.blogimage}
//                 alt="card-image"
//                 style={cardStyles.image}
//               />
//             </div>
//             <div className="card-body1" style={cardStyles.content}>
//               <h4 className="card-subtitle1">{blog.title}</h4>
//               <ul className="d-flex">
//                 <li className="text-center fw-light"><IoPerson className="me-2" />{blog.author}</li>
//                 <li className="text-center fw-light ms-4">
//                   <FaCalendarDays className="me-2" /> {formatDate(blog.updatedAt)}
//                 </li>
//               </ul>
//               <p className="card-text1">{blog.content}</p>
//             </div>
//           </div>
//         ))
//       ) : (
//         <p>No blogs available for this category.</p>
//       )}
//     </Container>
//   );
// }

// export default Blog;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Button, Alert, Spinner } from "react-bootstrap";
import { API_BASE_URL } from "../../utils";
import { FaCalendarDays } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { FaRegCalendarDays } from "react-icons/fa6";
import { Helmet } from "react-helmet";

// Inline styles for hover effect and card layout
const cardStyles = {
  container: {
    margin: '1rem',
    borderRadius: '8px',
    overflow: 'hidden',
    transition: 'transform 0.3s, box-shadow 0.3s',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  
  },

  content: {
    padding: '1rem',
    background: '#fff',
  },
  hoverEffect: {
    transform: 'scale(1.03)',
    boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
  }
};

function Blog() {
  const [categories, setCategories] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch categories data
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/user/getcategory`)
      .then((response) => {
        const fetchedCategories = response.data.categories || [];
        setCategories(fetchedCategories);
        if (fetchedCategories.length > 0) {
          // Set the first category as selected by default
          setSelectedCategoryId(fetchedCategories[0]._id);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err.response ? err.response.data : err.message);
        setError("Error fetching categories.");
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedCategoryId) {
      setLoading(true);
      axios
        .get(`${API_BASE_URL}/blogs/${selectedCategoryId}`)
        .then((response) => {
          setBlogData(response.data.blogs || []);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching blog data:", err.response ? err.response.data : err.message);
          setError("Error fetching blog data.");
          setLoading(false);
        });
    }
  }, [selectedCategoryId]);

  const handleTabSelect = (key) => {
    setSelectedCategoryId(key);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  if (loading) return <Spinner animation="border" variant="primary" />;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <Container>
       <Helmet>
        <title> Latest blog about Fasteners Manufacturers |  Best India Kart</title>
        <meta name="description" content="Discover the latest blog and industry trends in fasteners manufacturing with Best India Kart. As a leading manufacturer based in  India"/>
        {/* <meta name="keywords" content="Jobs, Service,Automobiles,Store, Education, Travels, Event"/> */}
      </Helmet>
      <div className="my-3">
        {categories.length > 0 ? (
          <div className="text-center">
            {categories.map((category) => (
              <Button
                key={category._id}
                variant={selectedCategoryId === category._id ? "secondary" : "primary"}
                onClick={() => handleTabSelect(category._id)}
                block
                className="m-2 "
              >
                {category.name}
              </Button>
            ))}
          </div>
        ) : (
          <Alert variant="info">No categories available</Alert>
        )}
      </div>

      {blogData.length > 0 ? (
        blogData.map((blog, index) => (
          <div
            className={`card-container1 ${index === 0 ? 'highlight' : ''} d-flex`}
            style={index === 0 ? { ...cardStyles.container, ...cardStyles.hoverEffect } : cardStyles.container}
            key={blog._id}
          >
            <div className="d-flex">
            <div className="card-header1">
              <img
                src={blog.blogimage}
                alt={blog.title}
                style={cardStyles.image}
                width={"400px"}
                height={"300px"}
              />
            </div>

            <div className="card-body1" style={cardStyles.content}>
              <h4 className="card-subtitle1">{blog.title}</h4>
              <ul className="d-flex">
                <li className="text-center fw-light"><IoPerson className="me-2" />{blog.author}</li>
                <li className="text-center fw-light ms-4">
                  <FaRegCalendarDays className="me-2" /> {formatDate(blog.updatedAt)}
                </li>
              </ul>
              <p className="card-text1">{blog.content}</p>
            </div>
            </div>
          </div>
        ))
      ) : (
        <Alert variant="info">No blogs available for this category.</Alert>
      )}
    </Container>
  );
}

export default Blog;
