import React from "react";
import "../ContactSection/ContactSection.scss";
import { FaTruckFast } from "react-icons/fa6";
import { IoReload } from "react-icons/io5";
import { MdShield } from "react-icons/md";
import { TbMessages } from "react-icons/tb";

function ContactSection() {
  const contact = [
    {
      link: "https://maps.app.goo.gl/ghxGoyfwGxQYYvT38",
      icon: FaTruckFast,
      text: "Free Shipping over ₹99",
    },
    {
      link: "/",
      icon: IoReload,
      text: "30 Days money back",
    },
    {
      link: "tel:+917812810488",
      icon: MdShield,
      text: "100% Secure Payment",
    },
    {
      link: "tel:+917812810488",
      icon: TbMessages,
      text: "24/7 Dedicated Support",
    },
  ];

  return (
    <div className="contact-section row">
      {contact.map((item, index) => (
        <a
          key={index}
          href={item?.link}
          target="_blank"
          className="text-decoration-none text-light col-md-3 col-sm-6"
        >
          <div className={`contact-box contact-box-${index}`}>
            <item.icon className="contact-icon" />
            <p>{item?.text}</p>
          </div>
        </a>
      ))}
    </div>
  );
}

export default ContactSection;
