// import React, { useEffect, useState } from "react";
// import "../PartNumberPage/PartNumberPage.scss";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { API_BASE_URL } from "../../../utils/index";
// import Filters from "./Filters";
// import FilterResults from "./FilterResults";

// function PartNumberPage() {
//   const { filterSection } = useParams();
//   const [query, setQuery] = useState("");
//   const [filter, setFilter] = useState([]);
//   const [filterName,setFilterName]=useState("")
//   const [searchResult, setSearchResults] = useState([]);
//   const [selectSubCategory, setSelectSubCategory] = useState("");
//   const [filterLoder, setFilterLoader] = useState(false);
//   const [error,setError] =useState("")
//   const [filterNames,setFilterNames]=useState("")

//   const handleLabelClick = (value,filterName) => {
//     console.log("coming in handle");
//     setQuery(value);
//     setFilterName(filterName);
//     // console.log(filterName,"coming in handle");
//   };

//   // const adminId = localStorage.getItem("adminId");

//   // const headers = {
//   //   Authorization: `Bearer ${adminId}`,
//   // };
//   const adminId = localStorage.getItem("userId");

//   const headers = {
//     Authorization: `Bearer ${adminId}`,
//   };

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/user/product/getpartnumberbytype/${filterSection}`)
//       .then((response) => {
//         console.log(response,"shit")
//         setSelectSubCategory(response?.data?.subcategory?._id);
//         // console.log(
//         //   "checking in part products",
//         //   response.data.filteredPartnumbers
//         // );
//         // setSearchResults(response?.data?.filteredPartnumbers)
//         setSearchResults(response?.data?.filteredPartnumbers)
//         setFilterNames(response?.data?.bodytype)
//         // console.log("filter name",response?.data?.subcategory?.name)
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });

//     // setQuery(filterSection);
//   }, []);

//   useEffect(() => {
//     if (query.trim() !== "") {
//       setFilterLoader(true);
//       axios
//         .get(
//           `${API_BASE_URL}/user/poroduct/filterproduct/${filterSection}?name=${filterName}&value=${query}`
//         )
//         .then((response) => {
//           console.log(response,"shit")
//           setFilterLoader(false);

//           setSearchResults(response?.data?.filteredProducts);
//           setError(0)
//         })
//         .catch((error) => {

//           console.error("Error fetching search results:", error?.response?.data?.message);
//           setFilterLoader(false);
//           setError(error?.response?.data?.message)
//         });
//     } else {
//       setSearchResults([]);
//     }
//   }, [query]);

//   useEffect(() => {
//     if (selectSubCategory) {
//       axios
//         .get(
//           `${API_BASE_URL}/user/filter/getfilterbysubcategory/${selectSubCategory}`,
//           { headers }
//         )
//         .then((res) => {

//           setFilter(res?.data?.filterTypesBysucategory);
//           //=====================================================================================================
//           // console.log("getAll",res.data)
//           // console.log("getAll filter type by subcategory",res.data.filterTypesBysucategory)
//           // console.log("=========================== Filtered type by subcategory==========================================")

// //=================================================================================================================
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   }, [selectSubCategory]);

//   return (
//     <div className="filter-container px-2r  m-0 bgcolor-pink" >
//       <Filters filter={filter} handleLabelClick={handleLabelClick} />

//       <FilterResults filterLoder={filterLoder} filter={filter} error={error} searchResult={searchResult} filtername={filterNames} />
//     </div>
//   );
// }

// export default PartNumberPage;
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { API_BASE_URL } from "../../../utils/index";
// import Filters from "./Filters";
// import FilterResults from "./FilterResults";

// function PartNumberPage() {
//   const { filterSection } = useParams();
//   const [query, setQuery] = useState([]);
//   const [filter, setFilter] = useState([]);
//   const [filterName, setFilterName] = useState([]);
//   const [searchResult, setSearchResults] = useState([]);
//   const [selectSubCategory, setSelectSubCategory] = useState("");
//   const [filterLoder, setFilterLoader] = useState(false);
//   const [error, setError] = useState("");
//   const [filterNames, setFilterNames] = useState("");
//   const [categoryName, setCategoryName] = useState("");
//   const [subCategoryName, setSubCategoryName] = useState("");

//   const handleLabelClick = (value, filterName) => {
//     // console.log("coming in handle");
//     setQuery((prevQuery) => [...prevQuery, value]);
//     setFilterName((prevNames) => [...prevNames, filterName]);
//   };

//   const adminId = localStorage.getItem("userId");

//   const replaceHyphensWithSpaces = (urlString) => {
//     if (typeof urlString === 'string') {
//        return urlString.replace(/-/g, ' ');
//     }
//   };
//   // const filterSection=replaceHyphensWithSpaces(params)

//   // Define headers dynamically based on conditions
//   const headers = {
//     Authorization: `Bearer ${adminId}`,
//     "Content-Type": "application/json", // Example header
//     "Another-Header": "HeaderValue", // Add more headers as needed
//   };

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/user/product/getpartnumberbytype/${filterSection.includes('-')
//         ? filterSection.replace(/-/g, ' ')
//         : filterSection}`)
//       .then((response) => {
//         // console.log(response, "shit");
//         console.log(response,"getpartnumberbytype")
//         setSelectSubCategory(response?.data?.subcategory?._id);
//         setSearchResults(response?.data?.filteredPartnumbers);
//         setFilterNames(response?.data?.bodytype);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }, [filterSection]);

//   useEffect(() => {
//     if (query.length > 0) {
//       setFilterLoader(true);
//       const formattedQuery = query.join(",");
//       const formattedFilterName = Array.isArray(filterName)
//         ? filterName.join(",")
//         : filterName;

//       axios
//         .get(
//           `${API_BASE_URL}/user/poroduct/filterproduct/${filterSection.includes('-')
//             ? filterSection.replace(/-/g, ' ')
//             : filterSection }?name=${formattedFilterName}&value=${formattedQuery}`,
//           { headers }
//         )
//         .then((response) => {
//           console.log(response, "shit");
//           setFilterLoader(false);
//           setSearchResults(response?.data?.filteredProducts);
//           setError("");
//         })
//         .catch((error) => {
//           console.error(
//             "Error fetching search results:",
//             error?.response?.data?.message
//           );
//           setFilterLoader(false);
//           setError(error?.response?.data?.message);
//         });
//     } else {
//       setSearchResults([]);
//     }
//   }, [query, filterName, filterSection]);

//   useEffect(() => {
//     if (selectSubCategory) {
//       axios
//         .get(
//           `${API_BASE_URL}/user/filter/getfilterbysubcategory/${selectSubCategory}`,
//           { headers }
//         )
//         .then((res) => {
//           setFilter(res?.data?.filterTypesBysucategory);
//           setCategoryName(
//             res?.data?.filterTypesBysucategory[0]?.category?.name
//           );
//           setSubCategoryName(
//             res?.data?.filterTypesBysucategory[0]?.subcategory?.name
//           );
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   }, [selectSubCategory]);

//   return (
//     <div className="filter-container px-2r m-0 bgcolor-pink">
//       <Filters filter={filter} handleLabelClick={handleLabelClick} />
//       <FilterResults
//         filterLoder={filterLoder}
//         filter={filter}
//         error={error}
//         searchResult={searchResult}
//         filtername={filterNames}
//         categoryName={categoryName}
//         subCategoryName={subCategoryName}
//       />
//     </div>
//   );
// }

// export default PartNumberPage;
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { API_BASE_URL } from "../../../utils/index";
// import Filters from "./Filters";
// import FilterResults from "./FilterResults";

// function PartNumberPage() {
//   const { filterSection } = useParams();
//   const [query, setQuery] = useState([]);
//   const [filter, setFilter] = useState([]);
//   const [filterName, setFilterName] = useState([]);
//   const [searchResult, setSearchResults] = useState([]);
//   const [selectSubCategory, setSelectSubCategory] = useState("");
//   const [filterLoader, setFilterLoader] = useState(false);
//   const [error, setError] = useState("");
//   const [filterNames, setFilterNames] = useState("");
//   const [categoryName, setCategoryName] = useState("");
//   const [subCategoryName, setSubCategoryName] = useState("");

//   const handleLabelClick = (value, filterName) => {
//     setQuery((prevQuery) => [...prevQuery, value]);
//     setFilterName((prevNames) => [...prevNames, filterName]);
//   };

//   const adminId = localStorage.getItem("userId");

//   const headers = {
//     Authorization: `Bearer ${adminId}`,
//     "Content-Type": "application/json",
//   };

//   useEffect(() => {
//     // Fetch part numbers
//     const fetchPartNumbers = async () => {
//       try {
//         const response = await axios.get(
//           `${API_BASE_URL}/user/product/getpartnumberbytype/${
//             filterSection.includes('-')
//               ? filterSection.replace(/-/g, ' ')
//               : filterSection
//           }`,
//           {
//             params: { subcategoryName: subCategoryName || '' },
//             headers,
//           }
//         );
//         console.log("Part numbers response:", response);
//         setSelectSubCategory(response?.data?.subcategory?._id);
//         setSearchResults(response?.data?.filteredPartnumbers);
//         setFilterNames(response?.data?.bodytype);
//       } catch (error) {
//         console.log("Error fetching part numbers:", error);
//       }
//     };

//     fetchPartNumbers();
//   }, [filterSection, subCategoryName]);

//   useEffect(() => {
//     // Fetch filtered results
//     if (query.length > 0) {
//       setFilterLoader(true);
//       const formattedQuery = query.join(",");
//       const formattedFilterName = Array.isArray(filterName)
//         ? filterName.join(",")
//         : filterName;

//       axios
//         .get(
//           `${API_BASE_URL}/user/product/filterproduct/${
//             filterSection.includes('-')
//               ? filterSection.replace(/-/g, ' ')
//               : filterSection
//           }`,
//           {
//             params: { name: formattedFilterName, value: formattedQuery },
//             headers,
//           }
//         )
//         .then((response) => {
//           console.log("Filter results response:", response);
//           setFilterLoader(false);
//           setSearchResults(response?.data?.filteredProducts);
//           setError("");
//         })
//         .catch((error) => {
//           console.error("Error fetching search results:", error?.response?.data?.message);
//           setFilterLoader(false);
//           setError(error?.response?.data?.message);
//         });
//     } else {
//       setSearchResults([]);
//     }
//   }, [query, filterName, filterSection]);

//   useEffect(() => {
//     // Fetch filters by subcategory
//     if (selectSubCategory) {
//       axios
//         .get(
//           `${API_BASE_URL}/user/filter/getfilterbysubcategory/${selectSubCategory}`,
//           { headers }
//         )
//         .then((res) => {
//           setFilter(res?.data?.filterTypesBysucategory);
//           setCategoryName(res?.data?.filterTypesBysucategory[0]?.category?.name);
//           setSubCategoryName(res?.data?.filterTypesBysucategory[0]?.subcategory?.name);
//         })
//         .catch((error) => {
//           console.log("Error fetching filters by subcategory:", error);
//         });
//     }
//   }, [selectSubCategory]);

//   return (
//     <div className="filter-container px-2r m-0 bgcolor-pink">
//       <Filters filter={filter} handleLabelClick={handleLabelClick} />
//       <FilterResults
//         filterLoader={filterLoader}
//         filter={filter}
//         error={error}
//         searchResult={searchResult}
//         filtername={filterNames}
//         categoryName={categoryName}
//         subCategoryName={subCategoryName}
//       />
//     </div>
//   );
// }

// export default PartNumberPage;

// --------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { API_BASE_URL } from "../../../utils/index";
// import Filters from "./Filters";
// import FilterResults from "./FilterResults";

// function PartNumberPage() {
//   const { filterSection } = useParams();
//   console.log(filterSection ,"sdsdsd")
//   const [query, setQuery] = useState([]);
//   const [filter, setFilter] = useState([]);
//   const [filterName, setFilterName] = useState([]);
//   const [searchResult, setSearchResults] = useState([]);
//   const [selectSubCategory, setSelectSubCategory] = useState("");
//   const [filterLoader, setFilterLoader] = useState(false);
//   const [error, setError] = useState("");
//   const [filterNames, setFilterNames] = useState("");
//   const [categoryName, setCategoryName] = useState("");
//   const [subCategoryName, setSubCategoryName] = useState("");

//   const handleLabelClick = (value, filterName) => {
//     setQuery((prevQuery) => [...prevQuery, value]);
//     setFilterName((prevNames) => [...prevNames, filterName]);
//   };

//   const adminId = localStorage.getItem("userId");

//   const replaceHyphensWithSpaces = (urlString) => {
//     if (typeof urlString === 'string') {
//       return urlString.replace(/-/g, ' ');
//     }
//   };

//   const headers = {
//     Authorization: `Bearer ${adminId}`,
//     "Content-Type": "application/json",
//     // Add more headers if needed
//   };

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/user/product/getpartnumberbytype/${filterSection.includes('-')
//         ? filterSection.replace(/-/g, ' ')
//         : filterSection}?subcategoryName=${subCategoryName || ''}`)
//       .then((response) => {
//         console.log(response,"sddsdsd")
//         setSelectSubCategory(response?.data?.subcategory?._id);
//         setSearchResults(response?.data?.filteredPartnumbers);
//         setFilterNames(response?.data?.bodytype);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }, [filterSection, subCategoryName]);

//   useEffect(() => {
//     if (query.length > 0) {
//       setFilterLoader(true);
//       const formattedQuery = query.join(",");
//       const formattedFilterName = Array.isArray(filterName)
//         ? filterName.join(",")
//         : filterName;

//       axios
//         .get(
//           `${API_BASE_URL}/user/product/filterproduct/${filterSection.includes('-')
//             ? filterSection.replace(/-/g, ' ')
//             : filterSection}?name=${formattedFilterName}&value=${formattedQuery}`,
//           { headers }
//         )
//         .then((response) => {
//           setFilterLoader(false);
//           setSearchResults(response?.data?.filteredProducts);
//           setError("");
//         })
//         .catch((error) => {
//           console.error("Error fetching search results:", error?.response?.data?.message);
//           setFilterLoader(false);
//           setError(error?.response?.data?.message);
//         });
//     } else {
//       setSearchResults([]);
//     }
//   }, [query, filterName, filterSection]);

//   useEffect(() => {
//     console.log("Current subCategoryName:", ); // Debugging line

//     if (selectSubCategory) {
//       axios
//         .get(
//           `${API_BASE_URL}/user/filter/getfilterbysubcategory/${selectSubCategory}`,
//           { headers }
//         )
//         .then((res) => {
//           setFilter(res?.data?.filterTypesBysucategory);
//           setCategoryName(res?.data?.filterTypesBysucategory[0]?.category?.name);
//           setSubCategoryName(res?.data?.filterTypesBysucategory[0]?.subcategory.name|| "null");
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   }, [selectSubCategory]);

//   return (
//     <div className="filter-container px-2r m-0 bgcolor-pink">
//       <Filters filter={filter} handleLabelClick={handleLabelClick} />
//       <FilterResults
//         filterLoader={filterLoader}
//         filter={filter}
//         error={error}
//         searchResult={searchResult}
//         filtername={filterNames}
//         categoryName={categoryName}
//         subCategoryName={subCategoryName}
//       />
//     </div>
//   );
// }

// export default PartNumberPage;

//======================================================================================================================================
//======================================================================================================================================
//======================================================================================================================================
//======================================================================================================================================
//======================================================================================================================================


import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../utils/index";
import Filters from "./Filters";
import FilterResults from "./FilterResults";

function PartNumberPage() {
  const { slug, subcategoryName, filterSection } = useParams();
  const [query, setQuery] = useState([]);
  const [filter, setFilter] = useState([]);
  const [filterName, setFilterName] = useState([]);
  const [searchResult, setSearchResults] = useState([]);
  const [selectSubCategory, setSelectSubCategory] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [error, setError] = useState("");
  const [filterNames, setFilterNames] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");

  // const handleLabelClick = (value, filterName) => {
  //   setQuery((prevQuery) => [...prevQuery, value]);
  //   setFilterName((prevNames) => [...prevNames, filterName]);
  // };

  const handleLabelClick = (value, filterName) => {
    setQuery((prevQuery) => {
      const newQuery = [...prevQuery];
      const indexQuery = newQuery.indexOf(value);
  
      if (indexQuery !== -1) {
        newQuery.splice(indexQuery, 1);
  
        setFilterName((prevNames) => {
          const newNames = [...prevNames];
          const indexName = newNames.indexOf(filterName);
  
          if (indexQuery !== -1) {
            newNames.splice(indexQuery, 1);
          }
          return newNames;
        });
  
      } else {
        newQuery.push(value);
  
        setFilterName((prevNames) => {
          const newNames = [...prevNames];
          newNames.push(filterName);
          
          return newNames;
        });
      }
  
      return newQuery;
    });
  };
  

  const adminId = localStorage.getItem("userId");

  const headers = {
    Authorization: `Bearer ${adminId}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/user/product/getpartnumberbytype/${
            filterSection.includes("-")
              ? filterSection.replace(/-/g, " ")
              : filterSection
          }?subcategoryName=${subcategoryName.replace(/-/g, " ") || ""}`
        );
        setSelectSubCategory(response?.data?.subcategory?._id);
        setSearchResults(response?.data?.filteredPartnumbers);
        setFilterNames(response?.data?.bodytype);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [filterSection, subCategoryName]);

  useEffect(() => {
    const fetchFilteredResults = async () => {
      if (query.length > 0) {
        setFilterLoader(true);
        console.log("query---->%%%%%%%%%%%%%", query);
        const formattedQuery = query.join(",");

        console.log("formattedQuery---->", filterName);
        const formattedFilterName = Array.isArray(filterName)
          ? filterName.join(",")
          : filterName;

        try {
          const response = await axios.get(
            // `${API_BASE_URL}/user/product/filterproduct/${
            `${API_BASE_URL}/user/poroduct/filterproduct/${
              filterSection.includes("-")
                ? filterSection.replace(/-/g, " ")
                : filterSection
            }?name=${formattedFilterName}&value=${formattedQuery}`,
            { headers }
          );
          setSearchResults(response?.data?.filteredProducts);
          setError("");
        } catch (error) {
          console.error(
            "Error fetching search results:",
            error?.response?.data?.message
          );
          setError(error?.response?.data?.message);
        } finally {
          setFilterLoader(false);
        }
      } else {
        setSearchResults([]);
      }
    };

    fetchFilteredResults();
  }, [query, filterName, filterSection]);

  useEffect(() => {
    const fetchFilters = async () => {
      if (selectSubCategory) {
        try {
          const res = await axios.get(
            `${API_BASE_URL}/user/filter/getfilterbysubcategory/${selectSubCategory}`,
            { headers }
          );
          setFilter(res?.data?.filterTypesBysucategory);
          setCategoryName(
            res?.data?.filterTypesBysucategory[0]?.category?.name
          );
          setSubCategoryName(
            res?.data?.filterTypesBysucategory[0]?.subcategory.name || "null"
          );
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchFilters();
  }, [selectSubCategory]);

  return (
    <div className="filter-container px-2r m-0 bgcolor-pink">
      <Filters filter={filter} handleLabelClick={handleLabelClick} />
      <FilterResults
        filterLoader={filterLoader}
        filter={filter}
        error={error}
        searchResult={searchResult}
        filtername={filterNames}
        categoryName={categoryName}
        subCategoryName={subCategoryName}
      />
    </div>
  );
}

export default PartNumberPage;
