// import React, { useState, useEffect } from 'react';
// import './scrollup.css';
// import { FaCircleArrowUp } from 'react-icons/fa6';

// function Scrollup() {
//   const [isVisible, setIsVisible] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [strokeDasharray, setStrokeDasharray] = useState(307.919); // Default circumference
//   const [strokeDashoffset, setStrokeDashoffset] = useState(307.919); // Initial offset

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollY = window.scrollY;
//       const maxScroll = 600; 

//       if (scrollY > 100) {
//         setIsVisible(true);
//         setIsScrolled(scrollY > 600);

//         // Calculate stroke-dashoffset as a function of scroll position
//         const offset = Math.max(0, strokeDasharray - (scrollY / maxScroll) * strokeDasharray);
//         setStrokeDashoffset(offset);
//       } else {
//         setIsVisible(false);
//         setIsScrolled(false);
//         setStrokeDashoffset(strokeDasharray); // Reset offset
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, [strokeDasharray]);

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: 'smooth' });
//   };

//   return (
//     <div
//       className={`progress-wrap ${isVisible ? 'active-progress' : ''} ${isScrolled ? 'scroll-blue' : ''}`}
//       onClick={scrollToTop}
//     >
//       <svg width="100%" height="100%" viewBox="-1 -1 102 102">
//         <circle
//           cx="50"
//           cy="50"
//           r="49"
//           style={{
//             strokeDasharray: strokeDasharray,
//             strokeDashoffset: strokeDashoffset,
//           }}
//         />
//         <FaCircleArrowUp />
//       </svg>
//     </div>
//   );
// }

// export default Scrollup;
import React, { useState, useEffect } from 'react';
import './scrollup.css';
import { FaCircleArrowUp } from 'react-icons/fa6';


function Scrollup() {
  const [isVisible, setIsVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [strokeDasharray, setStrokeDasharray] = useState(307.919); // Default circumference
  const [strokeDashoffset, setStrokeDashoffset] = useState(307.919); // Initial offset

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const maxScroll = 600; 

      if (scrollY > 100) {
        setIsVisible(true);
        setIsScrolled(scrollY > 600);

        // Calculate stroke-dashoffset as a function of scroll position
        const offset = Math.max(0, strokeDasharray - (scrollY / maxScroll) * strokeDasharray);
        setStrokeDashoffset(offset);
      } else {
        setIsVisible(false);
        setIsScrolled(false);
        setStrokeDashoffset(strokeDasharray); // Reset offset
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [strokeDasharray]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div
      className={`progress-wrap ${isVisible ? 'active-progress' : ''} ${isScrolled ? 'scroll-blue' : ''}`}
      onClick={scrollToTop}
    >
      <svg width="70%" height="70%" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r="48"
          className="progress-circle"
          style={{
            strokeDasharray: strokeDasharray,
            strokeDashoffset: strokeDashoffset,
          }}
        />
      </svg>
      <FaCircleArrowUp className="scroll-icon" />
    </div>
  );
}

export default Scrollup;
