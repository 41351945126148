// import React from "react";
// import { LiaBookSolid } from "react-icons/lia";
// import "../Catalogs/Catalogs.scss";
// import CatalogsInputs from "../../components/CatalogsInputs/CatalogsInputs";
// import { generatePdf } from "./pdfUtils";



// function Catalogs() {
//   return (
//     // <div className="px-2r py-4">
//     //   <div>
//     //     <h4 className="fw-bold">Request Catalogs</h4>
//     //     <p className="catalogs-content">
//     //       Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cum nobis ab
//     //       nihil velit, assumenda aliquam nisi adipisci facere ipsa doloremque
//     //       iure tempore fuga, veritatis, alias earum! Non voluptatem sint iure?
//     //     </p>
//     //     <p className="catalogs-content">
//     //       Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cum nobis ab
//     //       nihil velit, assumenda aliquam nisi adipisci facere ipsa doloremque
//     //       iure tempore fuga, veritatis, alias earum! Non voluptatem sint iure?
//     //     </p>
//     //   </div>
//     //   <h6 className="fw-bold">Please send the following catalogs</h6>
//     //   <div className="d-flex justify-content-between flex-wrap">
//     //     <div className="catalogs-book">
//     //       <input type="checkbox" id="drip-blind-rivets" />
//     //       <label
//     //         htmlFor="drip-blind-rivets"
//     //         className="d-flex align-items-center"
//     //       >
//     //         <LiaBookSolid className="catalogs-image" />
//     //         <p className="m-0 fw-bold">GRIP BLIND RIVETS</p>
//     //       </label>
//     //     </div>
//     //     <div className="catalogs-book">
//     //       <input type="checkbox" id="drip-breksteam-systems" />
//     //       <label
//     //         htmlFor="drip-breksteam-systems"
//     //         className="d-flex align-items-center"
//     //       >
//     //         <LiaBookSolid className="catalogs-image" />
//     //         <p className="m-0 fw-bold">GRIP BREAKSTEAM SYSTEMS</p>
//     //       </label>
//     //     </div>
//     //     <div className="catalogs-book">
//     //       <input type="checkbox" id="drip-breksteam-systems2" />
//     //       <label
//     //         htmlFor="drip-breksteam-systems2"
//     //         className="d-flex align-items-center"
//     //       >
//     //         <LiaBookSolid className="catalogs-image" />
//     //         <p className="m-0 fw-bold">GRIP BREAKSTEAM SYSTEMS</p>
//     //       </label>
//     //     </div>
//     //   </div>
//     //   <div className="contact-information py-4">
//     //     <h6 className="contact-title fw-bold">Contact Information</h6>
//     //     <p className="fw-semibold">Required Field</p>
//     //     <CatalogsInputs />
//     //   </div>
//     // </div>
//     <>
//     <div style={{ padding: '20px' }}>
//       <h1>Catalog</h1>
//       <ul>
//         <li>
//           <h2>Item 1</h2>
//           <p>Description for item 1.</p>
//         </li>
//         <li>
//           <h2>Item 2</h2>
//           <p>Description for item 2.</p>
//         </li>
//         <li>
//           <h2>Item 3</h2>
//           <p>Description for item 3.</p>
//         </li>
//         {/* Add more items as needed */}
//       </ul>
//       <button onClick={generatePdf}>Generate PDF</button> {/* Button to trigger PDF generation */}
//     </div>
//     </>
//   );
// }

// export default Catalogs;
// src/Pages/Catalogs/Catalogs.js
import React from 'react';
import PdfViewer from "./pdfUtils"
import samplePDF from './BEST BROCHURE (F).pdf';
import "../Catalogs/Catalogs.scss" 
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function Catalogs() {
  return (
    <div className='container mt-5'>

      <PdfViewer fileUrl={samplePDF} />
    </div>
  );
}

export default Catalogs;
// console.log("sdfsd")