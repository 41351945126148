// // import React from "react";
// // import { Container, Row, Col } from "react-bootstrap";
// // import {
// //   Box,
// //   Typography,
// //   Link as MuiLink,
// //   AppBar,
// //   Toolbar,
// //   IconButton,
// //   Menu,
// //   MenuItem,
// //   List,
// //   ListItem,
// //   ListItemText,
// //   Hidden,
// // } from "@mui/material";
// // import MenuIcon from "@mui/icons-material/Menu";

// // const PrivacyPolicy = () => {
// //   const [anchorEl, setAnchorEl] = React.useState(null);

// //   const handleMenuOpen = (event) => {
// //     setAnchorEl(event.currentTarget);
// //   };

// //   const handleMenuClose = () => {
// //     setAnchorEl(null);
// //   };

// //   return (
// //     <Container>
// //       <Box mt={4}>
// //         <Typography variant="h3" component="h1" gutterBottom>
// //           Privacy Policy
// //         </Typography>
// //         <Typography variant="subtitle1" gutterBottom>
// //           Effective Date: 08/07/2024
// //         </Typography>
// //         <Row>
// //           <Col lg={8}>
// //             <Box mt={3}>
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 1. Introduction:
// //               </Typography>
// //               <Typography variant="body1" gutterBottom>
// //                 Bumaas Engineering Solution & Technologies Pvt Ltd, Bumaas is
// //                 committed to protecting your privacy. This Privacy Policy
// //                 explains how we collect, use, disclose, and safeguard your
// //                 information when you visit our websites bestindiakart.com.
// //                 Please read this Privacy Policy carefully. If you do not agree
// //                 with the terms of this privacy policy, please do not access the
// //                 site.
// //               </Typography>{" "}
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 2. Information We Collect
// //               </Typography>
// //               <Typography variant="body2" gutterBottom>
// //                 <article style={{ fontSize: "15px" }}>
// //                   We may collect information about you in various ways. The
// //                   information we may collect on the Site includes:
// //                 </article>
// //                 <ul style={{ listStyle: "disc" }}>
// //                   <li>
// //                     <strong>Personal Data</strong> : Personally identifiable
// //                     information, such as your name, shipping address, email
// //                     address, and telephone number, that you voluntarily give to
// //                     us when you register with the Site or when you choose to
// //                     participate in various activities related to the Site.
// //                   </li>
// //                   <li>
// //                     <strong>Derivative Data</strong> : Information our servers
// //                     automatically collect when you access the Site, such as your
// //                     IP address, your browser type, your operating system, your
// //                     access times, and the pages you have viewed directly before
// //                     and after accessing the Site.{" "}
// //                   </li>
// //                 </ul>
// //               </Typography>
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 3. Use of Your Information{" "}
// //               </Typography>
// //               <article style={{ fontSize: "15px" }}>
// //                 We may use information collected about you via the Site to:
// //               </article>
// //               <Typography variant="body3" gutterBottom>
// //                 <ul style={{ listStyle: "disc" }}>
// //                   <li>Create and manage your account.</li>
// //                   <li>Process your transactions.</li>
// //                   <li>Email you regarding your account or order.</li>
// //                   <li>Send you a newsletter.</li>
// //                   <li>Improve our services and website.</li>
// //                   <li>
// //                     Monitor and analyze usage and trends to improve your
// //                     experience with the Site.
// //                   </li>
// //                 </ul>
// //               </Typography>
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 4. Disclosure of Your Information
// //               </Typography>
// //               <article style={{ fontSize: "15px" }}>
// //                 We may share information we have collected about you in certain
// //                 situations. Your information may be disclosed as follows:
// //               </article>
// //               <Typography variant="body4" gutterBottom>
// //                 <ul style={{ listStyle: "disc" }}>
// //                   <li>
// //                     <strong>By Law or to Protect Rights:</strong>If we believe
// //                     the release of information about you is necessary to respond
// //                     to legal process, to investigate or remedy potential
// //                     violations of our policies, or to protect the rights,
// //                     property, and safety of others, we may share your
// //                     information as permitted or required by any applicable law,
// //                     rule, or regulation.
// //                   </li>
// //                   <li>
// //                     <strong>Third-Party Service Providers:</strong>We may share
// //                     your information with third parties that perform services
// //                     for us or on our behalf, including payment processing, data
// //                     analysis, email delivery, hosting services, customer
// //                     service, and marketing assistance.
// //                   </li>
// //                   <li>
// //                     <strong>Marketing Communications:</strong>With your consent,
// //                     or with an opportunity for you to withdraw consent, we may
// //                     share your information with third parties for marketing
// //                     purposes, as permitted by law.{" "}
// //                   </li>
// //                 </ul>
// //               </Typography>
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 5. Use of Cookies
// //               </Typography>
// //               <Typography variant="body5" gutterBottom>
// //                 We may use cookies, web beacons, tracking pixels, and other
// //                 tracking technologies on the Site to help customize the Site and
// //                 improve your experience. When you access the Site, your personal
// //                 information is not collected through the use of tracking
// //                 technology. Most browsers are set to accept cookies by default.
// //                 You can remove or reject cookies, but be aware that such action
// //                 could affect the availability and functionality of the Site.
// //               </Typography>
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 6. Security of Your Information
// //               </Typography>
// //               <Typography variant="body6" gutterBottom>
// //                 We use administrative, technical, and physical security measures
// //                 to help protect your personal information. While we have taken
// //                 reasonable steps to secure the personal information you provide
// //                 to us, please be aware that despite our efforts, no security
// //                 measures are perfect or impenetrable, and no method of data
// //                 transmission can be guaranteed against any interception or other
// //                 type of misuse.
// //               </Typography>
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 7. Policy for Children
// //               </Typography>
// //               <Typography variant="body7" gutterBottom>
// //                 We do not knowingly solicit information from or market to
// //                 children under the age of 13. If we learn that we have collected
// //                 personal information from a child under age 13 without
// //                 verification of parental consent, we will delete that
// //                 information as quickly as possible.
// //               </Typography>
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 8. Controls for Do-Not-Track Features
// //               </Typography>
// //               <Typography variant="body8" gutterBottom>
// //                 Most web browsers and some mobile operating systems include a
// //                 Do-Not-Track ("DNT") feature or setting you can activate to
// //                 signal your privacy preference not to have data about your
// //                 online browsing activities monitored and collected
// //               </Typography>
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 9. Options Regarding Your Information
// //               </Typography>
// //               <Typography variant="body9" gutterBottom>
// //                 You may review, update, or delete your personal information by
// //                 contacting us at marketing@bumaas.com. Upon your request to
// //                 terminate your account, we will deactivate or delete your
// //                 account and information from our active databases. However, some
// //                 information may be retained in our files to prevent fraud,
// //                 troubleshoot problems, assist with any investigations, enforce
// //                 our Terms of Use and/or comply with legal requirements.
// //               </Typography>
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 10. Updates to This Policy
// //               </Typography>
// //               <Typography variant="body10" gutterBottom>
// //                 We may update this Privacy Policy from time to time in order to
// //                 reflect, for example, changes to our practices or for other
// //                 operational, legal, or regulatory reasons. Any changes or
// //                 modifications will be effective immediately upon posting the
// //                 updated Privacy Policy on the Site, and you waive the right to
// //                 receive specific notice of each such change or modification.
// //               </Typography>
// //               <Typography variant="h6" component="h3" gutterBottom>
// //               11. Contact Us
// //               </Typography>
// //               <article style={{ fontSize: "15px" }}>
// //                 If you have questions or comments about this Privacy Policy,
// //                 please contact us at:
// //               </article>
// //               <strong>
// //                 Bumaas Engineering Solution & Technologies Pvt Ltd
// //               </strong>
// //               <Typography variant="body11" gutterBottom>
// //                 No 1A, Jeevarathinam Street, KSR Nagar, Ambattur, Chennai, Tamil
// //                 Nadu - 600053, India Mobile: +91 7812810488E-Mail:
// //                 marketing@bumaas.com
// //               </Typography>

// //             </Box>
// //           </Col>
// //           <Col lg={4}>
// //             <Box mt={3} p={2} bgcolor="#f9f9f9" border={1} borderColor="#ddd">
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 Table of contents
// //               </Typography>
// //               <List>
// //                 <ListItem button component="a" href="#1">
// //                   <ListItemText primary="1. Introduction" />
// //                 </ListItem>
// //                 <ListItem button component="a" href="#2">
// //                   <ListItemText primary="2. Information We Collect" />
// //                 </ListItem>
// //                 <ListItem button component="a" href="#3">
// //                   <ListItemText primary="3. Use of Your Information" />
// //                 </ListItem>
// //                 <ListItem button component="a" href="#4">
// //                   <ListItemText primary="4. Disclosure of Your Information" />
// //                 </ListItem>
// //                 <ListItem button component="a" href="#5">
// //                   <ListItemText primary="5. Use of Cookies" />
// //                 </ListItem>
// //                 <ListItem button component="a" href="#6">
// //                   <ListItemText primary="6. Security of Your Information" />
// //                 </ListItem>
// //                 <ListItem button component="a" href="#7">
// //                   <ListItemText primary="7. Policy for Children" />
// //                 </ListItem>
// //                 <ListItem button component="a" href="#8">
// //                   <ListItemText primary="8. Controls for Do-Not-Track Features" />
// //                 </ListItem>
// //                 <ListItem button component="a" href="#9">
// //                   <ListItemText primary="9. Options Regarding Your Information" />
// //                 </ListItem>
// //                 <ListItem button component="a" href="#10">
// //                   <ListItemText primary="10. Updates to This Policy" />
// //                 </ListItem>
// //                 <ListItem button component="a" href="#11">
// //                   <ListItemText primary="11. Contact Us" />
// //                 </ListItem>
// //               </List>
// //             </Box>
// //           </Col>
// //         </Row>
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default PrivacyPolicy;

// // import 'bootstrap/dist/css/bootstrap.min.css';
// // import React from "react";
// // import { Container, Row, Col } from "react-bootstrap";
// // import {
// //   Box,
// //   Typography,
// //   Link as MuiLink,
// //   List,
// //   ListItem,
// //   ListItemText,
// // } from "@mui/material";
// // import { Link } from "react-scroll"; // Using react-scroll for smooth scrolling

// // const PrivacyPolicy = () => {
// //   return (
// //     <Container>
// //       <Box mt={4}>
// //         <Typography variant="h3" component="h1" gutterBottom>
// //           Privacy Policy
// //         </Typography>
// //         <Typography variant="subtitle1" gutterBottom>
// //           Effective Date: 08/07/2024
// //         </Typography>
// //         <Row>
// //           <Col lg={8}>
// //             <Box mt={3}>
// //               <Typography variant="h6" component="h3" id="1" gutterBottom>
// //                 1. Introduction:
// //               </Typography>
// //               <Typography variant="body1" gutterBottom>
// //                 Bumaas Engineering Solution & Technologies Pvt Ltd, Bumaas is
// //                 committed to protecting your privacy. This Privacy Policy
// //                 explains how we collect, use, disclose, and safeguard your
// //                 information when you visit our websites bestindiakart.com.
// //                 Please read this Privacy Policy carefully. If you do not agree
// //                 with the terms of this privacy policy, please do not access the
// //                 site.
// //               </Typography>
// //               <Typography variant="h6" component="h3" id="2" gutterBottom>
// //                 2. Information We Collect
// //               </Typography>
// //               <Typography variant="body2" gutterBottom>
// //                 <article style={{ fontSize: "15px" }}>
// //                   We may collect information about you in various ways. The
// //                   information we may collect on the Site includes:
// //                 </article>
// //                 <ul style={{ listStyle: "disc" }}>
// //                   <li>
// //                     <strong>Personal Data</strong>: Personally identifiable
// //                     information, such as your name, shipping address, email
// //                     address, and telephone number, that you voluntarily give to
// //                     us when you register with the Site or when you choose to
// //                     participate in various activities related to the Site.
// //                   </li>
// //                   <li>
// //                     <strong>Derivative Data</strong>: Information our servers
// //                     automatically collect when you access the Site, such as your
// //                     IP address, your browser type, your operating system, your
// //                     access times, and the pages you have viewed directly before
// //                     and after accessing the Site.
// //                   </li>
// //                 </ul>
// //               </Typography>
// //               <Typography variant="h6" component="h3" id="3" gutterBottom>
// //                 3. Use of Your Information
// //               </Typography>
// //               <article style={{ fontSize: "15px" }}>
// //                 We may use information collected about you via the Site to:
// //               </article>
// //               <Typography variant="body3" gutterBottom>
// //                 <ul style={{ listStyle: "disc" }}>
// //                   <li>Create and manage your account.</li>
// //                   <li>Process your transactions.</li>
// //                   <li>Email you regarding your account or order.</li>
// //                   <li>Send you a newsletter.</li>
// //                   <li>Improve our services and website.</li>
// //                   <li>
// //                     Monitor and analyze usage and trends to improve your
// //                     experience with the Site.
// //                   </li>
// //                 </ul>
// //               </Typography>
// //               <Typography variant="h6" component="h3" id="4" gutterBottom>
// //                 4. Disclosure of Your Information
// //               </Typography>
// //               <article style={{ fontSize: "15px" }}>
// //                 We may share information we have collected about you in certain
// //                 situations. Your information may be disclosed as follows:
// //               </article>
// //               <Typography variant="body4" gutterBottom>
// //                 <ul style={{ listStyle: "disc" }}>
// //                   <li>
// //                     <strong>By Law or to Protect Rights:</strong>If we believe
// //                     the release of information about you is necessary to respond
// //                     to legal process, to investigate or remedy potential
// //                     violations of our policies, or to protect the rights,
// //                     property, and safety of others, we may share your
// //                     information as permitted or required by any applicable law,
// //                     rule, or regulation.
// //                   </li>
// //                   <li>
// //                     <strong>Third-Party Service Providers:</strong>We may share
// //                     your information with third parties that perform services
// //                     for us or on our behalf, including payment processing, data
// //                     analysis, email delivery, hosting services, customer
// //                     service, and marketing assistance.
// //                   </li>
// //                   <li>
// //                     <strong>Marketing Communications:</strong>With your consent,
// //                     or with an opportunity for you to withdraw consent, we may
// //                     share your information with third parties for marketing
// //                     purposes, as permitted by law.
// //                   </li>
// //                 </ul>
// //               </Typography>
// //               <Typography variant="h6" component="h3" id="5" gutterBottom>
// //                 5. Use of Cookies
// //               </Typography>
// //               <Typography variant="body5" gutterBottom>
// //                 We may use cookies, web beacons, tracking pixels, and other
// //                 tracking technologies on the Site to help customize the Site and
// //                 improve your experience. When you access the Site, your personal
// //                 information is not collected through the use of tracking
// //                 technology. Most browsers are set to accept cookies by default.
// //                 You can remove or reject cookies, but be aware that such action
// //                 could affect the availability and functionality of the Site.
// //               </Typography>
// //               <Typography variant="h6" component="h3" id="6" gutterBottom>
// //                 6. Security of Your Information
// //               </Typography>
// //               <Typography variant="body6" gutterBottom>
// //                 We use administrative, technical, and physical security measures
// //                 to help protect your personal information. While we have taken
// //                 reasonable steps to secure the personal information you provide
// //                 to us, please be aware that despite our efforts, no security
// //                 measures are perfect or impenetrable, and no method of data
// //                 transmission can be guaranteed against any interception or other
// //                 type of misuse.
// //               </Typography>
// //               <Typography variant="h6" component="h3" id="7" gutterBottom>
// //                 7. Policy for Children
// //               </Typography>
// //               <Typography variant="body7" gutterBottom>
// //                 We do not knowingly solicit information from or market to
// //                 children under the age of 13. If we learn that we have collected
// //                 personal information from a child under age 13 without
// //                 verification of parental consent, we will delete that
// //                 information as quickly as possible.
// //               </Typography>
// //               <Typography variant="h6" component="h3" id="8" gutterBottom>
// //                 8. Controls for Do-Not-Track Features
// //               </Typography>
// //               <Typography variant="body8" gutterBottom>
// //                 Most web browsers and some mobile operating systems include a
// //                 Do-Not-Track ("DNT") feature or setting you can activate to
// //                 signal your privacy preference not to have data about your
// //                 online browsing activities monitored and collected.
// //               </Typography>
// //               <Typography variant="h6" component="h3" id="9" gutterBottom>
// //                 9. Options Regarding Your Information
// //               </Typography>
// //               <Typography variant="body9" gutterBottom>
// //                 You may review, update, or delete your personal information by
// //                 contacting us at marketing@bumaas.com. Upon your request to
// //                 terminate your account, we will deactivate or delete your
// //                 account and information from our active databases. However, some
// //                 information may be retained in our files to prevent fraud,
// //                 troubleshoot problems, assist with any investigations, enforce
// //                 our Terms of Use and/or comply with legal requirements.
// //               </Typography>
// //               <Typography variant="h6" component="h3" id="10" gutterBottom>
// //                 10. Updates to This Policy
// //               </Typography>
// //               <Typography variant="body10" gutterBottom>
// //                 We may update this Privacy Policy from time to time in order to
// //                 reflect, for example, changes to our practices or for other
// //                 operational, legal, or regulatory reasons. Any changes or
// //                 modifications will be effective immediately upon posting the
// //                 updated Privacy Policy on the Site, and you waive the right to
// //                 receive specific notice of each such change or modification.
// //               </Typography>
// //               <Typography variant="h6" component="h3" id="11" gutterBottom>
// //                 11. Contact Us
// //               </Typography>
// //               <article style={{ fontSize: "15px" }}>
// //                 If you have questions or comments about this Privacy Policy,
// //                 please contact us at:
// //               </article>
// //               <strong>
// //                 Bumaas Engineering Solution & Technologies Pvt Ltd
// //               </strong>
// //               <Typography variant="body11" gutterBottom>
// //                 No 1A, Jeevarathinam Street, KSR Nagar, Ambattur, Chennai, Tamil
// //                 Nadu - 600053, India Mobile: +91 7812810488E-Mail:
// //                 marketing@bumaas.com
// //               </Typography>
// //             </Box>
// //           </Col>
// //           <Col lg={4}>
// //             <Box mt={3} p={2} bgcolor="#f9f9f9" border={1} borderColor="#ddd">
// //               <Typography variant="h6" component="h3" gutterBottom>
// //                 Table of Contents
// //               </Typography>
// //               <List>
// //                 <ListItem button component={Link} to="1" smooth duration={500}>
// //                   <ListItemText primary="1. Introduction" />
// //                 </ListItem>
// //                 <ListItem button component={Link} to="2" smooth duration={500}>
// //                   <ListItemText primary="2. Information We Collect" />
// //                 </ListItem>
// //                 <ListItem button component={Link} to="3" smooth duration={500}>
// //                   <ListItemText primary="3. Use of Your Information" />
// //                 </ListItem>
// //                 <ListItem button component={Link} to="4" smooth duration={500}>
// //                   <ListItemText primary="4. Disclosure of Your Information" />
// //                 </ListItem>
// //                 <ListItem button component={Link} to="5" smooth duration={500}>
// //                   <ListItemText primary="5. Use of Cookies" />
// //                 </ListItem>
// //                 <ListItem button component={Link} to="6" smooth duration={500}>
// //                   <ListItemText primary="6. Security of Your Information" />
// //                 </ListItem>
// //                 <ListItem button component={Link} to="7" smooth duration={500}>
// //                   <ListItemText primary="7. Policy for Children" />
// //                 </ListItem>
// //                 <ListItem button component={Link} to="8" smooth duration={500}>
// //                   <ListItemText primary="8. Controls for Do-Not-Track Features" />
// //                 </ListItem>
// //                 <ListItem button component={Link} to="9" smooth duration={500}>
// //                   <ListItemText primary="9. Options Regarding Your Information" />
// //                 </ListItem>
// //                 <ListItem button component={Link} to="10" smooth duration={500}>
// //                   <ListItemText primary="10. Updates to This Policy" />
// //                 </ListItem>
// //                 <ListItem button component={Link} to="11" smooth duration={500}>
// //                   <ListItemText primary="11. Contact Us" />
// //                 </ListItem>
// //               </List>
// //             </Box>
// //           </Col>
// //         </Row>
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default PrivacyPolicy;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  Box,
  Typography,
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import "./PrivacyPolicy.scss";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <section className="privacy-section">
       <Helmet>
        <title>Privacy Policy | Bolts And Nuts Manufacturers In India</title>
        <meta name="description" content="Best India Kart is a reliable manufacturer of high-quality bolts and nuts, delivering durable and precise fastening solutions for various industrial needs."/>
        {/* <meta name="keywords" content="Jobs, Service,Automobiles,Store, Education, Travels, Event"/> */}
      </Helmet>
      <Container className="privacy-policy-wrapper">
          <h1 className="privacypolicy-page-heading">Privacy Policy</h1>
        <Box mt={4}>
          <Typography variant="subtitle1" gutterBottom>
            Effective Date: 08/07/2024
          </Typography>
          <Row>
            <Col lg={8}>
              <Box mt={3} className="privacy-policy-content">
                <Typography variant="h6" component="h3" id="1" gutterBottom>
                  1. Introduction:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Bumaas Engineering Solution & Technologies Pvt Ltd, Bumaas is
                  committed to protecting your privacy. This Privacy Policy
                  explains how we collect, use, disclose, and safeguard your
                  information when you visit our websites bestindiakart.com.
                  Please read this Privacy Policy carefully. If you do not agree
                  with the terms of this privacy policy, please do not access
                  the site.
                </Typography>
                <Typography variant="h6" component="h3" id="2" gutterBottom>
                  2. Information We Collect
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <article style={{ fontSize: "15px" }}>
                    We may collect information about you in various ways. The
                    information we may collect on the Site includes:
                  </article>
                  <ul style={{ listStyle: "disc" }}>
                    <li>
                      <strong>Personal Data :</strong> Personally identifiable
                      information, such as your name, shipping address, email
                      address, and telephone number, that you voluntarily give
                      to us when you register with the Site or when you choose
                      to participate in various activities related to the Site.
                    </li>
                    <li>
                      <strong>Derivative Data :</strong> Information our servers
                      automatically collect when you access the Site, such as
                      your IP address, your browser type, your operating system,
                      your access times, and the pages you have viewed directly
                      before and after accessing the Site.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="h6" component="h3" id="3" gutterBottom>
                  3. Use of Your Information
                </Typography>
                <Typography variant="body3" component="p" gutterBottom>
                <article style={{ fontSize: "15px" }}>
                  We may use information collected about you via the Site to:
                </article>
                  <ul style={{ listStyle: "disc" }}>
                    <li>Create and manage your account.</li>
                    <li>Process your transactions.</li>
                    <li>Email you regarding your account or order.</li>
                    <li>Send you a newsletter.</li>
                    <li>Improve our services and website.</li>
                    <li>
                      Monitor and analyze usage and trends to improve your
                      experience with the Site.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="h6" component="h3" id="4" gutterBottom>
                  4. Disclosure of Your Information
                </Typography>
                <Typography variant="body4" component="p" gutterBottom>
                <article style={{ fontSize: "15px" }}>
                  We may share information we have collected about you in
                  certain situations. Your information may be disclosed as
                  follows:
                </article>
                  <ul style={{ listStyle: "disc" }}>
                    <li>
                      <strong>By Law or to Protect Rights :</strong> If we believe
                      the release of information about you is necessary to
                      respond to legal process, to investigate or remedy
                      potential violations of our policies, or to protect the
                      rights, property, and safety of others, we may share your
                      information as permitted or required by any applicable
                      law, rule, or regulation.
                    </li>
                    <li>
                      <strong>Third-Party Service Providers :</strong> We may
                      share your information with third parties that perform
                      services for us or on our behalf, including payment
                      processing, data analysis, email delivery, hosting
                      services, customer service, and marketing assistance.
                    </li>
                    <li>
                      <strong>Marketing Communications :</strong> With your
                      consent, or with an opportunity for you to withdraw
                      consent, we may share your information with third parties
                      for marketing purposes, as permitted by law.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="h6" component="h3" id="5" gutterBottom>
                  5. Use of Cookies
                </Typography>
                <Typography variant="body5" component="p" gutterBottom>
                  We may use cookies, web beacons, tracking pixels, and other
                  tracking technologies on the Site to help customize the Site
                  and improve your experience. When you access the Site, your
                  personal information is not collected through the use of
                  tracking technology. Most browsers are set to accept cookies
                  by default. You can remove or reject cookies, but be aware
                  that such action could affect the availability and
                  functionality of the Site.
                </Typography>
                <Typography variant="h6" component="h3" id="6" gutterBottom>
                  6. Security of Your Information
                </Typography>
                <Typography variant="body6" component="p" gutterBottom>
                  We use administrative, technical, and physical security
                  measures to help protect your personal information. While we
                  have taken reasonable steps to secure the personal information
                  you provide to us, please be aware that despite our efforts,
                  no security measures are perfect or impenetrable, and no
                  method of data transmission can be guaranteed against any
                  interception or other type of misuse.
                </Typography>
                <Typography variant="h6" component="h3" id="7" gutterBottom>
                  7. Policy for Children
                </Typography>
                <Typography variant="body7" component="p" gutterBottom>
                  We do not knowingly solicit information from or market to
                  children under the age of 13. If we learn that we have
                  collected personal information from a child under age 13
                  without verification of parental consent, we will delete that
                  information as quickly as possible.
                </Typography>
                <Typography variant="h6" component="h3" id="8" gutterBottom>
                  8. Controls for Do-Not-Track Features
                </Typography>
                <Typography variant="body8" component="p" gutterBottom>
                  Most web browsers and some mobile operating systems include a
                  Do-Not-Track ("DNT") feature or setting you can activate to
                  signal your privacy preference not to have data about your
                  online browsing activities monitored and collected.
                </Typography>
                <Typography variant="h6" component="h3" id="9" gutterBottom>
                  9. Options Regarding Your Information
                </Typography>
                <Typography variant="body9" component="p" gutterBottom>
                  You may review, update, or delete your personal information by
                  contacting us at marketing@bumaas.com Upon your request to
                  terminate your account, we will deactivate or delete your
                  account and information from our active databases. However,
                  some information may be retained in our files to prevent
                  fraud, troubleshoot problems, assist with any investigations,
                  enforce our Terms of Use and/or comply with legal
                  requirements.
                </Typography>
                <Typography variant="h6" component="h3" id="10" gutterBottom>
                  10. Updates to This Policy
                </Typography>
                <Typography variant="body10" component="p" gutterBottom>
                  We may update this Privacy Policy from time to time in order
                  to reflect, for example, changes to our practices or for other
                  operational, legal, or regulatory reasons. Any changes or
                  modifications will be effective immediately upon posting the
                  updated Privacy Policy on the Site, and you waive the right to
                  receive specific notice of each such change or modification.
                </Typography>
                <Typography variant="h6" component="h3" id="11" gutterBottom>
                  11. Contact Us
                </Typography>
                <Typography variant="body11" component="p" id="11" gutterBottom>
                <article style={{ fontSize: "15px" }}>
                  If you have questions or comments about this Privacy Policy,
                  please contact us at:
                </article>
                <strong>
                  Bumaas Engineering Solution & Technologies Pvt Ltd
                </strong>
                <Typography variant="body11" component="p"  gutterBottom>
                  No 1A, Jeevarathinam Street, KSR Nagar, Ambattur, Chennai,
                  Tamil Nadu - 600053, India 
                </Typography>
                <Typography variant="body11" component="p" gutterBottom>
                  Mobile: &nbsp;
                  <a
                    href="tel:7812810488"
                    style={{ textDecoration: "none" }}
                    className="contact-web-links"
                  >
                    +91 7812810488
                  </a>
                </Typography>
                <Typography variant="body11" component="p" gutterBottom>
                  Email:&nbsp; 
                  <a
                      href="mailto:marketing@bumaas.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "green" }}
                      className="contact-web-links"
                    >
                      marketing@bumaas.com{" "}
                    </a>
                </Typography>
                </Typography>
              </Box>
            </Col>
            <Col lg={4}>
              <Box mt={3} p={2} bgcolor="#f9f9f9" border={1} borderColor="#ddd">
                <Typography variant="h6" component="h3" gutterBottom>
                  Table of Contents
                </Typography>
                <List component="nav" aria-label="Table of Contents">
                  <ListItem button component="a" href="#1">
                    <ListItemText primary="1. Introduction" />
                  </ListItem>
                  <ListItem button component="a" href="#2">
                    <ListItemText primary="2. Information We Collect" />
                  </ListItem>
                  <ListItem button component="a" href="#3">
                    <ListItemText primary="3. Use of Your Information" />
                  </ListItem>
                  <ListItem button component="a" href="#4">
                    <ListItemText primary="4. Disclosure of Your Information" />
                  </ListItem>
                  <ListItem button component="a" href="#5">
                    <ListItemText primary="5. Use of Cookies" />
                  </ListItem>
                  <ListItem button component="a" href="#6">
                    <ListItemText primary="6. Security of Your Information" />
                  </ListItem>
                  <ListItem button component="a" href="#7">
                    <ListItemText primary="7. Policy for Children" />
                  </ListItem>
                  <ListItem button component="a" href="#8">
                    <ListItemText primary="8. Controls for Do-Not-Track Features" />
                  </ListItem>
                  <ListItem button component="a" href="#9">
                    <ListItemText primary="9. Options Regarding Your Information" />
                  </ListItem>
                  <ListItem button component="a" href="#10">
                    <ListItemText primary="10. Updates to This Policy" />
                  </ListItem>
                  <ListItem button component="a" href="#11">
                    <ListItemText primary="11. Contact Us" />
                  </ListItem>
                </List>
              </Box>
            </Col>
          </Row>
        </Box>
      </Container>
    </section>
  );
};

export default PrivacyPolicy;

// import React from 'react'

// const PrivacyPolicy = () => {
//   return (
//     <div>
//       ttt
//     </div>
//   )
// }

// export default PrivacyPolicy
