import React from "react";
import "./Footer.scss";
import footer_logo from "../../Assets/Images/brand-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaXTwitter,
  FaYoutube,

  FaLinkedinIn,
} from "react-icons/fa6";
import CopyRights from "../CopyRights/CopyRights";
import Swal from "sweetalert2/dist/sweetalert2.js";

function LandingPageFooter() {
  const navigate = useNavigate();
  const custom_service = [
    {
      name: "Register",
      link: "/registration",
    },
    {
      name: "How to use",
      link: "/howtouse",
    },
    {
      name: "Catalog",
      link: "/catalog",
    },
    {
      name: "Catalog Request",
      link: "/brochure",
    },
    {
      name: "Inquiry",
      link: "/inquiry",
    },
    {
      name: "Site Map",
      link: "/contact",
    },
  ];

  const my_account = [
    {
      name: "Request a Quote",
      link: "/inquiry",
    },
    {
      name: "My Quote / Order History",
      link: "/profile",
    },
    {
      name: "Cart",
      link: "/carts",
    },
    {
      name: "My Page",
      link: "/profile",
    },
  ];

  const about = [
    {
      name: "About us",
      link: "/about",
    },
    {
      name: "Shipping Policy",
      link: "/shipping-policy",
    },
    {
      name: "Refunds & Cancellations",
      link: "/cancel-and-refund",
    },
    {
      name: "Privacy Policy",
      link: "/privacypolicy",
    },
    {
      name: "Terms & Conditions",
      link: "/terms-and-conditions",
    },
    {
      name: "Blogs",
      link: "/blogs",
    },
    {
      name: "Contact Us",
      link: "/contact",
    },
  ];

  const socialMedia = [

    {
      icon: <FaXTwitter />,
      link: "https://twitter.com/bestindiakart",
      altname:"twitter"
    },
    {
      icon: <FaFacebookF />,
      link: "https://www.facebook.com/digitalbestindiakart",
      altname:"Facebook"
    },
    {
      icon: <FaInstagram />,
      link: "https://www.instagram.com/bestindiakart02/",
      altname:"Instagram"
    },
    {
      icon: <FaLinkedinIn />,
      link: "https://www.linkedin.com/company/bestindiakart",
      altname:"Linkednin"
    },
    
    {
      icon: <FaYoutube />,
      link: "/",
      altname:"Youtube"
    },
  ];

  const handleNavigation = (e, link) => {
    e.preventDefault();
    const userId = localStorage.getItem("userId");
    if (link === "/profile" && !userId) {
      Swal.fire({
        icon: "error",
        title: "Please login!",
        showConfirmButton: false,
        timer: 5000,
      });
    } else {
      navigate(link);
    }
  };

  return (
    <>
      <div className="footer">
        <div className="row m-0">
          <div className="col-md-3 py-2">
            <Link to="/">
              <img src={footer_logo} alt="BestIndiaKart"/>
            </Link>

            <h5 className="footer-title">
              Best India Kart - Online Fasteners Market
            </h5>
            <p className="sub-title">Contact Support</p>
            <a
              target="_blank"
              href="tel:+917812810488"
              className="contact-number text-decoration-none"
            >
              +91 7812810488
            </a>
            <p className="sub-title">
              No 1A, Jeevarathinam Street, KSR Nagar, Ambattur - 600 053
            </p>

            {/* <div className="social-media">
            </div> */}
            {/* {socialMedia.map((item, i) => (
              <span key={i}>{item?.icon}</span>
            ))} */}
            <div className="social-media">
              {socialMedia.map((media, index) => (
                <a
                  key={index}
                  href={media.link}
                  // target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                  aria-label={media.altname}
                >
                  <span key={index} style={{ color: "black" }}>
                    {media?.icon}
                  </span>
                </a>
              ))}
            </div>
          </div>

          <div className="col-md-3 footer-small col-sm-6 py-2">
            <h5>Customer Service</h5>
            <div className="links-container">
              {custom_service.map((item, i) => (
                <Link key={i} to={item?.link} className="footer-links ">
                  <p className="m-0"> {item?.name} </p>
                </Link>
              ))}
            </div>
          </div>

          {/* <div className="col-md-3 footer-small col-sm-6 py-2">
            <h5>My Account</h5>
            <div className="links-container">
              {my_account.map((item, i) => (
                <Link key={i} to={item?.link} className="footer-links ">
                  <p className="m-0"> {item?.name} </p>
                </Link>
              ))}
            </div>
          </div> */}

          <div className="col-md-3 footer-small col-sm-6 py-2">
            <h5>My Account</h5>
            <div className="links-container">
              {my_account.map((item, i) => (
                <a
                  key={i}
                  href={item.link}
                  onClick={(e) => handleNavigation(e, item.link)}
                  className="footer-links"
                >
                  <p className="m-0">{item.name}</p>
                </a>
              ))}
            </div>
          </div>

          <div className="col-md-3 col-sm-6 py-2">
            <h5>About Best Inida kart </h5>
            <div className="links-container">
              {about.map((item, i) => (
                <Link key={i} to={item?.link} className="footer-links ">
                  <p className="m-0"> {item?.name} </p>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      <CopyRights />
    </>
  );
}

export default LandingPageFooter;
