
// import React, { useEffect, useState } from "react";
// import {
//   MDBCard,
//   MDBCardBody,
//   MDBCol,
//   MDBContainer,
//   MDBIcon,
//   MDBRow,
//   MDBTypography,
// } from "mdb-react-ui-kit";
// import "../Order/Orderdetails.css"; // Ensure this path is correct

// export default function OrderDetails6({ order }) {
//   const [animatedStep, setAnimatedStep] = useState(0);

//   useEffect(() => {
//     if (order) {
//       const { orderStatus } = order;
//       const currentStep = progressSteps.findIndex(step => step.status === orderStatus) + 1;
//       setAnimatedStep(currentStep);
//     }
//   }, [order]);

//   if (!order) return null;

//   const { orderId, orderStatus, createdAt,shippingAt,deliveredAt } = order;

//   const formatDate = (dateString) => {
//     return new Date(dateString).toLocaleDateString("en-US", {
//       year: "numeric",
//       month: "short",
//       day: "numeric",
//     });
//   };

//   const getStatusStyle = (status) => {
//     switch (status) {
//       case "Payment Pending":
//         return { color: "#F3A638" }; // Orange for pending payment
//       case "Payment success":
//         return { color: "#4CB64C" }; // Green for payment success
//       case "processing":
//         return { color: "#54B7D3" }; // Light Blue for processing
//       case "Shipped":
//         return { color: "#1E91CF" }; // Blue for shipped
//       case "delivered":
//         return { color: "#4CB64C" }; // Green for delivered
//       case "cancel":
//         return { color: "#E3503E" }; // Red for canceled
//       default:
//         return {};
//     }
//   };

//   const progressSteps = [
//     { status: "Payment Pending", icon: "clock", label: "Pending Payment" },
//     { status: "Payment success", icon: "check-circle", label: "Payment Success" },
//     { status: "processing", icon: "cogs", label: "Order Processing" },
//     { status: "Shipped", icon: "truck", label: "Order Shipped" },
//     { status: "delivered", icon: "home", label: "Order Delivered" },
//     { status: "cancel", icon: "ban", label: "Order Canceled" },
//   ];

//   const totalSteps = progressSteps.length;
//   const progressWidth = `${(animatedStep / totalSteps) * 100}%`;

//   return (
//     <section >
//       <MDBContainer className=" h-100">
//         <MDBRow className="justify-content-center align-items-center h-100">
//           <MDBCol size="12">
//           <table>
//   <tr>
//     <th>Order Id</th>
//     <th>Shipped Date</th>
//     <th>Expected Arrival</th>
//   </tr>
//   <tr>
//     <td>{orderId}</td>
//     <td>{formatDate(shippingAt)}</td>
//     <td>{formatDate(deliveredAt)}</td>
//   </tr>
 
// </table>
//                 {/* <div className="d-flex justify-content-between align-items-center mb-5">
//                   <div>
//                     <MDBTypography tag="h5" className="mb-0">
//                       Order Id :{" "}
//                       <span className="text-primary font-weight-bold">{orderId}</span>
//                     </MDBTypography>
//                   </div>
//                   <div className="text-end  d-flex">
//                     <p className="mb-0 ">
//                     Shipped Date <span>{formatDate(shippingAt)}</span>
//                     </p>
//                     <p className="mb-0 m-2">
//                       Expected Arrival <span>{formatDate(deliveredAt)}</span>
//                     </p>
//                     <p className="mb-0">
                     
//                     </p>
//                   </div>
//                 </div> */}
//             <MDBCard className="card-stepper text-black " >
//               <MDBCardBody className="" style={{boxShadow:"none"}}>

//                 <ul id="progressbar-2" className="d-flex justify-content-between mx-0 mt-4 mb-5 px-0 pt-0 pb-2">
//                   {progressSteps.map((step, index) => (
//                     <li
//                       key={step.status}
//                       id={`step${index + 1}`}
//                       className={`step ${index < animatedStep ? "active" : "text-muted"} text-center`}
//                       style={{
//                         ...getStatusStyle(step.status),
//                         width: `${100 / progressSteps.length}%`,
//                       }}
//                     >
//                       <MDBIcon fas icon={step.icon} size="2x" className="mb-2 step-label1" />
//                       <div className="step-label">{step.label}</div>
//                     </li>
//                   ))}
//                   <div
//                     style={{
//                       position: "absolute",
//                       top: "15px",
//                       left: "0",
//                       width: progressWidth,
//                       height: "10px",
//                       background: "#6520ff",
//                       zIndex: -1,
//                       transition: "width 1s ease-in-out",
//                     }}
//                   ></div>
//                 </ul>
//               </MDBCardBody>
//             </MDBCard>
//           </MDBCol>
//         </MDBRow>
//       </MDBContainer>
//     </section>
//   );
// }

import React, { useEffect, useState } from "react";
// import { CascadeSelect } from 'primereact/cascadeselect';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
  MDBIcon,
} from "mdb-react-ui-kit";
import "../Order/Orderdetails.css"; // Ensure this path is correct

export default function OrderDetails6({ order }) {
  const [animatedStep, setAnimatedStep] = useState(0);

  useEffect(() => {
    if (order) {
      const { orderStatus } = order;
      const currentStep =
        progressSteps.findIndex((step) => step.status === orderStatus) + 1;
      setAnimatedStep(currentStep);
    }
  }, [order]);

  if (!order) return null;

  const { orderId, shippingAt, deliveredAt } = order;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Payment Pending":
        return { color: "#F3A638" }; // Orange for pending payment
      case "Payment success":
        return { color: "#4CB64C" }; // Green for payment success
      case "processing":
        return { color: "#54B7D3" }; // Light Blue for processing
      case "Shipped":
        return { color: "#1E91CF" }; // Blue for shipped
      case "Delivered":
        return { color: "#4CB64C" }; // Green for delivered
      case "cancel":
        return { color: "#E3503E" }; // Red for canceled
      default:
        return {};
    }
  };

  const progressSteps = [
    { status: "Payment Pending", icon: "clock", label: "Pending Payment" },
    {
      status: "Payment success",
      icon: "check-circle",
      label: "Payment Success",
    },
    { status: "processing", icon: "cogs", label: "Order Processing" },
    { status: "Shipped", icon: "truck", label: "Order Shipped" },
    { status: "Delivered", icon: "home", label: "Order Delivered" },
  ];

  const totalSteps = progressSteps.length;
  const progressWidth = `${(animatedStep / totalSteps) * 90}%`;

  return (
    <section className="container">
      <MDBContainer className="h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol size="12">
            <table className="text-center ddd">
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Ship Date</th>
                  <th>ETA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{orderId}</td>
                  <td>
                    {shippingAt ? formatDate(shippingAt) : (
                      <>
                        <MDBIcon fas icon="spinner" spin />
                        <span> Processing..</span>
                      </>
                    )}
                    {/* <CascadeSelect
                      loading
                      placeholder="Loading..."
                      className="w-full md:w-14rem"
                      breakpoint="767px"
                      style={{ minWidth: '14rem', display: 'inline-block', marginLeft: '10px' }}
                    /> */}
                  </td>
                  <td>
                  {deliveredAt ? formatDate(deliveredAt) : (
                      <>
                        <MDBIcon fas icon="spinner" spin />
                        <span> Processing..</span>
                      </>
                    )}
                 
                  </td>
                </tr>
              </tbody>
            </table>
            <MDBCard className="card-stepper text-black dssd">
              <MDBCardBody className="" style={{ boxShadow: "none" }}>
                <ul
                  id="progressbar-2"
                  className="d-flex justify-content-between mx-0 mt-4 mb-5 px-0 pt-0 pb-2"
                >
                  {progressSteps.map((step, index) => (
                    <li
                      key={step.status}
                      id={`step${index + 1}`}
                      className={`step ${
                        index < animatedStep ? "active" : "text-muted"
                      } text-center`}
                      style={{
                        ...getStatusStyle(step.status),
                        width: `${100 / progressSteps.length}%`,
                      }}
                    >
                      <div className="mt-4">
                        <span className="step-label1 ">
                          <MDBIcon
                            fas
                            icon={step.icon}
                            className="fa-2x mb-1"
                          />
                        </span>
                        <span className="step-label">{step.label}</span>
                      </div>
                    </li>
                  ))}
                  <div
                    style={{
                      position: "absolute",
                      top: "15px",
                      left: "0",
                      width: progressWidth,
                      height: "10px",
                      background: "#6520ff",
                      zIndex: -1,
                      transition: "width 1s ease-in-out",
                    }}
                  ></div>
                </ul>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
