// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Card from "react-bootstrap/Card";
// import AddCartButton from "../Buttons/AddCartButton";
// import WishlistButton from "../Buttons/WishlistButton";
// import ProductOveriew from "../ProductOveriew/ProductOveriew";
// import ProductImages from "../Products/ProductImages/ProductImages";
// import { IoCaretBack } from "react-icons/io5";
// import Loader from "../Loader";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils";
// import ToolsProductIdProvider from "../hoc/ToolsProductIdProvider";

// function ViewAllToolsProducts({ categoryId, categoryName }) {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     if (categoryId) {
//       axios
//         .get(`${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`)
//         .then((response) => {
//           setData(response?.data?.products);
//           // console.log("getproductbycategory", response.data);
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//         });
//     }
//   }, [categoryId]);

//   return (
//     <div className="products-wrapper px-2r py-4">
//       <center className="products-heading-container">
//         <h4 className="product-heading">Tools</h4>
//         <Link to="/" className="view-all-products">
//           <IoCaretBack className="fs-5" />
//           Go Back
//         </Link>
//       </center>

//       {data?.length !== 0 ? (
//         <div className="products m-0">
//           {data.map((product) => {
//             return (
//               <div className="card">
//                 <center>
//                   <Card.Title className="px-2 text-dark card-title">
//                     <Link
//                       className="text-dark text-decoration-none "
//                       to={`/product/${product?._id}`}
//                     >
//                       {product?.partNumber}
//                     </Link>
//                   </Card.Title>
//                 </center>
//                 <Link
//                   className="text-dark text-decoration-none "
//                   to={`/product/${product?._id}`}
//                 >
//                   <ProductImages productimages={product?.productimages} />
//                 </Link>

//                 <center className="cart-footer">
//                   <div className="card-price">
//                     <h6 className="m-0 current-price">₹ {product?.saleprice}</h6>
//                     <p className="m-0 old-price">₹ {product?.oldprice}</p>
//                   </div>

//                   <AddCartButton
//                     type="product_button"
//                     className="card-add-cart-button"
//                     data={product}
//                   />
//                 </center>

//                 <div className="card-content text-danger">
//                   <WishlistButton type="icon" data={product} />
//                   <div className="add-to-card">
//                     <ProductOveriew product={product} />
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       ) : (
//         <Loader />
//       )}
//     </div>
//   );
// }

// export default ToolsProductIdProvider(ViewAllToolsProducts);



import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import AddCartButton from "../Buttons/AddCartButton";
import WishlistButton from "../Buttons/WishlistButton";
import ProductOveriew from "../ProductOveriew/ProductOveriew";
import ProductImages from "../Products/ProductImages/ProductImages";
import { IoCaretBack } from "react-icons/io5";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import ToolsProductIdProvider from "../hoc/ToolsProductIdProvider";
import Lottie from "lottie-react";
import NoProductFound from "../../Preloader/No product Found Animation.json";
import Preloader from "../../Preloader/Preloader-Animation.json";

function ViewAllToolsProducts({ categoryId, categoryName }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (categoryId) {
          const response = await axios.get(`${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`);
          setData(response?.data?.products);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [categoryId]);



  const replaceSpacesWithHyphens = (urlString) => {

    if (typeof urlString === 'string') {
      // Replace all spaces with hyphens
      return urlString.replace(/ /g, '-').toLocaleLowerCase();
    }else return urlString;
}


  return (
    <div className="products-wrapper px-2r py-4">
      <center className="products-heading-container">
        <h4 className="product-heading">Tools</h4>
        <Link to="/" className="view-all-products">
          <IoCaretBack className="fs-5" />
          Go Back
        </Link>
      </center>

      {loading ? (
        <Lottie
          style={{ width: "100vw", height: "50vh" }}
          animationData={Preloader}
        />
      ) : (
        data?.length !== 0 ? (
          <div className="products m-0">
            {data.map((product) => (
              <div className="card" key={product?._id}>
                <center>
                  <Card.Title className="px-2 text-dark card-title">
                    <Link
                      className="text-dark text-decoration-none "
                      // to={`/product/${product?._id}`}
                      // to={`/product/${product?.partNumber}`}
                      // to={`/product/${product?.slug}`}
                      to={
                        product?.descriptions[0]?.value !== undefined
                          ? `/${product?.category?.name}/${replaceSpacesWithHyphens(product?.subcategoryname?.name)}/${replaceSpacesWithHyphens(product?.descriptions[0]?.value)}/${product?.slug}`
                          : `/product/${product?.slug}`
                      }
                    >
                      {product?.partNumber}
                    </Link>
                  </Card.Title>
                </center>
                <Link
                  className="text-dark text-decoration-none "
                  // to={`/product/${product?._id}`}
                  // to={`/product/${product?.partNumber}`}
                  // to={`/product/${product?.slug}`}
                  to={
                    product?.descriptions[0]?.value !== undefined
                      ? `/${product?.category?.name}/${replaceSpacesWithHyphens(product?.subcategoryname?.name)}/${replaceSpacesWithHyphens(product?.descriptions[0]?.value)}/${product?.slug}`
                      : `/product/${product?.slug}`
                  }
                >
                  <ProductImages productimages={product?.productimages} />
                </Link>
                <center className="cart-footer">
                  <div className="card-price">
                    <h6 className="m-0 current-price">₹ {product?.saleprice}</h6>
                    <p className="m-0 old-price">₹ {product?.oldprice}</p>
                  </div>
                  <AddCartButton
                    type="product_button"
                    className="card-add-cart-button"
                    data={product}
                  />
                </center>
                <div className="card-content text-danger">
                  <WishlistButton type="icon" data={product} />
                  <div className="add-to-card">
                    <ProductOveriew product={product} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Lottie
            style={{ width: "100vw", height: "50vh" }}
            animationData={NoProductFound}
          />
        )
      )}
    </div>
  );
}

export default ToolsProductIdProvider(ViewAllToolsProducts);
