// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { FaCircle, FaSearch } from "react-icons/fa";
// import { Button, FormControl, InputGroup } from "react-bootstrap";
// import { API_BASE_URL } from "../../../utils";
// import { useAuthHeaders } from "../../../components/Token";
// import "../Profile.scss"
// import Swal from 'sweetalert2';
// const ITEMS_PER_PAGE = 5;

// const OrderHistory = () => {
//   const [orders, setOrders] = useState([]);
//   const [filteredOrders, setFilteredOrders] = useState([]);
//   const [selectedOrderId, setSelectedOrderId] = useState(null);
//   const [showUpdateModal, setShowUpdateModal] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [search, setSearch] = useState("");
//   const [totalOrders, setTotalOrders] = useState(0);
//   const [canceledOrders, setCanceledOrders] = useState([]);

//   const isLoggedIn = localStorage.getItem("userId");
//   const headers = useAuthHeaders();

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const response = await axios.get(`${API_BASE_URL}/user/order/myOrder`, {
//           headers,
//         });
//         const ordersData = response?.data?.orders || [];
//         setOrders(ordersData);
//         setFilteredOrders(ordersData); // Set filtered orders initially
//         setTotalOrders(ordersData.length);
//       } catch (error) {
//         console.error("Error fetching orders:", error);
//       }
//     };

//     if (isLoggedIn) {
//       fetchOrders();
//     }
//   }, [isLoggedIn, headers]);

//   const handleToCancel = (orderId) => {
//     Swal.fire({
//       title: 'Are you sure?',
//       text: "You won't be able to revert this!",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, cancel it!'
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         try {
//           const response = await axios.put(
//             `${API_BASE_URL}/user/order/cancelorder/${orderId}`,
//             null,
//             { headers }
//           );
//           console.log(response.data);
//           setCanceledOrders(prevCanceledOrders => [...prevCanceledOrders, orderId]); // Add canceled order ID
//           setOrders(prevOrders =>
//             prevOrders.map(order =>
//               order._id === orderId ? { ...order, orderStatus: 'cancel' } : order
//             )
//           );
//           setFilteredOrders(prevFilteredOrders =>
//             prevFilteredOrders.map(order =>
//               order._id === orderId ? { ...order, orderStatus: 'cancel' } : order
//             )
//           );
//           Swal.fire(
//             'Canceled!',
//             'Your order has been canceled.',
//             'success'
//           );
//         } catch (error) {
//           console.error("Error canceling order:", error);
//           Swal.fire(
//             'Error!',
//             'There was an error canceling your order.',
//             'error'
//           );
//         }
//       }
//     });
//   };

//   const getStatusStyle = (orderStatus) => {
//     switch (orderStatus) {
//       case "Payment Pending":
//         return { color: "#F3A638" };
//       case "Payment success":
//         return { color: "#4CB64C" };
//       case "processing":
//         return { color: "#54B7D3" };
//       case "shipped":
//         return { color: "#1E91CF" };
//       case "delivered":
//         return { color: "#4CB64C" };
//       case "cancel":
//         return { color: "#E3503E" };
//       default:
//         return {};
//     }
//   };

//   const formatDate = (dateString) => {
//     return new Date(dateString).toLocaleDateString("en-US", {
//       year: "numeric",
//       month: "short",
//       day: "numeric",
//     });
//   };

//   const handleUpdateClick = (orderId) => {
//     setSelectedOrderId(orderId);
//     setShowUpdateModal(true);
//   };

//   const handlePageChange = (newPage) => {
//     if (newPage >= 1 && newPage <= Math.ceil(totalOrders / ITEMS_PER_PAGE)) {
//       setCurrentPage(newPage);
//     }
//   };

//   const handleSearchChange = (event) => {
//     setSearch(event.target.value);
//     const searchQuery = event.target.value.toLowerCase();
//     const filtered = orders.filter((order) =>
//       order.orderId.toLowerCase().includes(searchQuery)
//     );
//     setFilteredOrders(filtered);
//     setTotalOrders(filtered.length);
//     setCurrentPage(1);
//   };

//   const indexOfLastOrder = currentPage * ITEMS_PER_PAGE;
//   const indexOfFirstOrder = indexOfLastOrder - ITEMS_PER_PAGE;
//   const currentOrders = filteredOrders.slice(
//     indexOfFirstOrder,
//     indexOfLastOrder
//   );
//   const totalPages = Math.ceil(filteredOrders.length / ITEMS_PER_PAGE);

//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div className="col">
//           <div className="d-flex mt-4 mb-4">
//             <h2>History</h2>
//             <div className="ms-auto d-flex bg-light" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//               <input
//                 type="search"
//                 placeholder="Search by Order ID"
//                 value={search}
//                 onChange={handleSearchChange}
//                 className="form-control border border-0"
//               />
//               <InputGroup.Text className="bg-white border border-0">
//                 <FaSearch />
//               </InputGroup.Text>
//             </div>
//           </div>
//           <div className="table-responsive">
//             <table className="table table-striped">
//               <thead style={{ border: "1px solid" }}>
//                 <tr className="border border-1">
//                   <th scope="col" className="border border-1">
//                     S.No
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Order ID
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Part No
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Address
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Quantity
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Amount
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Status
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Action
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {currentOrders.length > 0 ? (
//                   currentOrders.map((order, index) => (
//                     <tr key={order?._id}>
//                       <td>{indexOfFirstOrder + index + 1}</td>
//                       <td>{order?.orderId}</td>
//                       <td
//                         style={{
//                           cursor: "pointer",
//                           color: "#007BFF",
//                         }}
//                       >
//                         <Link
//                           to={`/newProductsDetials/${order?.orderItems[0]?.productId}`}
//                           style={{ textDecoration: "none" }}
//                         >
//                           {order?.orderItems[0]?.productId?.partNumber}
//                         </Link>
//                       </td>
//                       <td>
//                         {order?.alternateAddress?.alternateStreetAddress ||
//                           order?.shippingInfo?.streetaddress}
//                       </td>
//                       <td>{order?.orderItems[0]?.quantity}</td>
//                       <td>{order?.orderTotal}</td>
//                       <td>
//                         <FaCircle style={getStatusStyle(order?.orderStatus)} />{" "}
//                         {order?.orderStatus}
//                       </td>
//                       <td>
//                         <Button
//                           className="btn btn-danger btn-sm"
//                           onClick={() => handleToCancel(order._id)}
//                           disabled={canceledOrders.includes(order._id) || order.orderStatus === 'cancel'}
//                         >
//                           Cancel
//                         </Button>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="8" className="text-center text-danger">
//                       No orders found
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//           <div className="">
//             <ul className="pagination justify-content-center ">
//               <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
//                 <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
//                   Previous
//                 </button>
//               </li>
//               {[...Array(totalPages)].map((_, index) => (
//                 <li
//                   key={index}
//                   className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
//                 >
//                   <button className="page-link" onClick={() => handlePageChange(index + 1)}>
//                     {index + 1}
//                   </button>
//                 </li>
//               ))}
//               <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
//                 <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
//                   Next
//                 </button>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OrderHistory;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { FaCircle, FaSearch } from "react-icons/fa";
// import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
// import { API_BASE_URL } from "../../../utils";
// import { useAuthHeaders } from "../../../components/Token";
// import Swal from 'sweetalert2';
// import "../Profile.scss"
// import OrderDetails6 from "./OrderDetails6"; // Import the OrderDetails6 component

// const ITEMS_PER_PAGE = 5;

// const OrderHistory = () => {
//   const [orders, setOrders] = useState([]);
//   const [filteredOrders, setFilteredOrders] = useState([]);
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   // const [showUpdateModal, setShowTrackModal] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [search, setSearch] = useState("");
//   const [totalOrders, setTotalOrders] = useState(0);
//   const [canceledOrders, setCanceledOrders] = useState([]);
//   const [showTrackModal, setShowTrackModal] = useState(false); // State for track modal

//   const isLoggedIn = localStorage.getItem("userId");
//   const headers = useAuthHeaders();

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const response = await axios.get(`${API_BASE_URL}/user/order/myOrder`, {
//           headers,
//         });
//         const ordersData = response?.data?.orders || [];
//         setOrders(ordersData);
//         setFilteredOrders(ordersData); // Set filtered orders initially
//         setTotalOrders(ordersData.length);
//       } catch (error) {
//         console.error("Error fetching orders:", error);
//       }
//     };

//     if (isLoggedIn) {
//       fetchOrders();
//     }
//   }, [isLoggedIn, headers]);

//   const handleToCancel = (orderId) => {
//     Swal.fire({
//       title: 'Are you sure?',
//       text: "You won't be able to revert this!",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, cancel it!'
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         try {
//           const response = await axios.put(
//             `${API_BASE_URL}/user/order/cancelorder/${orderId}`,
//             null,
//             { headers }
//           );
//           console.log(response.data);
//           setCanceledOrders(prevCanceledOrders => [...prevCanceledOrders, orderId]); // Add canceled order ID
//           setOrders(prevOrders =>
//             prevOrders.map(order =>
//               order._id === orderId ? { ...order, orderStatus: 'cancel' } : order
//             )
//           );
//           setFilteredOrders(prevFilteredOrders =>
//             prevFilteredOrders.map(order =>
//               order._id === orderId ? { ...order, orderStatus: 'cancel' } : order
//             )
//           );
//           Swal.fire(
//             'Canceled!',
//             'Your order has been canceled.',
//             'success'
//           );
//         } catch (error) {
//           console.error("Error canceling order:", error);
//           Swal.fire(
//             'Error!',
//             'There was an error canceling your order.',
//             'error'
//           );
//         }
//       }
//     });
//   };

//   const getStatusStyle = (orderStatus) => {
//     switch (orderStatus) {
//       case "Payment Pending":
//         return { color: "#F3A638" };
//       case "Payment success":
//         return { color: "#4CB64C" };
//       case "processing":
//         return { color: "#54B7D3" };
//       case "shipped":
//         return { color: "#1E91CF" };
//       case "delivered":
//         return { color: "#4CB64C" };
//       case "cancel":
//         return { color: "#E3503E" };
//       default:
//         return {};
//     }
//   };

//   const formatDate = (dateString) => {
//     return new Date(dateString).toLocaleDateString("en-US", {
//       year: "numeric",
//       month: "short",
//       day: "numeric",
//     });
//   };
//   const handleTrackClick = (order) => {
//     setSelectedOrder(order);
//     setShowTrackModal(true);
//   };

//   const handlePageChange = (newPage) => {
//     if (newPage >= 1 && newPage <= Math.ceil(totalOrders / ITEMS_PER_PAGE)) {
//       setCurrentPage(newPage);
//     }
//   };

//   const handleSearchChange = (event) => {
//     setSearch(event.target.value);
//     const searchQuery = event.target.value.toLowerCase();
//     const filtered = orders.filter((order) =>
//       order.orderId.toLowerCase().includes(searchQuery)
//     );
//     setFilteredOrders(filtered);
//     setTotalOrders(filtered.length);
//     setCurrentPage(1);
//   };

//   const handleTrackModalClose = () => {
//     setShowTrackModal(false);
//     setSelectedOrder(null);
//   };

//   const indexOfLastOrder = currentPage * ITEMS_PER_PAGE;
//   const indexOfFirstOrder = indexOfLastOrder - ITEMS_PER_PAGE;
//   const currentOrders = filteredOrders.slice(
//     indexOfFirstOrder,
//     indexOfLastOrder
//   );
//   const totalPages = Math.ceil(filteredOrders.length / ITEMS_PER_PAGE);

//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div className="col">
//           <div className="d-flex mt-4 mb-4">
//             <h2>History</h2>
//             <div className="ms-auto d-flex bg-light" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
//               <input
//                 type="search"
//                 placeholder="Search by Order ID"
//                 value={search}
//                 onChange={handleSearchChange}
//                 className="form-control border border-0"
//               />
//               <InputGroup.Text className="bg-white border border-0">
//                 <FaSearch />
//               </InputGroup.Text>
//             </div>
//           </div>
//           <div className="table-responsive">
//             <table className="table table-striped">
//               <thead style={{ border: "1px solid" }}>
//                 <tr className="border border-1">
//                   <th scope="col" className="border border-1">
//                     S.No
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Order ID
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Part No
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Address
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Quantity
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Amount
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Status
//                   </th>
//                   <th scope="col" className="border border-1">
//                     Action
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {currentOrders.length > 0 ? (
//                   currentOrders.map((order, index) => (
//                     <tr key={order?._id}>
//                       <td>{indexOfFirstOrder + index + 1}</td>
//                       <td>{order?.orderId}</td>
//                       <td
//                         style={{
//                           cursor: "pointer",
//                           color: "#007BFF",
//                         }}
//                       >
//                         <Link
//                           to={`/newProductsDetials/${order?.orderItems[0]?.productId}`}
//                           style={{ textDecoration: "none" }}
//                         >
//                           {order?.orderItems[0]?.productId?.partNumber}
//                         </Link>
//                       </td>
//                       <td>
//                         {order?.alternateAddress?.alternateStreetAddress ||
//                           order?.shippingInfo?.streetaddress}
//                       </td>
//                       <td>{order?.orderItems[0]?.quantity}</td>
//                       <td>{order?.orderTotal}</td>
//                       <td>
//                         <FaCircle style={getStatusStyle(order?.orderStatus)} />{" "}
//                         {order?.orderStatus}
//                       </td>
//                       <td>
//                         <Button
//                           className="btn btn-danger btn-sm me-2"
//                           onClick={() => handleToCancel(order._id)}
//                           disabled={canceledOrders.includes(order._id) || order.orderStatus === 'cancel'}
//                         >
//                           Cancel
//                         </Button>

//                         <Button
//                           className="btn btn-primary btn-sm"
//                           onClick={() => handleTrackClick(order._id)}
//                           disabled={order.orderStatus === "cancel"}
//                         >
//                           Track
//                         </Button>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="8" className="text-center">
//                       No orders found.
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//             <div className="d-flex justify-content-between align-items-center">
//               <div>
//                 Showing {indexOfFirstOrder + 1} to{" "}
//                 {Math.min(indexOfLastOrder, filteredOrders.length)} of{" "}
//                 {totalOrders} entries
//               </div>
//               <div>
//                 <ul className="pagination mb-0">
//                   <li
//                     className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
//                   >
//                     <button
//                       className="page-link"
//                       onClick={() => handlePageChange(currentPage - 1)}
//                     >
//                       Previous
//                     </button>
//                   </li>
//                   {Array.from({ length: totalPages }, (_, index) => (
//                     <li
//                       key={index}
//                       className={`page-item ${currentPage === index + 1 ? "active" : ""
//                         }`}
//                     >
//                       <button
//                         className="page-link"
//                         onClick={() => handlePageChange(index + 1)}
//                       >
//                         {index + 1}
//                       </button>
//                     </li>
//                   ))}
//                   <li
//                     className={`page-item ${currentPage === totalPages ? "disabled" : ""
//                       }`}
//                   >
//                     <button
//                       className="page-link"
//                       onClick={() => handlePageChange(currentPage + 1)}
//                     >
//                       Next
//                     </button>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Track Order Modal */}
//       <Modal show={showTrackModal} onHide={handleTrackModalClose}  size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>Order Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {selectedOrder && (
//             <OrderDetails6
//               orderId={selectedOrder.orderId}
//               status={selectedOrder.orderStatus}
//             />
//           )}

//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleTrackModalClose}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default OrderHistory;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaCircle, FaSearch } from "react-icons/fa";
import { Button, InputGroup, Modal } from "react-bootstrap";
import { API_BASE_URL } from "../../../utils";
import { useAuthHeaders } from "../../../components/Token";
import Swal from "sweetalert2";
import "../Profile.scss";
import OrderDetails6 from "./OrderDetails6"; // Import the OrderDetails6 component

const ITEMS_PER_PAGE = 5;

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalOrders, setTotalOrders] = useState(0);
  const [canceledOrders, setCanceledOrders] = useState([]);
  const [showTrackModal, setShowTrackModal] = useState(false); // State for track modal

  const isLoggedIn = localStorage.getItem("userId");
  const headers = useAuthHeaders();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const header = {
          Authorization: `Bearer ${userId}`,
          "Content-Type": "application/json",
        };
        const response = await axios.get(`${API_BASE_URL}/user/order/myOrder`, {
          headers: header,
        });
        // console.log(response,"orderpage")
        const ordersData = response?.data?.orders || [];
        setOrders(ordersData);
        setFilteredOrders(ordersData); // Set filtered orders initially
        setTotalOrders(ordersData.length);

      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    if (isLoggedIn) {
      fetchOrders();
    }
  }, [isLoggedIn, headers]);

  const handleToCancel = (orderId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.put(
            `${API_BASE_URL}/user/order/cancelorder/${orderId}`,
            null,
            { headers:headers }
          );
          // console.log(response.data);
          setCanceledOrders((prevCanceledOrders) => [
            ...prevCanceledOrders,
            orderId,
          ]); // Add canceled order ID
          setOrders((prevOrders) =>
            prevOrders.map((order) =>
              order._id === orderId
                ? { ...order, orderStatus: "cancel" }
                : order
            )
          );
          setFilteredOrders((prevFilteredOrders) =>
            prevFilteredOrders.map((order) =>
              order._id === orderId
                ? { ...order, orderStatus: "cancel" }
                : order
            )
          );
          Swal.fire("Canceled!", "Your order has been canceled.", "success");
        } catch (error) {
          console.error("Error canceling order:", error);
          Swal.fire(
            "Error!",
            "There was an error canceling your order.",
            "error"
          );
        }
      }
    });
  };

  const getStatusStyle = (orderStatus) => {
    switch (orderStatus) {
      case "Payment Pending":
        return { color: "#F3A638" };
      case "Payment success":
        return { color: "#4CB64C" };
      case "processing":
        return { color: "#54B7D3" };
      case "Shipped":
        return { color: "#1E91CF" };
      case "Delivered":
        return { color: "#4CB64C" };
      case "cancel":
        return { color: "#E3503E" };
      default:
        return {};
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
 
  const replaceHyphensWithSpaces = (urlString) => {
    if (typeof urlString === 'string') {
      return urlString.replace(/-/g, ' ');
    }else
    return urlString
  };
  
  const replaceSpacesWithHyphens = (urlString) => {
    if (typeof urlString === "string") {
      // Replace all spaces with hyphens
      return urlString.replace(/ /g, "-").toLocaleLowerCase();
    } else return urlString;
  };


  const handleTrackClick = (order) => {
    setSelectedOrder(order);
    setShowTrackModal(true);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalOrders / ITEMS_PER_PAGE)) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    const searchQuery = event.target.value.toLowerCase();
    const filtered = orders.filter((order) =>
      order.orderId.toLowerCase().includes(searchQuery)
    );
    setFilteredOrders(filtered);
    setTotalOrders(filtered.length);
    setCurrentPage(1);
  };

  const handleTrackModalClose = () => {
    setShowTrackModal(false);
    setSelectedOrder(null);
  };

  const indexOfLastOrder = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstOrder = indexOfLastOrder - ITEMS_PER_PAGE;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );
  const totalPages = Math.ceil(filteredOrders.length / ITEMS_PER_PAGE);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="d-flex mt-4 mb-4 fcffc">
            <h2>History</h2>
            <div
              className="ms-auto d-flex bg-light"
              style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
            >
              <input
                type="search"
                placeholder="Search by Order ID"
                value={search}
                onChange={handleSearchChange}
                className="form-control border border-0"
              />
              <InputGroup.Text className="bg-white border border-0">
                <FaSearch />
              </InputGroup.Text>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead style={{ border: "1px solid" }}>
                <tr className="border border-1">
                  <th scope="col" className="border border-1">
                    S.No
                  </th>
                  <th scope="col" className="border border-1">
                    Order ID
                  </th>
                  <th scope="col" className="border border-1">
                    Part No
                  </th>
                  <th scope="col" className="border border-1">
                    Address
                  </th>
                  <th scope="col" className="border border-1">
                    Quantity
                  </th>
                  <th scope="col" className="border border-1">
                    Amount
                  </th>
                  <th scope="col" className="border border-1">
                    Status
                  </th>
                  <th scope="col" className="border border-1">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentOrders.length > 0 ? (
                  currentOrders.map((order, index) => (
                    <tr key={order?._id}>
                      <td>{indexOfFirstOrder + index + 1}</td>
                      <td>{order?.orderId}</td>
                      <td
                        style={{
                          cursor: "pointer",
                          color: "#007BFF",
                        }}
                      >
                        {order?.orderItems[0]?.productId?.partNumber ? (
                         <Link
                         to={`/${replaceSpacesWithHyphens(order?.orderItems[0]?.category)}/${replaceSpacesWithHyphens(order?.orderItems[0]?.subcategory)}/${replaceSpacesWithHyphens(order?.orderItems[0]?.description[0]?.value)}/${order?.orderItems[0]?.productId?.slug}`}
                         style={{ textDecoration: 'none' }}
                         >
                       
                         {order?.orderItems[0]?.productId?.partNumber}
                       </Link>
                        ) : (
                          "Part Number Not Available"
                        )}
                      </td>
                      <td>
                        {order?.alternateAddress?.alternateStreetAddress ||
                          order?.shippingInfo?.streetaddress}
                      </td>
                      <td>{order?.orderItems[0]?.quantity}</td>
                      <td>{order?.orderTotal}</td>
                      <td>
                        <FaCircle style={getStatusStyle(order?.orderStatus)} />{" "}
                        {order?.orderStatus}
                      </td>
                      <td>
                        <Button
                          className="btn btn-danger btn-sm me-2"
                          onClick={() => handleToCancel(order._id)}
                          disabled={
                            canceledOrders.includes(order._id) ||
                            order.orderStatus === "cancel" ||
                            order.orderStatus === "Delivered"
                          }
                        >
                          Cancel
                        </Button>

                        <Button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleTrackClick(order)}
                          disabled={order.orderStatus === "cancel"}
                        >
                          Track
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No orders found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                Showing {indexOfFirstOrder + 1} to{" "}
                {Math.min(indexOfLastOrder, filteredOrders.length)} of{" "}
                {totalOrders} entries
              </div>
              <div>
                <ul className="pagination mb-0">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Track Order Modal */}
      <Modal show={showTrackModal} onHide={handleTrackModalClose} size="lg">
        <Modal.Header closeButton>
          {/* <Modal.Title></Modal.Title> */}
        </Modal.Header>
        <h4 className="text-center  justify-content-center m-auto text-uppercase">
          {" "}
          Order Tracking{" "}
        </h4>
        <Modal.Body style={{ background: "none" }} className="border border-0">
          {selectedOrder && <OrderDetails6 order={selectedOrder} />}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrderHistory;
