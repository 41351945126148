import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import {
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { API_BASE_URL } from "../utils/index";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./InquiryPage.scss";
import axios from "axios";
import { Helmet } from "react-helmet";

const InquiryForm = () => {
  const [isLoading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      // .email("Invalid email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid email address"
      )
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      companyName: "",
      mobile: "",
      subject: "",
      message: "",
      preferredMethodToContact: "Email",
      enqiryfile: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      await sendToBackend(values);
    },
  });

  const convertFileToBlob = (file) => {
    return new Blob([file], { type: file?.type });
  };

  // const sendToBackend = async (datas) => {
  //   if (isLoading) {
  //     return;
  //   }

  //   setLoading(true);
  //   console.log("data is come or not checking", datas);
  //   try {
  //     const formData = new FormData();
  //     formData.append("name", datas?.name);
  //     formData.append("email", datas?.email);
  //     formData.append("companyName", datas?.companyName);
  //     formData.append("mobile", datas?.mobile);
  //     formData.append("subject", datas?.subject);
  //     formData.append("message", datas?.message);
  //     formData.append(
  //       "preferredMethodToContact",
  //       datas?.preferredMethodToContact
  //     );
  //     if (datas?.enqiryfile) {
  //       const fileBlob = convertFileToBlob(datas?.enqiryfile);
  //       formData.append("enqiryfile", fileBlob, datas?.enqiryfile?.name);
  //     }

  //     if (localStorage.getItem("userId")) {
  //       const userId = localStorage.getItem("userId");
  //       const headers = {
  //         Authorization: `Bearer ${userId}`,
  //       };

  //       axios
  //         .get(`${API_BASE_URL}/myprofile`, { headers: headers })
  //         .then((response) => {
  //           console.log("user Id is the ---->", response.data?.user?._id);
  //           formData.append("user", response.data?.user?._id);
  //         })
  //         .catch((err) => {
  //           console.log(`${err}`);
  //         });
  //     }

  //     const response = await axios.post(
  //       `${API_BASE_URL}/user/enquiry`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     console.log("Backend response:", response.data);
  //     console.log("Backend response message:", response?.data?.data?.message);

  //     Swal.fire({
  //       icon: "success",
  //       title: "Form Submitted successfully!",
  //       text: response.data.message,
  //       showConfirmButton: false,
  //       timer: 5000,
  //     });
  //   } catch (error) {
  //     // console.error("Error sending data to backend:", error);
  //     // console.error("Error sending data to backend:", error.response);

  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: error.response.data.message,
  //       timer: 3000,
  //     });
  //   } finally {
  //     // Any cleanup or final steps can be done here
  //     formik.resetForm();
  //     setLoading(false); // Reset loading state after the API call is complete
  //   }
  // };

  const sendToBackend = async (datas) => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    // console.log("data is come or not checking", datas);

    try {
      const formData = new FormData();
      formData.append("name", datas?.name);
      formData.append("email", datas?.email);
      formData.append("companyName", datas?.companyName);
      formData.append("mobile", datas?.mobile);
      formData.append("subject", datas?.subject);
      formData.append("message", datas?.message);
      formData.append(
        "preferredMethodToContact",
        datas?.preferredMethodToContact
      );

      if (datas?.enqiryfile) {
        const fileBlob = convertFileToBlob(datas?.enqiryfile);
        formData.append("enqiryfile", fileBlob, datas?.enqiryfile?.name);
      }

      if (localStorage.getItem("userId")) {
        const userId = localStorage.getItem("userId");
        const headers = {
          Authorization: `Bearer ${userId}`,
        };

        const response = await axios.get(`${API_BASE_URL}/myprofile`, {
          headers: headers,
        });
        // console.log("user Id is the ---->", response.data?.user?._id);
        formData.append("user", response.data?.user?._id);
      }

      const response = await axios.post(
        `${API_BASE_URL}/user/enquiry`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // console.log("Backend response:", response.data);
      // console.log("Backend response message:", response?.data?.data?.message);

      Swal.fire({
        icon: "success",
        title: "Form Submitted successfully!",
        text: response.data.message,
        showConfirmButton: false,
        timer: 5000,
      });
    } catch (error) {
      console.error("Error sending data to backend:", error);
      console.error("Error sending data to backend:", error.response);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
        timer: 3000,
      });
    } finally {
      // Any cleanup or final steps can be done here
      formik.resetForm();
      setLoading(false); // Reset loading state after the API call is complete
    }
  };

  return (
    <section className="inquiry-section">
      <Helmet>
        <title> Nut Bolt Manufacturer in India </title>
        <meta
          name="description"
          content="Best India Kart is a trusted nut and bolt manufacturer in India, offering high-quality, durable fastening solutions tailored to meet the demands of various industries."
        />
        {/* <meta name="keywords" content="Jobs, Service,Automobiles,Store, Education, Travels, Event"/> */}
      </Helmet>
      <Container className="inquiry-page-container">
        <Form onSubmit={formik.handleSubmit} className="inquiry-page-form">
          <h1 className="about-heading ">Inquiry Form</h1>
          <p>
            Please fill out the form below with your details and the nature of
            your inquiry. Our team will get back to you as soon as possible.
          </p>

          <h3>Contact Information:</h3>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formName">
                <Form.Label>Name:</Form.Label>
                <TextField
                  {...formik.getFieldProps("name")}
                  placeholder="Enter your name"
                  fullWidth
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formCompanyName">
                <Form.Label>Company Name (if applicable):</Form.Label>
                <TextField
                  {...formik.getFieldProps("companyName")}
                  placeholder="Enter your company name"
                  fullWidth
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email Address:</Form.Label>
                <TextField
                  {...formik.getFieldProps("email")}
                  type="email"
                  placeholder="Enter your email address"
                  fullWidth
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone Number:</Form.Label>
                <TextField
                  {...formik.getFieldProps("mobile")}
                  type="tel"
                  placeholder="Enter your mobile number"
                  fullWidth
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* <h3 style={{ marginTop: "15px" }}>Inquiry Details:</h3>
          <Row>
            <Col md={12}>
              <Form.Group controlId="formSubject">
                <Form.Label>Subject:</Form.Label>
                <TextField
                  {...formik.getFieldProps("subject")}
                  select
                  fullWidth
                  error={
                    formik.touched.subject && Boolean(formik.errors.subject)
                  }
                  helperText={formik.touched.subject && formik.errors.subject}
                >
                  <MenuItem value="" disabled>
                    Select the subject
                  </MenuItem>

                  <MenuItem value="Product Availability Inquiry">
                    Product Availability Inquiry
                  </MenuItem>
                  <MenuItem value="Request for Quote">
                    Request for Quote
                  </MenuItem>
                  <MenuItem value="Technical Support Request">
                    Technical Support Request
                  </MenuItem>
                  <MenuItem value="Order Status Inquiry">
                    Order Status Inquiry
                  </MenuItem>
                  <MenuItem value="Product Customization Request">
                    Product Customization Request
                  </MenuItem>
                  <MenuItem value="Partnership Opportunities">
                    Partnership Opportunities
                  </MenuItem>
                  <MenuItem value="Shipping and Delivery Information">
                    Shipping and Delivery Information
                  </MenuItem>
                  <MenuItem value="Return and Exchange Inquiry">
                    Return and Exchange Inquiry
                  </MenuItem>
                  <MenuItem value="Billing and Payment Issues">
                    Billing and Payment Issues
                  </MenuItem>
                  <MenuItem value="General Information Request">
                    General Information Request
                  </MenuItem>
                  <MenuItem value="others">Others</MenuItem>
                </TextField>
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col md={12}>
              <Form.Group controlId="formSubject">
                <Form.Label>Subject:</Form.Label>
                <TextField
                  {...formik.getFieldProps("subject")}
                  select
                  fullWidth
                  disabled={isLoading}
                  error={
                    formik.touched.subject && Boolean(formik.errors.subject)
                  }
                  helperText={formik.touched.subject && formik.errors.subject}
                  id="select-subject"
                >
                  <MenuItem value="" disabled>
                    Select the subject
                  </MenuItem>
                  <MenuItem value="Product Availability Inquiry">
                    Product Availability Inquiry
                  </MenuItem>
                  <MenuItem value="Request for Quote">
                    Request for Quote
                  </MenuItem>
                  <MenuItem value="Technical Support Request">
                    Technical Support Request
                  </MenuItem>
                  <MenuItem value="Order Status Inquiry">
                    Order Status Inquiry
                  </MenuItem>
                  <MenuItem value="Product Customization Request">
                    Product Customization Request
                  </MenuItem>
                  <MenuItem value="Partnership Opportunities">
                    Partnership Opportunities
                  </MenuItem>
                  <MenuItem value="Shipping and Delivery Information">
                    Shipping and Delivery Information
                  </MenuItem>
                  <MenuItem value="Return and Exchange Inquiry">
                    Return and Exchange Inquiry
                  </MenuItem>
                  <MenuItem value="Billing and Payment Issues">
                    Billing and Payment Issues
                  </MenuItem>
                  <MenuItem value="General Information Request">
                    General Information Request
                  </MenuItem>
                  <MenuItem value="others">Others</MenuItem>
                </TextField>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="formMessage">
                <Form.Label>Message:</Form.Label>
                <TextareaAutosize
                  {...formik.getFieldProps("message")}
                  placeholder="Enter your message or specific inquiry details"
                  minRows={4}
                  style={{
                    width: "100%",
                    paddingLeft: "15px",
                    paddingTop: "15px",
                  }}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Form.Group>
            </Col>
          </Row>

          <h3>Preferred Contact Method:</h3>
          <Row>
            <Col md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Preferred Contact Method:
                </FormLabel>
                <RadioGroup
                  row
                  {...formik.getFieldProps("preferredMethodToContact")}
                >
                  <FormControlLabel
                    value="Email"
                    control={<Radio />}
                    label="Email"
                  />
                  <FormControlLabel
                    value="Phone"
                    control={<Radio />}
                    label="Phone"
                  />
                </RadioGroup>
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group controlId="formenqiryfile">
                <Form.Label>Attachment (if any):</Form.Label>
                <input
                  id="enqiryfile"
                  name="enqiryfile"
                  type="file"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "enqiryfile",
                      event.currentTarget.files[0]
                    );
                  }}
                  className="form-control"
                />
                {formik.errors.enqiryfile && formik.touched.enqiryfile && (
                  <div className="error">{formik.errors.enqiryfile}</div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Button
            variant="primary"
            type="submit"
            className="inquiry-form-submit-button"
            disabled={isLoading}
          >
            {isLoading ? "Loading…" : "Submit"}
          </Button>
        </Form>
      </Container>
    </section>
  );
};

export default InquiryForm;
