// import React, { useEffect, useState } from "react";
// import { API_BASE_URL } from "../../../utils";
// import axios from "axios";

// function OverAllProductIdProvider(WrappedComponent) {
//   return function WithHOC(props) {
//     const [category, setCategory] = useState([]);
//     const categoryId = category[0]?._id;
//     const categoryName = category[0]?.name;

//     useEffect(() => {
//       axios
//         .get(`${API_BASE_URL}/category/user/getcategory`)
//         .then((response) => {
//           setCategory(response?.data?.categories || []);
//           console.log("================>",response.data)
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//         });
//     }, []);

//     return categoryId ? (
//       <>
//         {category.map((category, index) => {
//           <WrappedComponent
//             key={index}
//             {...props}
//             categoryId={category?._id}
//             categoryName={category?.name}
//           />;
//         })}
//       </>
//     ) : null;
//   };
// }

// export default OverAllProductIdProvider;


import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../utils";
import axios from "axios";

function OverAllProductIdProvider(WrappedComponent) {
  return function WithHOC(props) {
    const [category, setCategory] = useState([]);
    const categoryId = category[0]?._id;
    const categoryName = category[0]?.name;

    useEffect(() => {
      axios
        .get(`${API_BASE_URL}/category/user/getcategory`)
        .then((response) => {
          setCategory(response?.data?.categories || []);
          // console.log("================>", response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, []);

    return categoryId ? (
      <>
        {category.map((category, index) => (
          <WrappedComponent
            key={index}
            {...props}
            categoryId={category?._id}
            categoryName={category?.name}
          />
        ))}
      </>
    ) : null;
  };
}

export default OverAllProductIdProvider;
