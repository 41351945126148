import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { API_BASE_URL } from "../../utils";
import { Link } from "react-router-dom";
import ProductImages from "../Products/ProductImages/ProductImages";
import TimeProgressBar from "../Products/TimeProgressBar";
import DealCountdown from "../Products/DealCountdown";
import Slider from "react-slick";
import "../Products/DealOfTheDayProducts/dealOfTheDayProducts.scss";
import ToolsProductIdProvider from "../hoc/ToolsProductIdProvider";

function DealOfDayToolsProducts({ categoryName }) {
  const [data, setData] = useState([]);
  const categoryId =categoryName

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/user/product/getdealoftheday/${categoryId}`)
      .then((res) => {
        // console.log("res deal of", res.data.products);
        setData(res?.data?.products);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const replaceSpacesWithHyphens = (urlString) => {

    if (typeof urlString === 'string') {
      // Replace all spaces with hyphens
      return urlString.replace(/ /g, '-').toLocaleLowerCase();
    }else return urlString;
}
  return (
    <div className="deal-of-day-container px-2r py-4">
      {data && data?.length !== 0 && (
        <center>
          <h4 className="deal-of-day-title">Deal of the Day</h4>
        </center>
      )}

      <Slider {...settings}>
        {data.map((item, i) => (
          <Link
            // to={`/product/${item?._id}`}
            // to={`/product/${item?.partNumber}`}
            // to={`/product/${item?.slug}`}

            to={
              item?.descriptions[0]?.value !== undefined
                ? `/${replaceSpacesWithHyphens(item?.category?.name)}/${replaceSpacesWithHyphens(item?.subcategoryname?.name)}/${replaceSpacesWithHyphens(item?.descriptions[0]?.value)}`
                : `/product/${item?.slug}`
            }
            className="deal-card text-decoration-none"
            key={i}
          >
            <ProductImages
              productimages={item?.productimages}
              className="deal-card-image"
            />

            <div className="deal-card-content">
              <h6 className="product-partnumber m-0"> {item?.partNumber} </h6>

              <div className="card-price">
                <h6 className="m-0 current-price">₹ {item?.saleprice} </h6>
                <p className="m-0 old-price">₹ {item?.oldprice} </p>
              </div>
              <TimeProgressBar totalTime={item?.dealExpiryDate} />

              <DealCountdown expiryDate={item?.dealExpiryDate} />
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
}

export default ToolsProductIdProvider(DealOfDayToolsProducts);
