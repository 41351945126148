import React from "react";
import "../SearchResultsPage/searchResultsPage.scss";
import SearchPageResults from "../../components/Search/SearchPageResults";

function SearchResultsPage() {
  return (
    <div className="search-container row">
      <SearchPageResults />
    </div>
  );
}

export default SearchResultsPage;
