// import React from "react";
// import Loader from "../../Loader";
// import FilterTable from "./FilterTable";
// import NoDataFound from "./No data found.json";
// import Lottie from "lottie-react";
// import NoDataFound1 from "../../../Preloader/No product Found Animation.json";
// import Preloader from "../../../Preloader/Preloader-Animation.json";
// import { HiViewColumns } from "react-icons/hi2";
// import { HiViewGrid } from "react-icons/hi";
// import FIlterCards from "./FIlterCards";
// function FilterResults({ searchResult, error, filterLoder }) {
//   const categoryName = searchResult[0]?.category?.name;

//   return (
//     <div className="table-container">
//       {error.length > 0 ? (
//         <>
//           {/* <Lottie
//             style={{ width: "100%", height: "60vh" }}
//             animationData={NoDataFound}
//           />
//           <h5 style={{ color: "red", marginLeft: "24%" }}>{error}</h5> */}
//           <Lottie
//             style={{ width: "70vw", height: "50vh" }}
//             animationData={NoDataFound1}
//           />
//         </>
//       ) : filterLoder === true ? (
//         <>
//           <Lottie
//             style={{ width: "100vw", height: "50vh" }}
//             animationData={Preloader}
//           />
//         </>
//       ) : (
//         <>
//         <div className="d-flex"> 

//         <h5 className="text-uppercase fw-bold" >Search Results...</h5>
//         <span className="ms-auto fs-3">
//         <HiViewGrid />
//           </span>
//           <span className=" fs-3">

//         <HiViewColumns />
//           </span>
//         </div>
//         <div className="" style={{background:"#F3F3F3",padding:"20px"}} >

//           <FilterTable searchResult={searchResult} />
//         </div>
//         <FIlterCards searchResult={searchResult}   />
//         </>
//       )}
//     </div>
//   );
// }

// export default FilterResults;
// import React, { useState } from "react";
// import Loader from "../../Loader";
// import FilterTable from "./FilterTable";
// import NoDataFound from "./No data found.json";
// import Lottie from "lottie-react";
// import NoDataFound1 from "../../../Preloader/No product Found Animation.json";
// import Preloader from "../../../Preloader/Preloader-Animation.json";
// import { FaThList } from "react-icons/fa";
// import { MdOutlineGridView } from "react-icons/md";
// import { IoGridSharp } from "react-icons/io5";
// import FIlterCards from "./FIlterCards";



// function FilterResults({ searchResult, error, filterLoder,filter, filtername}) {
//   console.log(filtername,"ssssssssss")
//   const [viewMode, setViewMode] = useState("grid"); // Initialize state to toggle between views

//   const resultLength=searchResult?.length

//   console.log("resultLength-------->",resultLength)
//   const categoryName = searchResult[0]?.category?.name;

//   const handleViewChange = (view) => {
//     setViewMode(view);
//   };

//   return (
//     <div className="table-container">
//       {error.length > 0 ? (
//         <>
//           <Lottie
//             style={{ width: "70vw", height: "50vh" }}
//             animationData={NoDataFound1}
//           />
//         </>
//       ) : filterLoder === true ? (
//         <>
//           <Lottie
//             style={{ width: "100vw", height: "50vh" }}
//             animationData={Preloader}
//           />
//         </>
//       ) : (
//         <>
//           <div className="d-flex">
//             {console.log(filter,"shiwwwww")}
//             <h5 className="text-uppercase fw-bold">{filtername}({resultLength})</h5>
//             {console.log(searchResult,"shie")}
//             <span className="ms-auto fs-4" onClick={() => handleViewChange("grid")}>
//             <IoGridSharp />

//             </span>
//             <span className="fs-4 ms-2 me-2" onClick={() => handleViewChange("columns")}>
//             <FaThList />
//             </span>
//           </div>
//           <div className="" style={{ background: "#F3F3F3", padding: "20px" }}>
//             {viewMode === "grid" ? (
//               <FIlterCards searchResult={searchResult}  filter={filter}/>
//             ) : (
//               <FilterTable searchResult={searchResult} filter={filter} />
//             )}
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export default FilterResults;
// import React, { useState } from "react";
// import Loader from "../../Loader";
// import FilterTable from "./FilterTable";
// import NoDataFound from "./No data found.json";
// import Lottie from "lottie-react";
// import NoDataFound1 from "../../../Preloader/No product Found Animation.json";
// import Preloader from "../../../Preloader/Preloader-Animation.json";
// import { FaThList, FaSearch } from "react-icons/fa";
// import { MdOutlineGridView } from "react-icons/md";
// import { IoGridSharp } from "react-icons/io5";
// import FIlterCards from "./FIlterCards";

// function FilterResults({ searchResult, error, filterLoder, filter, filtername }) {
//   console.log(filtername, "ssssssssss");
//   const [viewMode, setViewMode] = useState("grid"); // Initialize state to toggle between views
//   const [searchTerm, setSearchTerm] = useState("");

//   const resultLength = searchResult?.length;

//   console.log("resultLength-------->", resultLength);
//   const categoryName = searchResult[0]?.category?.name;

//   const handleViewChange = (view) => {
//     setViewMode(view);
//   };

//   const handleSearch = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   const filteredResults = searchResult.filter((item) =>
//     item.partNumber?.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div className="table-container">
//       {error.length > 0 ? (
//         <>
//           <Lottie
//             style={{ width: "70vw", height: "50vh" }}
//             animationData={NoDataFound1}
//           />
//         </>
//       ) : filterLoder === true ? (
//         <>
//           <Lottie
//             style={{ width: "100vw", height: "50vh" }}
//             animationData={Preloader}
//           />
//         </>
//       ) : (
//         <>
//           <div className="d-flex align-items-center mb-3">
//             {console.log(filter, "shiwwwww")}
//             <h5 className="text-uppercase fw-bold">
//               {filtername} ({resultLength})
//             </h5>
//             {console.log(searchResult, "shie")}
//             <div className="input-group ms-auto w-50">
//               <input
//                 type="text"
//                 className="form-control "
//                 placeholder="Search part number"
//                 value={searchTerm}
//                 onChange={handleSearch}
//               />
//               <span className="input-group-text">
//                 <FaSearch />
//               </span>
//             </div>
//             <span className=" ms-4 fs-4" onClick={() => handleViewChange("grid")}>
//               <IoGridSharp />
//             </span>
//             <span className="fs-4 ms-2 me-2" onClick={() => handleViewChange("columns")}>
//               <FaThList />
//             </span>
           
//           </div>
//           <div className="" style={{ background: "#F3F3F3", padding: "20px" }}>
//             {viewMode === "grid" ? (
//               <FIlterCards searchResult={filteredResults} filter={filter} />
//             ) : (
//               <FilterTable searchResult={filteredResults} filter={filter} />
//             )}
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export default FilterResults;
import React, { useState } from "react";
import Loader from "../../Loader";
import FilterTable from "./FilterTable";
import NoDataFound from "./No data found.json";
import Lottie from "lottie-react";
import NoDataFound1 from "../../../Preloader/No product Found Animation.json";
import Preloader from "../../../Preloader/Preloader-Animation.json";
import { FaSearch, FaThList } from "react-icons/fa";
import { MdOutlineGridView } from "react-icons/md";
import { IoGridSharp } from "react-icons/io5";
import FIlterCards from "./FIlterCards";

function FilterResults({ searchResult = [], error = "", filterLoder = false, filter = [], filtername = "",categoryName,subCategoryName}) {
  // console.log(filtername, "ssssssssss");/
  const [viewMode, setViewMode] = useState("grid"); // Initialize state to toggle between views

  const resultLength = searchResult?.length;

  // console.log("resultLength-------->", resultLength);
  // const categoryName = searchResult[0]?.category?.name;

  const handleViewChange = (view) => {
    setViewMode(view);
  };

  return (
    <div className="table-container">
      {error.length > 0 ? (
        <>
          <Lottie
            style={{ width: "70vw", height: "50vh" }}
            animationData={NoDataFound1}
          />
        </>
      ) : filterLoder === true ? (
        <>
          <Lottie
            style={{ width: "100vw", height: "50vh" }}
            animationData={Preloader}
          />
        </>
      ) : (
        <>
          <div className="d-flex align-items-center mb-2">
            {/* {console.log(filter, "shiwwwww")} */}
            <h5 className="text-uppercase fw-bold">
              {filtername} <span className=" fw-light">
              ({resultLength})
                </span>
            </h5>
            {/* {console.log(searchResult, "shie")} */}
            <div className="input-group text-inputgroupsss ms-auto w-50 me-4">
              <input
                type="text"
                className="form-control"
                placeholder="Search part number"
                // Add the necessary state and handler for the search input here
              />
              <span className="input-group-text">
                <FaSearch />
              </span>
            </div>
            <span className=" fs-4 fdfdfd" onClick={() => handleViewChange("grid")}>
              <IoGridSharp />
            </span>
            <span className="fs-4 ms-2 me-2" onClick={() => handleViewChange("columns")}>
              <FaThList />
            </span>
      
          </div>
          <div className="" style={{ background: "#F3F3F3", padding: "10px" }}>
            {viewMode === "grid" ? (
              <FIlterCards searchResult={searchResult} filter={filter} categoryName={categoryName} subCategoryName={subCategoryName}/>
            ) : (
              <FilterTable searchResult={searchResult} filter={filter} categoryName={categoryName} subCategoryName={subCategoryName}/>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default FilterResults;
