import React from 'react'
import FastenersProducts from './FastenersProducts/FastenersProducts'
import Products from './Products'
import ToolsProducts from '../ToolsProducts/ToolsProducts'
import AllProduct from './ProductComponents/AllProduct'

function Product() {


  return (
    <div>
     <AllProduct/> 
    </div>
  )
}

export default Product
