import React from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../Assets/Images/brand-logo.svg";
import SearchBar from "../Search/SearchBar";
import AuthAndCartStatus from "./AuthAndCartStatus";
import BannerSection from "../BannerSection/BannerSection";

function MainHeader() {
  return (
    <Navbar className="navbar m-0">
      <Link to="/">
        <img src={Logo} className="brand-logo" alt="BestIndiaKart" />
      </Link>

      <SearchBar />

      <AuthAndCartStatus />
      
      
      {/* <li><BannerSection /> </li> */}
    </Navbar>
  );
}

export default MainHeader;
