import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SEVEN_DAYS_MS = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
// const SEVEN_DAYS_MS = 20 * 1000; // 7 days in milliseconds

const Timer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleActivity = () => {
      const now = Date.now();
      localStorage.setItem("lastActivity", now.toString());
      // console.log("Activity detected, timestamp updated:", now);
    };

    const checkInactivity = () => {
      const lastActivity = localStorage.getItem("lastActivity");
      if (
        lastActivity &&
        Date.now() - parseInt(lastActivity) > SEVEN_DAYS_MS &&
        location.pathname !== "/loginpage" &&
        location.pathname !== "/otpverification"
      ) {
        // console.log("Inactivity detected, navigating to login page...");
        localStorage.removeItem("userId");
        localStorage.removeItem("refreshToken");
        alert("You have been inactive for 7 days. Please log in again.");
        navigate("/loginpage");
      }
    };

    const startTimer = () => {
      handleActivity(); // Initialize activity timestamp
      window.addEventListener("mousemove", handleActivity);
      window.addEventListener("keydown", handleActivity);

      const interval = setInterval(checkInactivity, 1000); // Check every second

      return () => {
        window.removeEventListener("mousemove", handleActivity);
        window.removeEventListener("keydown", handleActivity);
        clearInterval(interval);
      };
    };

    if (localStorage.getItem("userId")) {
      const cleanup = startTimer();
      return cleanup;
    }
  }, [navigate, location.pathname]);

  return null; // This component does not render anything
};

export default Timer;
