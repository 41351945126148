// // src/PdfViewer.js
// import React from 'react';
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css'
// const workerUrl = "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

// const PdfViewer = ({ fileUrl }) => {
//   const defaultLayoutPluginInstance = defaultLayoutPlugin();

//   return (
//     <div style={{ height: '100vh' }}>
//       <Worker workerUrl={workerUrl}>
//         <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
//       </Worker>
//     </div>
//   );
// };

// export default PdfViewer;
// src/PdfViewer.js
// import React, { useRef } from 'react';
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// import { pdfjs } from 'pdfjs-dist';

// const workerUrl = "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

// const PdfViewer = ({ fileUrl }) => {
//   const defaultLayoutPluginInstance = defaultLayoutPlugin();
//   const viewerRef = useRef(null);

//   const handlePrint = () => {
//     if (viewerRef.current) {
//       viewerRef.current.print();
//     }
//   };

//   const handleDownload = () => {
//     const link = document.createElement('a');
//     link.href = fileUrl;
//     link.download = 'document.pdf'; // Specify a filename
//     link.click();
//   };

//   return (
//     <div style={{ height: '100vh', position: 'relative' }}>
//       <Worker workerUrl={workerUrl}>
//         <Viewer
//           fileUrl={fileUrl}
//           plugins={[defaultLayoutPluginInstance]}
//           ref={viewerRef}
//         />
//       </Worker>
//       <div style={{ position: 'absolute', top: 10, right: 10 }}>
//         <button onClick={handlePrint} style={{ marginRight: 10 }}>
//           Print
//         </button>
//         <button onClick={handleDownload}>
//           Download
//         </button>
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;
import React, { useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, DefaultLayoutPluginProps } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const workerUrl = "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

const PdfViewer = ({ fileUrl }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
  
    renderToolbar: (props) => (
      <div className="rpv-default-layout__toolbar">
        {/* Other toolbar items */}
        {props.toolbarSlots?.searchPopover}
     
        {props.toolbarSlots?.pageNavigation}
        {props.toolbarSlots?.fullScreen}
        {props.toolbarSlots?.currentPage}
     
      </div>
    ),
  });
  
  const viewerRef = useRef(null);

  
// console.log("sdfsd")
 

  return (
    <div style={{ height: '100vh', position: 'relative' }}>
      <Worker workerUrl={workerUrl}>
        <Viewer
          fileUrl={fileUrl}
          plugins={[defaultLayoutPluginInstance]}
          ref={viewerRef}
        />
      </Worker>
      
    </div>
  );
};

export default PdfViewer;
