import React from "react";
import "../../Assets/Images/loginPage-banner.jpg";
import "../LoginPage/LoginPage.scss";
import { FcCancel } from "react-icons/fc";
import "../OtpError/OtpError.scss"
import { Link } from "react-router-dom";

function OtpError() {
  return (
    <div className="login-page">
      <div className="login-page-inner">
        <center className="w-100">
            <FcCancel className="error-icon" /> 
            <h5 className="fs-4 fw-bold">Incoret  OTP</h5> 
            <Link to="/otpverification">
                <button className="error-button">Trey Again</button>
            </Link>
        </center>
      </div>
    </div> 
  );
}

export default OtpError;