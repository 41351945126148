// // // import React from "react";
// // // import "./About.scss";
// // // import About_History from "./About_History.jpg";
// // // import ProductsImages from "./ProductsImages";
// // // const About = () => {
// // //   return (
// // //     <div>
// // //       <section style={{ backgroundColor: "#0443C0", color: "white" }}>
// // //         <h1 className="about-heading" style={{ textAlign: "center" }}>
// // //           About Us
// // //         </h1>
// // //         <article>
// // //           <section className="about-history">
// // //             <aside>
// // //               <img src={About_History} alt="Image not found" />{" "}
// // //             </aside>
// // //             <article>
// // //               Bumaas Engineering Solution & Technologies Pvt Ltd, established in
// // //               2018, has quickly become a leading name in the Indian fastener
// // //               industry. We provide high-quality fastening solutions, ensuring
// // //               excellence and reliability across various applications. Our
// // //               commitment to quality is backed by our certifications: IATF 16949,
// // //               ISO 9001, ISO 14001, and ISO 45001.
// // //             </article>
// // //           </section>
// // //           {/* ================================== */}
// // //           {/* ================================== */}
// // //           <section className="about-our-product">
// // //             <h3 className="about-our-product-heading">Our Product Range</h3>
// // //             <div className="about-our-product-details">
// // //               We offer a comprehensive array of fasteners and installation tools
// // //               to meet diverse industrial needs. Our product lineup includes
// // //               rivets, rivet nuts, self-clinching fasteners, welded fasteners,
// // //               and cage nuts, ensuring secure and robust connections. We also
// // //               specialize in SPR (Self-Piercing Rivets) and special screws for
// // //               advanced applications. Our range extends to plastic fasteners,
// // //               bolts, nuts, washers, and brass fasteners, catering to various
// // //               material requirements. Additionally, we provide anti-loosening
// // //               fasteners, solid rivets, semi-tubular rivets, sealing plugs, and
// // //               wire threaded inserts, ensuring complete fastening solutions for
// // //               every project.
// // //             </div>
// // //             <section className="about-our-product-content">
// // //               <ProductsImages />
// // //             </section>
// // //           </section>
// // //           {/* ===================================================== */}
// // //           {/* ===================================================== */}
// // //           <section className="about-ecommerce-platform">
// // //             <h2 className="about-ecommerce-platform-heading">
// // //               Innovative E-Commerce Platform
// // //             </h2>
// // //             <section className="about-ecommerce-platform-content">
// // //               Our advanced e-commerce platform ensures a seamless shopping
// // //               experience with: <br /> • User-friendly interface<br></br>• Secure
// // //               payment options<br></br>• Fast, reliable delivery services
// // //               <br></br>
// // //               Our platform also allows other sellers to list their
// // //               fastener-related products, fostering a collaborative marketplace.
// // //             </section>
// // //           </section>
// // //           {/* //////////////////// */}
// // //           <h2>Commitment to Quality</h2>
// // //           Quality is at the heart of Bumaas. We rigorously test each product to
// // //           ensure it meets the highest standards, guaranteeing reliable
// // //           performance in every application.
// // //           <h2>Vision and Values</h2>
// // //           As a proud Indian company based we are dedicated to:<br></br>•
// // //           Innovation: Continuously enhancing our product range.<br></br>•
// // //           Customer Focus: Providing exceptional service and support.<br></br>•
// // //           Integrity: Upholding the highest ethical standards in our business
// // //           practices.<br></br>
// // //           <h2>Contact Us</h2>
// // //           Reach out to us for all your fastening needs: <br></br>• Address: No
// // //           1A, Jeevarathinam Street, KSR Nagar, Ambattur, Chennai, Tamil Nadu -
// // //           600053<br></br>• Mobile: +91 7812810488<br></br>•
// // //           E-Mail:marketing@bumaas.com.<br></br>• Websites:www.bumaas.com,
// // //           grip-fasteners.com, bestindiakart.com<br></br>
// // //           Thank you for choosing Bumaas Engineering Solution & Technologies Pvt
// // //           Ltd. We look forward to building strong, lasting partnerships and
// // //           driving excellence in the fastener industry. Join us on our journey
// // //           towards fastening excellence.
// // //         </article>
// // //       </section>
// // //     </div>
// // //   );
// // // };

// // // export default About;

// // import React from "react";
// // import "./About.scss";
// // import About_History from "./About_History.jpg";
// // import ProductsImages from "./ProductsImages";

// // const About = () => {
// //   return (
// //     <div>
// //       <section className="about-section">
// //         <article>
// //           <section className="about-history">
// //             <aside>
// //               {/* <img src={About_History} alt="About history" /> */}
// //             </aside>
// //             <article>
// //               <h1 className="about-heading">About Us</h1>
// //               Bumaas Engineering Solution & Technologies Pvt Ltd, established in
// //               2018, has quickly become a leading name in the Indian fastener
// //               industry. We provide high-quality fastening solutions, ensuring
// //               excellence and reliability across various applications. Our
// //               commitment to quality is backed by our certifications: IATF 16949,
// //               ISO 9001, ISO 14001, and ISO 45001.
// //             </article>
// //           </section>
// //           <section className="about-our-product">
// //             <h3 className="about-our-product-heading">Our Product Range</h3>
// //             <div className="about-our-product-details">
// //               We offer a comprehensive array of fasteners and installation tools
// //               to meet diverse industrial needs. Our product lineup includes
// //               rivets, rivet nuts, self-clinching fasteners, welded fasteners,
// //               and cage nuts, ensuring secure and robust connections. We also
// //               specialize in SPR (Self-Piercing Rivets) and special screws for
// //               advanced applications. Our range extends to plastic fasteners,
// //               bolts, nuts, washers, and brass fasteners, catering to various
// //               material requirements. Additionally, we provide anti-loosening
// //               fasteners, solid rivets, semi-tubular rivets, sealing plugs, and
// //               wire threaded inserts, ensuring complete fastening solutions for
// //               every project.
// //             </div>
// //             <section className="about-our-product-content">
// //               <ProductsImages />
// //             </section>
// //           </section>
// //           <section className="about-ecommerce-platform">
// //             <h2 className="about-ecommerce-platform-heading">
// //               Innovative E-Commerce Platform
// //             </h2>
// //             <section className="about-ecommerce-platform-content">
// //               <b>
// //                 Our advanced e-commerce platform ensures a seamless shopping
// //                 experience with:
// //               </b>
// //               <br />
// //               <ul>
// //                 <li>User-friendly interface</li>
// //                 <li>Secure payment options</li>
// //                 <li>Fast, reliable delivery services</li>
// //               </ul>
// //               <br></br>
// //               Our platform also allows other sellers to list their
// //               fastener-related products, fostering a collaborative marketplace.
// //             </section>
// //           </section>
// //           <h2>Commitment to Quality</h2>
// //           Quality is at the heart of Bumaas. We rigorously test each product to
// //           ensure it meets the highest standards, guaranteeing reliable
// //           performance in every application.
// //           <h2>Vision and Values</h2>
// //           As a proud Indian company based we are dedicated to:<br></br>•
// //           Innovation: Continuously enhancing our product range.<br></br>•
// //           Customer Focus: Providing exceptional service and support.<br></br>•
// //           Integrity: Upholding the highest ethical standards in our business
// //           practices.<br></br>
// //           <h2>Contact Us</h2>
// //           Reach out to us for all your fastening needs: <br></br>• Address: No
// //           1A, Jeevarathinam Street, KSR Nagar, Ambattur, Chennai, Tamil Nadu -
// //           600053<br></br>• Mobile: +91 7812810488<br></br>•
// //           E-Mail:marketing@bumaas.com.<br></br>• Websites:www.bumaas.com,
// //           grip-fasteners.com, bestindiakart.com<br></br>
// //           Thank you for choosing Bumaas Engineering Solution & Technologies Pvt
// //           Ltd. We look forward to building strong, lasting partnerships and
// //           driving excellence in the fastener industry. Join us on our journey
// //           towards fastening excellence.
// //         </article>
// //       </section>
// //     </div>
// //   );
// // };

// // export default About;

// import React, { useRef, useEffect } from "react";
// import "./About.scss";
// import About_History from "../Assets/collage.png";
// import Email from "../Assets/email_icon.png";
// import Mobile from "../Assets/mobile.png";
// import Website from "../Assets/website.png";
// import ProductsImages from "./ProductsImages";
// import ScrollAnimation from "react-animate-on-scroll";

// const About = () => {
//   const platformRef = useRef(null);

//   useEffect(() => {
//     const options = {
//       root: null, // Use the viewport as the root
//       rootMargin: "0px",
//       threshold: 0.5, // Trigger when 50% of the section is visible
//     };

//     const observer = new IntersectionObserver((entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           entry.target.classList.add("in-view");
//         } else {
//           entry.target.classList.remove("in-view");
//         }
//       });
//     }, options);

//     if (platformRef.current) {
//       observer.observe(platformRef.current);
//     }

//     return () => {
//       if (platformRef.current) {
//         observer.unobserve(platformRef.current);
//       }
//     };
//   }, []);

//   return (
//     <div>
//       <section className="about-section">
//         <article>
//           <section className="about-history">
//             <aside>
//               <img src={About_History} alt="About history" />
//             </aside>
//             <article>
//               <h1 className="about-heading">About Us</h1>
//               Bumaas Engineering Solution & Technologies Pvt Ltd, established in
//               2018, has quickly become a leading name in the Indian fastener
//               industry. We provide high-quality fastening solutions, ensuring
//               excellence and reliability across various applications. Our
//               commitment to quality is backed by our certifications: IATF 16949,
//               ISO 9001, ISO 14001, and ISO 45001.
//             </article>
//           </section>
//           <section className="about-our-product">
//             <h3 className="about-our-product-heading">Our Product Range</h3>
//             <div className="about-our-product-details">
//               We offer a comprehensive array of fasteners and installation tools
//               to meet diverse industrial needs. Our product lineup includes
//               rivets, rivet nuts, self-clinching fasteners, welded fasteners,
//               and cage nuts, ensuring secure and robust connections. We also
//               specialize in SPR (Self-Piercing Rivets) and special screws for
//               advanced applications. Our range extends to plastic fasteners,
//               bolts, nuts, washers, and brass fasteners, catering to various
//               material requirements. Additionally, we provide anti-loosening
//               fasteners, solid rivets, semi-tubular rivets, sealing plugs, and
//               wire threaded inserts, ensuring complete fastening solutions for
//               every project.
//             </div>
//             <section ref={platformRef} className="about-our-product-content">
//               <ProductsImages />
//             </section>
//           </section>
//           <section className="about-ecommerce-platform">
//             <h2 className="about-ecommerce-platform-heading">
//               Innovative E-Commerce Platform
//             </h2>
//             <section className="about-ecommerce-platform-content">
//               <b>
//                 <div style={{ marginBottom: "15px" }}>
//                   {" "}
//                   Our advanced e-commerce platform ensures a seamless shopping
//                   experience with:
//                 </div>
//               </b>
//               <ScrollAnimation
//                 className="scroller-ul"
//                 animateIn="fadeIn"
//                 animateOut="fadeOut"
//               >
//                 <ul>
//                   <li>User-friendly interface</li>
//                 </ul>
//               </ScrollAnimation>
//               <ScrollAnimation
//                 className="scroller-ul"
//                 animateIn="fadeIn"
//                 animateOut="fadeOut"
//               >
//                 <ul>
//                   <li>Secure payment options</li>
//                 </ul>
//               </ScrollAnimation>
//               <ScrollAnimation
//                 className="scroller-ul"
//                 animateIn="fadeIn"
//                 animateOut="fadeOut"
//               >
//                 <ul>
//                   <li> Fast, reliable delivery services</li>
//                 </ul>
//               </ScrollAnimation>
//               <ul></ul>
//               <br />
//               Our platform also allows other sellers to list their
//               fastener-related products, fostering a collaborative marketplace.
//             </section>
//           </section>
//           <section className="Commitment-to-Quality-container">
//             <h2 className="Commitment-to-Quality-heading">
//               Commitment to Quality
//             </h2>
//             <p className="Commitment-to-Quality-content" style={{textAlign:"center"}}>
//               Quality is at the heart of Bumaas. We rigorously test each product
//               to ensure it meets the highest standards, guaranteeing reliable
//               performance in every application.
//             </p>
//           </section>
//           {/* ///////////////////////////////////// */}
//           {/* ///////////////////////////////////// */}
//           {/* ///////////////////////////////////// */}
//           {/* ///////////////////////////////////// */}
//           <section className="Vision-and-Values">
//             <h2
//               className="Vision-and-Values-heading"

//             >
//               Vision and Values
//             </h2>

//             <section className="about-ecommerce-platform-content vision-and-values">
//               <b>
//                 <div style={{ marginBottom: "15px" }}>
//                   As a proud Indian company based we are dedicated to:
//                 </div>
//               </b>
//               <ScrollAnimation
//                 className="scroller-ul"
//                 animateIn="fadeIn"
//                 animateOut="fadeOut"
//               >
//                 <ul>
//                   <li>Innovation: Continuously enhancing our product range.</li>
//                 </ul>
//               </ScrollAnimation>
//               <ScrollAnimation
//                 className="scroller-ul"
//                 animateIn="fadeIn"
//                 animateOut="fadeOut"
//               >
//                 <ul>
//                   <li>
//                     Customer Focus: Providing exceptional service and support.
//                   </li>
//                 </ul>
//               </ScrollAnimation>
//               <ScrollAnimation
//                 className="scroller-ul"
//                 animateIn="fadeIn"
//                 animateOut="fadeOut"
//               >
//                 <ul>
//                   <li>
//                     {" "}
//                     Integrity: Upholding the highest ethical standards in our
//                     business practices.
//                   </li>
//                 </ul>
//               </ScrollAnimation>
//               <br />
//             </section>
//             <p></p>
//           </section>
//           {/* ///////////////////////////////////////////////////////////// */}
//           {/* ///////////////////////////////////////////////////////////// */}
//           {/* ///////////////////////////////////////////////////////////// */}
//           {/* ///////////////////////////////////////////////////////////// */}
//           <section className="about-ecommerce-platform">
//             <h2 className="about-ecommerce-platform-heading about-contact-us" style={{textDecoration:"underline"}}>
//               Contact Us
//             </h2>
//             <section className="about-ecommerce-platform-content" style={{textAlign:"center"}}>
//               <b>
//                 <span style={{ marginBottom: "5px" }}>
//                   Reach out to us for all your fastening needs: <br />
//                 </span>
//                 <span>
//                   Address: No 1A, Jeevarathinam Street, KSR Nagar, Ambattur,
//                   Chennai, Tamil Nadu - 600053
//                 </span>
//               </b>
//               <article>
//                 <div>
//                   <img src={Mobile} className="about-contact-images" alt="image not found" />Mobile: <a href="tel:7812810488" style={{textDecoration:"none"}}>+91 7812810488</a>
//                 </div>
//                 <div>
//                   <img src={Email} className="about-contact-images" alt="image not found" /> E-Mail: <a href="mailto:marketing@bumaas.com" style={{textDecoration:"none"}}>marketing@bumaas.com.</a>
//                 </div>
//                 <div>
//                   <img src={Website} className="about-contact-images" alt="image not found"  />

//                   Websites: <a href="https://www.bumaas.com/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>www.bumaas.com</a>, <a href="https://www.grip-fasteners.com/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>grip-fasteners.com</a>, <a href="https://www.bestindiakart.com/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>bestindiakart.com</a>
//                 </div>
//               </article>
//               <br />
//               Thank you for choosing Bumaas Engineering Solution & Technologies
//               Pvt Ltd. We look forward to building strong, lasting partnerships
//               and driving excellence in the fastener industry. Join us on our
//               journey towards fastening excellence.
//             </section>
//           </section>
//           {/* /////////////////////////////////////// */}
//         </article>
//       </section>
//     </div>
//   );
// };

// export default About;

// import React from "react";
// import { motion, useInView } from "framer-motion";
// import "./About.scss";
// import About_History from "../Assets/collage.png";
// import Email from "../Assets/email_icon.png";
// import Mobile from "../Assets/mobile.png";
// import Website from "../Assets/website.png";
// import ProductsImages from "./ProductsImages";

// const About = () => {
//   // Define variants for animations
//   const variants = {
//     hidden: { opacity: 0, y: 50 },
//     visible: { opacity: 1, y: 0 },
//   };

//   const ref1 = React.useRef(null);
//   const ref2 = React.useRef(null);
//   const ref3 = React.useRef(null);
//   const ref4 = React.useRef(null);
//   const ref5 = React.useRef(null);
//   const ref6 = React.useRef(null);

//   const inView1 = useInView(ref1);
//   const inView2 = useInView(ref2);
//   const inView3 = useInView(ref3);
//   const inView4 = useInView(ref4);
//   const inView5 = useInView(ref5);
//   const inView6 = useInView(ref6);

//   return (
//     <div>
//       <section className="about-section">
//         <article>
//           <motion.section
//             className="about-history"
//             ref={ref1}
//             initial="hidden"
//             animate={inView1 ? "visible" : "hidden"}
//             variants={variants}
//             transition={{ duration: 0.5 }}
//           >
//             <aside>
//               <img src={About_History} alt="About history" />
//             </aside>
//             <article>
//               <h1 className="about-heading">About Us</h1>
//               Bumaas Engineering Solution & Technologies Pvt Ltd, established in
//               2018, has quickly become a leading name in the Indian fastener
//               industry. We provide high-quality fastening solutions, ensuring
//               excellence and reliability across various applications. Our
//               commitment to quality is backed by our certifications: IATF 16949,
//               ISO 9001, ISO 14001, and ISO 45001.
//             </article>
//           </motion.section>
//           <motion.section
//             className="about-our-product"
//             ref={ref2}
//             initial="hidden"
//             animate={inView2 ? "visible" : "hidden"}
//             variants={variants}
//             transition={{ duration: 0.5 }}
//           >
//             <h3 className="about-our-product-heading">Our Product Range</h3>
//             <div className="about-our-product-details">
//               We offer a comprehensive array of fasteners and installation tools
//               to meet diverse industrial needs. Our product lineup includes
//               rivets, rivet nuts, self-clinching fasteners, welded fasteners,
//               and cage nuts, ensuring secure and robust connections. We also
//               specialize in SPR (Self-Piercing Rivets) and special screws for
//               advanced applications. Our range extends to plastic fasteners,
//               bolts, nuts, washers, and brass fasteners, catering to various
//               material requirements. Additionally, we provide anti-loosening
//               fasteners, solid rivets, semi-tubular rivets, sealing plugs, and
//               wire threaded inserts, ensuring complete fastening solutions for
//               every project.
//             </div>
//             <section className="about-our-product-content">
//               <ProductsImages />
//             </section>
//           </motion.section>
//           <motion.section
//             className="about-ecommerce-platform"
//             ref={ref3}
//             initial="hidden"
//             animate={inView3 ? "visible" : "hidden"}
//             variants={variants}
//             transition={{ duration: 0.5 }}
//           >
//             <h2 className="about-ecommerce-platform-heading">
//               Innovative E-Commerce Platform
//             </h2>
//             <section className="about-ecommerce-platform-content">
//               <b>
//                 <div style={{ marginBottom: "15px" }}>
//                   Our advanced e-commerce platform ensures a seamless shopping
//                   experience with:
//                 </div>
//               </b>
//               <ul>
//                 <li>User-friendly interface</li>
//                 <li>Secure payment options</li>
//                 <li>Fast, reliable delivery services</li>
//               </ul>
//               <br />
//               Our platform also allows other sellers to list their
//               fastener-related products, fostering a collaborative marketplace.
//             </section>
//           </motion.section>
//           <motion.section
//             className="Commitment-to-Quality-container"
//             ref={ref4}
//             initial="hidden"
//             animate={inView4 ? "visible" : "hidden"}
//             variants={variants}
//             transition={{ duration: 0.5 }}
//           >
//             <h2 className="Commitment-to-Quality-heading">
//               Commitment to Quality
//             </h2>
//             <p
//               className="Commitment-to-Quality-content"
//               style={{ textAlign: "center" }}
//             >
//               Quality is at the heart of Bumaas. We rigorously test each product
//               to ensure it meets the highest standards, guaranteeing reliable
//               performance in every application.
//             </p>
//           </motion.section>
//           <motion.section
//             className="Vision-and-Values"
//             ref={ref5}
//             initial="hidden"
//             animate={inView5 ? "visible" : "hidden"}
//             variants={variants}
//             transition={{ duration: 0.5 }}
//           >
//             <h2 className="Vision-and-Values-heading">Vision and Values</h2>
//             <section className="about-ecommerce-platform-content vision-and-values">
//               <b>
//                 <div style={{ marginBottom: "15px" }}>
//                   As a proud Indian company based we are dedicated to:
//                 </div>
//               </b>
//               <ul>
//                 <li>Innovation: Continuously enhancing our product range.</li>
//                 <li>Customer Focus: Providing exceptional service and support.</li>
//                 <li>Integrity: Upholding the highest ethical standards in our
//                   business practices.
//                 </li>
//               </ul>
//               <br />
//             </section>
//           </motion.section>
//           <motion.section
//             className="about-ecommerce-platform"
//             ref={ref6}
//             initial="hidden"
//             animate={inView6 ? "visible" : "hidden"}
//             variants={variants}
//             transition={{ duration: 0.5 }}
//           >
//             <h2
//               className="about-ecommerce-platform-heading about-contact-us"
//               style={{ textDecoration: "underline" }}
//             >
//               Contact Us
//             </h2>
//             <section
//               className="about-ecommerce-platform-content"
//               style={{ textAlign: "center" }}
//             >
//               <b>
//                 <span style={{ marginBottom: "5px" }}>
//                   Reach out to us for all your fastening needs: <br />
//                 </span>
//                 <span>
//                   Address: No 1A, Jeevarathinam Street, KSR Nagar, Ambattur,
//                   Chennai, Tamil Nadu - 600053
//                 </span>
//               </b>
//               <article>
//                 <div>
//                   <img
//                     src={Mobile}
//                     className="about-contact-images"
//                     alt="image not found"
//                   />
//                   Mobile:{" "}
//                   <a href="tel:7812810488" style={{ textDecoration: "none" }}>
//                     +91 7812810488
//                   </a>
//                 </div>
//                 <div>
//                   <img
//                     src={Email}
//                     className="about-contact-images"
//                     alt="image not found"
//                   />
//                   E-Mail:{" "}
//                   <a
//                     href="mailto:marketing@bumaas.com"
//                     style={{ textDecoration: "none" }}
//                   >
//                     marketing@bumaas.com.
//                   </a>
//                 </div>
//                 <div>
//                   <img
//                     src={Website}
//                     className="about-contact-images"
//                     alt="image not found"
//                   />
//                   Websites:{" "}
//                   <a
//                     href="https://www.bumaas.com/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     style={{ textDecoration: "none" }}
//                   >
//                     www.bumaas.com
//                   </a>
//                   ,{" "}
//                   <a
//                     href="https://www.grip-fasteners.com/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     style={{ textDecoration: "none" }}
//                   >
//                     grip-fasteners.com
//                   </a>
//                   ,{" "}
//                   <a
//                     href="https://www.bestindiakart.com/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     style={{ textDecoration: "none" }}
//                   >
//                     bestindiakart.com
//                   </a>
//                 </div>
//               </article>
//               <br />
//               Thank you for choosing Bumaas Engineering Solution & Technologies
//               Pvt Ltd. We look forward to serving you and providing the finest
//               fastening solutions for your business.
//             </section>
//           </motion.section>
//         </article>
//       </section>
//     </div>
//   );
// };

// export default About;

import React from "react";
import { motion, useInView } from "framer-motion";
import "./About.scss";
import About_History from "../Assets/collage.png";
import A1 from "../Assets/About1.png";
import a2 from "../Assets/About2.jpg";
import a3 from "../Assets/About3.jpg";
import ProductsImages from "./ProductsImages";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const About = () => {
  // Define variants for animations
  const variants = {
    hidden: { opacity: 0, y: 50 },
    visibleFromBottom: { opacity: 1, y: 0 },
    visibleFromTop: { opacity: 1, y: 0 },
    hiddenLeft: { opacity: 0, x: -50 },
    visibleFromLeft: { opacity: 1, x: 0, y: 0 },
    hiddenRight: { opacity: 0, x: 50 },
    visibleFromRight: { opacity: 1, x: 0, y: 0 },
    hiddenTopLeft: { opacity: 0, x: -50, y: -50 },
    visibleFromTopLeft: { opacity: 1, x: 0, y: 20 },
    hiddenTopRight: { opacity: 0, x: 50, y: -50 },
    visibleFromTopRight: { opacity: 1, x: 0, y: 0 },
    hiddenBottomLeft: { opacity: 0, x: -50, y: 50 },
    visibleFromBottomLeft: { opacity: 1, x: 0, y: 0 },
    hiddenBottomRight: { opacity: 0, x: 50, y: 50 },
    visibleFromBottomRight: { opacity: 1, x: 0, y: 0 },
  };

  const ref1 = React.useRef(null);
  const ref2 = React.useRef(null);
  const ref3 = React.useRef(null);
  const ref4 = React.useRef(null);
  const ref5 = React.useRef(null);
  // const ref6 = React.useRef(null);

  const inView1 = useInView(ref1, { triggerOnce: true });
  const inView2 = useInView(ref2, { triggerOnce: true });
  const inView3 = useInView(ref3, { triggerOnce: true });
  const inView4 = useInView(ref4, { triggerOnce: true });
  const inView5 = useInView(ref5, { triggerOnce: true });
  // const inView6 = useInView(ref6, { triggerOnce: true });

  return (
    <div>
      <Helmet>
        <title>Top Fasteners Manufacturers in India</title>
        <meta
          name="description"
          content="Best India Kart is a trusted fasteners manufacturer in India, providing high-quality bolts, nuts, screws for various industries with precision."
        />
      </Helmet>
      <section className="about-section">
        <h1 className="about-heading">About Us</h1>
        <article>
          <motion.section
            className="about-history container"
            ref={ref1}
            initial="hidden"
            animate={inView1 ? "visibleFromBottom" : "hidden"}
            variants={variants}
            // transition={{ duration: 0.5 }}

            transition={{ duration: 1 }}
          >
            <aside>
              <img src={About_History} alt="About history" />
            </aside>
            <article className="mt-2" style={{ fontFamily: "arial" }}>
              Bumaas Engineering Solution And Technologies Pvt Ltd, established
              in 2018, has quickly become a leading name in the Indian fastener
              industry. As a company, we are dedicated to providing high-quality
              fastening solutions that ensure excellence and reliability across
              a wide array of applications. Our commitment to quality is
              demonstrated through our certifications: IATF 16949, ISO 9001, ISO
              14001, and ISO 45001, which highlight our adherence to
              international standards in quality management, environmental
              management, and occupational health and safety management. <br />
              Our journey began with a vision to innovate and lead in the
              fastener industry. Since our inception, we have been driven by a
              passion for connecting the right people with the right solutions.
              Our team comprises dedicated professionals who bring a wealth of
              experience and expertise, ensuring that we consistently meet and
              exceed our customers' expectations. We have grown steadily,
              expanding our product offerings and enhancing our capabilities to
              serve diverse industries, from automotive and aerospace to
              construction and electronics.
            </article>
          </motion.section>
          <motion.section
            className="about-our-product"
            ref={ref2}
            initial="hidden"
            animate={inView2 ? "visibleFromTop" : "hidden"}
            variants={variants}
            // transition={{ duration: 0.5 }}
            transition={{ duration: 1 }}
          >
            <h3 className="about-heading">Our Product Range</h3>
            <div className="about-our-product-details container">
              We offer a comprehensive array of fasteners and installation tools
              to meet diverse industrial needs. Our product lineup includes
              rivets, rivet nuts, self-clinching fasteners, welded fasteners,
              and cage nuts, ensuring secure and robust connections. We also
              specialize in SPR (Self-Piercing Rivets) and special screws for
              advanced applications. Our range extends to plastic fasteners,
              bolts, nuts, washers, and brass fasteners, catering to various
              material requirements. Additionally, we provide anti-loosening
              fasteners, solid rivets, semi-tubular rivets, sealing plugs, and
              wire threaded inserts, ensuring complete fastening solutions for
              every project.
            </div>
            <section className="about-our-product-content ">
              <ProductsImages />
            </section>
          </motion.section>

          <secttion className="about-card ">
            <Container>
              <div className="row cad-adt">
                <div className="col-md-4 about-crd">
                  <motion.section
                    className="about-ecommerce-platform"
                    ref={ref3}
                    initial="hidden"
                    animate={inView3 ? "visibleFromLeft" : "hiddenLeft"}
                    variants={variants}
                    // transition={{ duration: 0.5 }}
                    transition={{ duration: 1 }}
                  >
                    <Card>
                      <CardActionArea style={{ height: "100%" }}>
                        <CardMedia
                          component="img"
                          height="200"
                          image={a3}
                          alt="green iguana"
                          style={{ objectFit: "cover" }}
                        />
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="about-ecommerce-platform-heading"
                          >
                            Innovative E-Commerce Platform
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Our advanced e-commerce platform ensures a seamless
                            shopping experience with:
                            <ul className="about-page-ul-li">
                              <li>User-friendly interface</li>
                              <li>Secure payment options</li>
                              <li>Fast, reliable delivery services</li>
                            </ul>
                            Our platform also allows other sellers to list their
                            fastener-related products, fostering a collaborative
                            marketplace.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </motion.section>
                </div>
                <div className="col-md-4 about-crd ">
                  <motion.section
                    className="Commitment-to-Quality-container"
                    ref={ref4}
                    initial="hidden"
                    animate={inView4 ? "visibleFromRight" : "hiddenRight"}
                    variants={variants}
                    // transition={{ duration: 0.5 }}
                    transition={{ duration: 1 }}
                  >
                    {/* <Card style={{  height: "450px", width: "300px",backgroundColor:"#FEFFD2" }}> */}
                    <Card>
                      <CardActionArea style={{ height: "100%" }}>
                        <CardMedia
                          component="img"
                          height="200"
                          image={a2}
                          alt="green iguana"
                          style={{
                            objectFit: "cover",
                            // transform: "translateY(-10px)",
                          }}
                        />
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="about-ecommerce-platform-heading"
                          >
                            Commitment to Quality
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="Commitment-to-Quality-content"
                          >
                            Quality is at the heart of Bumaas. We rigorously
                            test each product to ensure it meets the highest
                            standards, guaranteeing reliable performance in
                            every application.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </motion.section>
                </div>
                <div className="col-md-4 about-crd">
                  <motion.section
                    className="Vision-and-Values"
                    ref={ref5}
                    initial="hidden"
                    animate={inView5 ? "visibleFromTopLeft" : "hiddenTopLeft"}
                    variants={variants}
                    // transition={{ duration: 0.5 }}
                    transition={{ duration: 1 }}
                  >
                    {/* <Card style={{  height: "450px", width: "300px",backgroundColor:"#89CFF3"}}> */}
                    <Card>
                      <CardActionArea style={{ height: "100%" }}>
                        <CardMedia
                          component="img"
                          height="200"
                          image={A1}
                          alt="green iguana"
                          style={{ objectFit: "cover" }}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            Vision and Values{" "}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="about-page-ul-li"
                          >
                            As a proud Indian company based we are dedicated to:
                            <ul className="about-page-ul-li">
                              <li>
                                Innovation: Continuously enhancing our product
                                range.
                              </li>
                              <li>
                                Customer Focus: Providing exceptional service
                                and support.
                              </li>
                              <li>
                                Integrity: Upholding the highest ethical
                                standards in our business practices.
                              </li>
                            </ul>
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </motion.section>{" "}
                </div>
              </div>
            </Container>
          </secttion>
        </article>
      </section>
    </div>
  );
};

export default About;
