import React from "react";
import "../FastanersCategories/FastanersCategories.scss";
import Form from "react-bootstrap/Form";

function FastanersCategories() {
  return (
    <>
      <Form.Select aria-label="Default select example">
        <option>All categories</option>
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
      </Form.Select>

      <table className="fastaners-table"> 
        <thead>
            <tr>
                <th>Fasteners</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-2 ">Screws,Bolts</td>
          </tr>
          <tr>
            <td className="p-2 ">Screws for Building Materials</td>
          </tr>
          <tr>
            <td className="p-2 ">Washers</td>
            <td>asdasd</td>
          </tr>
          <tr>
            <td className="p-2 ">Nuts</td>
          </tr>
          <tr>
            <td className="p-2 ">Anchors</td>
          </tr>
          <tr>
            <td className="p-2 ">Rivet</td>
          </tr>
          <tr>
            <td className="p-2 ">Insert</td>
          </tr>
          <tr>
            <td className="p-2 ">Tools</td>
          </tr>
          <tr>
            <td className="p-2 ">Washers, Collars</td>
          </tr>
          <tr>
            <td className="p-2 ">Shims</td>
          </tr>
          <tr>
            <td className="p-2 ">Hair Pins, Cotter Pins</td>
          </tr>
          <tr>
            <td className="p-2 ">Machine Keys</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default FastanersCategories;