// // import { BsPen } from "react-icons/bs";
// import * as React from "react";
// // import user_image from "../../Assets/Images/user-image-four.png";
// import { useContext, useEffect, useState } from "react";
// // import "../Profile/Profile.scss";
// // import { useNavigate } from "react-router-dom";
// // import axios from "axios";
// import { API_BASE_URL } from "../../../utils/index";
// // import userContext from "../../components/UseContext/UseContext";
// // import Swal from "sweetalert2/dist/sweetalert2.js";
// import Button from "@mui/material/Button";
// import {
//   Container,
//   Row,
//   Col,
//   ListGroup,
//   Card,
//   Form,
//   Accordion,
// } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// // import "./DashBoard.css";
// // import { motion } from "framer-motion";
// import { TextField, Box, Modal } from "@mui/material";
// import "react-toastify/dist/ReactToastify.css";
// // import { useFormik } from "formik";
// // import * as Yup from "yup";
// // import Typography from "@mui/material/Typography";
// // import { FaEye, FaEyeSlash } from "react-icons/fa";
// import { motion, useMotionValue, useTransform, animate } from "framer-motion";
// import axios from "axios";
// // import { useEffect } from "react";
// import { useAuthHeaders } from "../../../components/Token";

// const DashBoard = () => {
//   const count1 = useMotionValue(0);
//   const count2 = useMotionValue(0);
//   const count3 = useMotionValue(0);
//   const count4 = useMotionValue(0);
//   const rounded1 = useTransform(count1, Math.round);
//   const rounded2 = useTransform(count2, Math.round);
//   const rounded3 = useTransform(count3, Math.round);
//   const rounded4 = useTransform(count4, Math.round);

//   const [cart, setCart] = useState(0);
//   const [orders, setOrders] = useState(0);
//   const [downloads, setDownloads] = useState(0);
//   const [favorite, setFavorite] = useState(0);

//   const isLoggedIn = localStorage.getItem("userId");

//   const headers = useAuthHeaders();

//   // cartItems?.length
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let response;
//         if (isLoggedIn) {
//           response = await axios.get(`${API_BASE_URL}/user/order/getcart`, {
//             headers,
//           });
//         }
//         const cartLength = isLoggedIn
//           ? response.data.cartItemLength
//           : response.data.cartLength;
//         setCart(cartLength);
//       } catch (error) {
//         console.error("Error fetching cart data:", error);
//       }
//     };
//   }, []);

//   useEffect(() => {
//     const userId = localStorage.getItem("userId");
//     const headers = {
//       Authorization: `Bearer ${userId}`,
//       "Content-Type": "application/json",
//     };
//     axios
//       .get(`${API_BASE_URL}/user/order/myOrder`, { headers })
//       .then((res) => {
//         console.log("total orders are", res.data.totelOrder);
//         setOrders(res.data.totelOrder);
//       })
//       .catch((err) => console.log("ERROR is tje", err));
//   }, []);

//   useEffect(() => {
//     const animation1 = animate(count1, cart, {
//       duration: 5,
//     });
//     const animation2 = animate(count2, orders, {
//       duration: 5,
//     });
//     const animation3 = animate(count3, downloads, {
//       duration: 5,
//     });
//     const animation4 = animate(count4, favorite, {
//       duration: 5,
//     });
//   }, []);
//   const arr = [rounded1, rounded2, rounded3, rounded4];

//   return (
//     <Container fluid>
//       <Row className="filter-section mb-3">
//         <Col>
//           <h4>Dashboard</h4>
//         </Col>
//       </Row>
//       <Row>
//         {["Cart", "Orders", "Downloads", "Favorite"].map((item, index) => (
//           <Col md={3} sm={6} xs={12} key={index} className="mb-4">
//             <Card>
//               <Card.Body>
//                 <Card.Title style={{ textAlign: "center" }}>{item}</Card.Title>
//                 <Form>
//                   <Form.Group className="mb-2">
//                     <motion.h1 style={{ textAlign: "center" }}>
//                       {arr[index]}
//                     </motion.h1>
//                   </Form.Group>
//                 </Form>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </Container>
//   );
// };

// export default DashBoard;

import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import axios from "axios";
import { useAuthHeaders } from "../../../components/Token";
import { API_BASE_URL } from "../../../utils/index";
import userContext from "../../../components/UseContext/UseContext";
import { useNavigate } from "react-router-dom";

const DashBoard = () => {
  const count1 = useMotionValue(0);
  const count2 = useMotionValue(0);
  const count3 = useMotionValue(0);
  const count4 = useMotionValue(0);
  const rounded1 = useTransform(count1, Math.round);
  const rounded2 = useTransform(count2, Math.round);
  const rounded3 = useTransform(count3, Math.round);
  const rounded4 = useTransform(count4, Math.round);

  const [cart, setCart] = useState(0);
  const [orders, setOrders] = useState(0);
  const [downloads, setDownloads] = useState(0);
  const [favorite, setFavorite] = useState(0);
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("userId");

  const headers = useAuthHeaders();

  const store = useContext(userContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoggedIn) {
          const userId = localStorage.getItem("userId");

          if (!userId) {
            navigate("/");
            return;
          }

          const headers = {
            Authorization: `Bearer ${userId}`,
            "Content-Type": "application/json",
          };

          // console.log("headers are come in user dashboard",userId)
          // console.log("headers are come in user dashboard 777777",headers)

          const response = await axios.get(
            `${API_BASE_URL}/user/order/getcart`,
            {
              // headers:headers1,
              headers: headers,
            }
          );
          const cartLength = response.data.cartItemLength;
          setCart(cartLength);
        }
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };
    fetchData();
  }, [isLoggedIn, headers]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (isLoggedIn) {
          const userId = localStorage.getItem("userId");
          if (!userId) {
            navigate("/");
            return;
          }

          const headers = {
            Authorization: `Bearer ${userId}`,
            "Content-Type": "application/json",
          };

          // console.log("headers are come in user dashboard",userId)
          // console.log("headers are come in user dashboard 777777",headers)
          const response = await axios.get(
            `${API_BASE_URL}/user/order/myOrder`,
            { headers: headers }
          );
          setOrders(response.data.totalOrder);
          setFavorite(store?.wishlist?.length);
        }
      } catch (err) {
        console.log("ERROR is tje", err);
      }
    };
    fetchOrders();
  }, [isLoggedIn, headers]);

  useEffect(() => {
    const animation1 = animate(count1, cart, { duration: 2 });
    const animation2 = animate(count2, orders, { duration: 2 });
    const animation3 = animate(count3, downloads, { duration: 2 });
    const animation4 = animate(count4, favorite, { duration: 2 });

    return () => {
      animation1.stop();
      animation2.stop();
      animation3.stop();
      animation4.stop();
    };
  }, [cart, orders, downloads, favorite]);

  const arr = [rounded1, rounded2, rounded3, rounded4];

  return (
    <Container fluid>
      <Row className="filter-section mb-3">
        <Col>
          <h4>Dashboard</h4>
        </Col>
      </Row>
      <Row>
        {["Cart", "Orders", "Inquiries", "Wishlist"].map((item, index) => (
          <Col md={3} sm={6} xs={12} key={index} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>{item}</Card.Title>
                <Form>
                  <Form.Group className="mb-2">
                    <motion.h1 style={{ textAlign: "center" }}>
                      {arr[index]}
                    </motion.h1>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default DashBoard;
