import React, { useContext, useEffect, useState } from "react";
import userContext from "../../components/UseContext/UseContext";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import { IoCaretBack } from "react-icons/io5";
import Lottie from "lottie-react";
import { Link, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import WishlistButton from "../Buttons/WishlistButton";
import ProductOveriew from "../ProductOveriew/ProductOveriew";
import AddCartButton from "../Buttons/AddCartButton";
import ProductImages from "../Products/ProductImages/ProductImages";
import NoProductFound from "../../Preloader/No product Found Animation.json";
import Preloader from "../../Preloader/Preloader-Animation.json";
import { Button } from "react-bootstrap";

const ViewSubCategorybydescriptions = ({
  sortArray,
  subcategoryName,
  slug,
}) => {
  const [subCategoriesName, setSubCategoriesName] = useState("");
  const [loading, setLoading] = useState(true);

  const [product, setProduct] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/user/product/getpartnumberbytype/${
            sortArray.includes("-") ? sortArray.replace(/-/g, " ") : sortArray
          }?subcategoryName=${subcategoryName.replace(/-/g, " ") || ""}`
        );
        // console.log("rwe ponse values in the sorted arrays",response?.data?.filteredPartnumbers)
        setProduct(response?.data?.filteredPartnumbers);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [sortArray, subcategoryName]);
  const format = (dateString) => {
    return dateString.replace(/%20/g, "-");
  };

  const replaceSpacesWithHyphens = (urlString) => {
    if (typeof urlString === "string") {
      // Replace all spaces with hyphens
      return urlString.replace(/ /g, "-").toLocaleLowerCase();
    } else return urlString;
  };

  return (
    <div className="row px-2r m-0 products-wrapper ">
      <div className="products-heading-container ">
        <h4 className="product-heading text-center">{sortArray}</h4>
        
          <Button
            className="view-all-products text-end"
            style={{ background: "#0443C0" }}
          >
            <Link
              to={format(
                `/${replaceSpacesWithHyphens(slug)}/${replaceSpacesWithHyphens(
                  subcategoryName
                )}/${replaceSpacesWithHyphens(sortArray)}`
              )}
            >
              <IoCaretBack className="fs-5" />
              View All
            </Link>
          </Button>
        
      </div>

      {loading ? (
        <Lottie
          style={{ width: "100vw", height: "50vh" }}
          animationData={Preloader}
        />
      ) : (
        <>
          {product?.length !== 0 ? (
            <div className="products m-0">
              {product.slice(0, 5).map((product, i) => (
                <div className="card" key={i}>
                  {/* <h1>{product?.descriptions[0]?.value}</h1> */}
                  <center>
                    <Card.Title className="px-2 text-dark card-title">
                      {/* <Link
                                       className="text-dark text-decoration-none"
                                       to={
                                         product?.descriptions[0]?.value
                                           ? `/${slug}/${replaceSpacesWithHyphens(
                                               subCategoriesName
                                             )}/${replaceSpacesWithHyphens(
                                               product?.descriptions[0]?.value
                                             )}`
                                           : `/product/${product?.slug}`
                                       }
                                     >
                                       {product?.partNumber}
                                     </Link> */}
                    </Card.Title>
                  </center>

                  <Link
                    to={
                      product?.descriptions[0]?.value
                        ? `/${replaceSpacesWithHyphens(slug)}/${replaceSpacesWithHyphens(
                            product?.subcategoryname?.name
                          )}/${replaceSpacesWithHyphens(
                            product?.descriptions[0]?.value
                          )}/${replaceSpacesWithHyphens(product?.slug)}`
                        : `/product/${product?.slug}`
                    }
                  >
                    <ProductImages productimages={product?.productimages} />
                  </Link>

                  <center className="cart-footer">
                    <Link
                      to={
                        product?.descriptions[0]?.value
                          ? `/${replaceSpacesWithHyphens(slug)}/${replaceSpacesWithHyphens(
                              product?.subcategoryname?.name
                            )}/${replaceSpacesWithHyphens(
                              product?.descriptions[0]?.value
                            )}/${product?.slug}`
                          : `/product/${product?.slug}`
                      }
                      className="card-price text-decoration-none"
                    >
                      <h6 className="m-0 current-price">
                        ₹ {product?.saleprice}
                      </h6>
                      <p className="m-0 old-price">₹ {product?.oldprice}</p>
                    </Link>

                    <AddCartButton
                      type="product_button"
                      className="card-add-cart-button"
                      data={product}
                    />
                  </center>

                  <div className="card-content text-danger">
                    <WishlistButton type="icon" data={product} />
                    <div className="add-to-card">
                      <ProductOveriew product={product} /> {/* Fixed typo */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Lottie
              style={{ width: "100vw", height: "50vh" }}
              animationData={NoProductFound}
            />
          )}
        </>
      )}
    </div>

    // <h1>data</h1>
  );
};

export default ViewSubCategorybydescriptions;
