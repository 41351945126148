// import React, { useContext, useEffect, useState } from "react";
// import AddCartProducts from "../../components/AddCartProducts/AddCartProducts";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils/index";
// import { useAuthHeaders } from "../../components/Token";
// import userContext from "../../components/UseContext/UseContext";
// import CartTotal from "../../components/AddCartProducts/CartTotal";

// function AddToCard() {
//   const isLoggedIn = localStorage.getItem("userId");
//   const headers = useAuthHeaders();
//   const storeDataApi = useContext(userContext);
//   const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
//   const [cartItems, setCartItems] = useState([]);

//   const guesrID = localStorage.getItem("guestId");

//   useEffect(() => {
//     if (isLoggedIn) {
      
//       axios
//         .get(`${API_BASE_URL}/user/order/getcart`, {
//           headers,
//         })
//         .then((res) => {
//           setCartItems(res.data);
//           console.log("user get cart", res.data.cartItemLength);
//           localStorage.setItem("addtocartLength", res.data.cartItemLength);
//           storeDataApi.setAddCartLength(res.data.cartItemLength);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });

//     } else {
//       axios
//         .get(`${API_BASE_URL}/order/getguestcart/${guesrID}`, {
//           headers,
//         })
//         .then((res) => {
//           setCartItems(res.data);
//           storeDataApi.setAddCartLength(res.data.cartLength);
//           localStorage.setItem("addtocartLength", res.data.cartLength);
//         })
//         .catch((error) => {
//           console.log("error", error);
//         });
//     }
//   }, [isLoggedIn, storeDataApi.cartResults, storeDataApi.addCartLength]);

//   // screen size
//   useEffect(() => {
//     const handleResize = () => {
//       setIsLargeScreen(window.innerWidth > 768);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);
//   // screen size

//   return (
//     <>
//       <div className="listProducts-banner">
//         <h2>Add To Cart</h2>
//       </div>
//       <div className="products">
//         <div className="listProducts">
//           <div className="listProducts-products px-2r row m-0">
//             <div className="col-lg-8 col-md-12" style={{ overflowX: "auto" }}>
//               <table className="table">
//                 {isLargeScreen ? (
//                   <thead>
//                     <tr>
//                       <th className="product-remove">#</th>
//                       <th className="product-thumbnail-title">First Name</th>
//                       <th>PRODUCT</th>
//                       <th>PRICE PER PICE</th>
//                       <th>BOQ</th>
//                       <th>QUANTITY</th>
//                       <th>SUBTOTAL</th>
//                     </tr>
//                   </thead>
//                 ) : null}
//                 <AddCartProducts products={cartItems?.products} />
//               </table>
//             </div>

//             <div className="col-lg-4 col-md-12">
//               <CartTotal products={cartItems?.cartProductsTotals} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default AddToCard;











//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------


// import React, { useContext, useEffect, useState } from "react";
// import AddCartProducts from "../../components/AddCartProducts/AddCartProducts";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils/index";
// import { useAuthHeaders } from "../../components/Token";
// import userContext from "../../components/UseContext/UseContext";
// import CartTotal from "../../components/AddCartProducts/CartTotal";
// import Lottie from "lottie-react";
// import Preloader from "../../Preloader/Preloader-Animation.json";

// function AddToCard() {
//   const isLoggedIn = localStorage.getItem("userId");
//   const headers = useAuthHeaders();
//   const storeDataApi = useContext(userContext);
//   const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
//   const [cartItems, setCartItems] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const guesrID = localStorage.getItem("guestId");

//   useEffect(() => {
//     if (isLoggedIn) {
      
//       axios
//         .get(`${API_BASE_URL}/user/order/getcart`, {
//           headers,
//         })
//         .then((res) => {
//           setLoading(!loading);
//           setCartItems(res.data);
//           console.log("user get cart", res.data.cartItemLength);
//           localStorage.setItem("addtocartLength", res.data.cartItemLength);
//           storeDataApi.setAddCartLength(res.data.cartItemLength);
//           setLoading(!loading);
//         })
//         .catch((error) => {
//           setLoading(!loading);
//           console.log("error", error);
//           setLoading(!loading);
//         });
//     } else {
     
//       axios
//         .get(`${API_BASE_URL}/order/getguestcart/${guesrID}`, {
//           headers,
//         })
//         .then((res) => {
//           setLoading(!loading);
//           setCartItems(res.data);
//           storeDataApi.setAddCartLength(res.data.cartLength);
//           localStorage.setItem("addtocartLength", res.data.cartLength);
//           setLoading(!loading);
//         })
//         .catch((error) => {
//           setLoading(!loading);
//           console.log("error", error);
//           setLoading(!loading);
//         });
//     }
//   }, [isLoggedIn, storeDataApi.cartResults, storeDataApi.addCartLength]);

//   // screen size
//   useEffect(() => {
//     const handleResize = () => {
//       setIsLargeScreen(window.innerWidth > 768);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);
//   // screen size

//   return (
//     <>
//       <div className="listProducts-banner">
//         <h2>Add To Cart</h2>
//       </div>
//       <div className="products">
//         <div className="listProducts">
          
//           {loading && (
//             <div className="listProducts-products px-2r row m-0">
//               <div className="col-lg-8 col-md-12" style={{ overflowX: "auto" }}>
//                 <table className="table">
//                   {isLargeScreen ? (
//                     <thead>
//                       <tr>
//                         <th className="product-remove">#</th>
//                         <th className="product-thumbnail-title">First Name</th>
//                         <th>PRODUCT</th>
//                         <th>PRICE PER PICE</th>
//                         <th>BOQ</th>
//                         <th>QUANTITY</th>
//                         <th>SUBTOTAL</th>
//                       </tr>
//                     </thead>
//                   ) : null}
//                   <AddCartProducts products={cartItems?.products} />
//                 </table>
//               </div>

//               <div className="col-lg-4 col-md-12">
//                 <CartTotal products={cartItems?.cartProductsTotals} />
//               </div>
//             </div>
//           )}
//           {!loading && (
//             <Lottie
//               style={{ width: "100vw", height: "50vh" }}
//               animationData={Preloader}
//             />
//           )}
//         </div>
//       </div>
//     </>
//   );
// }

// export default AddToCard;


//------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------


import React, { useContext, useEffect, useState } from "react";
import AddCartProducts from "../../components/AddCartProducts/AddCartProducts";
import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import { useAuthHeaders } from "../../components/Token";
import userContext from "../../components/UseContext/UseContext";
import CartTotal from "../../components/AddCartProducts/CartTotal";
import Lottie from "lottie-react";
import Preloader from "../../Preloader/Preloader-Animation.json";





function AddToCard() {
  const isLoggedIn = localStorage.getItem("userId");
  const headers = useAuthHeaders();
  const storeDataApi = useContext(userContext);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const guestID = localStorage.getItem("guestId");

  // console.log("get ")
  useEffect(() => {

    const fetchData = async () => {
      try {

        let response;
        if (isLoggedIn) {
          const userId=localStorage.getItem("userId")
          const headers1 = {
            Authorization: `Bearer ${userId}`,
            "Content-Type": "application/json",
          };
          response = await axios.get(`${API_BASE_URL}/user/order/getcart`, {
            headers:headers1,
          });
        } else {
          const guestID = localStorage.getItem("guestId");
          const headers = {
            Authorization: `Bearer ${guestID}`,
            "Content-Type": "application/json",
          };
          response = await axios.get(`${API_BASE_URL}/order/getguestcart/${guestID}`, {
            headers:headers,
          });
        }
        
        setCartItems(response.data);

    
        if(isLoggedIn){
          const cartLength =response.data.cartItemLength;
          localStorage.setItem("addtocartLength", cartLength);
          storeDataApi.setAddCartLength(cartLength);
        }
        // const cartLength = isLoggedIn ? response.data.cartItemLength : response.data.cartLength;
        // localStorage.setItem("addtocartLength", cartLength);
        // storeDataApi.setAddCartLength(cartLength);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isLoggedIn, storeDataApi.cartResults, storeDataApi.addCartLength, headers, guestID]);

  // screen size
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // screen size

  return (
    <>
      <div className="listProducts-banner">
        <h2>Add To Cart</h2>
      </div>
      <div className="products">
        <div className="listProducts">
          {loading ? (
            <Lottie
              style={{ width: "100vw", height: "50vh" }}
              animationData={Preloader}
            />
          ) : (
            <div className="listProducts-products px-2r row m-0">
              <div className="col-lg-8 col-md-12" style={{ overflowX: "auto" }}>
                <table className="table">
                  {isLargeScreen ? (
                    <thead>
                      <tr>
                        <th className="product-remove">#</th>
                        <th className="product-thumbnail-title">First Name</th>
                        <th>PRODUCT</th>
                        <th style={{width:"25%",textAlign:"center"}}>PRICE PER PIECE</th>
                        <th>BOQ</th>
                        <th>QUANTITY</th>
                        <th>SUBTOTAL</th>
                      </tr>
                    </thead>
                  ) : null}
                  <AddCartProducts products={cartItems?.products} />
                </table>
              </div>

              <div className="col-lg-4 col-md-12">
                <CartTotal products={cartItems?.cartProductsTotals} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AddToCard;
