import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../utils";
import userContext from "../UseContext/UseContext";
import Card from "react-bootstrap/Card";
import ProductImages from "../Products/ProductImages/ProductImages";
import AddCartButton from "../Buttons/AddCartButton";
import WishlistButton from "../Buttons/WishlistButton";
import ProductOveriew from "../ProductOveriew/ProductOveriew";

function SearchPageResults() {
  const { slug } = useParams();
  const [searchResults, setSearchResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [productShow, setProductShow] = useState(false);

  useEffect(() => {
    console.log("slug in search results...:", slug);

    axios
      // .get(`${API_BASE_URL}/user/poroduct/filterproduct/${slug}`)
      .get(`${API_BASE_URL}/user/products/usersearchproducts?keyword=${slug}`)

      .then((res) => {
        // console.log("result come in serach API on seacrh button")
        // setSearchResults(res?.data?.filteredProducts);
        setSearchResults(res?.data?.products);
        setProductShow(true);
      })
      .catch((error) => {
        console.log("error", error);
        setProductShow(false);
        setErrorMessage(error?.response?.data?.message || "An error occurred");
      });
  }, [slug, errorMessage]);


  const replaceSpacesWithHyphens = (urlString) => {

    if (typeof urlString === 'string') {
      // Replace all spaces with hyphens
      return urlString.replace(/ /g, '-').toLocaleLowerCase();
    }else return urlString;
}


  return (
    <div className="results-products-wrapper products-wrapper my-4">
      {productShow === true ? (
        <div className="products m-0">
          {searchResults?.map((product, i) => {
            return (
              <div className="card" key={i}>
                <center>
                  <Card.Title className="px-2 text-dark card-title">
                    <Link
                      className="text-dark text-decoration-none"
                      // to={`/part-number-page/${
                      to={product?.descriptions[0]?.value !== undefined ?`/${replaceSpacesWithHyphens(searchResults[0]?.category?.name)}/${replaceSpacesWithHyphens(searchResults[0]?.subcategoryname?.name)}/${
                        replaceSpacesWithHyphens(product?.descriptions &&
                        product?.descriptions[0] &&
                        product?.descriptions[0]?.value)
                      }`:`/product/${product?.slug}`}
                    >
                      {product?.partNumber}
                    </Link>
                  </Card.Title>
                </center>

                <Link
                  // to={`/part-number-page/${
                    to={product?.descriptions[0]?.value !== undefined ?`/${replaceSpacesWithHyphens(searchResults[0]?.category?.name)}/${replaceSpacesWithHyphens(searchResults[0]?.subcategoryname?.name)}/${
                      replaceSpacesWithHyphens(product?.descriptions &&
                    product?.descriptions[0] &&
                    product?.descriptions[0]?.value)
                  }`:`/product/${product?.slug}`}
                >
                  <ProductImages productimages={product?.productimages} />
                </Link>

                <center className="cart-footer">
                  <Link
                    // to={`/part-number-page/${
                      to={product?.descriptions[0]?.value !== undefined ? `/${replaceSpacesWithHyphens(searchResults[0]?.category?.name)}/${replaceSpacesWithHyphens(searchResults[0]?.subcategoryname?.name)}/${
                        replaceSpacesWithHyphens(product?.descriptions &&
                      product?.descriptions[0] &&
                      product?.descriptions[0]?.value)
                    }`:`/product/${product?.slug}` }
                    className="card-price text-decoration-none"
                  >
                    <h6 className="m-0 current-price">₹ {product?.saleprice}</h6>
                    <p className="m-0 old-price">₹ {product?.oldprice}</p>
                  </Link>

                  <AddCartButton
                    type="product_button"
                    className="card-add-cart-button"
                    data={product}
                  />
                </center>

                <div className="card-content text-danger">
                  <WishlistButton type="icon" data={product} />
                  <div className="add-to-card">
                    <ProductOveriew product={product} />
                  </div>
                  {/* <AddCartButton
                    type="icon"
                    className="bg-primary"
                    data={product}
                  /> */}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <h6 className="text-danger m-4">{errorMessage}</h6>
      )}
    </div>
  );
}

export default SearchPageResults;